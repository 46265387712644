import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { ResourceBrowser } from '../ResourceBrowser/ResourceBrowser';
import { ViewType } from '../ResourceBrowser/ViewType';
import { doShowResourceBrowserModal } from '../../redux/actions';
import { EoleEditStateEntry } from '../../redux/types';
import './ResourceBrowserModal.scss';

export const ResourceBrowserModal: FC = () => {
  const isResourceBrowserModalShown = useSelector((state: EoleEditStateEntry) => state.ee.isResourceBrowserModalShown);
  const dispatch = useDispatch();
  const handleModalHide = useCallback(() => dispatch(doShowResourceBrowserModal(false)), [dispatch]);

  return (
    <Modal
      className="resource-browser-modal"
      show={isResourceBrowserModalShown}
      onHide={handleModalHide}
      size="lg"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Resources Browser</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-hidden">
        <ResourceBrowser defaultViewType={ViewType.list} canDownload canRemove canSelect />
      </Modal.Body>
    </Modal>
  );
};
