"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Iso639v3 = void 0;
/**
 * List of ISO 639-3 language codes.
 *
 * @remarks [Full list](https://iso639-3.sil.org/code_tables/639/data)
 *
 * @public
 */
var Iso639v3;
(function (Iso639v3) {
  Iso639v3["afr"] = "afr";
  Iso639v3["aka"] = "aka";
  Iso639v3["amh"] = "amh";
  Iso639v3["apc"] = "apc";
  Iso639v3["ara"] = "ara";
  Iso639v3["asm"] = "asm";
  Iso639v3["aze"] = "aze";
  Iso639v3["bak"] = "bak";
  Iso639v3["bam"] = "bam";
  Iso639v3["bel"] = "bel";
  Iso639v3["ben"] = "ben";
  Iso639v3["bho"] = "bho";
  Iso639v3["bih"] = "bih";
  Iso639v3["bos"] = "bos";
  Iso639v3["bod"] = "bod";
  Iso639v3["bul"] = "bul";
  Iso639v3["cat"] = "cat";
  Iso639v3["ceb"] = "ceb";
  Iso639v3["chr"] = "chr";
  Iso639v3["cmn"] = "cmn";
  Iso639v3["cos"] = "cos";
  Iso639v3["cym"] = "cym";
  Iso639v3["cze"] = "cze";
  Iso639v3["dan"] = "dan";
  Iso639v3["deu"] = "deu";
  Iso639v3["dut"] = "dut";
  Iso639v3["div"] = "div";
  Iso639v3["dog"] = "dog";
  Iso639v3["ekl"] = "ekl";
  Iso639v3["eng"] = "eng";
  Iso639v3["epo"] = "epo";
  Iso639v3["est"] = "est";
  Iso639v3["eus"] = "eus";
  Iso639v3["ewe"] = "ewe";
  Iso639v3["fas"] = "fas";
  Iso639v3["fao"] = "fao";
  Iso639v3["fij"] = "fij";
  Iso639v3["fil"] = "fil";
  Iso639v3["fin"] = "fin";
  Iso639v3["fra"] = "fra";
  Iso639v3["frc"] = "frc";
  Iso639v3["fry"] = "fry";
  Iso639v3["gla"] = "gla";
  Iso639v3["gle"] = "gle";
  Iso639v3["glg"] = "glg";
  Iso639v3["gua"] = "gua";
  Iso639v3["goa"] = "goa";
  Iso639v3["gre"] = "gre";
  Iso639v3["guj"] = "guj";
  Iso639v3["hat"] = "hat";
  Iso639v3["hau"] = "hau";
  Iso639v3["haw"] = "haw";
  Iso639v3["heb"] = "heb";
  Iso639v3["hin"] = "hin";
  Iso639v3["hmn"] = "hmn";
  Iso639v3["hrv"] = "hrv";
  Iso639v3["hsb"] = "hsb";
  Iso639v3["hun"] = "hun";
  Iso639v3["hye"] = "hye";
  Iso639v3["ibo"] = "ibo";
  Iso639v3["iku"] = "iku";
  Iso639v3["ikt"] = "ikt";
  Iso639v3["ind"] = "ind";
  Iso639v3["ilo"] = "ilo";
  Iso639v3["isl"] = "isl";
  Iso639v3["ita"] = "ita";
  Iso639v3["jav"] = "jav";
  Iso639v3["jpn"] = "jpn";
  Iso639v3["kan"] = "kan";
  Iso639v3["kat"] = "kat";
  Iso639v3["kaz"] = "kaz";
  Iso639v3["khm"] = "khm";
  Iso639v3["kin"] = "kin";
  Iso639v3["kir"] = "kir";
  Iso639v3["kor"] = "kor";
  Iso639v3["kur"] = "kur";
  Iso639v3["kri"] = "kri";
  Iso639v3["lao"] = "lao";
  Iso639v3["lat"] = "lat";
  Iso639v3["lav"] = "lav";
  Iso639v3["lin"] = "lin";
  Iso639v3["lif"] = "lif";
  Iso639v3["lit"] = "lit";
  Iso639v3["ltz"] = "ltz";
  Iso639v3["lug"] = "lug";
  Iso639v3["mal"] = "mal";
  Iso639v3["mar"] = "mar";
  Iso639v3["mai"] = "mai";
  Iso639v3["mkd"] = "mkd";
  Iso639v3["mlg"] = "mlg";
  Iso639v3["mlt"] = "mlt";
  Iso639v3["mon"] = "mon";
  Iso639v3["mri"] = "mri";
  Iso639v3["msa"] = "msa";
  Iso639v3["mww"] = "mww";
  Iso639v3["mya"] = "mya";
  Iso639v3["nep"] = "nep";
  Iso639v3["nor"] = "nor";
  Iso639v3["nya"] = "nya";
  Iso639v3["ori"] = "ori";
  Iso639v3["oss"] = "oss";
  Iso639v3["otq"] = "otq";
  Iso639v3["oro"] = "oro";
  Iso639v3["pan"] = "pan";
  Iso639v3["per"] = "per";
  Iso639v3["pol"] = "pol";
  Iso639v3["prs"] = "prs";
  Iso639v3["ptb"] = "ptb";
  Iso639v3["ptp"] = "ptp";
  Iso639v3["pus"] = "pus";
  Iso639v3["que"] = "que";
  Iso639v3["ril"] = "ril";
  Iso639v3["rom"] = "rom";
  Iso639v3["ron"] = "ron";
  Iso639v3["rus"] = "rus";
  Iso639v3["san"] = "san";
  Iso639v3["sin"] = "sin";
  Iso639v3["slo"] = "slo";
  Iso639v3["slv"] = "slv";
  Iso639v3["smo"] = "smo";
  Iso639v3["sna"] = "sna";
  Iso639v3["snd"] = "snd";
  Iso639v3["som"] = "som";
  Iso639v3["sot"] = "sot";
  Iso639v3["spa"] = "spa";
  Iso639v3["sqi"] = "sqi";
  Iso639v3["srCyrl"] = "srCyrl";
  Iso639v3["srp"] = "srp";
  Iso639v3["sun"] = "sun";
  Iso639v3["swa"] = "swa";
  Iso639v3["swe"] = "swe";
  Iso639v3["swh"] = "swh";
  Iso639v3["syc"] = "syc";
  Iso639v3["tat"] = "tat";
  Iso639v3["tah"] = "tah";
  Iso639v3["tam"] = "tam";
  Iso639v3["tel"] = "tel";
  Iso639v3["tgk"] = "tgk";
  Iso639v3["tgl"] = "tgl";
  Iso639v3["tha"] = "tha";
  Iso639v3["tir"] = "tir";
  Iso639v3["tlh"] = "tlh";
  Iso639v3["tlhQaak"] = "tlhQaak";
  Iso639v3["ton"] = "ton";
  Iso639v3["tso"] = "tso";
  Iso639v3["tuk"] = "tuk";
  Iso639v3["tur"] = "tur";
  Iso639v3["uig"] = "uig";
  Iso639v3["ukr"] = "ukr";
  Iso639v3["urd"] = "urd";
  Iso639v3["uzb"] = "uzb";
  Iso639v3["vie"] = "vie";
  Iso639v3["xho"] = "xho";
  Iso639v3["yid"] = "yid";
  Iso639v3["yor"] = "yor";
  Iso639v3["yua"] = "yua";
  Iso639v3["yue"] = "yue";
  Iso639v3["zhHans"] = "zhHans";
  Iso639v3["zhHant"] = "zhHant";
  Iso639v3["zul"] = "zul"; // Zulu
})(Iso639v3 = exports.Iso639v3 || (exports.Iso639v3 = {}));