import React, { FC } from 'react';
import ImageBackground from '../ImageBackground';

import './UnauthorizedPage.scss';
import { HomeLogo } from '../HomePage/HomeLogo';
import { getEoleEditRight } from '../../utils/Userprofile';
import { getEolementheWebUrl } from '../../utils/ServerUtils';

export const UnauthorizedPage: FC = () => {
  const isRightExpired = getEoleEditRight()?.expiry.getTime() < new Date().getTime();
  return (
    <div className="unauthorized-page">
      <ImageBackground />
      <div className="d-flex flex-column justify-content-center align-items-center pt-5">
        <HomeLogo />
        <div>Your account does not have right to access Eole-Edit {isRightExpired && <span> anymore</span>} </div>
        <>
          {isRightExpired && (
            <div>
              You still have access to your projects, but you cannot upload or download resources nor build projects
            </div>
          )}
        </>
        <div className="font-weight-bold mt-2">
          Please upgrade your account{' '}
          <a href={`${getEolementheWebUrl()}/cart?origin=${encodeURIComponent(document.location.toString())}`}>here</a>.
        </div>
      </div>
    </div>
  );
};
