import React from 'react';
import { QueryRenderer as RelayQueryRenderer } from 'react-relay';
import { OperationType, GraphQLTaggedNode } from 'relay-runtime';
import { RELAY_ENVIRONMENT } from './relay-environment';

export function getGraphQLError(error: any): string {
  const errors = error?.source?.errors ?? error?.res?.errors;
  const strError = JSON.stringify(error);
  console.error(strError);
  if (Array.isArray(errors)) {
    const message = errors[0]?.extensions?.exception?.response?.message[0] ?? errors[0]?.message;
    return message ?? strError;
  }
  return strError;
}

export interface IQueryRendererProps<TOperation extends OperationType> {
  query: GraphQLTaggedNode;
  variables?: TOperation['variables'];
  onError?: (error: Error) => React.ReactNode;
  render: (props: TOperation['response'] | null) => React.ReactNode;
}

function renderError(error: any) {
  return <div>{getGraphQLError(error)}</div>;
}

function renderChildren<TOperation extends OperationType>(
  queryProps: IQueryRendererProps<TOperation>,
  renderProps: {
    error: Error | null;
    props: TOperation['response'] | null;
    retry: (() => void) | null;
  }
): React.ReactNode {
  const { error, props } = renderProps;
  if (error && queryProps.onError) {
    return queryProps.onError(error);
  }
  return (
    <div className="App d-flex flex-column">
      {(error && renderError(error)) || (!props && <div>Loading...</div>) || queryProps.render(props)}
    </div>
  );
}

export const QueryRenderer = <TOperation extends OperationType>(props: IQueryRendererProps<TOperation>) => {
  return (
    // @ts-ignore
    <RelayQueryRenderer<TOperation>
      environment={RELAY_ENVIRONMENT}
      query={props.query}
      variables={props.variables ?? {}}
      render={(renderProps) => renderChildren(props, renderProps)}
    />
  );
};
