import React, { FC, useState, useEffect } from 'react';

import './ImageBackground.scss';

const imageUrls = [
  '/ressources/welcome-bg-1.webp',
  '/ressources/welcome-bg-2.webp',
  '/ressources/welcome-bg-3.webp',
  '/ressources/welcome-bg-4.webp'
];
const initialIndex = Math.floor(Math.random() * Math.floor(imageUrls.length));

const ImageBackground: FC = () => {
  const [imageIndex, setImageIndex] = useState(initialIndex);

  useEffect(() => {
    const picturePickerInterval = setInterval(() => {
      setImageIndex((prevImageIndex) => (prevImageIndex + 1 >= imageUrls.length ? 0 : prevImageIndex + 1)); //use callback version due to closure memory
    }, 8000);

    return () => {
      clearInterval(picturePickerInterval);
    };
  }, []);

  const url = imageUrls[imageIndex];
  return <div className="background-screen" style={{ background: `url("${url}")` }} />;
};

export default ImageBackground;
