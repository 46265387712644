import { initAllMedia, changeVolumeAllMedia, setPlaybackRateAllMedia, stopAllMedia, togglePlayPauseAllMedia } from '../media/mediaTrackManager';
import ACTION_TYPES from './actionTypes';
export function doInit(tracks, streamInfo) {
  if (tracks.length) {
    initAllMedia(streamInfo === null || streamInfo === void 0 ? void 0 : streamInfo.framerate, streamInfo === null || streamInfo === void 0 ? void 0 : streamInfo.duration);
  }
  return {
    type: ACTION_TYPES.INIT,
    payload: {
      tracks: tracks,
      streamInfo: streamInfo
    }
  };
}
export function doSetIsPlaying(isPlaying) {
  return {
    type: ACTION_TYPES.SET_IS_PLAYING,
    payload: {
      isPlaying: isPlaying
    }
  };
}
export function doStopPlaying() {
  stopAllMedia();
  return {
    type: ACTION_TYPES.NOOP
  };
}
export function doSetCurrentTime(currentTime) {
  return {
    type: ACTION_TYPES.SET_CURRENT_TIME,
    payload: {
      currentTime: currentTime
    }
  };
}
export function doSetTrackCanPlay(trackIndex, canPlay) {
  return {
    type: ACTION_TYPES.SET_TRACK_CAN_PLAY,
    payload: {
      trackIndex: trackIndex,
      canPlay: canPlay
    }
  };
}
export function doSetSelectedTrack(trackIndex) {
  return {
    type: ACTION_TYPES.SET_SELECTED_TRACK,
    payload: {
      trackIndex: trackIndex
    }
  };
}
export function doChangeVolume(volume) {
  changeVolumeAllMedia(volume);
  return {
    type: ACTION_TYPES.NOOP
  };
}
export function doSetVolume(volume) {
  return {
    type: ACTION_TYPES.SET_VOLUME,
    payload: {
      volume: volume
    }
  };
}
export function doMuteTrack(isMuted, trackIndex) {
  return {
    type: ACTION_TYPES.MUTE_TRACK,
    payload: {
      trackIndex: trackIndex,
      isMuted: isMuted
    }
  };
}
export function doSetPlaybackRate(playbackRate) {
  setPlaybackRateAllMedia(playbackRate);
  return {
    type: ACTION_TYPES.SET_PLAYBACK_RATE,
    payload: {
      playbackRate: playbackRate
    }
  };
}
export function doChangeTrackOutput(connectOutput, trackIndex, channelIndexes) {
  return {
    type: ACTION_TYPES.CHANGE_TRACK_OUTPUT,
    payload: {
      connectOutput: connectOutput,
      trackIndex: trackIndex,
      channelIndexes: channelIndexes
    }
  };
}
export function doSetTrackChannels(trackIndex, channels) {
  return {
    type: ACTION_TYPES.SET_TRACK_CHANNELS,
    payload: {
      trackIndex: trackIndex,
      channels: channels
    }
  };
}
export function doToggleOffsetDisplay() {
  return {
    type: ACTION_TYPES.TOGGLE_OFFSET_DISPLAY
  };
}
export function doSetOffset(offset) {
  return {
    type: ACTION_TYPES.SET_OFFSET,
    payload: {
      offset: offset
    }
  };
}
export function doSetSectionDuration(sectionIndex, duration) {
  return {
    type: ACTION_TYPES.SET_SECTION_DURATION,
    payload: {
      sectionIndex: sectionIndex,
      duration: duration
    }
  };
}
export function doSetSectionStart(trackIndex, sectionIndex, start, targetTrackIndex) {
  return {
    type: ACTION_TYPES.SET_SECTION_START,
    payload: {
      trackIndex: trackIndex,
      sectionIndex: sectionIndex,
      start: start,
      targetTrackIndex: targetTrackIndex
    }
  };
}
export function doSetSectionEnd(sectionIndex, end) {
  return {
    type: ACTION_TYPES.SET_SECTION_END,
    payload: {
      sectionIndex: sectionIndex,
      end: end
    }
  };
}
export function doSetSectionColor(sectionIndex, color) {
  return {
    type: ACTION_TYPES.SET_SECTION_COLOR,
    payload: {
      sectionIndex: sectionIndex,
      color: color
    }
  };
}
export function doSetSectionNotMovable(sectionIndex, notMovable) {
  return {
    type: ACTION_TYPES.SET_SECTION_NOT_MOVABLE,
    payload: {
      sectionIndex: sectionIndex,
      notMovable: notMovable
    }
  };
}
export function doSetSectionNotResizable(sectionIndex, notResizable) {
  return {
    type: ACTION_TYPES.SET_SECTION_NOT_RESIZABLE,
    payload: {
      sectionIndex: sectionIndex,
      notResizable: notResizable
    }
  };
}
export function doAddSection(start, duration, color, notMovable, notResizable) {
  return {
    type: ACTION_TYPES.ADD_SECTION,
    payload: {
      start: start,
      duration: duration,
      color: color,
      notMovable: notMovable,
      notResizable: notResizable
    }
  };
}
export function doRemoveSection(sectionIndex) {
  return {
    type: ACTION_TYPES.REMOVE_SECTION,
    payload: {
      sectionIndex: sectionIndex
    }
  };
}
export function doAddMarker(timeSec, title, tag, color, notMovable, priority) {
  return {
    type: ACTION_TYPES.ADD_MARKER,
    payload: {
      timeSec: timeSec,
      title: title,
      tag: tag,
      color: color,
      notMovable: notMovable,
      priority: priority
    }
  };
}
export function doRemoveMarker(markerIndex) {
  return {
    type: ACTION_TYPES.REMOVE_MARKER,
    payload: {
      markerIndex: markerIndex
    }
  };
}
export function doRemoveMarkers(title, tag) {
  return {
    type: ACTION_TYPES.REMOVE_MARKERS,
    payload: {
      title: title,
      tag: tag
    }
  };
}
export function doSetMarkerColor(markerIndex, color) {
  return {
    type: ACTION_TYPES.SET_MARKER_COLOR,
    payload: {
      markerIndex: markerIndex,
      color: color
    }
  };
}
export function doSetMarkerTitle(markerIndex, title) {
  return {
    type: ACTION_TYPES.SET_MARKER_TITLE,
    payload: {
      markerIndex: markerIndex,
      title: title
    }
  };
}
export function doSetMarkerTag(markerIndex, tag) {
  return {
    type: ACTION_TYPES.SET_MARKER_TAG,
    payload: {
      markerIndex: markerIndex,
      tag: tag
    }
  };
}
export function doSetMarkerNotMovable(markerIndex, notMovable) {
  return {
    type: ACTION_TYPES.SET_MARKER_NOT_MOVABLE,
    payload: {
      markerIndex: markerIndex,
      notMovable: notMovable
    }
  };
}
export function doSetMarkerPriority(markerIndex, priority) {
  return {
    type: ACTION_TYPES.SET_MARKER_PRIORITY,
    payload: {
      markerIndex: markerIndex,
      priority: priority
    }
  };
}
export function doSetMarkerTime(markerIndex, timeSec) {
  return {
    type: ACTION_TYPES.SET_MARKER_TIME,
    payload: {
      markerIndex: markerIndex,
      timeSec: timeSec
    }
  };
}
export function doSetSnapshotCallback(snapshotCallback) {
  return {
    type: ACTION_TYPES.SET_SNAPSHOT_CALLBACK,
    payload: {
      snapshotCallback: snapshotCallback
    }
  };
}
export function doTogglePlayPause(forcePlayPause) {
  togglePlayPauseAllMedia(forcePlayPause);
  return {
    type: ACTION_TYPES.NOOP
  };
}
export function doToggleIsBuffering(isBuffering) {
  return {
    type: ACTION_TYPES.SET_IS_BUFFERING,
    payload: {
      isBuffering: isBuffering
    }
  };
}
export function doPushMediaStream(mediaStream) {
  return {
    type: ACTION_TYPES.PUSH_MEDIA_STREAM,
    payload: {
      mediaStream: mediaStream
    }
  };
}