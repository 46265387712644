import { graphql } from 'relay-runtime';

export const GET_CURRENT_USER_QUERY = graphql`
  query GraphQLGetCurrentUserQuery {
    getCurrentUser {
      _id
      email
    }
  }
`;

export const GET_CURRENT_USER_INFOS_QUERY = graphql`
  query GraphQLGetCurrentUserInfosQuery {
    getCurrentUser {
      _id
      email
      firstName
      lastName
      company
      phone
      jobTitle
    }
  }
`;

export const GET_CURRENT_USERPROFILE_QUERY = graphql`
  query GraphQLGetCurrentUserProfileQuery {
    getCurrentUserProfile {
      _id
      owner
      eoleEditRight
      eoleEditExpiry
      eoleEditStorageUsed
      eoleEditStorageLimit
      eoleEditTimeUsed
      eoleEditTimeLimit
      eoleEditHasActiveSubscription
      type
      purge
      apiKey
    }
  }
`;

export const CREATE_USER_MUTATION = graphql`
  mutation GraphQLCreateUserMutation(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $company: String
    $jobTitle: String
    $phone: String
  ) {
    createUser(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      company: $company
      jobTitle: $jobTitle
      phone: $phone
    ) {
      _id
    }
  }
`;

export const SEND_PASSWORD_RESET_EMAIL_MUTATION = graphql`
  mutation GraphQLSendPasswordResetEmailMutation($email: String!) {
    sendPasswordResetEmail(email: $email)
  }
`;

export const VERIFY_EMAIL_MUTATION = graphql`
  mutation GraphQLVerifyEmailMutation($tokenId: String!) {
    verifyEmail(tokenId: $tokenId)
  }
`;

export const GET_FILES_QUERY = graphql`
  query GraphQLGetFilesQuery($mime: String) {
    getFiles(mime: $mime) {
      _id
      type
      mime
      name
      size
      duration
      width
      height
      src
      lowres
      ingestInEolementhe
      preview
    }
  }
`;

export const GET_FILE_QUERY = graphql`
  query GraphQLGetFileQuery($id: ID!) {
    getFile(id: $id) {
      _id
      type
      mime
      name
      size
      duration
      width
      height
      src
      lowres
      preview
    }
  }
`;

export const GET_LAST_BUILD_OF_PROJECT = graphql`
  query GraphQLGetLastBuildOfProjectQuery($projectId: String!) {
    getLastBuildOfProject(projectId: $projectId) {
      _id
      type
      mime
      name
      size
      duration
      width
      height
      src
      lowres
      preview
    }
  }
`;

export const REMOVE_FILE_MUTATION = graphql`
  mutation GraphQLRemoveFileMutation($id: ID!) {
    removeFile(id: $id)
  }
`;

export const BUILD_VIDEO_MUTATION = graphql`
  mutation GraphQLBuildVideoMutation(
    $buildId: String!
    $projectId: String!
    $title: String!
    $videoBitrate: Float!
    $framerate: Float!
  ) {
    buildVideo(
      buildId: $buildId
      projectId: $projectId
      title: $title
      videoBitrate: $videoBitrate
      framerate: $framerate
    ) {
      _id
    }
  }
`;

export const GET_BUILD_VIDEO_PROGRESS = graphql`
  query GraphQLGetBuildVideoQuery($buildId: String!) {
    getBuildProgress(buildId: $buildId) {
      taskProgress {
        title
        progress
      }
      stepProgress {
        currentStep
        progress
      }
    }
  }
`;

export const SAVE_PROJECT_MUTATION = graphql`
  mutation GraphQLSaveProjectMutation(
    $duration: Float!
    $tracks: [TrackInput]!
    $title: String!
    $isSameVideoSizeProject: String!
    $videoBitrate: Float!
    $size: SizeInput!
  ) {
    saveProject(
      duration: $duration
      tracks: $tracks
      title: $title
      isSameVideoSizeProject: $isSameVideoSizeProject
      videoBitrate: $videoBitrate
      size: $size
    ) {
      _id
      lastUpdate
    }
  }
`;

export const EDIT_PROJECT_MUTATION = graphql`
  mutation GraphQLEditProjectMutation($_id: ID, $title: String!, $videoBitrate: Float!) {
    editProject(_id: $_id, title: $title, videoBitrate: $videoBitrate) {
      _id
      lastUpdate
    }
  }
`;

export const SAVE_TEXT_PRESET_MUTATION = graphql`
  mutation GraphQLSaveTextPresetMutation(
    $name: String!
    $text: String!
    $font: String!
    $fontColor: String!
    $fontSize: Float!
    $fontStyle: String!
    $backgroundColor: String!
    $borderColor: String!
    $borderSize: Float!
    $rotation: Float!
  ) {
    saveTextPreset(
      name: $name
      text: $text
      font: $font
      fontColor: $fontColor
      fontSize: $fontSize
      fontStyle: $fontStyle
      backgroundColor: $backgroundColor
      borderColor: $borderColor
      borderSize: $borderSize
      rotation: $rotation
    ) {
      _id
      name
    }
  }
`;

export const REMOVE_PROJECT_MUTATION = graphql`
  mutation GraphQLRemoveProjectMutation($id: ID!) {
    removeProject(id: $id)
  }
`;

export const TRIM_MEDIA_MUTATION = graphql`
  mutation GraphQLTrimMediaMutation($srcId: String!, $owner: String!, $startTime: Float!, $endTime: Float!) {
    trimMedia(srcId: $srcId, owner: $owner, startTime: $startTime, endTime: $endTime)
  }
`;

export const GET_PROJECTS_QUERY = graphql`
  query GraphQLGetProjectsQuery {
    getProjects {
      _id
      owner
      duration
      title
      isSameVideoSizeProject
      videoBitrate
      tracks {
        id
        kind
        name
        muted
        visible
        width
        height
        scale
        sections {
          id
          start
          duration
          kind
          preview
          src
          srcId
          lowres
          volume
          startMedia
          endMedia
          text
          displayText
          fontSize
          font
          width
          height
          opacity
          cropX
          cropY
          cropWidth
          cropHeight
          initialHeight
          initialWidth
          x
          y
          rotation
          borderSize
          fontColor
          fontStyle
          backgroundColor
          borderColor
          effect {
            type
            durationSec
          }
          offset
          originMediaDuration
          trimmedSrc
          size
          amplitudeDegree
        }
      }
      size {
        height
        width
      }
      lastUpdate
    }
  }
`;
export const GET_PROJECT_QUERY = graphql`
  query GraphQLGetProjectQuery($projectId: String!) {
    getProject(projectId: $projectId) {
      _id
      owner
      duration
      title
      isSameVideoSizeProject
      videoBitrate
      tracks {
        id
        kind
        name
        muted
        visible
        width
        height
        scale
        sections {
          id
          start
          duration
          kind
          preview
          src
          srcId
          lowres
          volume
          startMedia
          endMedia
          text
          displayText
          fontSize
          font
          width
          height
          opacity
          cropX
          cropY
          cropWidth
          cropHeight
          initialHeight
          initialWidth
          x
          y
          rotation
          borderSize
          fontColor
          fontStyle
          backgroundColor
          borderColor
          effect {
            type
            durationSec
          }
          offset
          originMediaDuration
          trimmedSrc
          size
          amplitudeDegree
        }
      }
      size {
        height
        width
      }
      lastUpdate
    }
  }
`;

export const GET_TEXT_PRESETS_QUERY = graphql`
  query GraphQLGetTextPresetsQuery {
    getTextPresets {
      _id
      owner
      name
      text
      font
      fontColor
      fontSize
      fontStyle
      backgroundColor
      borderColor
      borderSize
      rotation
    }
  }
`;
