import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFileAudio, faFileImage, faFileVideo, faFolder } from '@fortawesome/free-solid-svg-icons';
import { Minimatch } from 'minimatch';
import prettyBytes from 'pretty-bytes';

import {
  ResourceType,
  IProject,
  FileResource,
  IAudioSection,
  IImageSection,
  IVideoSection,
  TrackSection,
  Track,
  SectionKind
} from '@eolementhe/video-editor-model';

import { ResourceFilter } from './IResourceFilter';
import { IResourceInfo } from './IResourceInfo';
import { ILinkedProject } from './IDirectoryViewProps';

type ResourceSection = IImageSection | IVideoSection | IAudioSection;

export class ResourceUtils {
  public static sortResources(resources: FileResource[]): FileResource[] {
    return resources.sort((resA, resB) => (resA.name >= resB.name ? 1 : -1));
  }

  public static resourceMatches(resource: FileResource, filters?: ResourceFilter[], canBrowse?: boolean) {
    return (
      canBrowse ||
      filters === undefined ||
      filters.length === 0 ||
      filters.some((filter) => ResourceUtils.resourceMatchesFilterProp(resource, filter))
    );
  }

  public static resourceMatchesFilterProp(resource: FileResource, prop: ResourceFilter) {
    switch (prop.type) {
      case 'FileType':
        return (
          resource.resourceKind === ResourceType.image ||
          resource.resourceKind === ResourceType.video ||
          resource.resourceKind === ResourceType.audio
        );
      case 'string':
        return this.resourceMatchesGlob(resource, prop.value);
      default:
        throw new Error(`Unsupported filter type.`);
    }
  }

  public static resourceMatchesGlob(resource: FileResource, filter: string) {
    const trimmedFilter = filter.trim();
    if (trimmedFilter.length === 0) return true;
    const matcherOptions = {
      dot: true,
      nocase: true,
      nocomment: true
    };
    const matcher = new Minimatch(trimmedFilter, matcherOptions);
    return matcher.match(resource.name);
  }

  public static getResourceInfo(resource: FileResource): IResourceInfo {
    switch (resource.resourceKind) {
      // case ResourceType.directory:
      //   return ResourceUtils.getDirectoryInfo(resource as IDirectoryResource);
      case ResourceType.image:
      case ResourceType.audio:
      case ResourceType.video:
        return ResourceUtils.getFileInfo(resource as FileResource);
      default:
        throw new Error(`Unsupported resource type`);
    }
  }

  public static getDirectoryInfo(dir: FileResource): IResourceInfo {
    return {
      type: ResourceType.directory,
      icon: faFolder,
      size: 'N/A',
      resource: dir
    };
  }

  public static getFileInfo(file: FileResource): IResourceInfo {
    return {
      type: file.resourceKind,
      icon: ResourceUtils.getFileIcon(file.resourceKind),
      size: file.size === undefined ? 'N/A' : prettyBytes(file.size),
      resource: file
    };
  }

  public static getFileIcon(fileType: ResourceType): IconProp {
    switch (fileType) {
      case ResourceType.audio:
        return faFileAudio;
      case ResourceType.image:
        return faFileImage;
      case ResourceType.video:
        return faFileVideo;
      default:
        throw new Error(`Unsupported file type ${fileType}`);
    }
  }

  public static getLinkedProjects(resource: FileResource, projects: IProject[]): ILinkedProject[] {
    const linkedProjects: ILinkedProject[] = [];
    projects.forEach((project) => {
      project.tracks.forEach((track: Track) => {
        track.sections.forEach((section: TrackSection) => {
          if ((section as ResourceSection).src === resource.src && project._id) {
            linkedProjects.push({ _id: project._id, title: project.title });
          }
        });
      });
    });
    return linkedProjects;
  }

  public static getResourceTypeOfSection(kind: SectionKind) {
    switch (kind) {
      case SectionKind.audio:
        return ResourceType.audio;
      case SectionKind.image:
        return ResourceType.image;
      case SectionKind.video:
        return ResourceType.video;
      case SectionKind.waveform:
        return ResourceType.waveform;
      case SectionKind.text:
        return ResourceType.text;
      case SectionKind.videoTransition:
        return ResourceType.videoTransition;
    }
  }
}
