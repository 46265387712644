import React, { Fragment } from 'react';
import { Dropdown, SplitButton } from 'react-bootstrap';
var DurationDropdown = function DurationDropdown(_a) {
  var duration = _a.duration,
    steps = _a.steps,
    action = _a.action,
    updateDuration = _a.updateDuration;
  var handleChangeDuration = function handleChangeDuration(value, action) {
    return function () {
      return updateDuration(value, action);
    };
  };
  var sign = action === 'add' ? '+' : '-';
  return React.createElement(SplitButton, {
    id: "dropdown-split-" + action,
    className: "update-duration-split ml-2",
    variant: "outline-primary",
    title: sign + " " + steps[0] / 60 / 1000 + " min",
    onClick: handleChangeDuration(steps[0], action),
    disabled: action === 'remove' && duration - steps[0] <= 0 ? true : false,
    size: "sm"
  }, steps.map(function (step, i) {
    if (i === 0) return;
    return React.createElement(Fragment, {
      key: "step-" + step + "-" + i
    }, action === 'add' || action === 'remove' && duration - step > 0 ? React.createElement(Dropdown.Item, {
      key: step,
      onClick: handleChangeDuration(step, action)
    }, sign, " ", step / 60000, " min") : React.createElement(Fragment, {
      key: "empty-step-" + step + "-" + i
    }));
  }));
};
export var UpdateDuration = function UpdateDuration(_a) {
  var duration = _a.duration,
    updateDuration = _a.updateDuration;
  var steps = [300000, 900000, 1800000];
  return React.createElement(React.Fragment, null, React.createElement(DurationDropdown, {
    duration: duration,
    steps: steps,
    action: "add",
    updateDuration: updateDuration
  }), React.createElement(DurationDropdown, {
    duration: duration,
    steps: steps,
    action: "remove",
    updateDuration: updateDuration
  }));
};