import { faFilm, faImage, faMusic, faParagraph, faMagic } from '@fortawesome/free-solid-svg-icons';

import { SectionKind } from '@eolementhe/video-editor-model';

export class TrackUtils {
  public static getTrackIcon(type: SectionKind) {
    switch (type) {
      case SectionKind.audio:
        return faMusic;
      case SectionKind.image:
        return faImage;
      case SectionKind.text:
        return faParagraph;
      case SectionKind.video:
        return faFilm;
      case SectionKind.videoTransition:
        return faMagic;
      case SectionKind.waveform:
        return faMusic;
      default:
        throw new Error(`Unsupported shape type ${type}`);
    }
  }
}
