var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { MarkdownMessage } from "./MarkdownMessage";
import { MarkdownTextArea } from "./MarkdownTextArea";
var MessageInput = function (_super) {
  __extends(MessageInput, _super);
  function MessageInput(props) {
    var _this = _super.call(this, props) || this;
    _this.state = {
      useState: props.value === undefined,
      value: ""
    };
    _this.handleChange = _this.handleChange.bind(_this);
    return _this;
  }
  MessageInput.getDerivedStateFromProps = function (nextProps, prevState) {
    if (nextProps.value === undefined && !prevState.useState) {
      return {
        useState: true
      };
    } else if (nextProps.value !== undefined && prevState.useState) {
      return {
        useState: false,
        value: ""
      };
    }
    return {};
  };
  MessageInput.prototype.render = function () {
    var _a = this.props,
      className = _a.className,
      placeholder = _a.placeholder,
      rows = _a.rows;
    var value = this.state.useState ? this.state.value : this.props.value;
    return React.createElement(Tabs, {
      id: "social-message-input",
      className: className,
      defaultActiveKey: "write"
    }, React.createElement(Tab, {
      className: "social-message-input-tab",
      eventKey: "write",
      title: "Write"
    }, React.createElement(MarkdownTextArea, {
      rows: rows,
      placeholder: placeholder,
      value: value,
      onChange: this.handleChange
    })), React.createElement(Tab, {
      className: "social-message-preview-tab",
      eventKey: "preview",
      title: "Preview"
    }, React.createElement(MarkdownMessage, {
      value: value !== null && value !== void 0 ? value : ""
    })));
  };
  MessageInput.prototype.handleChange = function (value) {
    if (this.state.useState) {
      this.setState({
        value: value
      });
    } else if (this.props.onChange) {
      this.props.onChange(value);
    }
  };
  return MessageInput;
}(React.PureComponent);
export { MessageInput };