import { ITextSection, SectionKind } from '@eolementhe/video-editor-model';
import uuidv4 from 'uuid/v4';

import { DEFAULT_TEXT, DEFAULT_DURATION } from './Constants';

export class TrackFactory {
  public static createText(duration: number): ITextSection {
    return {
      id: uuidv4(),
      start: 0,
      duration: DEFAULT_DURATION < duration ? DEFAULT_DURATION : duration,
      preview: '',
      kind: SectionKind.text,
      ...DEFAULT_TEXT
    };
  }
}
