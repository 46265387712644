var StreamController = function () {
  function StreamController(chunkList) {
    this.playerUrl = "";
    this.mimeType = "";
    this.duration = 0;
    this.chunks = chunkList;
    this.mediaSource = new MediaSource();
  }
  StreamController.prototype.getPlayerUrl = function () {
    if (!this.playerUrl) {
      this.playerUrl = URL.createObjectURL(this.mediaSource);
    }
    return this.playerUrl;
  };
  StreamController.prototype.setMediaSource = function (mimeType, duration) {
    this.mimeType = mimeType;
    this.duration = duration;
  };
  StreamController.prototype.initStream = function (mimeType, duration) {
    this.setMediaSource(mimeType, duration);
    this.mediaSource.addEventListener("sourceopen", this.handleSourceOpen.bind(this));
  };
  StreamController.prototype.handleSourceOpen = function () {
    var _this = this;
    var queue = this.chunks;
    var sourceBuffer = this.mediaSource.addSourceBuffer(this.mimeType);
    this.mediaSource.duration = this.duration;
    var initSegment = queue.shift();
    this.fetchSegmentAndAppend(initSegment, sourceBuffer, function () {
      _this.iter(queue, sourceBuffer);
    });
  };
  StreamController.prototype.fetchSegmentAndAppend = function (segmentUrl, sourceBuffer, callback) {
    this.fetchArrayBuffer(segmentUrl, function (buf) {
      sourceBuffer.addEventListener("updateend", function () {
        callback();
      });
      sourceBuffer.addEventListener("error", function (ev) {
        callback(ev);
      });
      sourceBuffer.appendBuffer(buf);
    });
  };
  StreamController.prototype.fetchArrayBuffer = function (url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("get", url);
    xhr.responseType = "arraybuffer";
    xhr.onload = function () {
      callback(xhr.response);
    };
    xhr.send();
  };
  StreamController.prototype.iter = function (queue, sourceBuffer) {
    var _this = this;
    var url = this.popSegmentFromQueue(queue);
    if (!url) return;
    this.fetchSegmentAndAppend(url, sourceBuffer, function (err) {
      if (err) {
        console.error(err);
      } else {
        setTimeout(_this.iter.bind(_this), 200);
      }
    });
  };
  StreamController.prototype.popSegmentFromQueue = function (queue) {
    return queue && queue.length > 0 ? queue.shift() : undefined;
  };
  return StreamController;
}();
export { StreamController };