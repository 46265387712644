import { getCrossIFrameCookie, CROSS_IFRAME_COOKIE } from '@eolementhe/core';
import uuidV4 from 'uuid/v4';
import fetchRetryMaker from 'fetch-retry';
import {
  IImageResource,
  IAudioResource,
  IVideoResource,
  IFile,
  FileResource,
  ResourceType
} from '@eolementhe/video-editor-model';
import { getEoleEditServerUrl } from './ServerUtils';
import { fileTypeFromMime } from '../components/ResourceBrowser/FileType';

const fetchRetry = fetchRetryMaker(fetch);

interface ProgressStatus {
  uploadProgress: number;
  lowresProgress: number;
}

export interface UploadProgress {
  uploadId: string;
  file: File;
  status: ProgressStatus;
  resource?: FileResource;
  error?: string;
}

export type UploadProgressCallback = (uploadProgress: UploadProgress) => void;

function getFileFromResponse(file: IFile): FileResource {
  const resourceKind = fileTypeFromMime(file.mime);
  const fileProps = {
    _id: file._id || uuidV4(),
    src: file.src,
    name: file.name ?? '',
    size: file.size ?? 0,
    preview: file.preview ?? '',
    lowres: file.lowres
  };
  switch (resourceKind) {
    case ResourceType.audio:
      return {
        resourceKind,
        ...fileProps,
        duration: file.duration ?? 1000,
        startTime: 0
      } as IAudioResource;
    case ResourceType.image:
      return {
        resourceKind,
        ...fileProps,
        width: file.width ?? 100,
        height: file.height ?? 100
      } as IImageResource;
    case ResourceType.video:
      return {
        resourceKind,
        ...fileProps,
        width: file.width ?? 100,
        height: file.height ?? 100,
        duration: file.duration ?? 1000,
        startTime: 0
      } as IVideoResource;
    default:
      throw new Error(`Unsupported resource type:${resourceKind}`);
  }
}

async function getUploadFileStatus(uploadId: string): Promise<ProgressStatus> {
  //const authToken = await getCrossIFrameCookie(CROSS_IFRAME_COOKIE.USER_TOKEN);
  const fetchResponse = await fetch(`${getEoleEditServerUrl()}/fileUpload/${uploadId}`, {
    method: 'GET'
  });
  return fetchResponse.json();
}

export function uploadFile(file: File, mimeType: string, onUploadProgress: UploadProgressCallback): void {
  // Use callback way and not promise to allow multiple update and not only one result
  (async () => {
    let intervalGetUploadStatus: number = 0;
    const uploadId = `${Date.now().toString()}${Math.random()}`;
    const uploadProgress: UploadProgress = {
      uploadId,
      file,
      status: {
        uploadProgress: 0,
        lowresProgress: 0
      }
    };
    try {
      const authToken = await getCrossIFrameCookie(CROSS_IFRAME_COOKIE.USER_TOKEN);

      onUploadProgress(uploadProgress);
      intervalGetUploadStatus = window.setInterval(async () => {
        try {
          const status = await getUploadFileStatus(uploadId);
          //if already have resource this means it is already completed and updated (could happen with slow network so await is released after => bug)
          if (uploadProgress.resource) {
            return;
          }
          uploadProgress.status = { ...uploadProgress.status, ...status };
          onUploadProgress(uploadProgress);
        } catch (error) {
          console.error('uploadFiles.ts uploadFile.intervalGetUploadStatus ', error);
        }
      }, 1000);

      const formData = new FormData();
      formData.append('file', file);
      const fetchResponse = await fetchRetry(`${getEoleEditServerUrl()}/fileUpload/${uploadId}`, {
        method: 'POST',
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${authToken}`,
          'eole-mime-type': mimeType // Avoid to send application/octet-stream as type because browser dont recognize it (mxf)
        })
      });

      const jsonResponse = await fetchResponse.json();

      clearInterval(intervalGetUploadStatus);
      uploadProgress.status = { ...uploadProgress.status, uploadProgress: 100, lowresProgress: 100 };
      uploadProgress.resource = getFileFromResponse(jsonResponse);
      onUploadProgress(uploadProgress);
    } catch (err) {
      clearInterval(intervalGetUploadStatus);
      uploadProgress.status = { uploadProgress: -1, lowresProgress: -1 };
      uploadProgress.resource = undefined;
      uploadProgress.error = String(err);
      onUploadProgress(uploadProgress);
      console.error('uploadFiles.ts uploadFile', err);
    }
  })();
}

// TODO: Move to a more appropriate file
export function createServerFileUrl(url: string, download?: boolean) {
  if (!url) {
    console.warn('uploadFiles.ts createServerFileUrl', 'Url is missing');
    return '';
  }
  if (!url.startsWith('http')) {
    url = `${getEoleEditServerUrl()}${url}`;
  }
  const parsedUrl = new URL(url);
  const params = new URLSearchParams(parsedUrl.search.slice(1));
  params.set('download', download ? 'true' : 'false');
  return `${parsedUrl.protocol}//${parsedUrl.host}${parsedUrl.pathname}?${params.toString()}`;
}
