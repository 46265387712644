var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import React from 'react';
import { faExclamationTriangle, faMusic, faPlus, faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBringFront } from '@fortawesome/pro-solid-svg-icons';
var DragStatus;
(function (DragStatus) {
  DragStatus[DragStatus["mouseUp"] = 0] = "mouseUp";
  DragStatus[DragStatus["mouseDown"] = 1] = "mouseDown";
  DragStatus[DragStatus["mouseMove"] = 2] = "mouseMove";
})(DragStatus || (DragStatus = {}));
export var SectionKind;
(function (SectionKind) {
  SectionKind["video"] = "video";
  SectionKind["audio"] = "audio";
  SectionKind["waveForm"] = "waveForm";
  SectionKind["text"] = "text";
  SectionKind["image"] = "image";
  SectionKind["videoTransition"] = "videoTransition";
})(SectionKind || (SectionKind = {}));
var SectionThatNeedsResources = [SectionKind.audio, SectionKind.image, SectionKind.video, SectionKind.waveForm];
var TimelineTrackSection = function (_super) {
  __extends(TimelineTrackSection, _super);
  function TimelineTrackSection(props) {
    var _this = _super.call(this, props) || this;
    _this.unmounted = false;
    _this.backgroundPicker = function () {
      var _a, _b, _c, _d;
      var preview = (_b = (_a = _this.props) === null || _a === void 0 ? void 0 : _a.section) === null || _b === void 0 ? void 0 : _b.preview;
      var bgUrl = preview ? "url(" + preview + ") center / contain" : '';
      var bgColor = (_d = (_c = _this.props) === null || _c === void 0 ? void 0 : _c.section) === null || _d === void 0 ? void 0 : _d.color;
      if (!bgColor && !preview) {
        switch (_this.props.section.kind) {
          case SectionKind.video:
            bgColor = bgColor || 'yellow';
            break;
          case SectionKind.audio:
            bgColor = bgColor || 'blueviolet';
            break;
          case SectionKind.waveForm:
            bgColor = bgColor || 'DarkBlue';
            break;
          case SectionKind.image:
            bgColor = bgColor || 'purple';
            break;
          case SectionKind.text:
            bgColor = bgColor || 'green';
            break;
          case SectionKind.videoTransition:
            bgColor = bgColor || 'none';
            break;
          default:
            bgColor = bgColor || 'red';
        }
      }
      return bgUrl || bgColor;
    };
    _this.getIcon = function (section) {
      if (SectionThatNeedsResources.includes(section.kind) && !section.srcId) {
        return React.createElement(FontAwesomeIcon, {
          icon: faExclamationTriangle
        });
      }
      var icon;
      switch (section.kind) {
        case SectionKind.waveForm:
          icon = faWaveSquare;
          break;
        case SectionKind.audio:
          icon = faMusic;
          break;
        default:
          break;
      }
      return icon ? React.createElement(FontAwesomeIcon, {
        icon: icon
      }) : React.createElement(React.Fragment, null);
    };
    _this.state = {
      changeDuration: false,
      dragStatus: DragStatus.mouseUp,
      startBeforeMove: _this.props.section.start,
      topPosition: undefined,
      placeholderDisplay: false,
      transitionDragOver: false
    };
    _this.sectionRef = React.createRef();
    _this.unmounted = false;
    _this.onMouseDown = _this.onMouseDown.bind(_this);
    _this.onWindowMouseUp = _this.onWindowMouseUp.bind(_this);
    _this.onWindowMouseMove = _this.onWindowMouseMove.bind(_this);
    _this.onGripLeftMouseDown = _this.onGripLeftMouseDown.bind(_this);
    _this.onGripLeftMouseUp = _this.onGripLeftMouseUp.bind(_this);
    _this.onGripLeftMouseMove = _this.onGripLeftMouseMove.bind(_this);
    _this.onGripRightMouseDown = _this.onGripRightMouseDown.bind(_this);
    _this.onGripRightMouseUp = _this.onGripRightMouseUp.bind(_this);
    _this.onGripRightMouseMove = _this.onGripRightMouseMove.bind(_this);
    return _this;
  }
  TimelineTrackSection.prototype.componentWillUnmount = function () {
    this.unmounted = true;
  };
  TimelineTrackSection.prototype.setAsCurrentSection = function (event) {
    event.isSection = true;
    if (this.props.onChangeCurrentSection) {
      this.props.onChangeCurrentSection(this.props.sectionIndex);
    }
  };
  TimelineTrackSection.prototype.onMouseDown = function (event) {
    var _a;
    this.setAsCurrentSection(event);
    var isEmptySection = false;
    if (SectionThatNeedsResources.includes(this.props.section.kind) && !this.props.section.srcId && this.props.onClickEmptySrcSection) {
      isEmptySection = true;
      this.props.onClickEmptySrcSection(this.props.sectionIndex);
    }
    if (!this.props.section.notMovable && !isEmptySection) {
      window.addEventListener('mousemove', this.onWindowMouseMove);
      window.addEventListener('mouseup', this.onWindowMouseUp);
      this.setState({
        dragStatus: DragStatus.mouseDown,
        startBeforeMove: this.props.section.start,
        topPosition: ((_a = this.sectionRef) === null || _a === void 0 ? void 0 : _a.current) ? parseInt(window.getComputedStyle(this.sectionRef.current, null).top, 10) : 0
      });
    }
  };
  TimelineTrackSection.prototype.onWindowMouseUp = function (event) {
    var _this = this;
    window.removeEventListener('mousemove', this.onWindowMouseMove);
    window.removeEventListener('mouseup', this.onWindowMouseUp);
    var tracksElements = Array.from(document.querySelectorAll('.timeline-track-content'));
    var destTrackIndex;
    for (var _i = 0, tracksElements_1 = tracksElements; _i < tracksElements_1.length; _i++) {
      var trackElement = tracksElements_1[_i];
      if (trackElement === null || trackElement === void 0 ? void 0 : trackElement.id) {
        var currentIndex = parseInt(trackElement.id.split('-')[1], 10);
        var trackElemRect = trackElement.getBoundingClientRect();
        if (currentIndex >= 0 && event.clientY >= trackElemRect.y && event.clientY <= trackElemRect.y + trackElemRect.height) {
          destTrackIndex = currentIndex;
          break;
        }
      }
    }
    if (this.state.dragStatus === DragStatus.mouseMove) {
      setTimeout(function () {
        if (_this.unmounted) {
          return;
        }
        _this.props.onEndDrag(_this.state.startBeforeMove, destTrackIndex);
      }, 2);
    }
    setTimeout(function () {
      if (_this.unmounted) {
        return;
      }
      _this.setState({
        topPosition: undefined
      });
    }, 4);
    this.setState({
      dragStatus: DragStatus.mouseUp
    });
  };
  TimelineTrackSection.prototype.onWindowMouseMove = function (event) {
    this.setState(function (prevState) {
      return {
        dragStatus: prevState.dragStatus === DragStatus.mouseDown ? DragStatus.mouseMove : prevState.dragStatus,
        topPosition: prevState.topPosition ? prevState.topPosition + event.movementY : event.movementY
      };
    });
    var start = this.props.section.start + event.movementX * this.props.stepDuration / this.props.scale;
    this.props.onChangeStart(Math.round(start), true);
  };
  TimelineTrackSection.prototype.onGripLeftMouseDown = function (event) {
    this.setAsCurrentSection(event);
    event.stopPropagation();
    if (!this.props.section.notResizable) {
      window.addEventListener('mousemove', this.onGripLeftMouseMove);
      window.addEventListener('mouseup', this.onGripLeftMouseUp);
    }
  };
  TimelineTrackSection.prototype.onGripLeftMouseUp = function (event) {
    event.stopPropagation();
    window.removeEventListener('mousemove', this.onGripLeftMouseMove);
    window.removeEventListener('mouseup', this.onGripLeftMouseUp);
  };
  TimelineTrackSection.prototype.onGripLeftMouseMove = function (event) {
    event.stopPropagation();
    var duration = 0;
    var startMedia = 0;
    if (this.props.scale) {
      var prevStart = this.props.section.start;
      var start = Math.round(this.props.section.start + event.movementX * this.props.stepDuration / this.props.scale);
      var actualStart = this.props.onChangeStart(start, false);
      var diff = prevStart - actualStart;
      duration = this.props.section.duration + diff;
      duration = Math.round(duration);
      if (this.props.section.startMedia != null) {
        startMedia = this.props.section.startMedia - diff;
        startMedia = Math.round(startMedia);
      }
    }
    this.props.onChangeDuration(duration);
    if (this.props.onChangeSectionStartMedia) {
      this.props.onChangeSectionStartMedia(startMedia);
    }
  };
  TimelineTrackSection.prototype.onGripRightMouseMove = function (event) {
    event.stopPropagation();
    var duration = 0;
    var endMedia = 0;
    if (this.props.scale) {
      var prevDuration = this.props.section.duration;
      duration = this.props.section.duration + event.movementX * this.props.stepDuration / this.props.scale;
      duration = Math.round(duration);
      var diff = prevDuration - duration;
      if (this.props.section.endMedia) {
        endMedia = this.props.section.endMedia - diff;
        endMedia = Math.round(endMedia);
      }
    }
    this.props.onChangeDuration(duration);
    if (this.props.onChangeSectionEndMedia) {
      this.props.onChangeSectionEndMedia(endMedia);
    }
  };
  TimelineTrackSection.prototype.onGripRightMouseDown = function (event) {
    this.setAsCurrentSection(event);
    event.stopPropagation();
    if (!this.props.section.notResizable) {
      window.addEventListener('mousemove', this.onGripRightMouseMove);
      window.addEventListener('mouseup', this.onGripRightMouseUp);
    }
  };
  TimelineTrackSection.prototype.onGripRightMouseUp = function (event) {
    event.stopPropagation();
    window.removeEventListener('mousemove', this.onGripRightMouseMove);
    window.removeEventListener('mouseup', this.onGripRightMouseUp);
  };
  TimelineTrackSection.prototype.render = function () {
    var _this = this;
    var _a, _b;
    var isSectionTransition = this.props.section.kind === SectionKind.videoTransition;
    var width = 0;
    var isPlaceholder = this.props.section.kind === SectionKind.videoTransition && !((_a = this.props.section.effect) === null || _a === void 0 ? void 0 : _a.type);
    if (this.props.stepDuration) {
      var sectionDuration = void 0;
      if (isSectionTransition && ((_b = this.props.section.effect) === null || _b === void 0 ? void 0 : _b.durationSec)) {
        sectionDuration = this.props.section.effect.durationSec * 1000;
      } else {
        sectionDuration = this.props.section.duration;
      }
      width = sectionDuration / this.props.stepDuration * this.props.scale + 1;
    }
    var style = {
      left: (this.props.stepDuration ? this.props.section.start / this.props.stepDuration : 0) * this.props.scale,
      width: width,
      background: this.backgroundPicker()
    };
    var className = isSectionTransition ? 'timeline-track-section-transition' : 'timeline-track-section';
    className += ' position-absolute';
    if (this.props.isCurrentSection && !isPlaceholder) {
      className += isSectionTransition ? ' timeline-track-section-transition-selected' : ' timeline-track-section-selected';
    }
    if (this.state.topPosition != null) {
      style.top = this.state.topPosition + "px";
    }
    if (!this.props.section.srcId && (this.props.section.kind === SectionKind.audio || this.props.section.kind === SectionKind.video || this.props.section.kind === SectionKind.waveForm || this.props.section.kind === SectionKind.image)) {
      style.cursor = 'pointer';
    }
    var onPlaceholderMouseEnter = function onPlaceholderMouseEnter() {
      _this.setState({
        placeholderDisplay: true
      });
    };
    var onPlaceholderMouseLeave = function onPlaceholderMouseLeave() {
      _this.setState({
        placeholderDisplay: false
      });
    };
    var onTransitionDragOver = function onTransitionDragOver() {
      _this.setState({
        placeholderDisplay: true,
        transitionDragOver: true
      });
    };
    var onTransitionDragLeave = function onTransitionDragLeave() {
      _this.setState({
        placeholderDisplay: false,
        transitionDragOver: false
      });
    };
    var onDropTransition = function onDropTransition() {
      _this.setState({
        placeholderDisplay: false,
        transitionDragOver: false
      });
      if (_this.props.onDropTransition) {
        _this.props.onDropTransition(_this.props.sectionIndex);
      }
    };
    var onTransitionClick = function onTransitionClick() {
      if (_this.props.onTransitionClick) {
        if (isPlaceholder && _this.props.onPlaceholderClick) {
          _this.props.onPlaceholderClick(_this.props.sectionIndex);
        }
        _this.props.onTransitionClick();
      }
    };
    return React.createElement("div", {
      ref: this.sectionRef,
      className: className,
      style: style,
      onMouseDown: isPlaceholder ? undefined : this.onMouseDown,
      onMouseEnter: isPlaceholder ? onPlaceholderMouseEnter : undefined,
      onMouseLeave: isPlaceholder ? onPlaceholderMouseLeave : undefined,
      onDrop: isSectionTransition ? onDropTransition : undefined,
      onDragOver: isSectionTransition ? onTransitionDragOver : undefined,
      onDragLeave: isSectionTransition ? onTransitionDragLeave : undefined,
      onClick: isSectionTransition ? onTransitionClick : undefined
    }, this.props.section.kind !== SectionKind.videoTransition && this.props.section.kind !== SectionKind.waveForm && React.createElement("div", {
      className: "timeline-track-section-grip-left " + (!this.props.section.notResizable ? 'resizable' : ''),
      onMouseDown: this.onGripLeftMouseDown
    }), React.createElement("div", {
      className: "timeline-track-section-inner " + (!this.props.section.notMovable ? 'movable' : '')
    }, this.props.section.text && !this.props.section.preview && React.createElement("span", {
      className: "" + (this.props.section.kind === SectionKind.video ? 'text-black' : '')
    }, React.createElement("span", {
      className: "mr-2"
    }, this.props.section.text), this.getIcon(this.props.section)), this.props.section.kind === SectionKind.videoTransition && React.createElement(React.Fragment, null, React.createElement(FontAwesomeIcon, {
      title: isPlaceholder ? ' Add a transition' : '',
      className: "icon-transition " + (isPlaceholder && !this.state.placeholderDisplay ? 'invisible' : 'visible') + " " + (this.state.transitionDragOver ? 'icon-transition-on-drag' : ''),
      icon: isPlaceholder ? faPlus : faBringFront
    }))), this.props.section.kind !== SectionKind.videoTransition && this.props.section.kind !== SectionKind.waveForm && React.createElement("div", {
      className: "timeline-track-section-grip-right " + (!this.props.section.notResizable ? 'resizable' : ''),
      onMouseDown: this.onGripRightMouseDown
    }));
  };
  return TimelineTrackSection;
}(React.Component);
export { TimelineTrackSection };