/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLRemoveProjectMutationVariables = {
    id: string;
};
export type GraphQLRemoveProjectMutationResponse = {
    readonly removeProject: boolean | null;
};
export type GraphQLRemoveProjectMutation = {
    readonly response: GraphQLRemoveProjectMutationResponse;
    readonly variables: GraphQLRemoveProjectMutationVariables;
};



/*
mutation GraphQLRemoveProjectMutation(
  $id: ID!
) {
  removeProject(id: $id)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "removeProject",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLRemoveProjectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GraphQLRemoveProjectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0fa48ca6dd3e07c90270d73f5cd65ea5",
    "id": null,
    "metadata": {},
    "name": "GraphQLRemoveProjectMutation",
    "operationKind": "mutation",
    "text": "mutation GraphQLRemoveProjectMutation(\n  $id: ID!\n) {\n  removeProject(id: $id)\n}\n"
  }
};
})();
(node as any).hash = 'ef3c983c54049154391ff1b22cebf97d';
export default node;
