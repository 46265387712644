"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FontFamily = void 0;
/**
 * The list of font families supported by our stack.
 *
 * @public
 */
var FontFamily;
(function (FontFamily) {
  FontFamily["arial"] = "arial";
  FontFamily["arialBlack"] = "arialBlack";
  FontFamily["courierNew"] = "courierNew";
  FontFamily["comicSans"] = "comicSans";
  FontFamily["georgia"] = "georgia";
  FontFamily["impact"] = "impact";
  FontFamily["palatinoLinotype"] = "palatinoLinotype";
  FontFamily["timesNewRoman"] = "timesNewRoman";
  FontFamily["trebuchet"] = "trebuchet";
  FontFamily["verdana"] = "verdana";
})(FontFamily = exports.FontFamily || (exports.FontFamily = {}));