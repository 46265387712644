export var JobTitle;
(function (JobTitle) {
  JobTitle["undefined"] = "undefined";
  JobTitle["antennaManager"] = "antennaManager";
  JobTitle["assistantDirector"] = "assistantDirector";
  JobTitle["audiovisualEditor"] = "audiovisualEditor";
  JobTitle["broadcastManager"] = "broadcastManager";
  JobTitle["chiefEditor"] = "chiefEditor";
  JobTitle["chiefInformationOfficer"] = "chiefInformationOfficer";
  JobTitle["communityManager"] = "communityManager";
  JobTitle["contentCreator"] = "contentCreator";
  JobTitle["digitalImageRestorer"] = "digitalImageRestorer";
  JobTitle["digitalManager"] = "digitalManager";
  JobTitle["director"] = "director";
  JobTitle["distributor"] = "distributor";
  JobTitle["documentalist"] = "documentalist";
  JobTitle["entrepreneur"] = "entrepreneur";
  JobTitle["executiveDirector"] = "executiveDirector";
  JobTitle["formatDeveloper"] = "formatDeveloper";
  JobTitle["freelance"] = "freelance";
  JobTitle["humanResourcesManager"] = "humanResourcesManager";
  JobTitle["imageConsultant"] = "imageConsultant";
  JobTitle["itDeveloperWebIntegrator"] = "itDeveloperWebIntegrator";
  JobTitle["journalist"] = "journalist";
  JobTitle["mediaProjectManager"] = "mediaProjectManager";
  JobTitle["operationsManager"] = "operationsManager";
  JobTitle["postProductionManager"] = "postProductionManager";
  JobTitle["producer"] = "producer";
  JobTitle["productionAssistant"] = "productionAssistant";
  JobTitle["productionManager"] = "productionManager";
  JobTitle["programmingOfficer"] = "programmingOfficer";
  JobTitle["soundEngineer"] = "soundEngineer";
  JobTitle["technicalManager"] = "technicalManager";
  JobTitle["translatorInterpreterLocalizer"] = "translatorInterpreterLocalizer";
  JobTitle["videoEngineer"] = "videoEngineer";
  JobTitle["visualMarketingCommunicationManager"] = "visualMarketingCommunicationManager";
})(JobTitle || (JobTitle = {}));