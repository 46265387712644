export function mergeClassName() {
  var className = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    className[_i] = arguments[_i];
  }
  return className.reduce(function (final, current) {
    if (current === undefined) return final;
    var split = current.split(/\s/).filter(function (part) {
      return part;
    });
    var parts = final ? [final].concat(split) : split;
    return parts.join(" ");
  }, "");
}