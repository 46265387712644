var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React from "react";
import getCaretCoordinates from "textarea-caret";
import { getEmoji, getEmojiNames, mergeClassName } from "../utils";
var MAX_SUGGESTION = 5;
var MarkdownTextArea = function (_super) {
  __extends(MarkdownTextArea, _super);
  function MarkdownTextArea(props) {
    var _this = _super.call(this, props) || this;
    _this.textArea = React.createRef();
    _this.state = {
      value: "",
      useState: props.value === undefined,
      showSuggestions: false,
      suggestionStart: {
        top: 0,
        left: 0,
        char: 0
      },
      suggestionsEnd: {
        top: 0,
        left: 0,
        char: 0
      },
      suggestions: [],
      currentSuggestion: -1
    };
    _this.handleChange = _this.handleChange.bind(_this);
    _this.handleKeyPress = _this.handleKeyPress.bind(_this);
    _this.handleKeyDown = _this.handleKeyDown.bind(_this);
    _this.handleKeyUp = _this.handleKeyUp.bind(_this);
    return _this;
  }
  MarkdownTextArea.getDerivedStateFromProps = function (nextProps, prevState) {
    if (nextProps.value === undefined && !prevState.useState) {
      return {
        useState: true
      };
    } else if (nextProps.value !== undefined && prevState.useState) {
      return {
        useState: false,
        value: ""
      };
    }
    return {};
  };
  MarkdownTextArea.prototype.setValue = function (value, extraState) {
    var _this = this;
    if (extraState === void 0) {
      extraState = null;
    }
    if (this.state.useState) {
      extraState = __assign(__assign({}, extraState), {
        value: value
      });
    } else if (this.props.value === undefined) {
      throw new Error("This should never happen");
    }
    var callback = function callback() {
      return _this.props.onChange && _this.props.onChange(value);
    };
    this.setState(extraState, callback);
  };
  MarkdownTextArea.prototype.render = function () {
    var _this = this;
    var value = this.state.useState ? this.state.value : this.props.value;
    return React.createElement("div", {
      className: "social-md-textarea"
    }, React.createElement("textarea", {
      ref: this.textArea,
      className: mergeClassName("social-md-textarea-textarea", this.props.className),
      value: value,
      rows: this.props.rows ? this.props.rows : undefined,
      placeholder: this.props.placeholder,
      onChange: this.handleChange,
      onKeyPress: this.handleKeyPress,
      onKeyDown: this.handleKeyDown,
      onKeyUp: this.handleKeyUp
    }), this.state.showSuggestions && React.createElement("ul", {
      className: "social-md-textarea-emoji-suggestions",
      style: {
        left: this.state.suggestionStart.left,
        top: this.state.suggestionStart.top
      }
    }, this.state.suggestions.map(function (suggestion, i) {
      return React.createElement("li", {
        key: suggestion.name,
        className: "social-md-textarea-emoji-suggestions-item" + (i === _this.state.currentSuggestion ? " active" : "")
      }, suggestion.emoji + " " + suggestion.name);
    })));
  };
  MarkdownTextArea.prototype.getSuggestionPos = function () {
    var textArea = this.textArea.current;
    if (textArea === null) throw new Error("This should not happen");
    var _a = getCaretCoordinates(textArea, textArea.selectionStart),
      top = _a.top,
      left = _a.left,
      height = _a.height;
    return {
      top: top + height - textArea.scrollTop,
      left: left,
      char: textArea.selectionStart
    };
  };
  MarkdownTextArea.prototype.getSuggestions = function (word) {
    if (word === void 0) {
      word = "";
    }
    var emojiNames = getEmojiNames(word);
    var sliceLength = !emojiNames.length ? 0 : emojiNames.length > MAX_SUGGESTION ? MAX_SUGGESTION : emojiNames.length;
    var sliced = emojiNames.slice(0, sliceLength);
    return sliced.map(function (name) {
      return {
        name: name,
        emoji: getEmoji(name)
      };
    });
  };
  MarkdownTextArea.prototype.handleChange = function (event) {
    this.setValue(event.target.value);
  };
  MarkdownTextArea.prototype.handleKeyDown = function (event) {
    var showSuggestions = this.state.showSuggestions;
    var textArea = this.textArea.current;
    if (!textArea) return;
    switch (event.key) {
      case ":":
        if (showSuggestions) {
          this.setState({
            showSuggestions: false
          });
        } else {
          var suggestionsStart = this.getSuggestionPos();
          if (!textArea.value.length || textArea.value[textArea.value.length - 1].match(/\s/)) {
            this.setState({
              showSuggestions: true,
              currentSuggestion: 0,
              suggestionStart: suggestionsStart,
              suggestionsEnd: suggestionsStart,
              suggestions: this.getSuggestions()
            });
          }
        }
        break;
      case "ArrowDown":
        if (showSuggestions) {
          event.preventDefault();
          var currentSuggestion = this.state.currentSuggestion === MAX_SUGGESTION - 1 ? 0 : this.state.currentSuggestion + 1;
          this.setState({
            currentSuggestion: currentSuggestion
          });
        }
        break;
      case "ArrowUp":
        if (showSuggestions) {
          event.preventDefault();
          var currentSuggestion = this.state.currentSuggestion === 0 ? MAX_SUGGESTION - 1 : this.state.currentSuggestion - 1;
          this.setState({
            currentSuggestion: currentSuggestion
          });
        }
        break;
      case " ":
      case "Escape":
        if (showSuggestions) {
          this.setState({
            showSuggestions: false
          });
        }
        break;
      default:
        break;
    }
  };
  MarkdownTextArea.prototype.handleKeyPress = function (event) {
    if (event.key === "Enter" && this.state.showSuggestions) {
      var textArea = this.textArea.current;
      if (!event.shiftKey) {
        event.preventDefault();
      }
      if (!textArea || event.shiftKey || !this.state.suggestions.length) {
        this.setState({
          showSuggestions: false
        });
        return;
      }
      var emoji = this.state.suggestions[this.state.currentSuggestion].emoji;
      var valueStart = textArea.value.substr(0, this.state.suggestionStart.char);
      var valueEnd = textArea.value.substr(textArea.selectionEnd, textArea.value.length - 1);
      var value = "" + valueStart + emoji + valueEnd;
      this.setValue(value, {
        showSuggestions: false
      });
    }
  };
  MarkdownTextArea.prototype.handleKeyUp = function (event) {
    if (this.state.showSuggestions) {
      var textArea = this.textArea.current;
      if (textArea === null) return;
      var suggestionsEnd = this.getSuggestionPos();
      if (this.state.suggestionStart.char >= suggestionsEnd.char) {
        this.setState({
          showSuggestions: false
        });
      }
      var word = textArea.value.substring(this.state.suggestionStart.char, suggestionsEnd.char);
      if (word.match(/\s+/)) {
        this.setState({
          showSuggestions: false
        });
      } else {
        this.setState({
          suggestions: this.getSuggestions(word),
          suggestionsEnd: suggestionsEnd
        });
      }
    }
  };
  return MarkdownTextArea;
}(React.PureComponent);
export { MarkdownTextArea };