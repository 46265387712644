import React from 'react';
import ReactDOM from 'react-dom';
import { createCrossIFrame, overrideConsole } from '@eolementhe/core';

import './index.scss';

import { App } from './App';
import * as serviceWorker from './utils/serviceWorker';

import { initI18n } from './utils/i18n';

overrideConsole();
initI18n();

const afterRender = async () => {
  try {
    await createCrossIFrame();
  } catch (err) {
    console.error('src/index.tsx', 'afterRender', err);
  }
};

ReactDOM.render(<App />, document.getElementById('root'), afterRender);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// eslint-disable-next-line @typescript-eslint/no-floating-promises
serviceWorker.unregister();
