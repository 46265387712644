export var es = {
  emailAddress: "Correo electrónico",
  password: "Contraseña",
  firstName: "Primer nombre",
  lastName: "Apellido",
  company: "Empresa",
  phone: "Número de teléfono",
  jobTitle: "Título profesional",
  disclaimer: "<0>Al crear una cuenta, acepta los <1>Términos de servicio</1>. " + "Para obtener más información sobre las prácticas de privacidad de Videomenthe, consulte" + "<3>Declaración de privacidadde Videomenthe</3>. " + "En ocasiones, le enviaremos correos electrónicos relacionados con la cuenta.</0>",
  submit: "Enviar",
  register: "Registro",
  signIn: "Registrarse",
  passwordReset: "Restablecimiento de contraseña",
  alreadyHaveAccount: "¿Ya tienes una cuenta?",
  forgotPassword: "¿Olvidó su contraseña?",
  dontHaveAccount: "¿No tiene una cuenta?",
  jobTitles: {
    undefined: "-- Seleccione una opción --",
    antennaManager: "administrador de antena",
    assistantDirector: "subgerente",
    audiovisualEditor: "editor audiovisual",
    broadcastManager: "gerente de transmisión",
    chiefEditor: "editor en jefe",
    chiefInformationOfficer: "director de información",
    communityManager: "administrador de la comunidad",
    contentCreator: "creador de contenido",
    digitalImageRestorer: "restaurador de imagen digital",
    digitalManager: "gerente digital",
    director: "director",
    distributor: "distribuidor",
    documentalist: "documentalista",
    entrepreneur: "empresario",
    executiveDirector: "director ejecutivo",
    formatDeveloper: "desarrollador de formatos",
    freelance: "Lanza libre",
    humanResourcesManager: "Gerente de Recursos Humanos",
    imageConsultant: "asesor de imagen",
    itDeveloperWebIntegrator: "es desarrollador/integrador web",
    journalist: "periodista",
    mediaProjectManager: "gerente de proyectos de medios",
    operationsManager: "Gerente de Operaciones",
    postProductionManager: "gerente de postproducción",
    producer: "productor",
    productionAssistant: "asistente de producción",
    productionManager: "jefe de producción",
    programmingOfficer: "oficial de programación",
    soundEngineer: "ingeniero de sonido",
    technicalManager: "gerente técnico",
    translatorInterpreterLocalizer: "traductor/intérprete/localizador",
    videoEngineer: "ingeniero de video",
    visualMarketingCommunicationManager: "gerente de marketing visual/comunicación"
  }
};