"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SERVE_ROOT = exports.FILE_ROOT_PATH = exports.TEMP_PATH = exports.PREVIEWS_PATH = exports.LOW_RES_PATH = exports.SOURCES_PATH = void 0;
exports.SOURCES_PATH = '/eole-edit/sources';
exports.LOW_RES_PATH = '/eole-edit/lowres';
exports.PREVIEWS_PATH = '/eole-edit/previews';
exports.TEMP_PATH = '/eole-edit/temp';
exports.FILE_ROOT_PATH = '/var/eolementhe';
exports.SERVE_ROOT = '/store';