import React, { FormEvent, FC, useCallback, useState, ChangeEvent, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Form, Modal, Button, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faUndo } from '@fortawesome/free-solid-svg-icons';
import { isString } from 'lodash';
import { faExchange } from '@fortawesome/pro-regular-svg-icons';
import { showOpenFileDialog } from '@eolementhe/react-components';
import { useRelayEnvironment, GraphQLTaggedNode } from 'react-relay';
import uuidv4 from 'uuid/v4';
import { ITrack, ITrackSection, SectionKind } from '@eolementhe/video-editor-model';
import { fetchQuery } from 'react-relay/hooks';
import { VIDEO_SIZES, DEFAULT_SIZE_INDEX } from '../../utils/VideoSizes';
import { SizeNumber } from './SizeNumber';
import { DEFAULT_PROJECT } from '../EditorPage/Constants';
import {
  doCreateProject,
  doShowProjectModal,
  doShowError,
  doSetFrameRate,
  doEditProject,
  doShowProjectBuildingModal
} from '../../redux/actions';
import { EoleEditStateEntry, MODALTYPE, ProjectStructure, IImportedSection} from '../../redux/types';
import './ProjectModal.scss';
import { BitrateInput, BITRATE_LIST } from './BitrateInput';
import { ProjectTypeInput } from './ProjectTypeInput';
import { requestUserProfile } from '../../utils/Userprofile';
import { doBuildProject } from '../../redux/buildAction';
import { getProjectEndTime } from '../../utils/getProjectEndTime';
import { GET_FILES_QUERY } from '../../api/GraphQL';
import { GraphQLGetFilesQuery } from '../../__generated__/GraphQLGetFilesQuery.graphql';

const DEFAULT_FPS = 25;

interface IUserProfile {
  _id: string;
  owner: string;
  eoleEditRight: boolean;
  eoleEditExpiry: unknown | null;
  eoleEditStorageUsed: number | null;
  eoleEditStorageLimit: number | null;
  eoleEditTimeUsed: number | null;
  eoleEditTimeLimit: number | null;
  eoleEditHasActiveSubscription: boolean | null;
  type: string | null;
  purge: number | null;
  apiKey: string;
}

export const ProjectModal: FC = () => {
  const mountedRef = useRef(false);
  const isProjectModalShown = useSelector((state: EoleEditStateEntry) => state.ee.isProjectModalShown);
  const modalType = useSelector((state: EoleEditStateEntry) => state.ee.modalType);
  const dispatch = useDispatch();
  const history = useHistory();
  const environment = useRelayEnvironment();
  const project = useSelector((state: EoleEditStateEntry) => state.ee.project);
  const [title, setTitle] = useState('');
  const [isSameVideoSizeProject, setIsSameVideoSizeProject] = useState<string>('');
  const [userprofile, setUserprofile] = useState<IUserProfile | undefined>(undefined);
  const [width, setWidth] = useState(DEFAULT_PROJECT.size.width);
  const [height, setHeight] = useState(DEFAULT_PROJECT.size.height);
  const [standardizedSizeIndex, setStandardizedSizeIndex] = useState(DEFAULT_SIZE_INDEX);
  const [videoBitrate, setVideoBitrate] = useState<string>(BITRATE_LIST.r5000);
  const [customBitrate, setCustomBitrate] = useState(5);
  const [projectStructure, setProjectStructure] = useState<ProjectStructure | undefined>();

  // Build properties
  const [fps, setFps] = useState(DEFAULT_FPS);
  const [isPossible, setIsPossible] = useState<boolean>(true);
  const [impossibleMessage, setImpossibleMessage] = useState<string>('');

  useEffect(() => {
    setTitle(modalType === MODALTYPE.CREATION ? '' : project?.title ?? '');
    setIsSameVideoSizeProject(
      !project?.isSameVideoSizeProject || project?.isSameVideoSizeProject === 'Unknow'
        ? 'uncut'
        : project?.isSameVideoSizeProject
    );
    setWidth(project?.size.width ?? DEFAULT_PROJECT.size.width);
    setHeight(project?.size.height ?? DEFAULT_PROJECT.size.height);
    const projectBitrate = project?.videoBitrate ? (project.videoBitrate / 1000).toFixed(1) : 5;
    const bitrateFromList = Object.values(BITRATE_LIST).find((val: string) => +val === +projectBitrate);
    if (bitrateFromList) {
      setVideoBitrate(bitrateFromList);
    } else {
      setCustomBitrate(+projectBitrate);
      setVideoBitrate(BITRATE_LIST.custom);
    }
  }, [project, modalType]);

  useEffect(() => {
    mountedRef.current = true;
    (async () => {
      try {
        const userprofile = await requestUserProfile();
        if (userprofile) {
          setUserprofile(userprofile.getCurrentUserProfile);
        }
        if (!mountedRef.current) {
          return;
        }
        if (userprofile && project) {
          const { eoleEditExpiry, eoleEditStorageUsed, eoleEditStorageLimit, eoleEditTimeUsed, eoleEditTimeLimit } =
            userprofile.getCurrentUserProfile;
          const projectEndTime = getProjectEndTime(project);
          if (eoleEditExpiry) {
            if (
              eoleEditTimeUsed != null &&
              eoleEditTimeLimit != null &&
              eoleEditTimeLimit > 0 &&
              eoleEditTimeUsed + projectEndTime >= eoleEditTimeLimit
            ) {
              setIsPossible(false);
              setImpossibleMessage("You don't have enough time left to build your video");
              return;
            }
            if (
              eoleEditStorageUsed != null &&
              eoleEditStorageLimit != null &&
              eoleEditStorageLimit > 0 &&
              eoleEditStorageUsed >= eoleEditStorageLimit
            ) {
              setIsPossible(false);
              setImpossibleMessage("You don't have enough space left to build your video");
              return;
            }
            if (isString(eoleEditExpiry) && new Date(eoleEditExpiry).getTime() < new Date().getTime()) {
              setIsPossible(false);
              setImpossibleMessage('Your rights have expired');
              return;
            }
          }
        }
      } catch (err) {
        console.error('ProjectModal.tsx, ' + err);
      }
    })();
    return () => {
      mountedRef.current = false;
    };
  }, [project]);

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value);
  };

  const swapDimensions = useCallback(() => {
    setWidth(height);
    setHeight(width);
  }, [setWidth, setHeight, width, height]);

  const handleWidthChange = useCallback((newWidth) => setWidth(newWidth), [setWidth]);

  const handleHeightChange = useCallback((newHeight) => setHeight(newHeight), [setHeight]);

  const hideModal = useCallback(() => {
    dispatch(doShowProjectModal(false, modalType));
  }, [dispatch, modalType]);

  const handleCancelClick = () => hideModal();

  const handleHide = () => hideModal();

  const getUserFiles = async () => {
    return fetchQuery<GraphQLGetFilesQuery>(environment, GET_FILES_QUERY as GraphQLTaggedNode, {
      mime: undefined
    }).toPromise();
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (projectStructure) {
      const result = await getUserFiles();
      projectStructure.tracks.forEach(track => {
        const importedSection: IImportedSection[] = track.sections;
        importedSection.forEach(section => {
          if (result && result.getFiles && result.getFiles.length) {
            result.getFiles.forEach(file => {
              if (section.text === file.name) {
                section.src = file.src;
                section.srcId = file._id;
                section.lowres = file.lowres || undefined;
                section.preview = file.preview || undefined;
              }
            })
          }
        })
      })
      const newTracks: ITrack[] = projectStructure.tracks.map(
        (track) =>
          ({
            id: uuidv4(),
            ...track,
            sections: track.sections.map((section) => ({ id: uuidv4(), ...section }))
          } as ITrack)
      );
      dispatch(
        doCreateProject(
          title,
          projectStructure.isSameVideoSizedProject,
          projectStructure.size.width,
          projectStructure.size.height,
          projectStructure.videoBitrate,
          projectStructure.duration,
          newTracks
        )
      );
    } else {
      let validBitrate = customBitrate;
      if (videoBitrate !== BITRATE_LIST.custom) {
        validBitrate = +videoBitrate;
      }
      dispatch(doCreateProject(title, isSameVideoSizeProject, width, height, Math.round(validBitrate * 1000)));
    }
    history.push('/editor');
    hideModal();
    setProjectStructure(undefined);
  };

  const handleSizeSelectChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newStandardizedSizeIndex = parseInt(event.currentTarget.value, 10);
      setStandardizedSizeIndex(newStandardizedSizeIndex);
      const newVideoSizes = VIDEO_SIZES[newStandardizedSizeIndex];
      setWidth(newVideoSizes.width);
      setHeight(newVideoSizes.height);
    },
    [setStandardizedSizeIndex, setWidth, setHeight]
  );
  const handleBitrateChange = useCallback(
    (newBitrate: string) => {
      setVideoBitrate(newBitrate);
    },
    [setVideoBitrate]
  );

  const handleCustomBitrateChange = useCallback(
    (newCustomBitrate: number) => {
      setCustomBitrate(newCustomBitrate);
    },
    [setCustomBitrate]
  );

  const onChangeFps = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFps(parseInt(event.currentTarget.value, 10));
      dispatch(doSetFrameRate(Number(event.currentTarget.value)));
    },
    [setFps, dispatch]
  );

  const onSubmitBuild = useCallback(async () => {
    if (userprofile) {
      const { eoleEditExpiry, eoleEditStorageUsed, eoleEditStorageLimit, eoleEditTimeUsed, eoleEditTimeLimit } =
        userprofile;
      if (eoleEditExpiry && project) {
        const projectEndTime = getProjectEndTime(project);
        if (
          eoleEditTimeUsed != null &&
          eoleEditTimeLimit != null &&
          eoleEditTimeLimit > 0 &&
          eoleEditTimeUsed + projectEndTime >= eoleEditTimeLimit
        ) {
          dispatch(doShowProjectModal(false, MODALTYPE.BUILD));
          dispatch(doShowError(undefined, "You don't have enough time left to build your video"));
          return;
        }
        if (
          eoleEditStorageUsed != null &&
          eoleEditStorageLimit != null &&
          eoleEditStorageLimit > 0 &&
          eoleEditStorageUsed >= eoleEditStorageLimit
        ) {
          dispatch(doShowProjectModal(false, MODALTYPE.BUILD));
          dispatch(doShowError(undefined, "You don't have enough space left to build your video"));

          return;
        }
        if (isString(eoleEditExpiry) && new Date(eoleEditExpiry).getTime() < new Date().getTime()) {
          dispatch(doShowProjectModal(false, MODALTYPE.BUILD));
          dispatch(doShowError(undefined, 'Your rights have expired'));
          return;
        }
      }
    }

    if (
      project?.tracks.find(
        (track: ITrack) =>
          track.sections.findIndex(
            (section: ITrackSection) =>
              (section.kind === SectionKind.video || section.kind === SectionKind.image) && !section.preview
          ) !== -1
      )
    ) {
      dispatch(doShowProjectModal(false, MODALTYPE.BUILD));
      dispatch(doShowError(undefined, 'One or more section is missing its source'));
      return;
    }

    if (project && project._id) {
      let validBitrate = customBitrate;
      if (videoBitrate !== BITRATE_LIST.custom) {
        validBitrate = +videoBitrate;
      }
      dispatch(
        doBuildProject(project._id, title, isSameVideoSizeProject, Math.round(validBitrate * 1000), fps, environment)
      );
      dispatch(doShowProjectModal(false, MODALTYPE.BUILD));
      dispatch(doShowProjectBuildingModal(true));
    } else {
      dispatch(doShowError(new Error('project or project._id is missing')));
    }
  }, [dispatch, fps, title, isSameVideoSizeProject, environment, videoBitrate, customBitrate, project, userprofile]);

  const onSubmitEdit = useCallback(() => {
    if (project && project._id) {
      let validBitrate = customBitrate;
      if (videoBitrate !== BITRATE_LIST.custom) {
        validBitrate = +videoBitrate;
      }
      dispatch(doEditProject(project, project._id, title, isSameVideoSizeProject, Math.round(validBitrate * 1000)));
    } else {
      dispatch(doShowError(new Error('project or project._id is missing')));
    }
  }, [dispatch, title, isSameVideoSizeProject, videoBitrate, customBitrate, project]);

  function getModalTitle() {
    if (modalType === MODALTYPE.CREATION) {
      return 'New Project';
    }
    if (modalType === MODALTYPE.BUILD) {
      return 'Build Project';
    }
    return 'Edit Project';
  }
  function getConfirmButton() {
    if (modalType === MODALTYPE.CREATION) {
      return (
        <Button variant="success" type="submit" disabled={!title}>
          Create
        </Button>
      );
    }
    return (
      <Button
        disabled={!title || !isPossible}
        title={isPossible ? '' : impossibleMessage}
        variant="success"
        onClick={modalType === MODALTYPE.BUILD ? onSubmitBuild : onSubmitEdit}
      >
        {modalType === MODALTYPE.BUILD ? 'Build Video' : 'Update Project'}
      </Button>
    );
  }

  async function parseJsonFile(file: File) {
    return new Promise<ProjectStructure | undefined>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        if (typeof event.target?.result === 'string') {
          resolve(JSON.parse(event.target.result));
        }
      };
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsText(file);
    });
  }

  const handleImportProjectStructure = async () => {
    const file = await showOpenFileDialog('.json');
    if (file?.[0] && file[0].type === 'application/json') {
      const result = await parseJsonFile(file[0]);
      if (result) {
        setProjectStructure(result);
      }
    }
  };

  const handleDeleteProjectStructure = () => {
    setProjectStructure(undefined);
  };

  return (
    <Modal show={isProjectModalShown} onHide={handleHide} size="sm" className="new-project-modal" centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faFile} size="1x" />
            <span className="ml-3">{getModalTitle()}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label column sm="auto">
                Title
              </Form.Label>
              <Form.Control type="text" value={title} onChange={handleChangeTitle} isInvalid={!title} />
              <Form.Control.Feedback type="invalid">{'Title field cannot be empty'}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          {!projectStructure ? (
            <>
              {modalType === MODALTYPE.CREATION && (
                <Form.Row className="align-items-center">
                  <fieldset disabled={modalType !== 'creation'}>
                    <Form.Group as={Col}>
                      <Form.Label column sm="auto">
                        Project Type
                      </Form.Label>
                      <Form.Group as={Col}>
                        <Row className="d-flex align-items-center">
                          <ProjectTypeInput
                            isSameVideoSizeProject={isSameVideoSizeProject}
                            setIsSameVideoSizeProject={setIsSameVideoSizeProject}
                          />
                        </Row>
                      </Form.Group>
                    </Form.Group>
                  </fieldset>
                </Form.Row>
              )}
              <fieldset disabled={modalType !== 'creation'}>
                <Form.Row className="align-items-center">
                  {modalType === MODALTYPE.CREATION && (
                    <Form.Group as={Col}>
                      <Form.Label column sm="auto">
                        Size
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="sizeSelectIndex"
                        value={standardizedSizeIndex.toString()}
                        onChange={handleSizeSelectChange}
                      >
                        {VIDEO_SIZES.map((size, i) => ({ size, index: i })).map((item) => (
                          <option key={item.index} value={item.index}>
                            {item.size.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )}
                  <SizeNumber value={width} label="Width" onSizeChange={handleWidthChange} />
                  <div className="mt-3 clickable" onClick={swapDimensions}>
                    <FontAwesomeIcon icon={faExchange} />
                  </div>
                  <SizeNumber value={height} label="Height" onSizeChange={handleHeightChange} />
                </Form.Row>
              </fieldset>
              <Form.Row className="align-items-center">
                <Form.Group as={Col}>
                  <Form.Label column sm="auto">
                    Video Bitrate
                  </Form.Label>
                  <Form.Group as={Row}>
                    <Col className="d-flex align-items-center">
                      <BitrateInput
                        videoBitrate={videoBitrate}
                        customVideoBitrate={customBitrate}
                        onBitrateChange={handleBitrateChange}
                        onCustomBitrateChange={handleCustomBitrateChange}
                      />
                    </Col>
                  </Form.Group>
                </Form.Group>
              </Form.Row>
              {modalType === MODALTYPE.BUILD && (
                <Form.Row className="align-items-center">
                  <Form.Group as={Col}>
                    <Form.Label column sm="auto">
                      Framerate
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder={DEFAULT_FPS.toString()}
                      value={fps}
                      onChange={onChangeFps}
                    />
                  </Form.Group>
                </Form.Row>
              )}
              <Form.Row className="align-items-center">
                <Form.Group as={Col}>
                  <Form.Label column sm="auto">
                    Output codec information: H264 / .mp4
                  </Form.Label>
                </Form.Group>
              </Form.Row>
            </>
          ) : (
            <div>
              Structure of {projectStructure.name} imported successfully !
              <FontAwesomeIcon
                icon={faUndo}
                title="Cancel structure import"
                className="ml-2 delete-project-structure-icon"
                onClick={handleDeleteProjectStructure}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {modalType === MODALTYPE.CREATION && <Button onClick={handleImportProjectStructure}>Import Structure</Button>}

          <Button variant="danger" onClick={handleCancelClick}>
            Cancel
          </Button>
          {getConfirmButton()}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
