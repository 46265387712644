var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import Hls from 'hls.js';
import { hlsConfig, HlsStream } from './HlsStream';
import { MpegDashStream } from './MpegDashStream';
var MediaStream = function () {
  function MediaStream(url) {
    this.tracks = [];
    this.manifestUrl = url;
    if (url.split('.').pop() === 'm3u8') {
      this.hls = new Hls(hlsConfig);
    }
  }
  MediaStream.prototype.getTracks = function () {
    return this.tracks;
  };
  MediaStream.prototype.getInfos = function () {
    return this.infos;
  };
  MediaStream.prototype.decode = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!this.hls) return [3, 2];
            return [4, this.decodeHls()];
          case 1:
            return [2, _a.sent()];
          case 2:
            return [4, this.decodeMpegDash()];
          case 3:
            return [2, _a.sent()];
        }
      });
    });
  };
  MediaStream.prototype.decodeHls = function () {
    var _this = this;
    return new Promise(function (resolve, reject) {
      _this.hlsStream = new HlsStream(_this.manifestUrl);
      _this.hlsStream.parseTracks(function (tracks, level) {
        _this.tracks = tracks;
        var framerate = 25;
        var duration = level.details ? level.details.totalduration * 1000 : 0;
        _this.infos = {
          currentTime: 0,
          url: _this.manifestUrl,
          duration: duration,
          framerate: framerate
        };
        resolve({
          tracks: tracks,
          infos: _this.infos
        });
      });
    });
  };
  MediaStream.prototype.decodeMpegDash = function () {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
      var dashStream, manifest, framerate, duration;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            dashStream = new MpegDashStream(this.manifestUrl);
            return [4, dashStream.parseManifest()];
          case 1:
            manifest = _c.sent();
            return [4, dashStream.parseTracks()];
          case 2:
            _c.sent();
            this.tracks = dashStream.getTracks();
            framerate = (_b = (_a = manifest.adaptations.find(function (adaptation) {
              return adaptation.framerate;
            })) === null || _a === void 0 ? void 0 : _a.framerate) !== null && _b !== void 0 ? _b : 25;
            duration = Date.parse(manifest.duration);
            this.infos = {
              currentTime: 0,
              url: this.manifestUrl,
              duration: duration,
              framerate: framerate
            };
            return [2, {
              tracks: this.tracks,
              infos: this.infos
            }];
        }
      });
    });
  };
  MediaStream.prototype.attachMedia = function (media, track) {
    this.media = media;
    if (this.hls) {
      this.hls.loadSource(track.url);
      this.hls.attachMedia(media);
    } else {
      media.src = track.url;
    }
    if (media instanceof HTMLVideoElement && track.width && track.height) {
      media.width = track.width;
      media.height = track.height;
    }
  };
  MediaStream.prototype.detachMedia = function () {
    if (this.hls) {
      this.hls.destroy();
    } else if (this.media) {
      this.media.src = '';
    }
  };
  return MediaStream;
}();
export { MediaStream };