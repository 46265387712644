import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { faDesktop, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { showOpenFileDialog, ProgressBar } from '@eolementhe/react-components';
import { FileResource } from '@eolementhe/video-editor-model';
import { uploadFile, UploadProgress } from '../../../utils/uploadFiles';
import { ResourceBrowser } from '../../ResourceBrowser/ResourceBrowser';
import { ViewType } from '../../ResourceBrowser/ViewType';
import './AddPersonalFileTab.scss';
import { doShowError } from '../../../redux/actions';
import { acceptableInputFileExtension, FileUtils } from '../../../utils/FileUtils';
import { getEoleEditRight } from '../../../utils/Userprofile';

interface AddPersonalFileTabProps {
  onAdd(files: FileResource[]): void;
  resourceType: FileResource['resourceKind'];
  defaultViewType?: ViewType;
  onAddTracks?(resource?: FileResource): void;
  canSelect?: boolean;
  showError?: (strError?: string) => void;
}

interface UploadState {
  uploadId: string;
  fileName: string;
  upProgress: number;
  lrProgress: number;
  resource?: FileResource;
}
export type UploadStates = Record<string, UploadState>;
interface AddPersonalFileTabState {
  uploads: UploadStates;
}

class AddPersonalFileTab extends React.Component<AddPersonalFileTabProps, AddPersonalFileTabState> {
  public constructor(props: AddPersonalFileTabProps) {
    super(props);
    this.state = { uploads: {} };
    this.handleClickBrowse = this.handleClickBrowse.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
  }

  private onUploadProgress(uploadProgress: UploadProgress): void {
    // We can have several uploads
    const uploadId = uploadProgress.uploadId;
    const fileName = uploadProgress.file.name;
    const upProgress = uploadProgress.status.uploadProgress;
    const lrProgress = uploadProgress.status.lowresProgress;
    const resource = uploadProgress.resource;

    const newStateUploads: UploadStates = this.state.uploads;
    if (resource) {
      if (newStateUploads[uploadId]) {
        delete newStateUploads[uploadId];
      }
      this.props.onAdd([resource]);
    } else if (uploadProgress.error || upProgress === -1 || lrProgress === -1) {
      if (this.props.showError) {
        this.props.showError(uploadProgress.error);
      }
      delete newStateUploads[uploadId];
    } else if (!newStateUploads[uploadId]) {
      newStateUploads[uploadId] = { uploadId, fileName, upProgress, lrProgress };
    } else {
      newStateUploads[uploadId] = { ...newStateUploads[uploadId], ...{ uploadId, fileName, upProgress, lrProgress } };
    }
    this.setState({ uploads: newStateUploads });
  }

  private async checkAndUpload(fileList: FileList | void) {
    const { resourceType } = this.props;
    if (!fileList) {
      return;
    }

    if (!FileUtils.isAcceptedInputFiles(fileList, resourceType)) {
      if (this.props.showError) {
        const fileNames = [...fileList].map((file) => file.name).join(', ');
        this.props.showError(`Selected file has an unsupported extension: ${fileNames}`);
      }
      return;
    }

    [...fileList].forEach((file) => {
      let mimeType = '';
      if (!file.type) {
        mimeType = `${resourceType}/${FileUtils.getExtension(file)}`;
      }
      uploadFile(file, mimeType, this.onUploadProgress);
    });
  }

  private async handleClickBrowse() {
    const { resourceType } = this.props;

    const acceptableInputFileExtensionsWithDot = acceptableInputFileExtension[resourceType].map(
      (extension) => `.${extension}`
    );

    const fileList = await showOpenFileDialog(acceptableInputFileExtensionsWithDot.join(', '));
    this.checkAndUpload(fileList);
  }

  private async handleDragOver(event: React.DragEvent<HTMLInputElement>) {
    event.preventDefault();
    event.stopPropagation();
  }

  private async handleDragLeave(event: React.DragEvent<HTMLInputElement>) {
    event.preventDefault();
    event.stopPropagation();
  }

  private async handleDrop(event: React.DragEvent<HTMLInputElement>) {
    event.preventDefault();
    event.stopPropagation();
    this.checkAndUpload(event.dataTransfer.files);
  }

  public render() {
    const uploadKeys = Object.keys(this.state.uploads);

    // Id there are upload running display upload progress panel
    if (uploadKeys && uploadKeys.length) {
      return uploadKeys.map((uploadKey) => {
        const upload = this.state.uploads[uploadKey];
        return (
          <div key={`upp-${uploadKey}`} className="add-personal-file-progress mt-4">
            <div className="upload-progress-title">Uploading and Preparing your resource</div>
            <div className="upload-progress-filename">{upload.fileName}</div>
            <ProgressBar key={`u-${uploadKey}`} textAbove="Upload Progress" percentProgress={upload.upProgress} />
            <ProgressBar key={`l-${uploadKey}`} textAbove="LowRes Progress" percentProgress={upload.lrProgress} />
          </div>
        );
      });
    }

    const isRightExpired = getEoleEditRight()?.expiry.getTime() < new Date().getTime();

    //Display resource browser otherwise
    return (
      <div
        className="add-personal-file-tab flex-fill d-flex flex-column min-height-0"
        onDragEnter={this.handleDragOver}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
      >
        <Row>
          <Form.Group as={Col}>
            <Form.Label>
              <FontAwesomeIcon icon={faDesktop} />
              {' Computer'}
            </Form.Label>
            <InputGroup>
              <Form.Control type="text" placeholder={'Drag & drop or click Browse'} disabled={true} />
              <InputGroup.Append>
                <Button
                  disabled={isRightExpired}
                  onClick={isRightExpired ? () => {} : this.handleClickBrowse}
                  title={isRightExpired ? 'Your rights have expired' : ''}
                >
                  <FontAwesomeIcon icon={faUpload} />
                  {' Browse'}
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Row>
        <div className="flex-fill overflow-auto min-height-0 mb-1">
          <ResourceBrowser
            defaultViewType={ViewType.thumbnails}
            resourceType={this.props.resourceType}
            canSelect={this.props.canSelect}
            onAddTracks={this.props.onAddTracks}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    showError: (strError?: string) => dispatch(doShowError(strError, 'Unable to upload file'))
  };
};
export default connect(null, mapDispatchToProps)(AddPersonalFileTab);
