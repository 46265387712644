var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React from "react";
var DEFAULT_CONTEXT = {
  selected: [],
  text: "",
  dropdownOpen: false,
  suggestions: [],
  currentSuggestion: 0
};
function reduceValue(context, action) {
  switch (action.kind) {
    case "set":
      return action.value;
    default:
      throw new Error("Unsupported reducer action.");
  }
}
function reduce(context, action) {
  var _a;
  if (action.kind === "set" && action.prop === "suggestions") {
    return __assign(__assign({}, context), {
      currentSuggestion: 0,
      suggestions: action.value
    });
  }
  if (action.kind === "set" && action.prop === "dropdownOpen" && action.value === false) {
    return __assign(__assign({}, context), {
      currentSuggestion: 0,
      dropdownOpen: action.value,
      text: ""
    });
  }
  return __assign(__assign({}, context), (_a = {}, _a[action.prop] = reduceValue(context, action), _a));
}
var ChipsInputContext = React.createContext(DEFAULT_CONTEXT);
var ChipsInputDispatchContext = React.createContext(function () {
  return 0;
});
export var ChipsInputContextProvider = function ChipsInputContextProvider(_a) {
  var children = _a.children,
    selected = _a.selected;
  var defaultContext = __assign(__assign({}, DEFAULT_CONTEXT), {
    selected: selected
  });
  var reducer = React.useCallback(function (context, action) {
    return reduce(context, action);
  }, []);
  var _b = React.useReducer(reducer, defaultContext),
    state = _b[0],
    dispatch = _b[1];
  return React.createElement(ChipsInputDispatchContext.Provider, {
    value: dispatch
  }, React.createElement(ChipsInputContext.Provider, {
    value: __assign(__assign({}, state), {
      selected: selected
    })
  }, children));
};
export function useContext() {
  return React.useContext(ChipsInputContext);
}
export function useDispatch() {
  return React.useContext(ChipsInputDispatchContext);
}