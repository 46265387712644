import { Timecode } from '@eolementhe/core';
var TimeUtils = function () {
  function TimeUtils() {}
  TimeUtils.formatTime = function (timecode, displayFrame) {
    return displayFrame ? timecode.toFString() : timecode.toMsString();
  };
  TimeUtils.formatOffset = function (framerate, offset, displayOffset) {
    return offset && displayOffset ? Timecode.fromString(offset, framerate) : undefined;
  };
  return TimeUtils;
}();
export { TimeUtils };