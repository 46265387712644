"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMimeGlobalType = exports.MimeGlobalType = void 0;
/**
 * Full list of MIME global types (the first part before `/`) according to IANA
 * standards.
 *
 * @public
 */
var MimeGlobalType;
(function (MimeGlobalType) {
  MimeGlobalType["application"] = "application";
  MimeGlobalType["audio"] = "audio";
  MimeGlobalType["example"] = "example";
  MimeGlobalType["font"] = "font";
  MimeGlobalType["image"] = "image";
  MimeGlobalType["message"] = "message";
  MimeGlobalType["model"] = "model";
  MimeGlobalType["multipart"] = "multipart";
  MimeGlobalType["text"] = "text";
  MimeGlobalType["video"] = "video";
})(MimeGlobalType = exports.MimeGlobalType || (exports.MimeGlobalType = {}));
/**
 * Extract the global type from a MIME type given as argument.
 *
 * @param mimeType - The mime type string to parse (e.g. `video/mp4`).
 *
 * @example
 *
 * ```ts
 * extractMimeGlobalType("video/mp4"); // Returns MimeGlobalType.video.
 * extractMimeGlobalType("unknown/mp4"); // Throws an error.
 * extractMimeGlobalType("mp4"); // Throws an Error.
 * ```
 *
 * @public
 */
function getMimeGlobalType(mimeType) {
  var regex = /([^\/]+)\/(.*)/;
  var match = mimeType.match(regex);
  var globalType = match === null || match === void 0 ? void 0 : match[1];
  if (!globalType) {
    throw new Error("Error while parsing MIME type from string \"".concat(mimeType, "\"."));
  }
  if (!Object.keys(MimeGlobalType).includes(globalType)) {
    throw new Error("Unknown MIME global type \"".concat(globalType, "\"."));
  }
  return MimeGlobalType[globalType];
}
exports.getMimeGlobalType = getMimeGlobalType;