import { CssFontFamily, ITextSection } from '@eolementhe/video-editor-model';

function getWordSize(widthCalculatorDiv: HTMLDivElement, word: string) {
  widthCalculatorDiv.textContent = word;
  const rect = widthCalculatorDiv.getBoundingClientRect();
  widthCalculatorDiv.textContent = '';
  return {
    width: rect.width,
    height: rect.height
  };
}

export function getDisplayText(section: ITextSection) {
  let resultText = '';
  try {
    const displayTextDom = document.querySelector('.display-text');
    const displayTextDiv = (displayTextDom || document.createElement('div')) as HTMLDivElement;
    displayTextDiv.innerHTML = `
          <div class="display-text">
              <p 
                  id="width-calculator" 
                  style="
                      font-family: font-family: ${CssFontFamily[section.font].cssFamily};;
                      font-size: ${section.fontSize}px;
                      display: inline-block;
                      line-height: 1;
                      box-sizing: border-box;
                      font-weight: ${
                        section.fontStyle && section.fontStyle.toLowerCase().includes('bold') ? 'bold' : 'normal'
                      };
                      font-style: ${
                        section.fontStyle && section.fontStyle.toLowerCase().includes('italic') ? 'italic' : 'normal'
                      };
                  "
              ></p>
          </div>
      `;
    if (!displayTextDom) {
      document.body.appendChild(displayTextDiv);
    }
    const widthCalculatorDiv = document.querySelector('.display-text #width-calculator') as HTMLDivElement;

    const textHeight = getWordSize(widthCalculatorDiv, 'Y').height;
    const boxSize = section.width * Math.max(Math.floor(section.height / textHeight), 1);

    let sum = 0;
    const textArr = section.text.split(' ');

    for (let i = 0; i < textArr.length; i++) {
      const word = textArr[i];
      const isLast = i === textArr.length - 1;
      const wordWidth = getWordSize(widthCalculatorDiv, word).width;
      sum += wordWidth;
      if (sum >= boxSize) {
        break;
      } else {
        resultText += word + (!isLast ? ' ' : '');
        sum += getWordSize(widthCalculatorDiv, 'i i').width - getWordSize(widthCalculatorDiv, 'ii').width;
      }
    }
  } catch (err) {
    console.log(err);
  }
  return resultText.trim();
}
