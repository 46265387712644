import { commitMutation } from 'react-relay';
import { Track } from '@eolementhe/video-editor-model';

import { RELAY_ENVIRONMENT } from '../api/relay-environment';
import { SAVE_PROJECT_MUTATION } from '../api/GraphQL';
import { GraphQLSaveProjectMutation } from '../__generated__/GraphQLSaveProjectMutation.graphql';

interface IProjectReturn {
  id: string;
  lastUpdate: string;
}

export function saveProject(
  duration: number,
  tracks: Track[],
  title: string,
  isSameVideoSizeProject: string,
  videoBitrate: number,
  size: {
    height: number;
    width: number;
  }
): Promise<IProjectReturn> {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    commitMutation<GraphQLSaveProjectMutation>(RELAY_ENVIRONMENT, {
      mutation: SAVE_PROJECT_MUTATION,
      variables: { title, isSameVideoSizeProject, size, duration, tracks, videoBitrate },
      onCompleted: (res) =>
        resolve({
          id: res.saveProject._id,
          lastUpdate: res.saveProject.lastUpdate as string
        }),
      onError: reject
    });
  });
}
