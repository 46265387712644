import { commitMutation } from 'react-relay';

import { RELAY_ENVIRONMENT } from '../api/relay-environment';
import { EDIT_PROJECT_MUTATION } from '../api/GraphQL';
import { GraphQLEditProjectMutation } from '../__generated__/GraphQLEditProjectMutation.graphql';

interface IProjectReturn {
  id: string;
  lastUpdate: string;
}

export function editProject(
  _id: string,
  title: string,
  isSameVideoSizeProject: string,
  videoBitrate: number
): Promise<IProjectReturn> {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    commitMutation<GraphQLEditProjectMutation>(RELAY_ENVIRONMENT, {
      mutation: EDIT_PROJECT_MUTATION,
      variables: { _id, title, isSameVideoSizeProject, videoBitrate },
      onCompleted: (res) =>
        resolve({
          id: res.editProject._id,
          lastUpdate: res.editProject.lastUpdate as string
        }),
      onError: reject
    });
  });
}
