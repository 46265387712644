import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IProject } from '@eolementhe/video-editor-model';

interface IProps {
  project: IProject;
}

export const ProjectSettingsPanel: React.FC<IProps> = ({ project }) => (
  <Col>
    <Row>{`Title: ${project.title}`}</Row>
    <Row>{`Size: ${project.size.width}x${project.size.height}`}</Row>
  </Col>
);
