import { commitMutation } from 'react-relay';
import { Environment, GraphQLTaggedNode } from 'react-relay/hooks';
import { GraphQLRemoveProjectMutation } from '../__generated__/GraphQLRemoveProjectMutation.graphql';
import { REMOVE_PROJECT_MUTATION } from './GraphQL';

export default async function (environment: Environment, id: string): Promise<string> {
  return new Promise((resolve, reject) => {
    commitMutation<GraphQLRemoveProjectMutation>(environment, {
      mutation: REMOVE_PROJECT_MUTATION as GraphQLTaggedNode,
      variables: { id },
      onCompleted: () => {
        resolve(id);
      },
      onError: (err: Error) => {
        reject(err);
      }
    });
  });
}
