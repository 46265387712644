import React, { FC } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { faMobile, faSquare, faTv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProjectTypeInputProps {
  isSameVideoSizeProject: string;
  setIsSameVideoSizeProject: (value: string | ((prevVar: string) => string)) => void;
}

export const ProjectTypeInput: FC<IProjectTypeInputProps> = ({ isSameVideoSizeProject, setIsSameVideoSizeProject }) => {
  const handleProjectTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSameVideoSizeProject(event.target.value);
  };
  return (
    <>
      <InputGroup>
        <Form.Check
          type="radio"
          id="broadcast-radio"
          checked={isSameVideoSizeProject === 'uncut'}
          value={'uncut'}
          onChange={handleProjectTypeChange}
        />
        <Form.Label>I only use the same video size</Form.Label>
      </InputGroup>
      <InputGroup>
        <Form.Check
          type="radio"
          id="cut-radio"
          value={'cut'}
          checked={isSameVideoSizeProject === 'cut'}
          onChange={handleProjectTypeChange}
          aria-describedby="cut-radio-help"
        />
        <Form.Label className="mr-2">I use different video size</Form.Label>
        <FontAwesomeIcon icon={faTv} className="mr-2" />
        <FontAwesomeIcon icon={faMobile} className="mr-2" />
        <FontAwesomeIcon icon={faSquare} className="mr-2" />
        <Form.Text id="cut-check-help" className="ml-4">
          Video Editing without transition, but they will arrive soon
        </Form.Text>
      </InputGroup>
    </>
  );
};
