var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React from "react";
import { DisplayMode } from "./DisplayMode";
import { JobTitle } from "./JobTitles";
var DEFAULT_CONTEXT = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  company: "",
  phoneCountryIndicator: "+33",
  phone: "",
  jobTitle: JobTitle.undefined,
  displayMode: DisplayMode.register
};
function reduceAction(action) {
  var _a;
  switch (action.kind) {
    case "set":
      return _a = {}, _a[action.prop] = action.value, _a;
    case "showRegister":
      return {
        displayMode: DisplayMode.register
      };
    case "showSignIn":
      return {
        displayMode: DisplayMode.signIn
      };
    case "showPasswordReset":
      return {
        displayMode: DisplayMode.passwordReset
      };
    default:
      throw new Error("Unsupported reducer action.");
  }
}
var AuthenticationReducer = function AuthenticationReducer(context, action) {
  return __assign(__assign({}, context), reduceAction(action));
};
var AuthenticationContext = React.createContext(DEFAULT_CONTEXT);
var AuthenticationDispatchContext = React.createContext(function () {
  return 0;
});
export var AuthenticationContextProvider = function AuthenticationContextProvider(_a) {
  var defaultDisplayMode = _a.defaultDisplayMode,
    children = _a.children;
  var displayMode = defaultDisplayMode !== null && defaultDisplayMode !== void 0 ? defaultDisplayMode : DEFAULT_CONTEXT.displayMode;
  var _b = React.useReducer(AuthenticationReducer, __assign(__assign({}, DEFAULT_CONTEXT), {
      displayMode: displayMode
    })),
    state = _b[0],
    dispatch = _b[1];
  return React.createElement(AuthenticationDispatchContext.Provider, {
    value: dispatch
  }, React.createElement(AuthenticationContext.Provider, {
    value: state
  }, children));
};
export function useContext() {
  return React.useContext(AuthenticationContext);
}
export function useDispatch() {
  return React.useContext(AuthenticationDispatchContext);
}