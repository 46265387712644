var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { fixOverlayTrigger } from "../react-bootstrap-types/OverlayTrigger";
var TextTooltip = function (_super) {
  __extends(TextTooltip, _super);
  function TextTooltip() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  TextTooltip.prototype.render = function () {
    var _a = this.props,
      text = _a.text,
      trigger = _a.trigger,
      props = __rest(_a, ["text", "trigger"]);
    return React.createElement(OverlayTrigger, __assign({}, props, {
      trigger: fixOverlayTrigger(trigger),
      overlay: function overlay(props) {
        return React.createElement(Tooltip, __assign({
          id: "text-tooltip"
        }, props), text);
      }
    }));
  };
  return TextTooltip;
}(React.PureComponent);
export { TextTooltip };