import React, { FC } from 'react';
import { Alert, Button } from 'react-bootstrap';

interface LoadProjectSaveAlertProps {
  show: boolean;
  onAnswer: (agree: boolean) => void;
}

const LoadProjectSaveAlert: FC<LoadProjectSaveAlertProps> = ({ show, onAnswer }) => {
  return (
    <Alert show={show} variant="dark" style={{ position: 'absolute', top: '20%', left: '25%', width: '50%' }}>
      <Alert.Heading>Save your project first</Alert.Heading>
      <p>
        You may have unsaved changes to your work and loading a new project will discard them. Do you want to load
        anyway ?
      </p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button className="mr-2" onClick={() => onAnswer(false)} variant="outline-dark">
          Cancel
        </Button>
        <Button className="mr-2" onClick={() => onAnswer(true)} variant="outline-success">
          Load
        </Button>
      </div>
    </Alert>
  );
};

export default LoadProjectSaveAlert;
