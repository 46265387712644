/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLGetBuildVideoQueryVariables = {
    buildId: string;
};
export type GraphQLGetBuildVideoQueryResponse = {
    readonly getBuildProgress: {
        readonly taskProgress: {
            readonly title: string;
            readonly progress: number;
        };
        readonly stepProgress: {
            readonly currentStep: string;
            readonly progress: number;
        };
    };
};
export type GraphQLGetBuildVideoQuery = {
    readonly response: GraphQLGetBuildVideoQueryResponse;
    readonly variables: GraphQLGetBuildVideoQueryVariables;
};



/*
query GraphQLGetBuildVideoQuery(
  $buildId: String!
) {
  getBuildProgress(buildId: $buildId) {
    taskProgress {
      title
      progress
    }
    stepProgress {
      currentStep
      progress
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "buildId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "buildId",
        "variableName": "buildId"
      }
    ],
    "concreteType": "BuildProgress",
    "kind": "LinkedField",
    "name": "getBuildProgress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskProgress",
        "kind": "LinkedField",
        "name": "taskProgress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StepProgress",
        "kind": "LinkedField",
        "name": "stepProgress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentStep",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLGetBuildVideoQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GraphQLGetBuildVideoQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bdeb5dac82b0e9fd8ae923c530e09cc9",
    "id": null,
    "metadata": {},
    "name": "GraphQLGetBuildVideoQuery",
    "operationKind": "query",
    "text": "query GraphQLGetBuildVideoQuery(\n  $buildId: String!\n) {\n  getBuildProgress(buildId: $buildId) {\n    taskProgress {\n      title\n      progress\n    }\n    stepProgress {\n      currentStep\n      progress\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8a67c0ccb449624a3ac66af0a3e827ca';
export default node;
