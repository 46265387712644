/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLSaveTextPresetMutationVariables = {
    name: string;
    text: string;
    font: string;
    fontColor: string;
    fontSize: number;
    fontStyle: string;
    backgroundColor: string;
    borderColor: string;
    borderSize: number;
    rotation: number;
};
export type GraphQLSaveTextPresetMutationResponse = {
    readonly saveTextPreset: {
        readonly _id: string;
        readonly name: string;
    };
};
export type GraphQLSaveTextPresetMutation = {
    readonly response: GraphQLSaveTextPresetMutationResponse;
    readonly variables: GraphQLSaveTextPresetMutationVariables;
};



/*
mutation GraphQLSaveTextPresetMutation(
  $name: String!
  $text: String!
  $font: String!
  $fontColor: String!
  $fontSize: Float!
  $fontStyle: String!
  $backgroundColor: String!
  $borderColor: String!
  $borderSize: Float!
  $rotation: Float!
) {
  saveTextPreset(name: $name, text: $text, font: $font, fontColor: $fontColor, fontSize: $fontSize, fontStyle: $fontStyle, backgroundColor: $backgroundColor, borderColor: $borderColor, borderSize: $borderSize, rotation: $rotation) {
    _id
    name
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "backgroundColor"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "borderColor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "borderSize"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "font"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fontColor"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fontSize"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fontStyle"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rotation"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "backgroundColor",
        "variableName": "backgroundColor"
      },
      {
        "kind": "Variable",
        "name": "borderColor",
        "variableName": "borderColor"
      },
      {
        "kind": "Variable",
        "name": "borderSize",
        "variableName": "borderSize"
      },
      {
        "kind": "Variable",
        "name": "font",
        "variableName": "font"
      },
      {
        "kind": "Variable",
        "name": "fontColor",
        "variableName": "fontColor"
      },
      {
        "kind": "Variable",
        "name": "fontSize",
        "variableName": "fontSize"
      },
      {
        "kind": "Variable",
        "name": "fontStyle",
        "variableName": "fontStyle"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "rotation",
        "variableName": "rotation"
      },
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "text"
      }
    ],
    "concreteType": "TextPreset",
    "kind": "LinkedField",
    "name": "saveTextPreset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLSaveTextPresetMutation",
    "selections": (v10/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Operation",
    "name": "GraphQLSaveTextPresetMutation",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "52feee0e9691be8f14eb7f24db921c31",
    "id": null,
    "metadata": {},
    "name": "GraphQLSaveTextPresetMutation",
    "operationKind": "mutation",
    "text": "mutation GraphQLSaveTextPresetMutation(\n  $name: String!\n  $text: String!\n  $font: String!\n  $fontColor: String!\n  $fontSize: Float!\n  $fontStyle: String!\n  $backgroundColor: String!\n  $borderColor: String!\n  $borderSize: Float!\n  $rotation: Float!\n) {\n  saveTextPreset(name: $name, text: $text, font: $font, fontColor: $fontColor, fontSize: $fontSize, fontStyle: $fontStyle, backgroundColor: $backgroundColor, borderColor: $borderColor, borderSize: $borderSize, rotation: $rotation) {\n    _id\n    name\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e03184ee7636fd71262e0d358dd7670a';
export default node;
