import Hls from 'hls.js';
export var hlsConfig = {
  maxBufferLength: 200,
  backBufferLength: 0,
  maxBufferSize: 600,
  maxBufferHole: 0.5,
  highBufferWatchdogPeriod: 4,
  debug: true
};
export function changeHlsConfig(config) {
  hlsConfig = config;
}
var HlsStream = function () {
  function HlsStream(url) {
    this.tracks = [];
    this.hls = new Hls(hlsConfig);
    console.log('Current Hls Config - ', hlsConfig);
    this.url = url;
  }
  HlsStream.prototype.getTracks = function () {
    return this.tracks;
  };
  HlsStream.prototype.parseTracks = function (cb) {
    var _this = this;
    this.hls.loadSource(this.url);
    this.hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
      _this.parseData(data, cb);
    });
  };
  HlsStream.prototype.parseData = function (data, cb) {
    var _this = this;
    var level = data.levels[0];
    this.tracks = [];
    if ((level === null || level === void 0 ? void 0 : level.url) && level.url[0]) {
      this.tracks.push({
        id: level.url[0],
        type: data.video ? 'video' : 'audio',
        url: level.url[0],
        width: level.width,
        height: level.height,
        isMuted: false,
        canPlay: false,
        channels: []
      });
    }
    this.tracks = this.tracks.concat(data.audioTracks.map(function (audioTrack) {
      return {
        id: audioTrack.url,
        type: 'audio',
        url: audioTrack.url,
        isMuted: false,
        canPlay: false,
        channels: []
      };
    }));
    setTimeout(function () {
      cb(_this.tracks, level);
    }, 3000);
  };
  return HlsStream;
}();
export { HlsStream };