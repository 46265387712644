var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { Button, Nav, Overlay } from 'react-bootstrap';
export var ActionButtonWithDropdown = function ActionButtonWithDropdown(_a) {
  var action = _a.action;
  var active = action.active,
    canExecute = action.canExecute,
    execute = action.execute,
    icon = action.icon,
    child = action.child,
    title = action.title,
    TypeElement = action.TypeElement,
    type = action.type;
  var executable = canExecute === undefined || canExecute;
  var _b = useState(false),
    isChildToggled = _b[0],
    setIsChildToggled = _b[1];
  var _c = useState(null),
    childTarget = _c[0],
    setChildTarget = _c[1];
  var _d = useState(false),
    isDropdownToggled = _d[0],
    setIsDropdownToggled = _d[1];
  var _e = useState(false),
    dropdownMouseEnter = _e[0],
    setDropdownMouseEnter = _e[1];
  var attachChildRef = function attachChildRef(target) {
    return setChildTarget(target);
  };
  useEffect(function () {
    if (canExecute && isDropdownToggled) {
      var domClick_1 = function domClick_1() {
        if (!dropdownMouseEnter) {
          setIsDropdownToggled(false);
        }
      };
      document.body.addEventListener('click', domClick_1);
      return function () {
        document.body.removeEventListener('click', domClick_1);
      };
    }
  }, [canExecute, dropdownMouseEnter, isDropdownToggled]);
  var renderButton = function renderButton() {
    return child ? React.createElement(React.Fragment, null, React.createElement(Button, {
      ref: attachChildRef,
      type: "button",
      variant: "outline-primary",
      className: isChildToggled && executable ? 'ab-active' : '',
      onClick: function onClick() {
        return setIsChildToggled(!isChildToggled);
      },
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      active: active,
      title: title,
      disabled: !executable
    }, React.createElement(FontAwesomeIcon, {
      icon: icon,
      size: "sm",
      fixedWidth: true
    })), React.createElement(Overlay, {
      target: childTarget,
      show: isChildToggled,
      placement: "right",
      rootClose: true,
      onHide: function onHide() {
        return setIsChildToggled(false);
      }
    }, function (_a) {
      var placement = _a.placement,
        scheduleUpdate = _a.scheduleUpdate,
        arrowProps = _a.arrowProps,
        outOfBoundaries = _a.outOfBoundaries,
        show = _a.show,
        props = __rest(_a, ["placement", "scheduleUpdate", "arrowProps", "outOfBoundaries", "show"]);
      return React.createElement("div", __assign({}, props, {
        className: "ab-children",
        style: __assign({}, props.style)
      }), child.map(function (_a) {
        var execute = _a.execute,
          title = _a.title,
          icon = _a.icon;
        return React.createElement(Button, {
          key: title,
          title: title,
          className: "ab-child",
          type: "button",
          variant: "outline-primary",
          onClick: execute
        }, React.createElement(FontAwesomeIcon, {
          icon: icon,
          size: "sm",
          fixedWidth: true
        }), title && React.createElement("span", {
          className: "ab-child-title"
        }, title));
      }));
    })) : React.createElement(Button, {
      type: "button",
      variant: "outline-primary",
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      onClick: execute,
      active: active,
      title: title,
      disabled: !executable
    }, React.createElement(FontAwesomeIcon, {
      icon: icon,
      size: "sm",
      fixedWidth: true
    }));
  };
  var renderNavLink = function renderNavLink() {
    return React.createElement(Nav.Link, {
      onClick: execute,
      active: active,
      disabled: !executable
    }, React.createElement(FontAwesomeIcon, {
      icon: icon,
      size: "sm",
      fixedWidth: true
    }));
  };
  var renderDropdown = function renderDropdown() {
    var disabledIcon = !canExecute ? {
      color: '#282828'
    } : {};
    return React.createElement("span", {
      className: "ab-dropdown"
    }, React.createElement(Button, {
      variant: "outline-primary",
      className: "action-button",
      size: "sm",
      onClick: function onClick() {
        return setIsDropdownToggled(!isDropdownToggled);
      },
      active: false,
      disabled: !canExecute
    }, React.createElement(FontAwesomeIcon, {
      icon: icon,
      fixedWidth: true,
      style: __assign({
        width: '0.8rem'
      }, disabledIcon)
    }), React.createElement(FontAwesomeIcon, {
      icon: faSortDown,
      size: "sm",
      style: __assign({
        transform: 'translateY(-3px)'
      }, disabledIcon)
    })), isDropdownToggled && React.createElement("div", {
      className: "ab-dropdown-content",
      onMouseEnter: function onMouseEnter() {
        return setDropdownMouseEnter(true);
      },
      onMouseLeave: function onMouseLeave() {
        return setDropdownMouseEnter(false);
      }
    }, TypeElement && React.createElement(TypeElement, {
      onClose: function onClose() {
        return setIsDropdownToggled(false);
      }
    })));
  };
  var renderClickable = function renderClickable() {
    switch (type) {
      case 'dropdown':
        return renderDropdown();
      case 'nav':
        return renderNavLink();
      case undefined:
      case 'button':
        return renderButton();
      default:
        throw new Error('Unsupported clickable type');
    }
  };
  var clickable = renderClickable();
  if (isDropdownToggled || isChildToggled) {
    return clickable;
  }
  return child ? renderButton() : clickable;
};