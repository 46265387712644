/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLEditProjectMutationVariables = {
    _id?: string | null;
    title: string;
    videoBitrate: number;
};
export type GraphQLEditProjectMutationResponse = {
    readonly editProject: {
        readonly _id: string;
        readonly lastUpdate: unknown;
    };
};
export type GraphQLEditProjectMutation = {
    readonly response: GraphQLEditProjectMutationResponse;
    readonly variables: GraphQLEditProjectMutationVariables;
};



/*
mutation GraphQLEditProjectMutation(
  $_id: ID
  $title: String!
  $videoBitrate: Float!
) {
  editProject(_id: $_id, title: $title, videoBitrate: $videoBitrate) {
    _id
    lastUpdate
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "title"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "videoBitrate"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "_id"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      },
      {
        "kind": "Variable",
        "name": "videoBitrate",
        "variableName": "videoBitrate"
      }
    ],
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "editProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastUpdate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLEditProjectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GraphQLEditProjectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "980038556ff4c90df2980ac3494c804a",
    "id": null,
    "metadata": {},
    "name": "GraphQLEditProjectMutation",
    "operationKind": "mutation",
    "text": "mutation GraphQLEditProjectMutation(\n  $_id: ID\n  $title: String!\n  $videoBitrate: Float!\n) {\n  editProject(_id: $_id, title: $title, videoBitrate: $videoBitrate) {\n    _id\n    lastUpdate\n  }\n}\n"
  }
};
})();
(node as any).hash = '4c07a4587970086357ba5ca7112a43e8';
export default node;
