var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import React from "react";
import { Cookies } from "react-cookie";
import { Alert } from "react-bootstrap";
import { MarkdownMessage } from "./MarkdownMessage";
var COOKIE_KEY = "AnnouncementBanner";
var AnnouncementBanner = function (_super) {
  __extends(AnnouncementBanner, _super);
  function AnnouncementBanner(props) {
    var _this = _super.call(this, props) || this;
    _this.state = {
      isDismissed: false
    };
    _this.handleDismiss = _this.handleDismiss.bind(_this);
    return _this;
  }
  AnnouncementBanner.getDerivedStateFromProps = function (_a, oldState) {
    var cookieId = _a.cookieId;
    if (cookieId === oldState.lastCookieId) return {};
    return {
      isDismissed: AnnouncementBanner.readIsDismissedCookie(cookieId),
      lastCookieId: cookieId
    };
  };
  AnnouncementBanner.readIsDismissedCookie = function (cookieId) {
    var cookiesHelper = new Cookies();
    var bannerCookie = cookiesHelper.get(COOKIE_KEY);
    return bannerCookie && bannerCookie.includes(cookieId);
  };
  AnnouncementBanner.prototype.render = function () {
    var _a;
    return React.createElement(React.Fragment, null, !this.state.isDismissed && React.createElement(Alert, {
      key: "4fbfe624-f53d-45b0-ba89-2523f16cfd74",
      variant: (_a = this.props.variant) !== null && _a !== void 0 ? _a : "primary",
      onClose: this.handleDismiss,
      className: this.props.className,
      dismissible: true
    }, React.createElement(MarkdownMessage, {
      className: "announcement-banner-message",
      value: this.props.message
    })));
  };
  AnnouncementBanner.prototype.handleDismiss = function () {
    var _a;
    var cookiesHelper = new Cookies();
    var bannerCookie = (_a = cookiesHelper.get(COOKIE_KEY)) !== null && _a !== void 0 ? _a : [];
    cookiesHelper.set(COOKIE_KEY, bannerCookie.concat([this.props.cookieId]));
    this.setState({
      isDismissed: true
    });
  };
  return AnnouncementBanner;
}(React.PureComponent);
export { AnnouncementBanner };