import { Reducer } from '@reduxjs/toolkit';
import {
  FileResource,
  TrackSection,
  TrackSections,
  SectionKind,
  Track,
  IVideoSection,
  IAudioSection,
  IVideoTransitionSection,
  ITrackSection,
  TransitionEffect,
  ITrack,
  TrackKind,
  IVideoTrack,
  MediaSection
} from '@eolementhe/video-editor-model';
import uuidv4 from 'uuid/v4';
import { omit, round } from 'lodash';
import { fetchQuery, GraphQLTaggedNode } from 'react-relay/hooks';
import ACTION_TYPES from './actionTypes';
import { EoleEditState, MODALTYPE, ProjectStructure, SelectableResource, StructureTrack } from './types';
import { CenterShapeDirection } from './actions';
import { TextPreset } from '../utils/saveTextPreset';
import { getDisplayText } from '../utils/calculateDisplayText';
import { GraphQLGetFileQuery, GraphQLGetFileQueryResponse } from '../__generated__/GraphQLGetFileQuery.graphql';
import { GET_FILE_QUERY } from '../api/GraphQL';
import { handleError } from '../utils/handleError';
import { RELAY_ENVIRONMENT } from '../api/relay-environment';
import { TimecodeFormatter } from '../utils/timeConverter';

const initialState: EoleEditState = {
  showSaveSuccess: false,
  isSaving: false,
  lastSave: '',
  isProjectModalShown: false,
  isBuildModalShown: false,
  isProjectBuildingModalShown: false,
  modalType: MODALTYPE.CREATION,
  isLoadModalShown: false,
  isResourceBrowserModalShown: false,
  isBuilding: false,
  buildTitle: 'Untitled',
  isSameVideoSizeProject: 'Unknow',
  selectedResources: [],
  resourceList: [],
  lastUpdateUserProfile: new Date()
};

interface SectionState {
  newState: EoleEditState;
  newStateTrack?: Track;
  newStateSection?: TrackSection;
  sectionToMove?: TrackSection;
  newStateTrackToMove?: Track;
}

function getReducedTrackAndSection(
  state: EoleEditState,
  trackIndex: number,
  sectionIndex?: number,
  targetTrackIndex?: number
): SectionState {
  const newState = { ...state };
  if (!newState.project) {
    return { newState: state };
  }
  newState.project = { ...newState.project };
  newState.project.tracks = [...newState.project.tracks];
  newState.project.tracks[trackIndex] = { ...newState.project?.tracks[trackIndex] };

  newState.project.tracks[trackIndex].sections = [...newState.project.tracks[trackIndex].sections] as TrackSections;
  const newStateTrack = newState.project.tracks[trackIndex];
  if (sectionIndex == null) {
    return { newState, newStateTrack, newStateSection: undefined };
  }
  newState.project.tracks[trackIndex].sections[sectionIndex] = {
    ...newState.project.tracks[trackIndex].sections[sectionIndex]
  };
  const newStateSection = newState.project.tracks[trackIndex].sections[sectionIndex];
  if (newStateSection.kind === SectionKind.videoTransition) {
    newStateSection.effect = { ...newStateSection.effect };
  }
  if (targetTrackIndex != null && targetTrackIndex !== trackIndex) {
    const sectionToMove = { ...newState.project.tracks[trackIndex].sections[sectionIndex] } as MediaSection;
    newState.project.tracks[trackIndex].sections = [...newState.project.tracks[trackIndex].sections] as MediaSection[];
    newState.project.tracks[targetTrackIndex] = { ...newState.project.tracks[targetTrackIndex] };
    if (
      newState.project.tracks[targetTrackIndex].kind === TrackKind.media &&
      newState.project.tracks[trackIndex].kind === TrackKind.media
    ) {
      newState.project.tracks[trackIndex].sections.splice(sectionIndex, 1);
      if (newState.project.tracks[targetTrackIndex].sections.length > 0) {
        const lastSection =
          newState.project.tracks[targetTrackIndex].sections[
            newState.project.tracks[targetTrackIndex].sections.length - 1
          ];
        sectionToMove.start = lastSection.start + lastSection.duration;
      }
      newState.project.tracks[targetTrackIndex].sections = [
        ...newState.project.tracks[targetTrackIndex].sections,
        sectionToMove
      ] as MediaSection[];
    }
  }
  return {
    newState,
    newStateTrack,
    newStateSection
  };
}

function isSelectedFileResource(selected: any): selected is FileResource {
  return (selected as FileResource).resourceKind !== undefined;
}

function isSelectedTransitionEffect(selected: any): selected is TransitionEffect {
  return (selected as TransitionEffect).durationSec !== undefined;
}

function pushSelectedResource(newSelectedResources: SelectableResource[], selectedResource: SelectableResource) {
  if (isSelectedFileResource(selectedResource)) {
    const isAlreadySelected = newSelectedResources.find((newSelectedResource) => {
      if (isSelectedFileResource(newSelectedResource)) {
        return newSelectedResource._id === selectedResource._id;
      }
      return false;
    });
    if (!isAlreadySelected) {
      newSelectedResources.push(selectedResource);
    }
  } else if (isSelectedTransitionEffect(selectedResource)) {
    const isAlreadySelected = newSelectedResources.find((newSelectedResource) => {
      if (isSelectedTransitionEffect(newSelectedResource)) {
        return newSelectedResource.type === selectedResource.type;
      }
      return false;
    });
    if (!isAlreadySelected) {
      newSelectedResources.push(selectedResource);
    }
  }
}

function getIndexesOfSectionBeforeAndAfter(
  track: ITrack,
  transitionSection: IVideoTransitionSection
): { indexOfSectionBefore: number; indexOfSectionAfter: number } {
  if (transitionSection.effect.type === 'fadeblack') {
    const indexOfSectionAfter = track.sections.findIndex(
      (section: ITrackSection) =>
        section.kind !== SectionKind.videoTransition &&
        section.start === transitionSection.start + (transitionSection.effect.durationSec * 1000) / 2
    );
    const indexOfSectionBefore = track.sections.findIndex(
      (section: ITrackSection) =>
        section.kind !== SectionKind.videoTransition &&
        section.start + section.duration - (transitionSection.effect.durationSec * 1000) / 2 === transitionSection.start
    );
    return { indexOfSectionBefore, indexOfSectionAfter };
  }
  const indexOfSectionAfter = track.sections.findIndex(
    (section: ITrackSection) =>
      section.kind !== SectionKind.videoTransition && section.start === transitionSection.start
  );
  const indexOfSectionBefore = track.sections.findIndex(
    (section: ITrackSection) =>
      section.kind !== SectionKind.videoTransition &&
      (section.start + section.duration === transitionSection.start ||
        section.start + section.duration ===
          transitionSection.start + (transitionSection.effect.durationSec * 1000 ?? 0))
  );
  return { indexOfSectionBefore, indexOfSectionAfter };
}

function sortTrackSectionsByStart(newStateTrack: ITrack) {
  newStateTrack.sections = newStateTrack.sections.sort((section1: any, section2: any) => {
    if (section1.start === section2.start) {
      return section1.duration - section2.duration;
    }
    return section1.start - section2.start;
  });
}

function deleteTransitionOffsetOnEverySectionAfterIndex(track: ITrack, sectionIndex: number, offset: number) {
  sortTrackSectionsByStart(track);
  track.sections.forEach((element: ITrackSection, index: number) => {
    if (index >= sectionIndex && element.offset && offset) {
      track.sections[index] = { ...track.sections[index] };
      track.sections[index].start = element.start + offset;
      track.sections[index].offset = element.offset - offset;
    }
  });
}

function deleteTransitionWhenNoAdjacentSection(newStateTrack: ITrack) {
  const copyOfTrack = { ...newStateTrack };
  copyOfTrack.sections = [...newStateTrack.sections] as TrackSections;
  const transitionSections = [...newStateTrack.sections].filter(
    (section: ITrackSection) => section.kind === SectionKind.videoTransition
  ) as IVideoTransitionSection[];
  for (const transitionSection of transitionSections) {
    const { indexOfSectionBefore, indexOfSectionAfter } = getIndexesOfSectionBeforeAndAfter(
      newStateTrack,
      transitionSection
    );
    if (indexOfSectionBefore === -1 || indexOfSectionAfter === -1) {
      const sectionTransitionIndex = copyOfTrack.sections.findIndex(
        (section: ITrackSection) => section.kind === SectionKind.videoTransition && section.id === transitionSection.id
      );
      copyOfTrack.sections[sectionTransitionIndex] = { ...newStateTrack.sections[sectionTransitionIndex] };
      copyOfTrack.sections.splice(sectionTransitionIndex, 1);
      const offsetToRemove = transitionSection.effect.durationSec * 1000;
      if (offsetToRemove) {
        deleteTransitionOffsetOnEverySectionAfterIndex(copyOfTrack, sectionTransitionIndex, offsetToRemove);
      }
    }
  }
  newStateTrack.sections = [...copyOfTrack.sections] as TrackSections;
  for (const index of copyOfTrack.sections.keys()) {
    newStateTrack.sections[index] = { ...copyOfTrack.sections[index] };
  }
}

function addPlaceholders(newStateTrack: ITrack, stateSection: ITrackSection) {
  const isVideoSectionBefore =
    newStateTrack.sections.findIndex(
      (section: ITrackSection) =>
        section.id !== stateSection.id &&
        section.kind === SectionKind.video &&
        section.start + section.duration === stateSection.start
    ) > -1;
  const isVideoSectionAfter =
    newStateTrack.sections.findIndex(
      (section: ITrackSection) =>
        section.id !== stateSection.id &&
        section.kind === SectionKind.video &&
        section.start === stateSection.start + stateSection.duration
    ) > -1;
  if (isVideoSectionBefore) {
    const newPlaceholder: IVideoTransitionSection = {
      start: stateSection.start,
      duration: 0,
      preview: '',
      kind: SectionKind.videoTransition,
      notMovable: true,
      notResizable: true,
      id: uuidv4(),
      effect: {
        type: '',
        durationSec: 0
      }
    };
    //@ts-ignore
    newStateTrack.sections.push(newPlaceholder);
  }
  if (isVideoSectionAfter) {
    const newPlaceholder: IVideoTransitionSection = {
      start: stateSection.start + stateSection.duration,
      duration: 0,
      preview: '',
      kind: SectionKind.videoTransition,
      notMovable: true,
      notResizable: true,
      id: uuidv4(),
      effect: {
        type: '',
        durationSec: 0
      }
    };
    //@ts-ignore
    newStateTrack.sections.push(newPlaceholder);
  }
}

function changeNormalTransitionToFadeblack(
  newStateTrack: ITrack,
  sectionIndex: number,
  transitionDuration: number,
  indexOfSectionBefore: number
) {
  newStateTrack.sections.forEach((element: ITrackSection, index: number) => {
    newStateTrack.sections[index] = { ...newStateTrack.sections[index] };
    if (index === sectionIndex) {
      newStateTrack.sections[index].start =
        newStateTrack.sections[indexOfSectionBefore].start +
        newStateTrack.sections[indexOfSectionBefore].duration -
        (transitionDuration * 1000) / 2;
      const offset = newStateTrack.sections[index].offset;
      if (offset != null) {
        newStateTrack.sections[index].offset = offset - (transitionDuration * 1000) / 2;
      } else {
        newStateTrack.sections[index].offset = 0;
      }
    }
    if (index > sectionIndex) {
      newStateTrack.sections[index].start = newStateTrack.sections[index].start + transitionDuration * 1000;
      const offset = newStateTrack.sections[index].offset;
      if (offset != null) {
        newStateTrack.sections[index].offset = offset - transitionDuration * 1000;
      } else {
        newStateTrack.sections[index].offset = 0;
      }
    }
  });
}

function changeFadeblackToNormalTransition(newStateTrack: ITrack, sectionIndex: number, transitionDuration: number) {
  newStateTrack.sections.forEach((element: ITrackSection, index: number) => {
    newStateTrack.sections[index] = { ...newStateTrack.sections[index] };
    if (index === sectionIndex) {
      newStateTrack.sections[index] = { ...newStateTrack.sections[index] };
      newStateTrack.sections[index].start -= (transitionDuration * 1000) / 2;
      const offset = newStateTrack.sections[index].offset ?? 0;
      newStateTrack.sections[index].offset = offset + (transitionDuration * 1000) / 2;
    }
    if (index > sectionIndex) {
      newStateTrack.sections[index] = { ...newStateTrack.sections[index] };
      newStateTrack.sections[index].start -= transitionDuration * 1000;
      const offset = newStateTrack.sections[index].offset ?? 0;
      newStateTrack.sections[index].offset = offset + transitionDuration * 1000;
    }
  });
}

function getTransitionLetterForEdl(transitionType: string) {
  switch (transitionType) {
    case 'fade':
    case 'fadeblack':
      return 'D   ';
    case 'wiperight':
      return 'W001';
    case 'wipeleft':
      return 'W501';
    case 'wipedown':
      return 'W002';
    case 'wipeup':
      return 'W502';
    default:
      return 'C       ';
  }
}

export const reducer: Reducer = (state: EoleEditState = initialState, action): EoleEditState => {
  const payload = action.payload;
  switch (action.type) {
    case ACTION_TYPES.CREATE_PROJECT: {
      const newState = { ...state };
      newState.project = JSON.parse(JSON.stringify(payload.project));
      return newState;
    }
    case ACTION_TYPES.SET_PROJECT_SAVING:
      return { ...state, isSaving: true };
    case ACTION_TYPES.SET_PROJECT_SAVED:
      const { lastSave, _id } = payload;
      const newState = { ...state, isSaving: false, lastSave };
      if (newState.project) {
        newState.project = { ...newState.project };
        newState.project._id = _id;
      }

      return newState;
    case ACTION_TYPES.SHOW_PROJECT_MODAL: {
      const { show, modalType } = payload;
      return { ...state, isProjectModalShown: show, modalType };
    }
    case ACTION_TYPES.SHOW_BUILD_MODAL: {
      const { show, modalType } = payload;
      return { ...state, isBuildModalShown: show, modalType };
    }
    case ACTION_TYPES.SHOW_PROJECT_BUILDING_MODAL: {
      const { show } = payload;
      return { ...state, isProjectBuildingModalShown: show };
    }
    case ACTION_TYPES.SHOW_LOAD_PROJECT_MODAL: {
      const { show } = payload;
      return { ...state, isLoadModalShown: show };
    }
    case ACTION_TYPES.SHOW_RESOURCE_BROWSER_MODAL: {
      const { show } = payload;
      return { ...state, isResourceBrowserModalShown: show };
    }
    case ACTION_TYPES.BUILDING_PROJECT: {
      const { isBuilding } = payload;
      return { ...state, isBuilding };
    }
    case ACTION_TYPES.BUILDING_STATUS: {
      const { buildTitle, buildStatus } = payload;
      return { ...state, buildTitle, buildStatus };
    }
    case ACTION_TYPES.SET_BUILD_ID: {
      const { builtVideoId } = payload;
      return { ...state, builtVideoId };
    }
    case ACTION_TYPES.SHOW_SAVE_SUCCESS: {
      const { showSaveSuccess } = payload;
      return { ...state, showSaveSuccess };
    }
    case ACTION_TYPES.SET_LAST_UPDATE_USERPROFILE: {
      const { lastUpdateUserProfile } = payload;
      return { ...state, lastUpdateUserProfile };
    }
    case ACTION_TYPES.LOAD_PROJECT: {
      const { project } = payload;
      return { ...state, project };
    }
    case ACTION_TYPES.CLOSE_PROJECT: {
      return { ...state, project: undefined };
    }
    case ACTION_TYPES.SELECT_RESOURCES: {
      const { resources, areSelected } = payload;
      const { selectedResources } = state;
      let newSelectedResources: SelectableResource[];
      if (areSelected) {
        newSelectedResources = [...selectedResources];
        resources.forEach((resource: SelectableResource) => {
          pushSelectedResource(newSelectedResources, resource);
        });
      } else {
        newSelectedResources = [];
        selectedResources.forEach((selectedResource) => {
          pushSelectedResource(newSelectedResources, selectedResource);
        });
      }
      return { ...state, selectedResources: newSelectedResources };
    }
    case ACTION_TYPES.SET_RESOURCE_LIST: {
      return { ...state, resourceList: [...payload.resourceList] };
    }
    case ACTION_TYPES.RESET_SELECTED_RESOURCES: {
      return { ...state, selectedResources: [] };
    }
    case ACTION_TYPES.SHOW_ERROR: {
      return { ...state, error: payload };
    }
    case ACTION_TYPES.SET_SECTION_DURATION: {
      const { newState, newStateSection } = getReducedTrackAndSection(state, payload.trackIndex, payload.sectionIndex);
      if (newStateSection) {
        newStateSection.duration = payload.duration;
      }
      return newState;
    }
    case ACTION_TYPES.SET_SECTION_START_MEDIA: {
      const { newState, newStateSection } = getReducedTrackAndSection(state, payload.trackIndex, payload.sectionIndex);
      if (
        newStateSection &&
        (newStateSection.kind === SectionKind.video || newStateSection.kind === SectionKind.audio)
      ) {
        newStateSection.startMedia = payload.start;
      }
      return newState;
    }
    case ACTION_TYPES.SET_SECTION_END_MEDIA: {
      const { newState, newStateSection } = getReducedTrackAndSection(state, payload.trackIndex, payload.sectionIndex);
      if (
        newStateSection &&
        (newStateSection.kind === SectionKind.video || newStateSection.kind === SectionKind.audio)
      ) {
        newStateSection.endMedia = payload.end;
      }
      return newState;
    }
    case ACTION_TYPES.SET_SECTION_ORIGIN_MEDIA_DURATION: {
      const { newState, newStateSection } = getReducedTrackAndSection(state, payload.trackIndex, payload.sectionIndex);
      if (
        newStateSection &&
        (newStateSection.kind === SectionKind.video || newStateSection.kind === SectionKind.audio)
      ) {
        newStateSection.originMediaDuration = payload.duration;
      }
      return newState;
    }

    case ACTION_TYPES.SET_SECTION_START: {
      const { trackIndex, sectionIndex, start, targetTrackIndex } = payload;
      const { newState, newStateTrack, newStateSection } = getReducedTrackAndSection(
        state,
        trackIndex,
        sectionIndex,
        targetTrackIndex
      );
      if (!newState.project) {
        return newState;
      }
      if (newStateTrack && newStateSection) {
        newStateSection.start = start;
        deleteTransitionWhenNoAdjacentSection(newStateTrack);
      }
      return newState;
    }
    case ACTION_TYPES.SET_SECTION_SRC_PROPERTIES: {
      const { trackIndex, sectionIndex, srcId, src, preview, lowres, text } = payload;
      const { newState, newStateTrack, newStateSection } = getReducedTrackAndSection(state, trackIndex, sectionIndex);
      if (!newState.project) {
        return newState;
      }
      if (
        newStateTrack &&
        newStateSection &&
        newStateSection.kind !== SectionKind.text &&
        newStateSection.kind !== SectionKind.videoTransition
      ) {
        newStateSection.srcId = srcId;
        newStateSection.src = src;
        newStateSection.preview = preview;
        newStateSection.lowres = lowres;
        newStateSection.text = text;
      }
      return newState;
    }

    case ACTION_TYPES.CENTER_SHAPE: {
      if (state.currentTrackIndex == null || state.currentSectionIndex == null) {
        return state;
      }
      const { newState, newStateSection } = getReducedTrackAndSection(
        state,
        state.currentTrackIndex,
        state.currentSectionIndex
      );
      if (
        newStateSection &&
        state.project &&
        (newStateSection.kind === SectionKind.image ||
          newStateSection.kind === SectionKind.text ||
          newStateSection.kind === SectionKind.waveform ||
          newStateSection.kind === SectionKind.video)
      ) {
        const x = state.project.size.width / 2 - newStateSection.width / 2;
        const y = state.project.size.height / 2 - newStateSection.height / 2;
        if (payload.direction === CenterShapeDirection.vertical) {
          newStateSection.y = y;
        } else if (payload.direction === CenterShapeDirection.horizontal) {
          newStateSection.x = x;
        } else {
          newStateSection.x = x;
          newStateSection.y = y;
        }
      }
      return newState;
    }
    case ACTION_TYPES.FIT_SHAPE: {
      if (state.currentTrackIndex == null || state.currentSectionIndex == null) {
        return state;
      }
      const { newState, newStateSection } = getReducedTrackAndSection(
        state,
        state.currentTrackIndex,
        state.currentSectionIndex
      );
      if (
        newStateSection &&
        state.project &&
        (newStateSection.kind === SectionKind.image ||
          newStateSection.kind === SectionKind.text ||
          newStateSection.kind === SectionKind.waveform ||
          newStateSection.kind === SectionKind.video)
      ) {
        if (payload.dimensions.width > 0) {
          newStateSection.width = payload.dimensions.width;
        }
        if (payload.dimensions.height > 0) {
          newStateSection.height = payload.dimensions.height;
        }
        if (payload.type === CenterShapeDirection.vertical) {
          newStateSection.y = state.project.size.height / 2 - newStateSection.height / 2;
        } else if (payload.type === CenterShapeDirection.horizontal) {
          newStateSection.x = state.project.size.width / 2 - newStateSection.width / 2;
        } else {
          newStateSection.y = state.project.size.height / 2 - newStateSection.height / 2;
          newStateSection.x = state.project.size.width / 2 - newStateSection.width / 2;
        }
      }
      return newState;
    }

    case ACTION_TYPES.UPDATE_PROJECT_DURATION: {
      if (!state.project) {
        return state;
      }
      const newDuration = payload.isAdd
        ? state.project.duration + payload.delta
        : state.project.duration - payload.delta;
      return { ...state, project: { ...state.project, duration: newDuration } };
    }
    case ACTION_TYPES.SET_SECTION_CURRENT: {
      const newState = { ...state };
      newState.currentSectionIndex = payload.currentSectionIndex;
      return newState;
    }
    case ACTION_TYPES.SET_FRAMERATE: {
      const newState = { ...state };
      newState.frameRate = payload.frameRate;
      return newState;
    }
    case ACTION_TYPES.SET_TRACK_CURRENT: {
      const newState = { ...state };
      newState.currentTrackIndex = payload.currentTrackIndex;
      return newState;
    }

    case ACTION_TYPES.SET_SECTION_SPLIT: {
      const { trackIndex, sectionIndex, currentSectionNewDuration, newSection } = payload;
      const { newState, newStateTrack, newStateSection } = getReducedTrackAndSection(state, trackIndex, sectionIndex);
      if (newStateTrack && newStateSection) {
        newStateTrack.sections = [...newStateTrack.sections, newSection];
        newStateSection.duration = currentSectionNewDuration;
      }

      return newState;
    }
    case ACTION_TYPES.SET_TRACK_PROP: {
      const { trackIndex, propName, propValue } = payload;
      const { newState, newStateTrack } = getReducedTrackAndSection(state, trackIndex);
      if (newStateTrack) {
        //TODO: Fix ts-ignore
        //@ts-ignore
        newStateTrack[propName] = propValue;
        return newState;
      }
      return state;
    }
    case ACTION_TYPES.SET_TEXT_SECTION: {
      const { trackIndex, sectionIndex, objectKeyValues } = payload;
      const { newState, newStateSection } = getReducedTrackAndSection(state, trackIndex, sectionIndex);

      if (newStateSection && newStateSection.kind === SectionKind.text) {
        // eslint-disable-next-line guard-for-in
        for (const propName in objectKeyValues as TextPreset) {
          //@ts-ignore
          newStateSection[propName] = objectKeyValues[propName];
        }
        newStateSection.displayText = getDisplayText(newStateSection);
      }

      return newState;
    }

    case ACTION_TYPES.SET_TEXT_SECTION_PROP: {
      const { trackIndex, sectionIndex, propValue, propName } = payload;
      const { newState, newStateSection } = getReducedTrackAndSection(state, trackIndex, sectionIndex);

      if (newStateSection && newStateSection.kind === SectionKind.text) {
        //TODO: Fix ts-ignore
        //@ts-ignore
        newStateSection[propName] = propValue;
        newStateSection.displayText = getDisplayText(newStateSection);
      }

      return newState;
    }

    case ACTION_TYPES.SET_IMAGE_SECTION_PROP: {
      const { trackIndex, sectionIndex, propValue, propName } = payload;
      const { newState, newStateSection } = getReducedTrackAndSection(state, trackIndex, sectionIndex);
      if (newStateSection && newStateSection.kind === SectionKind.image) {
        //TODO: Fix ts-ignore
        //@ts-ignore
        newStateSection[propName] = propValue;
      }
      return newState;
    }
    case ACTION_TYPES.SET_AUDIO_SECTION_PROP: {
      const { trackIndex, sectionIndex, propValue, propName } = payload;
      const { newState, newStateSection } = getReducedTrackAndSection(state, trackIndex, sectionIndex);
      if (
        newStateSection &&
        (newStateSection.kind === SectionKind.audio || newStateSection.kind === SectionKind.waveform)
      ) {
        //TODO: Fix ts-ignore
        //@ts-ignore
        newStateSection[propName] = propValue;
      }
      return newState;
    }
    case ACTION_TYPES.SET_WAVEFORM_SECTION_PROP: {
      const { trackIndex, sectionIndex, propValue, propName } = payload;
      const { newState, newStateSection } = getReducedTrackAndSection(state, trackIndex, sectionIndex);
      if (newStateSection && newStateSection.kind === SectionKind.waveform) {
        //TODO: Fix ts-ignore
        //@ts-ignore
        newStateSection[propName] = propValue;
      }
      return newState;
    }
    case ACTION_TYPES.SET_VIDEO_SECTION_PROP: {
      const { trackIndex, sectionIndex, propValue, propName } = payload;
      const { newState, newStateSection } = getReducedTrackAndSection(state, trackIndex, sectionIndex);
      if (newStateSection && newStateSection.kind === SectionKind.video) {
        //TODO: Fix ts-ignore
        //@ts-ignore
        newStateSection[propName] = propValue;
      }
      return newState;
    }
    case ACTION_TYPES.SET_TRANSITION_SECTION_PROP: {
      const { trackIndex, sectionIndex, propValue, propName } = payload;
      const { newState, newStateTrack, newStateSection } = getReducedTrackAndSection(state, trackIndex, sectionIndex);
      if (newStateTrack && newStateSection && newStateSection.kind === SectionKind.videoTransition) {
        sortTrackSectionsByStart(newStateTrack);
        const { indexOfSectionBefore, indexOfSectionAfter } = getIndexesOfSectionBeforeAndAfter(
          newStateTrack,
          newStateSection
        );
        if (indexOfSectionBefore > -1 && indexOfSectionAfter > -1) {
          const transitionDuration = newStateSection.effect.durationSec;
          const transitionType = newStateSection.effect.type;
          if (propName === 'type') {
            if (transitionType && transitionType !== 'fadeblack' && propValue === 'fadeblack') {
              changeNormalTransitionToFadeblack(newStateTrack, sectionIndex, transitionDuration, indexOfSectionBefore);
            } else if (transitionType === 'fadeblack' && propValue !== 'fadeblack') {
              changeFadeblackToNormalTransition(newStateTrack, sectionIndex, transitionDuration);
            }
          } else if (propName === 'durationSec' && newStateSection.effect.durationSec != null) {
            let maxTransitionDuration: number;
            if (
              newStateTrack.sections[indexOfSectionAfter].duration >
              newStateTrack.sections[indexOfSectionBefore].duration
            ) {
              maxTransitionDuration = newStateTrack.sections[indexOfSectionBefore].duration / 1000;
            } else {
              maxTransitionDuration = newStateTrack.sections[indexOfSectionAfter].duration / 1000;
            }
            if (propValue > maxTransitionDuration) {
              return newState;
            }
            const durationDifference = round((transitionDuration - propValue) * 1000, 2);
            if (transitionType === 'fadeblack') {
              newStateTrack.sections.forEach((element: ITrackSection, index: number) => {
                if (index === sectionIndex) {
                  newStateTrack.sections[index].start =
                    newStateTrack.sections[indexOfSectionBefore].start +
                    newStateTrack.sections[indexOfSectionBefore].duration -
                    (propValue * 1000) / 2;
                }
              });
            } else {
              newStateTrack.sections.forEach((element: ITrackSection, index: number) => {
                if (index >= sectionIndex) {
                  newStateTrack.sections[index] = { ...newStateTrack.sections[index] };
                  newStateTrack.sections[index].start += durationDifference;
                  const offset = newStateTrack.sections[index].offset ?? 0;
                  newStateTrack.sections[index].offset = offset - durationDifference;
                }
              });
            }
          }
        }
        //TODO: Fix ts-ignore
        //@ts-ignore
        newStateSection.effect[propName] = propValue;
      }
      return newState;
    }

    case ACTION_TYPES.SET_TEXT_SECTION_GEOM: {
      const {
        trackIndex,
        sectionIndex,
        posX,
        posY,
        width,
        height,
        rotation,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        initialWidth,
        initialHeight
      } = payload;
      const { newState, newStateSection } = getReducedTrackAndSection(state, trackIndex, sectionIndex);
      if (
        newStateSection &&
        (newStateSection.kind === SectionKind.image ||
          newStateSection.kind === SectionKind.text ||
          newStateSection.kind === SectionKind.waveform ||
          newStateSection.kind === SectionKind.video)
      ) {
        newStateSection.x = posX;
        newStateSection.y = posY;
        newStateSection.height = height;
        newStateSection.width = width;
        newStateSection.rotation = rotation;
        if (newStateSection.kind === SectionKind.video) {
          newStateSection.cropX = cropX;
          newStateSection.cropY = cropY;
          newStateSection.cropWidth = cropWidth;
          newStateSection.cropHeight = cropHeight;
          newStateSection.initialWidth = initialWidth;
          newStateSection.initialHeight = initialHeight;
        }
        if (newStateSection.kind === SectionKind.text) {
          newStateSection.displayText = getDisplayText(newStateSection);
        }
        return newState;
      }
      return state;
    }
    case ACTION_TYPES.DELETE_CURRENT_SECTION: {
      if (state.currentTrackIndex == null || state.currentSectionIndex == null) {
        return state;
      }
      const { newState, newStateTrack, newStateSection } = getReducedTrackAndSection(
        state,
        state.currentTrackIndex,
        state.currentSectionIndex
      );
      if (newStateTrack) {
        newStateTrack.sections.splice(state.currentSectionIndex, 1);
        if (newStateSection?.kind === SectionKind.videoTransition) {
          if (newStateSection.effect.type !== 'fadeblack') {
            const offsetToRemove = newStateSection.effect.durationSec * 1000;
            deleteTransitionOffsetOnEverySectionAfterIndex(newStateTrack, state.currentSectionIndex, offsetToRemove);
          }
          addPlaceholders(newStateTrack, newStateTrack.sections[state.currentSectionIndex - 1]);
          sortTrackSectionsByStart(newStateTrack);
        }
        deleteTransitionWhenNoAdjacentSection(newStateTrack);
        newState.currentSectionIndex = undefined;
        return newState;
      }
      return state;
    }
    case ACTION_TYPES.DELETE_SECTION: {
      const { trackIndex, sectionIndex } = payload;

      if (trackIndex == null || sectionIndex == null) {
        return state;
      }
      const { newState, newStateTrack } = getReducedTrackAndSection(state, trackIndex, sectionIndex);
      if (newStateTrack) {
        newStateTrack.sections.splice(sectionIndex, 1);
        newState.currentSectionIndex = undefined;
        return newState;
      }
      return state;
    }
    case ACTION_TYPES.DELETE_CURRENT_TRACK: {
      if (!state.currentTrackIndex) {
        return state;
      }
      const newState = { ...state };
      if (newState.project) {
        newState.project = { ...newState.project };
        newState.project.tracks = [...newState.project.tracks];
        newState.project.tracks.splice(state.currentTrackIndex, 1);
        newState.currentTrackIndex = undefined;
        newState.currentSectionIndex = undefined;
        return newState;
      }

      return state;
    }
    case ACTION_TYPES.ADD_TRACK: {
      const { newTrack } = payload;
      const newState = { ...state };
      if (newState.project) {
        newState.project = { ...newState.project };
        newState.project.tracks = [...newState.project.tracks];
        newState.project.tracks.push(newTrack);
        newState.currentTrackIndex = newState.project.tracks.length - 1;
        newState.currentSectionIndex = undefined;
        return newState;
      }

      return state;
    }
    case ACTION_TYPES.ADD_SECTION: {
      const { trackIndex, newSection } = payload;
      const newState = { ...state };
      if (newState.project) {
        newState.project = { ...newState.project };
        newState.project.tracks = [...newState.project.tracks];
        newState.project.tracks[trackIndex] = { ...newState.project.tracks[trackIndex] };

        newState.project.tracks[trackIndex].sections = [
          ...newState.project.tracks[trackIndex].sections
        ] as TrackSections;
        newState.project.tracks[trackIndex].sections.push(newSection);
        newState.currentTrackIndex = trackIndex;
        newState.currentSectionIndex = newState.project.tracks[trackIndex].sections.length - 1;
        newState.project.tracks[trackIndex].sections = newState.project.tracks[trackIndex].sections.sort(
          (section1: any, section2: any) => {
            if (section1.start === section2.start) {
              return section1.duration - section2.duration;
            }
            return section1.start - section2.start;
          }
        );
        return newState;
      }
      return state;
    }
    case ACTION_TYPES.SET_PROJECT_DURATION: {
      const { duration } = payload;
      const newState = { ...state };
      if (newState.project) {
        newState.project = { ...newState.project };
        newState.project.duration = duration;
        return newState;
      }
      return state;
    }
    case ACTION_TYPES.MOVE_TRACK: {
      let trackIndex = payload.trackIndex;
      const toUp = payload.toUp;

      if (trackIndex == null) {
        trackIndex = state.currentTrackIndex;
      }
      if (trackIndex == null) {
        return state;
      }
      const newState = { ...state };
      if (newState.project) {
        newState.project = { ...newState.project };
        newState.project.tracks = [...newState.project.tracks];
        const newTrackIndex = trackIndex + (toUp ? +1 : -1);
        if ((toUp && newTrackIndex > newState.project.tracks.length) || (!toUp && newTrackIndex < 0)) {
          return state;
        }
        const [currentTrack] = newState.project.tracks.splice(trackIndex, 1);
        newState.project.tracks.splice(newTrackIndex, 0, currentTrack);
        newState.currentTrackIndex = newTrackIndex;
        return newState;
      }
      return state;
    }

    case ACTION_TYPES.ADD_PLACE_HOLDER_TRANSITION: {
      const { trackIndex, sectionIndex } = payload;

      const { newState, newStateTrack, newStateSection } = getReducedTrackAndSection(state, trackIndex, sectionIndex);
      if (newState.project && newStateTrack) {
        const stateSection = newStateSection ?? newStateTrack.sections[newStateTrack?.sections.length - 1];
        if (trackIndex >= 0 && stateSection) {
          addPlaceholders(newStateTrack, stateSection);
          sortTrackSectionsByStart(newStateTrack);
          return newState;
        }
      }

      return state;
    }

    case ACTION_TYPES.EXPORT_EDL: {
      const newState = { ...state };
      if (newState.project) {
        const frameRate = newState.frameRate ?? 25;
        const videoTrackIndex = newState.project.tracks.findIndex((track) => track.kind === TrackKind.video);
        if (videoTrackIndex) {
          const sections: Array<IVideoSection | IVideoTransitionSection | IAudioSection> = [];
          const videoTrack = newState.project?.tracks[videoTrackIndex] as IVideoTrack;
          const videoSections = videoTrack.sections.filter((section) => {
            if (section.kind === SectionKind.video) {
              return section;
            }
            return false;
          });
          sections.push(...videoSections);
          sections.sort((section1, section2) => {
            return section1.start - section2.start;
          });
          let data = `TITLE: ${newState.project?.title}\n`;
          data += 'FCM: NON-DROP FRAME\n\n';
          if (sections) {
            (async (): Promise<void> => {
              try {
                for (const [index, section] of sections.entries()) {
                  if (section.kind === SectionKind.video) {
                    const srcId = section.srcId;
                    const sectionIndexInVideoTrack = videoTrack.sections.findIndex((sect) => sect.id === section.id);
                    const nextTransitionSectionEffect =
                      videoTrack.sections[sectionIndexInVideoTrack + 1]?.effect ?? undefined;
                    // eslint-disable-next-line no-await-in-loop
                    const result: GraphQLGetFileQueryResponse | undefined = await fetchQuery<GraphQLGetFileQuery>(
                      // @ts-ignore
                      RELAY_ENVIRONMENT,
                      GET_FILE_QUERY as GraphQLTaggedNode,
                      { id: srcId }
                    ).toPromise();
                    if (!result?.getFile) {
                      return;
                    }
                    const str = '' + (index + 1);
                    const pad = '000';
                    const ans = pad.substring(0, pad.length - str.length) + str;
                    data += `${ans}  ${result.getFile.name.slice(0, 8)}       ${
                      section.kind === SectionKind.video ? 'V' : 'A'
                    }     `;
                    if (nextTransitionSectionEffect?.type) {
                      data += `${getTransitionLetterForEdl(nextTransitionSectionEffect.type)} ${String(
                        Math.floor(nextTransitionSectionEffect.durationSec * 25)
                      ).padStart(3, '0')} `;
                    } else {
                      data += `C        `;
                    }
                    data += `${TimecodeFormatter(section.startMedia, frameRate)} ${TimecodeFormatter(
                      section.endMedia,
                      frameRate
                    )} ${TimecodeFormatter(section.start, frameRate)} ${TimecodeFormatter(
                      section.start + section.duration,
                      frameRate
                    )}\n`;
                    data += `* FROM CLIP NAME: ${result.getFile.name} \n \n`;
                  }
                }
                const edlFile = new Blob([data], {
                  type: 'text/plain'
                });

                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(edlFile);
                a.download = `${newState.project?.title}.edl`;
                a.click();
              } catch (err) {
                handleError(err, 'Cannot get source');
              }
            })();
          }
        }
      }

      return state;
    }

    case ACTION_TYPES.EXPORT_PROJECT_STRUCTURE: {
      const newState = { ...state };
      if (newState.project) {
        const project = newState.project;
        const structure: ProjectStructure = {
          name: project.title,
          duration: project.duration,
          isSameVideoSizedProject: project.isSameVideoSizeProject,
          size: project.size,
          tracks: project.tracks.map(
            (track) =>
              ({
                ...omit(track, ['id']),
                sections: track.sections.map((section) => omit(section, ['id', 'src', 'srcId', 'preview', 'lowres']))
              } as StructureTrack)
          ),
          videoBitrate: project.videoBitrate
        };
        const jsonData = JSON.stringify(structure);
        const projectFile = new Blob([jsonData], {
          type: 'application/json'
        });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(projectFile);
        a.download = `${project?.title}_structure.json`;
        a.click();
      }
      return state;
    }

    default:
      return state;
  }
};
