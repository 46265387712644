import { isNumber } from 'lodash';
import React, { ChangeEvent, FC } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { SizeNumber } from './SizeNumber';

export enum BITRATE_LIST {
  r1000 = '1',
  r2500 = '2.5',
  r5000 = '5',
  r10000 = '10',
  r15000 = '15',
  custom = 'Custom'
}

interface IBitrateInput {
  videoBitrate: string;
  customVideoBitrate: number;
  onBitrateChange: (bitrate: string) => void;
  onCustomBitrateChange: (customBitrate: number) => void;
}

export const MIN_BITRATE = 1;
export const MAX_BITRATE = 15;

export const BitrateInput: FC<IBitrateInput> = ({
  videoBitrate,
  customVideoBitrate,
  onBitrateChange,
  onCustomBitrateChange
}) => {
  const handleBitrateSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    if (isNumber(+value)) {
      onBitrateChange(value);
    } else if (value === BITRATE_LIST.custom) {
      onBitrateChange('0');
    }
  };
  const handleCustomBitrateChange = (newCustomBitrate: number) => {
    let validBitrate = +newCustomBitrate.toFixed(1);
    if (validBitrate > MAX_BITRATE) {
      validBitrate = MAX_BITRATE;
    } else if (validBitrate < MIN_BITRATE) {
      validBitrate = MIN_BITRATE;
    }
    onCustomBitrateChange(validBitrate);
  };
  return (
    <>
      <InputGroup>
        <Form.Control as="select" name="sizeSelectIndex" value={videoBitrate} onChange={handleBitrateSelectChange}>
          {Object.values(BITRATE_LIST).map((bitrate: string) => (
            <option key={bitrate} value={bitrate}>
              {bitrate}
            </option>
          ))}
        </Form.Control>
        {videoBitrate !== BITRATE_LIST.custom && (
          <InputGroup.Append>
            <InputGroup.Text>Mbit/s</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
      {videoBitrate === BITRATE_LIST.custom ? (
        <SizeNumber
          value={customVideoBitrate}
          isRow
          appendedText="Mbit/s"
          min={MIN_BITRATE}
          max={MAX_BITRATE}
          step={0.1}
          onSizeChange={handleCustomBitrateChange}
        />
      ) : (
        <div style={{ flex: 1 }} />
      )}
    </>
  );
};
