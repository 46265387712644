/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLBuildVideoMutationVariables = {
    buildId: string;
    projectId: string;
    title: string;
    videoBitrate: number;
    framerate: number;
};
export type GraphQLBuildVideoMutationResponse = {
    readonly buildVideo: {
        readonly _id: string;
    };
};
export type GraphQLBuildVideoMutation = {
    readonly response: GraphQLBuildVideoMutationResponse;
    readonly variables: GraphQLBuildVideoMutationVariables;
};



/*
mutation GraphQLBuildVideoMutation(
  $buildId: String!
  $projectId: String!
  $title: String!
  $videoBitrate: Float!
  $framerate: Float!
) {
  buildVideo(buildId: $buildId, projectId: $projectId, title: $title, videoBitrate: $videoBitrate, framerate: $framerate) {
    _id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "buildId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "framerate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "videoBitrate"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "buildId",
        "variableName": "buildId"
      },
      {
        "kind": "Variable",
        "name": "framerate",
        "variableName": "framerate"
      },
      {
        "kind": "Variable",
        "name": "projectId",
        "variableName": "projectId"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      },
      {
        "kind": "Variable",
        "name": "videoBitrate",
        "variableName": "videoBitrate"
      }
    ],
    "concreteType": "File",
    "kind": "LinkedField",
    "name": "buildVideo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLBuildVideoMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "GraphQLBuildVideoMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "98d8acf18b18d0781c3e514ee81ba6d0",
    "id": null,
    "metadata": {},
    "name": "GraphQLBuildVideoMutation",
    "operationKind": "mutation",
    "text": "mutation GraphQLBuildVideoMutation(\n  $buildId: String!\n  $projectId: String!\n  $title: String!\n  $videoBitrate: Float!\n  $framerate: Float!\n) {\n  buildVideo(buildId: $buildId, projectId: $projectId, title: $title, videoBitrate: $videoBitrate, framerate: $framerate) {\n    _id\n  }\n}\n"
  }
};
})();
(node as any).hash = '603c88418364e3f219f18136f26451cf';
export default node;
