import { isNumber } from 'lodash';
import React, { FC } from 'react';
import { TimeUsageIndicator } from './TimeUsageIndicator';

interface IUsageIndicatorProps {
  eoleEditRight: boolean;
  eoleEditExpiry: Date;
  eoleEditStorageUsed: number | null;
  eoleEditStorageLimit: number | null;
  eoleEditTimeUsed: number | null;
  eoleEditTimeLimit: number | null;
}

export const UsageIndicator: FC<IUsageIndicatorProps> = ({ eoleEditRight,
  eoleEditExpiry,
  eoleEditStorageUsed,
  eoleEditStorageLimit,
  eoleEditTimeUsed,
  eoleEditTimeLimit }) => {
  return (
    <>
      {eoleEditRight && (
        <div className="ml-3">
          {isNumber(eoleEditTimeLimit) && eoleEditTimeLimit > 0 && isNumber(eoleEditTimeUsed) ? (
            <TimeUsageIndicator
              timeLimit={eoleEditTimeLimit as number}
              timeUsed={eoleEditTimeUsed as number}
              expiry={eoleEditExpiry}
            />
          ) : isNumber(eoleEditStorageUsed) && isNumber(eoleEditStorageLimit) &&
          (
            <span className="mr-5">
              Storage used: {Math.round((eoleEditStorageUsed / eoleEditStorageLimit) * 100)}%
            </span>
          )
          }
        </div>
      )}
    </>
  )
}
