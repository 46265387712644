/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLGetCurrentUserProfileQueryVariables = {};
export type GraphQLGetCurrentUserProfileQueryResponse = {
    readonly getCurrentUserProfile: {
        readonly _id: string;
        readonly owner: string;
        readonly eoleEditRight: boolean;
        readonly eoleEditExpiry: unknown | null;
        readonly eoleEditStorageUsed: number | null;
        readonly eoleEditStorageLimit: number | null;
        readonly eoleEditTimeUsed: number | null;
        readonly eoleEditTimeLimit: number | null;
        readonly eoleEditHasActiveSubscription: boolean | null;
        readonly type: string | null;
        readonly purge: number | null;
        readonly apiKey: string;
    };
};
export type GraphQLGetCurrentUserProfileQuery = {
    readonly response: GraphQLGetCurrentUserProfileQueryResponse;
    readonly variables: GraphQLGetCurrentUserProfileQueryVariables;
};



/*
query GraphQLGetCurrentUserProfileQuery {
  getCurrentUserProfile {
    _id
    owner
    eoleEditRight
    eoleEditExpiry
    eoleEditStorageUsed
    eoleEditStorageLimit
    eoleEditTimeUsed
    eoleEditTimeLimit
    eoleEditHasActiveSubscription
    type
    purge
    apiKey
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserProfile",
    "kind": "LinkedField",
    "name": "getCurrentUserProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "eoleEditRight",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "eoleEditExpiry",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "eoleEditStorageUsed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "eoleEditStorageLimit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "eoleEditTimeUsed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "eoleEditTimeLimit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "eoleEditHasActiveSubscription",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "purge",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "apiKey",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLGetCurrentUserProfileQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GraphQLGetCurrentUserProfileQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6401b9e285644c45ec466ed9f67a5fa5",
    "id": null,
    "metadata": {},
    "name": "GraphQLGetCurrentUserProfileQuery",
    "operationKind": "query",
    "text": "query GraphQLGetCurrentUserProfileQuery {\n  getCurrentUserProfile {\n    _id\n    owner\n    eoleEditRight\n    eoleEditExpiry\n    eoleEditStorageUsed\n    eoleEditStorageLimit\n    eoleEditTimeUsed\n    eoleEditTimeLimit\n    eoleEditHasActiveSubscription\n    type\n    purge\n    apiKey\n  }\n}\n"
  }
};
})();
(node as any).hash = '2ecc2f27efd0ca1a3c89bcf285999114';
export default node;
