import prettyBytes from 'pretty-bytes';
import React from 'react';
import { Table } from 'react-bootstrap';
import { FileResource, ResourceType } from '@eolementhe/video-editor-model';
import { createServerFileUrl } from '../../utils/uploadFiles';
import './FileView.scss';

interface IProps {
  file: FileResource;
}

export const FileView: React.FC<IProps> = ({ file }) => {
  return (
    <div className="resource-file-view d-flex flex-column p-1 overflow-y-auto">
      <ResourcePreview file={file} />
      <Table className="mt-1 border-0">
        <tbody>
          <tr>
            <td className="p-0 font-weight-bold">Name:</td>
            <td className="p-0 pl-2 w-100">{file.name}</td>
          </tr>

          <tr>
            <td className="p-0 font-weight-bold">Title:</td>
            <td className="p-0 pl-2 w-100">{file.name}</td>
          </tr>

          <tr>
            <td className="p-0 font-weight-bold">Size:</td>
            <td className="p-0 pl-2 w-100">{prettyBytes(file.size)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const ResourcePreview: React.FC<IProps> = ({ file: { resourceKind, name, lowres, src } }) => {
  const fullUrl = createServerFileUrl(lowres || src);
  let resourceTag = <></>;
  switch (resourceKind) {
    default:
    case ResourceType.image:
      resourceTag = <img className="resource-preview" src={fullUrl} alt={name} />;
      break;
    case ResourceType.video:
      resourceTag = <video className="resource-preview" controls src={fullUrl} />;
      break;
    case ResourceType.audio:
      resourceTag = <audio className="resource-preview" controls src={fullUrl} />;
      break;
  }

  return <div className="resource-preview-container">{resourceTag}</div>;
};
