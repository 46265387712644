import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAction } from '@eolementhe/react-components';
import {
  faFile,
  faFileExport,
  faEdit,
  faFolderOpen,
  faSave,
  faClone,
  faSignOutAlt,
  faTimes,
  faUser,
  faDownload,
  faClosedCaptioning,
  faHeadset
} from '@fortawesome/free-solid-svg-icons';
import { faBadgeDollar, faFolderTree, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { faCog, faInfoCircle, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { Nav, Navbar, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { fetchQuery, GraphQLTaggedNode, useRelayEnvironment } from 'react-relay';
import moment from 'moment';
import { IFile } from '@eolementhe/video-editor-model';
import {
  GET_CURRENT_USERPROFILE_QUERY,
  GET_CURRENT_USER_INFOS_QUERY,
  GET_LAST_BUILD_OF_PROJECT
} from '../../api/GraphQL';
import { QueryRenderer, getGraphQLError } from '../../api/QueryRenderer';
import { Logo } from './Logo';
import { ActionButton } from '../ActionButton';
import { getEolementheWebUrl } from '../../utils/ServerUtils';
import {
  doSaveProject,
  doShowProjectModal,
  doShowBuildModal,
  doShowLoadProjectModal,
  doCloseProject,
  doShowResourceBrowserModal
} from '../../redux/actions';
import { EoleEditStateEntry, MODALTYPE } from '../../redux/types';
import './MenuBar.scss';
import { GraphQLGetCurrentUserInfosQuery } from '../../__generated__/GraphQLGetCurrentUserInfosQuery.graphql';
import { GraphQLGetCurrentUserProfileQuery } from '../../__generated__/GraphQLGetCurrentUserProfileQuery.graphql';
import { UsageIndicator } from './components/UsageIndicator';
import { handleError } from '../../utils/handleError';
import {
  GraphQLGetLastBuildOfProjectQuery,
  GraphQLGetLastBuildOfProjectQueryResponse
} from '../../__generated__/GraphQLGetLastBuildOfProjectQuery.graphql';
import ActionSaveAsDropdown from '../ActionSaveAsDropdown';
import { createServerFileUrl } from '../../utils/uploadFiles';
import { ActionExport } from '../ActionExport/ActionExport';

let saveSuccessTimeout: NodeJS.Timeout | undefined;

const SavedNotification: React.FC = () => {
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const { lastSave, isSaving } = useSelector((state: EoleEditStateEntry) => ({
    isSaving: state.ee.isSaving,
    lastSave: state.ee.lastSave
  }));

  const dispatch = useDispatch();
  const [renderTrigger, setRenderTrigger] = React.useState(Date.now());
  React.useEffect(() => {
    return function cleanup() {
      if (saveSuccessTimeout) {
        clearTimeout(saveSuccessTimeout);
        saveSuccessTimeout = undefined;
      }
    };
  }, []);

  React.useEffect(() => {
    if (isSaving) {
      setShowSaveSuccess(true);
      saveSuccessTimeout = setTimeout(() => {
        setShowSaveSuccess(false);
        saveSuccessTimeout = undefined;
      }, 3000);
    }
  }, [isSaving, dispatch]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRenderTrigger(Date.now());
    }, 30000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="position-absolute">
      {showSaveSuccess ? (
        <div className="font-weight-bold text-primary">
          Saved
          <FontAwesomeIcon className="ml-1" icon={faCheck} />
        </div>
      ) : (
        <>{lastSave ? <div id={renderTrigger.toString()}>Saved {moment(lastSave).fromNow()}</div> : <></>}</>
      )}
    </div>
  );
};

export const MenuBar: React.FC = () => {
  const project = useSelector((state: EoleEditStateEntry) => state.ee.project);
  const isSaving = useSelector((state: EoleEditStateEntry) => state.ee.isSaving);
  const lastUpdateUserProfile = useSelector((state: EoleEditStateEntry) => state.ee.lastUpdateUserProfile);
  const dispatch = useDispatch();
  const isBuilding = useSelector((state: EoleEditStateEntry) => state.ee.isBuilding);
  const lastBuildId = useSelector((state: EoleEditStateEntry) => state.ee.builtVideoId);
  const [lastBuildFile, setLastBuildFile] = useState<IFile | undefined>(undefined);
  const hasProject = !!project;
  const actions = {
    left: [
      {
        title: 'New Project',
        description: 'Create a new project.',
        icon: faFile,
        execute: () => dispatch(doShowProjectModal(true, MODALTYPE.CREATION))
      },
      {
        title: 'Open My Projects',
        description: 'Load or delete an existing project',
        icon: faFolderOpen,
        execute: () => dispatch(doShowLoadProjectModal(true))
      },
      {
        title: 'Save Project',
        description: 'Save current project',
        icon: faSave,
        execute: () => dispatch(doSaveProject()),
        canExecute: hasProject && !isSaving
      },
      {
        title: 'Save As',
        description: 'Save current project',
        icon: faClone,
        canExecute: hasProject,
        type: 'dropdown',
        TypeElement: ActionSaveAsDropdown
      },
      {
        title: !isBuilding ? 'Build Project' : 'Project building',
        description: !isBuilding ? 'Build current project' : 'Show current build',
        icon: !isBuilding ? faFileExport : faCog,
        spin: !!isBuilding,
        execute: () => {
          if (!isBuilding) {
            dispatch(doSaveProject());
            dispatch(doShowProjectModal(true, MODALTYPE.BUILD));
          } else {
            dispatch(doShowBuildModal(true, MODALTYPE.BUILD));
          }
        },
        canExecute: hasProject
      },
      {
        title: 'Edit Project',
        description: 'Edit current project',
        icon: faEdit,
        execute: () => {
          dispatch(doShowProjectModal(true, MODALTYPE.EDITION));
        },
        canExecute: hasProject
      },
      {
        title: 'Download',
        description: 'Download last build',
        icon: faDownload,
        execute: () => {
          if (lastBuildFile) {
            const aElement = document.createElement('a');
            aElement.download = lastBuildFile.name;
            aElement.href = createServerFileUrl(lastBuildFile.src, true);
            document.body.appendChild(aElement);
            aElement.click();
            aElement.remove();
          }
        },
        canExecute: hasProject && !!lastBuildFile
      },
      {
        title: 'Eole CC Subtitle',
        description: 'Subtitle last build in Eole CC',
        icon: faClosedCaptioning,
        execute: () => {
          if (lastBuildFile) {
            const aElement = document.createElement('a');
            aElement.download = lastBuildFile.name;
            aElement.href = `${getEolementheWebUrl()}/cc?id=${lastBuildFile.name}`;
            aElement.click();
            aElement.remove();
          }
        },
        canExecute: hasProject && !!lastBuildFile
      },
      {
        title: 'Export Menu',
        description: 'Menu of possible file to export',
        icon: faUpload,
        type: 'dropdown',
        TypeElement: ActionExport,
        canExecute: hasProject
      },
      {
        title: 'Close',
        description: 'Close current project',
        icon: faTimes,
        execute: () => dispatch(doCloseProject()),
        canExecute: hasProject
      }
    ] as IAction[],
    right: []
  };

  const history = useHistory();

  const onError = (error: Error) => {
    console.error(error);
    return <div>{getGraphQLError(error)}</div>;
  };

  const environment = useRelayEnvironment();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (project?._id) {
          const result: GraphQLGetLastBuildOfProjectQueryResponse | undefined =
            await fetchQuery<GraphQLGetLastBuildOfProjectQuery>(
              environment,
              GET_LAST_BUILD_OF_PROJECT as GraphQLTaggedNode,
              {
                projectId: project._id
              }
            ).toPromise();
          if (!result) {
            return;
          }
          setLastBuildFile(result?.getLastBuildOfProject as IFile);
        }
      } catch (err) {
        handleError(err as Error, 'Cannot get file');
      }
    })();
  }, [environment, project, lastBuildId]);

  return (
    <Navbar variant="dark" bg="dark" expand="sm" className="top-menu-bar shadow-sm d-flex flex-row p-0 pl-1">
      <Navbar.Brand>
        <Logo onClick={React.useCallback(() => history.push('/'), [history])} />
      </Navbar.Brand>
      <Nav>
        {actions.left.map((action) => (
          <ActionButton key={action.title} action={action} />
        ))}
      </Nav>

      <Nav className="navbar-center">{hasProject ? <SavedNotification /> : <></>}</Nav>
      <div className="flex-fill text-center align-self-center text-truncate ml-1 mr-1">
        {project && (
          <button
            className="navbar-middle-button"
            onClick={() => {
              history.push('/editor');
            }}
            title="Return to editor"
          >
            {project.title}
          </button>
        )}
      </div>
      <Nav className="navbar-center">
        {
          <QueryRenderer<GraphQLGetCurrentUserProfileQuery>
            query={GET_CURRENT_USERPROFILE_QUERY}
            onError={onError}
            variables={lastUpdateUserProfile}
            render={(props) => {
              if (!props?.getCurrentUserProfile) {
                throw new Error('Invalid GraphQL response.');
              }
              if (
                (props?.getCurrentUserProfile.type === 'lite' &&
                  !props?.getCurrentUserProfile.eoleEditHasActiveSubscription) ||
                props?.getCurrentUserProfile.type === 'trial'
              ) {
                return (
                  <ActionButton
                    className="text-primary"
                    action={{
                      title: 'Upgrade to Premium !',
                      description: 'Subscribe now.',
                      icon: faBadgeDollar,
                      execute: () => {
                        window.location.href = `${getEolementheWebUrl()}/cart?origin=${encodeURIComponent(
                          document.location.toString()
                        )}`;
                      }
                    }}
                  />
                );
              }
              if (
                props?.getCurrentUserProfile.type === 'lite' &&
                props?.getCurrentUserProfile.eoleEditHasActiveSubscription
              ) {
                return (
                  <ActionButton
                    className="text-primary"
                    action={{
                      title: 'Buy minutes !',
                      description: 'Get additional time.',
                      icon: faBadgeDollar,
                      execute: () => {
                        window.location.href = `${getEolementheWebUrl()}/cart?origin=${encodeURIComponent(
                          document.location.toString()
                        )}`;
                      }
                    }}
                  />
                );
              }
            }}
          />
        }
      </Nav>
      <Nav className="navbar-center">
        {
          <QueryRenderer<GraphQLGetCurrentUserProfileQuery>
            query={GET_CURRENT_USERPROFILE_QUERY}
            onError={onError}
            render={(props) => {
              if (!props?.getCurrentUserProfile) {
                throw new Error('Invalid GraphQL response.');
              }
              return (
                <UsageIndicator
                  eoleEditRight={props.getCurrentUserProfile.eoleEditRight}
                  eoleEditExpiry={props.getCurrentUserProfile.eoleEditExpiry as Date}
                  eoleEditStorageUsed={props.getCurrentUserProfile.eoleEditStorageUsed}
                  eoleEditStorageLimit={props.getCurrentUserProfile.eoleEditStorageLimit}
                  eoleEditTimeUsed={props.getCurrentUserProfile.eoleEditTimeUsed}
                  eoleEditTimeLimit={props.getCurrentUserProfile.eoleEditTimeLimit}
                />
              );
            }}
          />
        }
      </Nav>
      <Nav className="navbar-center">
        {
          <QueryRenderer<GraphQLGetCurrentUserInfosQuery>
            query={GET_CURRENT_USER_INFOS_QUERY}
            onError={onError}
            // eslint-disable-next-line react/jsx-no-bind
            render={(props) => {
              if (!props?.getCurrentUser) {
                throw new Error('Invalid GraphQL response.');
              }
              const { firstName, lastName, email } = props.getCurrentUser;
              return <div title={email}>{firstName && lastName ? `${firstName} ${lastName}` : email}</div>;
            }}
          />
        }
        <SettingButton />
      </Nav>
    </Navbar>
  );
};

const SettingButton: React.FC = () => {
  const dispatch = useDispatch();
  const handleResourcesItemClick = useCallback(() => {
    dispatch(doSaveProject());
    dispatch(doShowResourceBrowserModal(true));
  }, [dispatch]);

  return (
    <Dropdown className="mr-2">
      <Dropdown.Toggle size="lg" variant="outline-light" id="dropdown-basic" className="dropdown-profile-link">
        <FontAwesomeIcon icon={faCog} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu" alignRight={true}>
        <Dropdown.Item as="div">
          <p onClick={handleResourcesItemClick} className="d-flex align-items-center clickable">
            <FontAwesomeIcon icon={faFolderTree} />
            <span>Resources</span>
          </p>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <a
            className="d-flex align-items-center"
            href={getEolementheWebUrl()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span>Eolementhe</span>
          </a>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <a
            className="d-flex align-items-center"
            href={getEolementheWebUrl() + '/userProfile'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faUser} />
            <span>Profile</span>
          </a>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <a
            className="d-flex align-items-center"
            href={'https://support.videomenthe.fr'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faHeadset} />
            <span>Support</span>
          </a>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <a
            className="d-flex align-items-center"
            href="/release-notes/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            <span>Release Notes</span>
          </a>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
