/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLGetCurrentUserInfosQueryVariables = {};
export type GraphQLGetCurrentUserInfosQueryResponse = {
    readonly getCurrentUser: {
        readonly _id: string;
        readonly email: string;
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly company: string | null;
        readonly phone: string | null;
        readonly jobTitle: string | null;
    };
};
export type GraphQLGetCurrentUserInfosQuery = {
    readonly response: GraphQLGetCurrentUserInfosQueryResponse;
    readonly variables: GraphQLGetCurrentUserInfosQueryVariables;
};



/*
query GraphQLGetCurrentUserInfosQuery {
  getCurrentUser {
    _id
    email
    firstName
    lastName
    company
    phone
    jobTitle
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "getCurrentUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "company",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jobTitle",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLGetCurrentUserInfosQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GraphQLGetCurrentUserInfosQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "65f2b363ba136f7068ac6f6a6bad8fca",
    "id": null,
    "metadata": {},
    "name": "GraphQLGetCurrentUserInfosQuery",
    "operationKind": "query",
    "text": "query GraphQLGetCurrentUserInfosQuery {\n  getCurrentUser {\n    _id\n    email\n    firstName\n    lastName\n    company\n    phone\n    jobTitle\n  }\n}\n"
  }
};
})();
(node as any).hash = '2393164f61ff6f19478549152dbf57be';
export default node;
