"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.overrideConsole = void 0;
var _log = console.log;
var _info = console.info;
var _warn = console.warn;
var _error = console.error;
var getDate = function getDate() {
  return new Date().toISOString();
};
/**
 * @public
*/
exports.overrideConsole = function () {
  console.log = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    return _log.call.apply(_log, [console, getDate(), '| DBG |'].concat(args));
  };
  console.error = function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }
    return _error.call.apply(_error, [console, getDate(), '| ERR |'].concat(args));
  };
  console.warn = function () {
    for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }
    return _warn.call.apply(_warn, [console, getDate(), '| WRN |'].concat(args));
  };
  console.info = function () {
    for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }
    return _info.call.apply(_info, [console, getDate(), '| INF |'].concat(args));
  };
};