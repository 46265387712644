var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import React from "react";
import { mergeClassName } from "../utils/merge-class-name";
var CheckableInput = function (_super) {
  __extends(CheckableInput, _super);
  function CheckableInput(props) {
    var _this = _super.call(this, props) || this;
    _this.handleClick = _this.handleClick.bind(_this);
    return _this;
  }
  CheckableInput.prototype.render = function () {
    return React.createElement("div", {
      className: mergeClassName("clickable", "unselectable", this.props.className),
      onClick: this.handleClick
    }, React.createElement("input", {
      type: this.props.type,
      checked: this.props.checked,
      className: "ml-1 mr-1 align-items-center",
      readOnly: true
    }), this.props.children);
  };
  CheckableInput.prototype.handleClick = function (event) {
    var checked = this.props.type === "radio" ? true : !this.props.checked;
    this.props.onChange(checked);
  };
  return CheckableInput;
}(React.PureComponent);
export { CheckableInput };