import { faDropbox, faFlickr } from '@fortawesome/free-brands-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ResourceType,
  IImageResource,
  IImageSection,
  SectionKind,
  FileResource,
  IProject
} from '@eolementhe/video-editor-model';
import uuidv4 from 'uuid/v4';
import { AddTrackTabs } from './AddTrackTabs';
import AddPersonalFileTab from './AddPersonalFileTab';
import { EoleEditStateEntry } from '../../../redux/types';
import { doResetSelectedResources, doSelectResources } from '../../../redux/actions';

interface IProps {
  project: IProject;
  onAddTracks(images: IImageSection[]): void;
  automaticFitShape(project: IProject, section: IImageSection): void;
}

export const AddImageTrack: FC<IProps> = ({ project, onAddTracks, automaticFitShape }) => {
  const selectedResources = useSelector((state: EoleEditStateEntry) => state.ee.selectedResources);
  const dispatch = useDispatch();

  const handleClickAdd = (resource?: FileResource): void => {
    if (resource) {
      const image: IImageResource = resource as IImageResource;

      const section: IImageSection = {
        id: uuidv4(),
        preview: image.preview,
        text: image.name,
        start: 0,
        duration: 10000,
        kind: SectionKind.image,
        src: image.src,
        srcId: image._id,
        lowres: image.lowres,
        x: (1 % 10) * 20,
        y: (1 % 10) * 20,
        width: image.width,
        height: image.height,
        rotation: 0,
        opacity: 1
      };
      onAddTracks([section]);
      automaticFitShape(project, section);
    } else {
      const images: IImageResource[] = selectedResources as IImageResource[];

      const sections: IImageSection[] = images.map((image, i) => {
        return {
          id: uuidv4(),
          preview: image.preview,
          text: image.name,
          start: 0,
          duration: 10000,
          kind: SectionKind.image,
          src: image.src,
          srcId: image._id,
          lowres: image.lowres,
          x: (i % 10) * 20,
          y: (i % 10) * 20,
          width: image.width,
          height: image.height,
          rotation: 0,
          opacity: 1
        };
      });
      onAddTracks(sections);
      sections.forEach((section) => {
        automaticFitShape(project, section);
      });
      dispatch(doResetSelectedResources());
    }
  };

  const handleResourcesUploaded = (resources: IImageResource[]): void => {
    dispatch(doSelectResources(resources, true));
  };
  return (
    <AddTrackTabs
      defaultTabKey="personal"
      onClickAdd={undefined}
      tabs={[
        {
          tabKey: 'personal',
          title: 'Local',
          icon: faUser,
          content: (
            <AddPersonalFileTab
              resourceType={ResourceType.image}
              onAdd={handleResourcesUploaded}
              onAddTracks={handleClickAdd}
              canSelect={false}
            />
          )
        },
        {
          tabKey: 'flickr',
          title: 'Flickr',
          icon: faFlickr,
          disabled: true,
          content: <> </>
        },
        {
          tabKey: 'dropbox',
          title: 'Dropbox',
          icon: faDropbox,
          disabled: true,
          content: <div className="dropbox-btn" />
        }
      ]}
    />
  );
};
