import { faHourglassHalf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { FC } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { prettyTime } from '../../../utils/timeConverter';

interface ITimeUsageIndicatorProps {
  timeLimit: number;
  timeUsed: number;
  expiry: Date;
}

export const TimeUsageIndicator: FC<ITimeUsageIndicatorProps> = ({ timeLimit, timeUsed, expiry }) => {
  const isExpired = moment(expiry).isBefore();
  const popover = (
    <Popover id="popover-time-limit">
      <Popover.Title as="h3">
        <FontAwesomeIcon className="mr-2" icon={faHourglassHalf} />
        Time usage
      </Popover.Title>
      <Popover.Content>
        You have used <b>{prettyTime(timeUsed)}</b> on your credit of <b>{prettyTime(timeLimit)}</b>.
        {isExpired ? (
          <span>
            Your right has expired since the <b>{moment(expiry).format('DD/MM/YY')}</b>.
          </span>
        ) : (
          <span>
            You can use your <b>{prettyTime(timeLimit - timeUsed)}</b> remaining credits until the{' '}
            <b>{moment(expiry).format('DD/MM/YY')}</b>.
          </span>
        )}
      </Popover.Content>
    </Popover>
  );
  return (
    // 'hover' set twice to avoid bootstrap warning: https://github.com/react-bootstrap/react-bootstrap/issues/5027
    <OverlayTrigger trigger={['hover', 'hover']} placement="bottom" overlay={popover}>
      <div className="d-flex flex-column text-center mr-5">
        <span>{prettyTime(timeLimit - timeUsed)}</span>
        <span className={isExpired ? 'text-danger' : ''}>Until {moment(expiry).format('DD/MM/YY')}</span>
      </div>
    </OverlayTrigger>
  );
};
