"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FillType = void 0;
/**
 * Shape fill type (e.g. solid, gradient).
 * Used in conjunction with IFillable to determine how should the graphic item
 * should be filled.
 *
 * @public
 */
var FillType;
(function (FillType) {
  FillType["solid"] = "solid";
  FillType["gradient"] = "gradient";
})(FillType = exports.FillType || (exports.FillType = {}));