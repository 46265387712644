"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.replaceAll = void 0;
/**
 * Finds and replaces all occurrences of the given text by a replacement string.
 *
 * @param text - The text which must be edited.
 * @param find - The string to find.
 * @param replace - The replacement string to insert.
 *
 * @example Replace any occurrence of `foo` by `bar`:
 *
 * ```ts
 * replaceAll("Hello foo!", "foo", "bar"); // "Hello bar!"
 * replaceAll("foofoo!", "foo", "bar"); // "barbar!"
 * replaceAll("Hello world!", "foo", "bar"); // "Hello world!"
 * ```
 *
 * @public
 */
function replaceAll(text, find, replace) {
  return text.split(find).join(replace);
}
exports.replaceAll = replaceAll;