import Konva from 'konva';
import React, { ChangeEvent } from 'react';
import { IPosition, ITextSection } from '@eolementhe/video-editor-model';

interface IProps {
  value: ITextSection;
  node: Konva.Text;
  stagePosition: IPosition;
  onHide(value?: string): void;
}

interface IState {
  text: string;
}

export class KonvaTextArea extends React.Component<IProps, IState> {
  private textArea = React.createRef<HTMLTextAreaElement>();

  public constructor(props: IProps) {
    super(props);
    this.state = {
      text: this.props.value.text
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  public componentDidMount() {
    this.focus();
  }

  public componentDidUpdate(oldProps: IProps) {
    if (oldProps.value.text !== this.props.value.text) {
      this.setState({ text: this.props.value.text });
    }
  }

  private focus() {
    if (this.textArea.current === null) return;
    this.textArea.current.focus();
    this.textArea.current.select();
  }

  private handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ text: event.target.value });
  }

  private handleBlur() {
    if (this.textArea.current === null) return;
    this.props.onHide(this.textArea.current.value);
  }

  private handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (this.textArea.current === null) return;
    // On Esc do not set value back to node.
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  }

  public render() {
    const node = this.props.node;
    const { backgroundColor, fontStyle } = this.props.value;
    // We need to slightly move textarea on firefox
    // because it jumps a bit

    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const offsetY = isFirefox ? 2 + Math.round(node.fontSize() / 20) : 0;
    const style: React.CSSProperties = {
      position: 'absolute',
      left: `${this.props.stagePosition.x + node.x() - node.offsetX()}px`,
      top: `${this.props.stagePosition.y + node.y() - node.offsetY()}px`,
      width: `${node.width() - node.padding() * 2}px`,
      height: `${node.height() - node.padding() * 2 /*+ 5*/}px`,
      fontSize: `${node.fontSize()}px`,
      fontStyle: fontStyle.includes('italic') ? 'italic' : 'none',
      fontWeight: fontStyle.includes('bold') ? 'bold' : 'normal',
      padding: '0px',
      margin: '0px',
      overflow: 'hidden',
      background: backgroundColor,
      outline: 'none',
      resize: 'none',
      lineHeight: node.lineHeight(),
      fontFamily: node.fontFamily(),
      textAlign: node.align() as any,
      verticalAlign: node.verticalAlign(),
      color: node.fill(),
      transformOrigin: 'left top',
      transform: `translateY(-${offsetY}px)`
    };

    return (
      <textarea
        ref={this.textArea}
        value={this.state.text}
        onChange={this.handleChange}
        style={style}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
      />
    );
  }
}
