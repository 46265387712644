"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Iso3166Alpha2 = void 0;
/**
 * List of ISO 3166 alpha-2 country codes.
 *
 * @remarks
 * - [Official website](https://www.iso.org/iso-3166-country-codes.html)
 * - [Full list](https://www.iso.org/obp/ui/#search/code/)
 *
 * @public
 */
var Iso3166Alpha2;
(function (Iso3166Alpha2) {
  Iso3166Alpha2["af"] = "af";
  Iso3166Alpha2["al"] = "al";
  Iso3166Alpha2["am"] = "am";
  Iso3166Alpha2["az"] = "az";
  Iso3166Alpha2["ba"] = "ba";
  Iso3166Alpha2["bd"] = "bd";
  Iso3166Alpha2["bg"] = "bg";
  Iso3166Alpha2["br"] = "br";
  Iso3166Alpha2["by"] = "by";
  Iso3166Alpha2["ca"] = "ca";
  Iso3166Alpha2["cn"] = "cn";
  Iso3166Alpha2["cz"] = "cz";
  Iso3166Alpha2["de"] = "de";
  Iso3166Alpha2["dk"] = "dk";
  Iso3166Alpha2["ee"] = "ee";
  Iso3166Alpha2["es"] = "es";
  Iso3166Alpha2["et"] = "et";
  Iso3166Alpha2["fi"] = "fi";
  Iso3166Alpha2["fj"] = "fj";
  Iso3166Alpha2["fr"] = "fr";
  Iso3166Alpha2["gb"] = "gb";
  Iso3166Alpha2["ge"] = "ge";
  Iso3166Alpha2["gr"] = "gr";
  Iso3166Alpha2["hr"] = "hr";
  Iso3166Alpha2["ht"] = "ht";
  Iso3166Alpha2["hu"] = "hu";
  Iso3166Alpha2["id"] = "id";
  Iso3166Alpha2["ie"] = "ie";
  Iso3166Alpha2["il"] = "il";
  Iso3166Alpha2["in"] = "in";
  Iso3166Alpha2["ir"] = "ir";
  Iso3166Alpha2["is"] = "is";
  Iso3166Alpha2["it"] = "it";
  Iso3166Alpha2["jp"] = "jp";
  Iso3166Alpha2["kg"] = "kg";
  Iso3166Alpha2["kh"] = "kh";
  Iso3166Alpha2["kr"] = "kr";
  Iso3166Alpha2["kz"] = "kz";
  Iso3166Alpha2["la"] = "la";
  Iso3166Alpha2["lb"] = "lb";
  Iso3166Alpha2["lk"] = "lk";
  Iso3166Alpha2["ls"] = "ls";
  Iso3166Alpha2["lt"] = "lt";
  Iso3166Alpha2["lu"] = "lu";
  Iso3166Alpha2["lv"] = "lv";
  Iso3166Alpha2["mg"] = "mg";
  Iso3166Alpha2["mk"] = "mk";
  Iso3166Alpha2["mm"] = "mm";
  Iso3166Alpha2["mn"] = "mn";
  Iso3166Alpha2["mt"] = "mt";
  Iso3166Alpha2["mw"] = "mw";
  Iso3166Alpha2["mx"] = "mx";
  Iso3166Alpha2["my"] = "my";
  Iso3166Alpha2["ne"] = "ne";
  Iso3166Alpha2["ng"] = "ng";
  Iso3166Alpha2["nl"] = "nl";
  Iso3166Alpha2["no"] = "no";
  Iso3166Alpha2["np"] = "np";
  Iso3166Alpha2["nz"] = "nz";
  Iso3166Alpha2["pf"] = "pf";
  Iso3166Alpha2["ph"] = "ph";
  Iso3166Alpha2["pk"] = "pk";
  Iso3166Alpha2["pl"] = "pl";
  Iso3166Alpha2["pt"] = "pt";
  Iso3166Alpha2["ro"] = "ro";
  Iso3166Alpha2["rs"] = "rs";
  Iso3166Alpha2["ru"] = "ru";
  Iso3166Alpha2["rw"] = "rw";
  Iso3166Alpha2["sa"] = "sa";
  Iso3166Alpha2["se"] = "se";
  Iso3166Alpha2["si"] = "si";
  Iso3166Alpha2["sk"] = "sk";
  Iso3166Alpha2["so"] = "so";
  Iso3166Alpha2["sy"] = "sy";
  Iso3166Alpha2["th"] = "th";
  Iso3166Alpha2["tj"] = "tj";
  Iso3166Alpha2["to"] = "to";
  Iso3166Alpha2["tr"] = "tr";
  Iso3166Alpha2["tz"] = "tz";
  Iso3166Alpha2["ua"] = "ua";
  Iso3166Alpha2["un"] = "un";
  Iso3166Alpha2["us"] = "us";
  Iso3166Alpha2["uz"] = "uz";
  Iso3166Alpha2["vn"] = "vn";
  Iso3166Alpha2["ws"] = "ws";
  Iso3166Alpha2["za"] = "za";
  Iso3166Alpha2["zw"] = "zw";
})(Iso3166Alpha2 = exports.Iso3166Alpha2 || (exports.Iso3166Alpha2 = {}));