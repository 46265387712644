import React from 'react';
import prettyMilliseconds from 'pretty-ms';
import { IProject } from '@eolementhe/video-editor-model';

interface LoadProjectLineProps {
  project: IProject;
  selectedProject?: IProject;
  onSelectProject: (project: IProject) => void;
  onLoadProject: () => void;
}

const LoadProjectLine: React.FC<LoadProjectLineProps> = ({
  project,
  selectedProject,
  onSelectProject,
  onLoadProject
}) => {
  const isActive = selectedProject && project && selectedProject._id === project._id;
  const displayDate = project.lastUpdate ? new Date(project.lastUpdate).toLocaleString() : '';
  const handleClick = React.useCallback(() => {
    onSelectProject(project);
  }, [onSelectProject, project]);

  const handleDoubleClick = React.useCallback(() => {
    onSelectProject(project);
    onLoadProject();
  }, [project, onLoadProject, onSelectProject]);

  return (
    <>
      <tr
        className={isActive ? 'table-active' : ''}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        style={{ cursor: 'pointer' }}
      >
        <td className="pr-0 unselectable-text d-flex flex-row align-items-center">
          <span className="pl-1 pr-1 flex-fill text-truncate">{project.title}</span>
        </td>
        <td>{`${project.size.width} x ${project.size.height}`}</td>
        <td>{prettyMilliseconds(project.duration)}</td>
        <td>{displayDate}</td>
      </tr>
    </>
  );
};

export default LoadProjectLine;
