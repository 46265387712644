import { faDropbox } from '@fortawesome/free-brands-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uuidv4 from 'uuid/v4';
import { IAudioResource, ResourceType, IAudioSection, SectionKind, FileResource } from '@eolementhe/video-editor-model';

import { AddTrackTabs } from './AddTrackTabs';
import AddPersonalFileTab from './AddPersonalFileTab';
import { doResetSelectedResources, doSelectResources } from '../../../redux/actions';
import { EoleEditStateEntry } from '../../../redux/types';

interface IProps {
  onAddTracks(tracks: IAudioSection[]): void;
}

export const AddAudioTrack: FC<IProps> = ({ onAddTracks }) => {
  const selectedResources = useSelector((state: EoleEditStateEntry) => state.ee.selectedResources);
  const dispatch = useDispatch();

  const handleClickAdd = (resource?: FileResource): void => {
    if (resource) {
      const audio: IAudioResource = resource as IAudioResource;

      const section: IAudioSection = {
        id: uuidv4(),
        kind: SectionKind.audio,
        text: audio.name,
        src: audio.src,
        srcId: audio._id,
        lowres: audio.lowres,
        volume: 1,
        start: 0,
        duration: audio.duration,
        startMedia: 0,
        endMedia: 0 + audio.duration,
        originMediaDuration: 0 + audio.duration,
        preview: audio.preview
      };
      onAddTracks([section]);
    } else {
      const audios: IAudioResource[] = selectedResources as IAudioResource[];

      const sections: IAudioSection[] = audios.map((audio) => {
        return {
          id: uuidv4(),
          kind: SectionKind.audio,
          text: audio.name,
          src: audio.src,
          srcId: audio._id,
          lowres: audio.lowres,
          volume: 1,
          start: 0,
          duration: audio.duration,
          startMedia: 0,
          endMedia: 0 + audio.duration,
          originMediaDuration: 0 + audio.duration,
          preview: audio.preview
        };
      });
      onAddTracks(sections);
      dispatch(doResetSelectedResources());
    }
  };

  const handleResourcesUploaded = (resources: IAudioResource[]): void => {
    dispatch(doSelectResources(resources, true));
  };
  return (
    <AddTrackTabs
      defaultTabKey="personal"
      onClickAdd={undefined}
      tabs={[
        {
          tabKey: 'personal',
          title: 'Local',
          icon: faUser,
          content: (
            <AddPersonalFileTab
              resourceType={ResourceType.audio}
              onAdd={handleResourcesUploaded}
              onAddTracks={handleClickAdd}
              canSelect={false}
            />
          )
        },
        {
          tabKey: 'dropbox',
          title: 'Dropbox',
          icon: faDropbox,
          disabled: true,
          content: <div className="dropbox-btn" />
        }
      ]}
    />
  );
};
