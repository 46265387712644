/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TrackInput = {
    height?: number | null;
    id: string;
    kind: string;
    muted?: boolean | null;
    name: string;
    sections: Array<SectionInput>;
    visible?: boolean | null;
    width?: number | null;
    scale?: number | null;
};
export type SectionInput = {
    borderColor?: string | null;
    borderSize?: number | null;
    duration: number;
    font?: string | null;
    fontStyle?: string | null;
    fontColor?: string | null;
    fontSize?: number | null;
    backgroundColor?: string | null;
    width?: number | null;
    height?: number | null;
    id: string;
    kind: string;
    opacity?: number | null;
    preview?: string | null;
    rotation?: number | null;
    src?: string | null;
    srcId?: string | null;
    lowres?: string | null;
    start: number;
    startMedia?: number | null;
    endMedia?: number | null;
    text?: string | null;
    displayText?: string | null;
    volume?: number | null;
    x?: number | null;
    y?: number | null;
    notMovable?: boolean | null;
    notResizable?: boolean | null;
    effect?: TransitionEffectInput | null;
    offset?: number | null;
    originMediaDuration?: number | null;
    isWaveform?: boolean | null;
    linkedAudioSectionId?: string | null;
    cropX?: number | null;
    cropY?: number | null;
    cropWidth?: number | null;
    cropHeight?: number | null;
    initialWidth?: number | null;
    initialHeight?: number | null;
    trimmedSrc?: string | null;
    size?: number | null;
    amplitudeDegree?: number | null;
};
export type TransitionEffectInput = {
    type?: string | null;
    durationSec?: number | null;
};
export type SizeInput = {
    height: number;
    width: number;
};
export type GraphQLSaveProjectMutationVariables = {
    duration: number;
    tracks: Array<TrackInput | null>;
    title: string;
    isSameVideoSizeProject: string;
    videoBitrate: number;
    size: SizeInput;
};
export type GraphQLSaveProjectMutationResponse = {
    readonly saveProject: {
        readonly _id: string;
        readonly lastUpdate: unknown;
    };
};
export type GraphQLSaveProjectMutation = {
    readonly response: GraphQLSaveProjectMutationResponse;
    readonly variables: GraphQLSaveProjectMutationVariables;
};



/*
mutation GraphQLSaveProjectMutation(
  $duration: Float!
  $tracks: [TrackInput]!
  $title: String!
  $isSameVideoSizeProject: String!
  $videoBitrate: Float!
  $size: SizeInput!
) {
  saveProject(duration: $duration, tracks: $tracks, title: $title, isSameVideoSizeProject: $isSameVideoSizeProject, videoBitrate: $videoBitrate, size: $size) {
    _id
    lastUpdate
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "duration"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSameVideoSizeProject"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "size"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tracks"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "videoBitrate"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "duration",
        "variableName": "duration"
      },
      {
        "kind": "Variable",
        "name": "isSameVideoSizeProject",
        "variableName": "isSameVideoSizeProject"
      },
      {
        "kind": "Variable",
        "name": "size",
        "variableName": "size"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      },
      {
        "kind": "Variable",
        "name": "tracks",
        "variableName": "tracks"
      },
      {
        "kind": "Variable",
        "name": "videoBitrate",
        "variableName": "videoBitrate"
      }
    ],
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "saveProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastUpdate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLSaveProjectMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "GraphQLSaveProjectMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "a9f5f75a2ba9841a67cc47b1ac10f56c",
    "id": null,
    "metadata": {},
    "name": "GraphQLSaveProjectMutation",
    "operationKind": "mutation",
    "text": "mutation GraphQLSaveProjectMutation(\n  $duration: Float!\n  $tracks: [TrackInput]!\n  $title: String!\n  $isSameVideoSizeProject: String!\n  $videoBitrate: Float!\n  $size: SizeInput!\n) {\n  saveProject(duration: $duration, tracks: $tracks, title: $title, isSameVideoSizeProject: $isSameVideoSizeProject, videoBitrate: $videoBitrate, size: $size) {\n    _id\n    lastUpdate\n  }\n}\n"
  }
};
})();
(node as any).hash = '6212d6888834c6b606f1fe24066280ef';
export default node;
