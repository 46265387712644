"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.xor = void 0;
/**
 * Returns the result of a logical XOR between the given arguments.
 *
 * @param a - The left-side of the XOR operation.
 * @param b - The right-side of the XOR operation.
 *
 * @example
 *
 * ```ts
 * xor(false, false); // false
 * xor(false, true); // true
 * xor(true, false); // true
 * xor(true, true); // false
 * ```
 *
 * @public
 */
function xor(a, b) {
  return !a && b || a && !b;
}
exports.xor = xor;