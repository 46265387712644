import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileResource } from '@eolementhe/video-editor-model';
import { IDirectoryViewProps } from './IDirectoryViewProps';
import { ResourceUtils } from './ResourceUtils';
import { IResourceInfo } from './IResourceInfo';

type IconViewProps = IDirectoryViewProps;

export const IconView: React.FC<IconViewProps> = ({ resources: children, ...props }) => {
  const handleOnSelect = (resource: FileResource, selected: boolean) => {
    props.onSelectResources([resource], selected);
  };

  return (
    <Container className="overflow-y-auto min-height-0">
      {children.map(ResourceUtils.getResourceInfo).map((info) => (
        <ResourceView key={info.resource._id} {...props} resourceInfo={info} onSelect={handleOnSelect} />
      ))}
    </Container>
  );
};

type ResourceViewProps = Pick<
  IconViewProps,
  'onClickResource' | 'canSelect' | 'selectedResources' | 'onSelectResources'
> & {
  resourceInfo: IResourceInfo;
  onSelect: (resource: FileResource, selected: boolean) => void;
};

const ResourceView: React.FC<ResourceViewProps> = ({
  resourceInfo,
  canSelect,
  selectedResources,
  onSelect,
  onClickResource
}) => {
  const { icon, resource } = resourceInfo;

  const handleIconClick = () => {
    if (onClickResource) {
      onClickResource(resource);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onSelect(resource, e.target.checked);
  };
  return (
    <Button
      variant="outline-primary"
      className="btn border-0 m-1 pl-0 pr-0 position-relative resource-browser-icon-button"
    >
      {canSelect && (
        <input
          type="checkbox"
          className="resource-browser-icon-checkbox"
          aria-label={`Select ${resource.name}`}
          checked={
            !!(
              selectedResources &&
              selectedResources.find((selectedResource: FileResource) => selectedResource._id === resource._id)
            )
          }
          onChange={handleCheckboxChange}
        />
      )}
      <div className="resource-browser-resource-icon" onClick={handleIconClick}>
        <FontAwesomeIcon icon={icon} size="2x" fixedWidth />
        <div className="mt-1 w-100 text-center resource-browser-resource-name">{resource.name}</div>
      </div>
    </Button>
  );
};
