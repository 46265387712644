var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
var ActionPopover = function (_super) {
  __extends(ActionPopover, _super);
  function ActionPopover() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  ActionPopover.prototype.render = function () {
    return React.createElement(OverlayTrigger, {
      trigger: ['hover', 'focus'],
      placement: this.props.placement || 'bottom',
      delay: {
        show: 250,
        hide: 0
      },
      overlay: this.renderPopover()
    }, this.props.children);
  };
  ActionPopover.prototype.renderPopover = function () {
    var _a = this.props,
      description = _a.description,
      icon = _a.icon,
      title = _a.title;
    return React.createElement(Popover, {
      id: "action-popover"
    }, React.createElement(Popover.Title, {
      as: "h3"
    }, React.createElement(FontAwesomeIcon, {
      icon: icon
    }), " " + title), React.createElement(Popover.Content, null, description));
  };
  return ActionPopover;
}(React.PureComponent);
export { ActionPopover };