import { faDropbox } from '@fortawesome/free-brands-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uuidv4 from 'uuid/v4';
import {
  IVideoResource,
  ResourceType,
  IVideoSection,
  SectionKind,
  FileResource,
  TrackKind
} from '@eolementhe/video-editor-model';
import { AddTrackTabs } from './AddTrackTabs';
import AddPersonalFileTab from './AddPersonalFileTab';
import { doAddVideoTransitionPlaceHolder, doResetSelectedResources, doSelectResources } from '../../../redux/actions';
import { EoleEditStateEntry } from '../../../redux/types';

interface IProps {
  defaultWidth: number;
  defaultHeight: number;
  projectType: string;
  onAddTracks(tracks: IVideoSection[]): void;
}

export const AddVideoTrack: FC<IProps> = ({ defaultWidth, defaultHeight, projectType, onAddTracks }) => {
  const selectedResources = useSelector((state: EoleEditStateEntry) => state.ee.selectedResources);

  const videoTrackIndex = useSelector((state: EoleEditStateEntry) =>
    state.ee.project?.tracks.findIndex((track) => track.kind === TrackKind.video)
  );
  const dispatch = useDispatch();

  const handleClickAdd = (resource?: FileResource): void => {
    if (resource) {
      const video: IVideoResource = resource as IVideoResource;
      const section: IVideoSection = {
        id: uuidv4(),
        kind: SectionKind.video,
        text: video.name,
        src: video.src,
        srcId: video._id,
        lowres: video.lowres,
        volume: 1,
        x: (1 % 10) * 20,
        y: (1 % 10) * 20,
        width: video.width,
        height: video.height,
        rotation: 0,
        opacity: 1,
        start: 0,
        duration: video.duration,
        startMedia: 0,
        endMedia: 0 + video.duration,
        originMediaDuration: 0 + video.duration,
        preview: video.preview,
        cropX: video.cropX,
        cropY: video.cropY,
        cropWidth: video.cropWidth,
        cropHeight: video.cropHeight,
        initialWidth: video.width,
        initialHeight: video.height
      };
      onAddTracks([section]);
      if (videoTrackIndex && projectType === 'uncut') {
        dispatch(doAddVideoTransitionPlaceHolder(videoTrackIndex));
      }
    } else {
      const videos: IVideoResource[] = selectedResources as IVideoResource[];

      const sections: IVideoSection[] = videos.map((video, i) => {
        return {
          id: uuidv4(),
          kind: SectionKind.video,
          text: video.name,
          src: video.src,
          srcId: video._id,
          lowres: video.lowres,
          volume: 1,
          x: (i % 10) * 20,
          y: (i % 10) * 20,
          width: video.width,
          height: video.height,
          rotation: 0,
          opacity: 1,
          start: 0,
          duration: video.duration,
          startMedia: 0,
          endMedia: 0 + video.duration,
          originMediaDuration: 0 + video.duration,
          preview: video.preview,
          cropX: video.cropX,
          cropY: video.cropY,
          cropWidth: video.cropWidth,
          cropHeight: video.cropHeight,
          initialWidth: video.width,
          initialHeight: video.height
        };
      });
      onAddTracks(sections);
      dispatch(doResetSelectedResources());
    }
  };

  const handleResourcesUploaded = (resources: IVideoResource[]): void => {
    dispatch(doSelectResources(resources, true));
  };
  return (
    <AddTrackTabs
      defaultTabKey="personal"
      onClickAdd={undefined}
      tabs={[
        {
          tabKey: 'personal',
          title: 'Local',
          icon: faUser,
          content: (
            <AddPersonalFileTab
              resourceType={ResourceType.video}
              onAdd={handleResourcesUploaded}
              onAddTracks={handleClickAdd}
              canSelect={false}
            />
          )
        },
        {
          tabKey: 'dropbox',
          title: 'Dropbox',
          icon: faDropbox,
          disabled: true,
          content: <div className="dropbox-btn" />
        }
      ]}
    />
  );
};
