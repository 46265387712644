import { compareTwoStrings } from "string-similarity";
function sortAlphabetically(words) {
  return words.sort(function (current, target) {
    return current.toLowerCase().localeCompare(target);
  });
}
function sortRatings(ratings) {
  return ratings.sort(function (current, target) {
    return current.rating <= target.rating ? -1 : 1;
  });
}
var Suggest = function () {
  function Suggest(suggestions, caseSensitive) {
    this.caseSensitive = caseSensitive;
    this.suggestions = sortAlphabetically(suggestions);
  }
  Suggest.prototype.formatText = function (txt) {
    return this.caseSensitive ? txt : txt.toLowerCase();
  };
  Suggest.prototype.suggest = function (text) {
    var searchText = this.formatText(text);
    if (!searchText) {
      return this.suggestions;
    }
    return this.search(searchText);
  };
  Suggest.prototype.search = function (text) {
    if (text.length === 1) {
      return this.suggestFromCharacter(text);
    }
    return this.suggestFromString(text);
  };
  Suggest.prototype.suggestFromCharacter = function (searchText) {
    var _this = this;
    return this.suggestions.filter(function (word) {
      var firstChar = _this.formatText(word.substr(0, 1));
      return firstChar === searchText;
    });
  };
  Suggest.prototype.suggestFromString = function (searchText) {
    var _this = this;
    var ratings = this.suggestions.map(function (suggestion) {
      return {
        target: suggestion,
        rating: compareTwoStrings(searchText, _this.formatText(suggestion))
      };
    });
    return sortRatings(ratings).filter(function (rating) {
      return rating.rating > 0;
    }).map(function (rating) {
      return rating.target;
    });
  };
  return Suggest;
}();
export function suggest(suggestions, text, caseSensitive) {
  if (caseSensitive === void 0) {
    caseSensitive = false;
  }
  var suggest = new Suggest(suggestions, caseSensitive);
  return suggest.suggest(text);
}