import { Action } from '@reduxjs/toolkit';
import {
  BuildProgress,
  FileResource,
  IProject,
  ISize,
  ITrack,
  ITrackSection,
  TransitionEffect
} from '@eolementhe/video-editor-model';
import EoleEditError from '../types/EoleEditError';

export type SelectableResource = FileResource | TransitionEffect;
export interface EoleEditState {
  isSaving: boolean;
  lastSave: string;
  isProjectModalShown: boolean;
  isBuildModalShown: boolean;
  modalType: string;
  isLoadModalShown: boolean;
  isProjectBuildingModalShown: boolean;
  isBuilding: boolean;
  buildTitle: string;
  isSameVideoSizeProject: string;
  builtVideoId?: string;
  buildStatus?: BuildProgress;
  showSaveSuccess: boolean;
  selectedLoadedProject?: IProject;
  project?: IProject;
  isResourceBrowserModalShown: boolean;
  selectedResources: SelectableResource[];
  error?: EoleEditError;
  currentTrackIndex?: number;
  currentSectionIndex?: number;
  resourceList: FileResource[];
  lastUpdateUserProfile: Date;
  frameRate?: number;
}

export interface EoleEditStateEntry {
  ee: EoleEditState;
}

export interface EoleEditAction extends Action {
  payload?: any;
}

export enum MODALTYPE {
  CREATION = 'creation',
  EDITION = 'edition',
  BUILD = 'build'
}

export type DraggableResource = FileResource | TransitionEffect;

export interface WaveformDataArray {
  sectionId: string;
  dataArray: string;
}

// interfaces used to avoid saving ids in project structure
interface StructureSection extends Omit<ITrackSection, 'id'> {}

export interface StructureTrack extends Omit<ITrack, 'id' | 'sections'> {
  sections: StructureSection[];
}

export interface ProjectStructure {
  name: string;
  duration: number;
  isSameVideoSizedProject: string;
  videoBitrate: number;
  size: ISize;
  tracks: StructureTrack[];
}

export interface IImportedSection extends Omit<ITrackSection, 'id'> {
  text?: string;
  src?: string;
  srcId?: string;
  lowres?: string;
}