import { fetchQuery } from 'react-relay/hooks';
import {
  GraphQLGetCurrentUserProfileQuery,
  GraphQLGetCurrentUserProfileQueryResponse
} from '../__generated__/GraphQLGetCurrentUserProfileQuery.graphql';
import { RELAY_ENVIRONMENT } from '../api/relay-environment';
import { GET_CURRENT_USERPROFILE_QUERY } from '../api/GraphQL';

export interface UserProfileRight {
  right: boolean | undefined;
  expiry: Date;
}

let userProfileRight: UserProfileRight;

export const requestUserProfile = async (): Promise<GraphQLGetCurrentUserProfileQueryResponse | undefined> => {
  try {
    const userprofile: GraphQLGetCurrentUserProfileQueryResponse | undefined =
      await fetchQuery<GraphQLGetCurrentUserProfileQuery>(
        // @ts-ignore
        RELAY_ENVIRONMENT,
        GET_CURRENT_USERPROFILE_QUERY,
        {}
      ).toPromise();
    return userprofile;
  } catch (error) {
    console.error('App.tsx/UserProfile | ', error);
  }
};

export const requestEoleEditRight = async (): Promise<UserProfileRight | undefined> => {
  try {
    const userprofile: GraphQLGetCurrentUserProfileQueryResponse | undefined =
      await fetchQuery<GraphQLGetCurrentUserProfileQuery>(
        // @ts-ignore
        RELAY_ENVIRONMENT,
        GET_CURRENT_USERPROFILE_QUERY,
        {}
      ).toPromise();
    userProfileRight = {
      right: userprofile?.getCurrentUserProfile.eoleEditRight,
      expiry: new Date(userprofile?.getCurrentUserProfile.eoleEditExpiry as string)
    };
    return userProfileRight;
  } catch (error) {
    console.error('App.tsx/UserProfile | ', error);
  }
};

export const getEoleEditRight = (): UserProfileRight => {
  return userProfileRight;
};
