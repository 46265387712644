import { faPlus, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { FileResource } from '@eolementhe/video-editor-model';
import { IAction } from '@eolementhe/react-components';
import { createServerFileUrl } from '../../utils/uploadFiles';
import './ThumbnailCard.scss';
import { getEoleEditRight } from '../../utils/Userprofile';
import { doSelectResources } from '../../redux/actions';

interface IProps {
  content: JSX.Element;
  author?: string;
  authorUrl?: string;
  canSelect?: boolean;
  onAddTracks?(resource?: FileResource): void;
  resource: FileResource;
  selected: boolean;
  showSelectedBorder?: boolean;
  extraActions?: IAction[];
  onSelect(selected: boolean): void;
  canDownload?: boolean;
  url?: string;
  onRemoveResources?: () => void;
  canRemove?: boolean;
  resourceName: string;
}

function useShowOptions(): [boolean, () => void, () => void] {
  const [showOptions, setShowOptions] = React.useState(false);
  const handleMouseEnter = React.useCallback(() => {
    if (!showOptions) {
      setShowOptions(true);
    }
  }, [showOptions, setShowOptions]);
  const handleMouseLeave = React.useCallback(() => {
    if (showOptions) {
      setShowOptions(false);
    }
  }, [showOptions, setShowOptions]);
  return [showOptions, handleMouseEnter, handleMouseLeave];
}

//TODO: replace handleMouseXXX by css hover
export const ThumbnailCard: React.FC<IProps> = (props) => {
  const [showOptions, handleMouseEnter, handleMouseLeave] = useShowOptions();
  const { content, selected, resourceName, resource } = props;
  const className = `thumbnail-card  ${selected ? 'thumbnail-card-selected' : ''}`;
  const dispatch = useDispatch();
  const handleDragStart = () => {
    dispatch(doSelectResources([resource], true));
  };
  return (
    <Card
      title={resourceName}
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      draggable
      onDragStart={handleDragStart}
    >
      {content}
      <div className="thumbnail-card-name">{resourceName}</div>
      {showOptions && <div className={'thumbnail-card-options-background'} />}
      {showOptions && <Options {...props} />}
    </Card>
  );
};

function getSelectAction({ selected, onSelect }: IProps): IAction {
  return {
    title: selected ? 'Unselect' : 'Select',
    description: 'Select current item',
    icon: selected ? faCheckSquare : faSquare,
    execute: () => onSelect(!selected)
  } as IAction;
}

function getAddRessourceAction({ onAddTracks, resource }: IProps): IAction {
  if (onAddTracks !== undefined)
    return {
      title: 'Add',
      description: 'Add ressource in current track',
      icon: faPlus,
      execute: () => {
        onAddTracks(resource);
      }
    } as IAction;

  return {
    title: 'Add',
    description: 'Add ressource in current track',
    icon: faPlus,
    execute: () => {}
  } as IAction;
}

function getDownloadAction({ canDownload, url }: IProps): IAction {
  return {
    title: 'Download',
    description: 'Download this file',
    icon: faDownload,
    active: canDownload ?? false,
    execute: () => {
      if (!url) {
        return;
      }
      const win = window.open(createServerFileUrl(url), '_blank');
      if (win) {
        win.focus();
      }
    }
  } as IAction;
}

function getRemoveAction(onRemoveResources: () => void): IAction {
  return {
    title: 'Remove',
    description: 'Remove this file',
    icon: faTrash,
    active: true,
    execute: () => onRemoveResources()
  } as IAction;
}

function useActions(props: IProps): IAction[] {
  let actions = [];

  if (props.canSelect) {
    actions.push(getSelectAction(props));
  }

  if (props.onAddTracks) {
    actions.push(getAddRessourceAction(props));
  }

  if (props.canRemove && props.onRemoveResources) {
    actions.push(getRemoveAction(props.onRemoveResources));
  }

  if (props.canDownload) {
    actions.push(getDownloadAction(props));
  }
  if (props.extraActions) {
    actions = actions.concat(props.extraActions);
  }
  return actions;
}

const Options: React.FC<IProps> = (props) => {
  const actions = useActions(props);
  const isRightExpired = getEoleEditRight().expiry.getTime() < new Date().getTime();
  return (
    <div
      className="position-absolute w-100 h-100 d-flex text-white justify-content-around"
      style={{ top: 0, left: 0, right: 0, bottom: 0 }}
    >
      {actions.map((action) => (
        <button
          key={action.title}
          className="btn text-white m-0 p-0"
          disabled={isRightExpired && action.title === 'Expand'}
          onClick={isRightExpired && action.title === 'Expand' ? () => {} : action.execute}
          title={isRightExpired && action.title === 'Expand' ? 'Your rights have expired' : ''}
          type="button"
        >
          <FontAwesomeIcon icon={action.icon} size="2x" fixedWidth />
        </button>
      ))}
    </div>
  );
};
