"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BuildStatusEnum = void 0;
/** @public */
var BuildStatusEnum;
(function (BuildStatusEnum) {
  BuildStatusEnum["none"] = "none";
  BuildStatusEnum["end"] = "end";
  BuildStatusEnum["video"] = "video";
  BuildStatusEnum["media"] = "media";
  BuildStatusEnum["build"] = "build";
  BuildStatusEnum["blankVideo"] = "blankVideo";
  BuildStatusEnum["addAudioStream"] = "addAudioStream";
  BuildStatusEnum["videoSection"] = "videoSection";
  BuildStatusEnum["addWaveform"] = "addWaveform";
  BuildStatusEnum["createVideo"] = "createVideo";
  BuildStatusEnum["audioSection"] = "audioSection";
  BuildStatusEnum["buildTask"] = "buildTask";
})(BuildStatusEnum = exports.BuildStatusEnum || (exports.BuildStatusEnum = {}));