export var ButtonVariant;
(function (ButtonVariant) {
  ButtonVariant["primary"] = "primary";
  ButtonVariant["secondary"] = "secondary";
  ButtonVariant["success"] = "success";
  ButtonVariant["danger"] = "danger";
  ButtonVariant["warning"] = "warning";
  ButtonVariant["info"] = "info";
  ButtonVariant["dark"] = "dark";
  ButtonVariant["light"] = "light";
  ButtonVariant["link"] = "link";
  ButtonVariant["outlinePrimary"] = "outline-primary";
  ButtonVariant["outlineSecondary"] = "outline-secondary";
  ButtonVariant["outlineSuccess"] = "outline-success";
  ButtonVariant["outlineDanger"] = "outline-danger";
  ButtonVariant["outlineWarning"] = "outline-warning";
  ButtonVariant["outlineInfo"] = "outline-info";
  ButtonVariant["outlineDark"] = "outline-dark";
  ButtonVariant["outlineLight"] = "outline-light";
})(ButtonVariant || (ButtonVariant = {}));
export var ButtonSize;
(function (ButtonSize) {
  ButtonSize["default"] = "default";
  ButtonSize["sm"] = "sm";
  ButtonSize["lg"] = "lg";
})(ButtonSize || (ButtonSize = {}));