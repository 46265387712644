var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
export var ContentType;
(function (ContentType) {
  ContentType["video"] = "video";
  ContentType["audio"] = "audio";
  ContentType["text"] = "text";
})(ContentType || (ContentType = {}));
function getUrl(url) {
  var explode = url.split("/");
  explode.pop();
  return explode.join("/") + "/";
}
export function findOneTypedAdaptation(type, adaptations) {
  if (adaptations) {
    return adaptations.find(function (adaptation) {
      return adaptation.contentType === type;
    });
  }
  return;
}
export function findTypedAdaptations(type, adaptations) {
  if (adaptations) {
    return adaptations.filter(function (adaptation) {
      return adaptation.contentType === type;
    });
  }
  return;
}
function getDimension(type, adaptation) {
  var value = adaptation.getAttribute(type);
  return value ? parseInt(value) : undefined;
}
function getDuration(duration) {
  return duration !== null && duration !== void 0 ? duration : "PT10M";
}
function getGeneralInformation(manifest, filePath) {
  var _a, _b;
  var locationLink = (_a = manifest.getElementsByTagName("Location")[0].textContent) !== null && _a !== void 0 ? _a : filePath;
  var url = getUrl(locationLink);
  var manifestDuration = manifest.getElementsByTagName("MPD")[0].getAttribute("mediaPresentationDuration");
  var duration = getDuration(manifestDuration);
  var title = (_b = manifest.getElementsByTagName("Title")[0].textContent) !== null && _b !== void 0 ? _b : "untitled";
  return {
    manifestUrl: filePath,
    url: url,
    duration: duration,
    title: title
  };
}
function getContentType(mimeType) {
  return mimeType.split("/")[0];
}
function getAdaptationChildren(adaptationElement) {
  var _a, _b;
  var id = (_a = adaptationElement.getAttribute("id")) !== null && _a !== void 0 ? _a : "";
  var mimeType = (_b = adaptationElement.getAttribute("mimeType")) !== null && _b !== void 0 ? _b : "";
  var contentType = getContentType(mimeType);
  var width = getDimension("width", adaptationElement);
  var height = getDimension("height", adaptationElement);
  var framerateAttribute = adaptationElement.getAttribute("framerate");
  var framerate = framerateAttribute ? parseInt(framerateAttribute) : 25;
  var segmentTemplateElement = adaptationElement.getElementsByTagName("SegmentTemplate")[0];
  return {
    id: id,
    mimeType: mimeType,
    contentType: contentType,
    width: width,
    height: height,
    framerate: framerate,
    segmentTemplateElement: segmentTemplateElement
  };
}
function getTemplateChildren(template) {
  var _a, _b;
  var timescaleString = template.getAttribute("timescale");
  var timescale = timescaleString ? parseInt(timescaleString) : 1000;
  var mediaTemplate = (_a = template.getAttribute("media")) !== null && _a !== void 0 ? _a : "";
  var initializationTemplate = (_b = template.getAttribute("initialization")) !== null && _b !== void 0 ? _b : "";
  var segmentElement = template.querySelectorAll("S")[0];
  return {
    timescale: timescale,
    mediaTemplate: mediaTemplate,
    initializationTemplate: initializationTemplate,
    segmentElement: segmentElement
  };
}
function parseSegment(element) {
  var durationString = element.getAttribute("d");
  var duration = durationString ? parseInt(durationString) : 1000;
  var repetitionString = element.getAttribute("r");
  var repetition = repetitionString ? parseInt(repetitionString) : 0;
  return {
    duration: duration,
    repetition: repetition
  };
}
function getSegmentTimeline(element) {
  var segmentElements = element.querySelectorAll("S");
  var segments = [];
  segmentElements.forEach(function (element) {
    return segments.push(parseSegment(element));
  });
  return segments;
}
function getTotalDuration(segmentTimeline) {
  var timeline = segmentTimeline.map(function (segment) {
    return segment.duration;
  });
  var totalDuration = timeline.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue;
  });
  return totalDuration;
}
function parseRepresentation(element, segmentTimeline) {
  var _a, _b;
  var id = (_a = element.getAttribute("id")) !== null && _a !== void 0 ? _a : "";
  var codecs = (_b = element.getAttribute("codecs")) !== null && _b !== void 0 ? _b : "";
  var baseUrlElement = element.getElementsByTagName("BaseURL")[0];
  var baseUrlText = baseUrlElement ? baseUrlElement.textContent : undefined;
  var baseUrl = baseUrlText !== null && baseUrlText !== void 0 ? baseUrlText : "";
  var bandwidthString = element.getAttribute("bandwidth");
  var bandwidth = bandwidthString ? parseInt(bandwidthString) : 8000;
  var totalDuration = getTotalDuration(segmentTimeline);
  return {
    id: id,
    baseUrl: baseUrl,
    codecs: codecs,
    bandwidth: bandwidth,
    totalDuration: totalDuration
  };
}
function getRepresentation(element, segmentTimeline) {
  var representationElements = element.querySelectorAll("Representation");
  var representations = [];
  representationElements.forEach(function (element) {
    return representations.push(parseRepresentation(element, segmentTimeline));
  });
  return representations;
}
function parseAdaptation(element) {
  var _a = getAdaptationChildren(element),
    id = _a.id,
    mimeType = _a.mimeType,
    contentType = _a.contentType,
    height = _a.height,
    width = _a.width,
    framerate = _a.framerate,
    segmentTemplateElement = _a.segmentTemplateElement;
  var _b = getTemplateChildren(segmentTemplateElement),
    timescale = _b.timescale,
    mediaTemplate = _b.mediaTemplate,
    initializationTemplate = _b.initializationTemplate,
    segmentElement = _b.segmentElement;
  var startTimeString = segmentElement.getAttribute("t");
  var startTime = startTimeString ? parseInt(startTimeString) : 0;
  var segmentTimeline = getSegmentTimeline(element);
  var representation = getRepresentation(element, segmentTimeline);
  return {
    id: id,
    contentType: contentType,
    mimeType: mimeType,
    height: height,
    width: width,
    framerate: framerate,
    timescale: timescale,
    mediaTemplate: mediaTemplate,
    initializationTemplate: initializationTemplate,
    startTime: startTime,
    segmentTimeline: segmentTimeline,
    representation: representation
  };
}
function getAdaptationList(manifest) {
  var adaptationElements = manifest.querySelectorAll("AdaptationSet");
  var adaptations = [];
  adaptationElements.forEach(function (element) {
    return adaptations.push(parseAdaptation(element));
  });
  return adaptations;
}
function parseManifest(manifest, url) {
  var adaptations = getAdaptationList(manifest);
  var generalInformation = getGeneralInformation(manifest, url);
  var manifestObject = __assign(__assign({}, generalInformation), {
    adaptations: adaptations
  });
  return manifestObject;
}
export function getMpegDashManifest(url) {
  return new Promise(function (resolve, reject) {
    if (url) {
      var xhr_1 = new XMLHttpRequest();
      xhr_1.open("GET", url, true);
      xhr_1.responseType = "text";
      xhr_1.send();
      xhr_1.onreadystatechange = function () {
        if (xhr_1.readyState === xhr_1.DONE) {
          var parser = new DOMParser();
          var xmlData = parser.parseFromString(xhr_1.response, "text/xml");
          var parsedFile = parseManifest(xmlData, url);
          resolve(parsedFile);
        }
      };
      xhr_1.addEventListener("error", function (e) {
        console.error("Error: " + e + " Could not load url.");
        reject();
      }, false);
    } else {
      console.log("No url provided.");
      reject();
    }
  });
}
export function getTimeline(adaptation) {
  var timeline = [];
  if (!adaptation) return timeline;
  var durations = adaptation.segmentTimeline.flatMap(function (segment) {
    return Array.from(new Array(segment.repetition + 1), function () {
      return segment.duration;
    });
  });
  durations.reduce(function (accumulator, currentValue) {
    timeline.push(accumulator);
    return accumulator + currentValue;
  }, adaptation.startTime);
  return timeline;
}