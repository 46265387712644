"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.coreReducer = void 0;
var initialState = {
  storeCreated: false
};
function coreReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'core.store.create':
      return {
        storeCreated: true
      };
    default:
      return state;
  }
}
exports.coreReducer = coreReducer;