var _a, _b;
import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faUserPlus, faKey } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "./context";
import { DisplayMode } from "./DisplayMode";
var TITLE_ICONS = (_a = {}, _a[DisplayMode.register] = faUserPlus, _a[DisplayMode.signIn] = faSignInAlt, _a[DisplayMode.passwordReset] = faKey, _a);
var TITLE_TEXT_KEYS = (_b = {}, _b[DisplayMode.register] = "register", _b[DisplayMode.signIn] = "signIn", _b[DisplayMode.passwordReset] = "passwordReset", _b);
export var Title = function Title() {
  var displayMode = useContext().displayMode;
  var t = useTranslation().t;
  return React.createElement("h5", {
    className: "authentication-title"
  }, React.createElement(FontAwesomeIcon, {
    icon: TITLE_ICONS[displayMode]
  }), "\xA0", t(TITLE_TEXT_KEYS[displayMode]));
};