var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMusic, faFilm, faImage } from '@fortawesome/free-solid-svg-icons';
import { ButtonVariant, ButtonSize } from '../../../react-bootstrap-types';
import { ActionButton, ActionButtonTitleLayout } from '../../../actions';
var iconByKind = {
  media: faImage,
  video: faFilm,
  audio: faMusic
};
var TimelineTrackHeader = function (_super) {
  __extends(TimelineTrackHeader, _super);
  function TimelineTrackHeader(props) {
    return _super.call(this, props) || this;
  }
  TimelineTrackHeader.prototype.buildClassName = function () {
    return ['timeline-track timeline-track-header', 'd-flex p-0 flew-row', this.props.isSelected ? 'timeline-current-track' : 'timeline-unselected-track', this.props.borderStyle].join(' ');
  };
  TimelineTrackHeader.prototype.render = function () {
    var _a = this.props,
      name = _a.name,
      kind = _a.kind;
    var icon = kind ? iconByKind[kind] : faFilm;
    return React.createElement("div", {
      className: this.buildClassName()
    }, React.createElement("div", {
      className: "flex-fill p-1 timeline-track-header-text",
      onClick: this.props.onSelectTrack
    }, React.createElement(FontAwesomeIcon, {
      title: name,
      className: "timeline-track-header-icon",
      icon: icon,
      fixedWidth: true
    })), this.props.actions.map(function (action) {
      return React.createElement(ActionButton, __assign({
        key: action.title
      }, action, {
        placement: "top",
        titleLayout: ActionButtonTitleLayout.noTitle,
        className: "btn p-0 pl-1 pr-1 border-0 no-rounded h-100",
        variant: ButtonVariant.outlinePrimary,
        size: ButtonSize.sm
      }));
    }));
  };
  return TimelineTrackHeader;
}(React.PureComponent);
export { TimelineTrackHeader };