export default {
  SET_PROJECT_SAVING: 'SET_PROJECT_SAVING',
  SET_PROJECT_SAVED: 'SET_PROJECT_SAVED',
  SET_LAST_UPDATE_USERPROFILE: 'SET_LAST_UPDATE_USERPROFILE',
  SHOW_PROJECT_MODAL: 'SHOW_PROJECT_MODAL',
  SHOW_BUILD_MODAL: 'SHOW_BUILD_MODAL',
  SHOW_LOAD_PROJECT_MODAL: 'SHOW_LOAD_PROJECT_MODAL',
  SHOW_PROJECT_BUILDING_MODAL: 'SHOW_PROJECT_BUILDING_MODAL',
  BUILD_PROJECT: 'BUILD_PROJECT',
  BUILDING_PROJECT: 'BUILDING_PROJECT',
  BUILDING_STATUS: 'BUILDING_STATUS',
  SET_BUILD_ID: 'SET_BUILD_ID',
  SHOW_SAVE_SUCCESS: 'SHOW_SAVE_SUCCESS',
  LOAD_PROJECT: 'LOAD_PROJECT',
  CLOSE_PROJECT: 'CLOSE_PROJECT',
  CREATE_PROJECT: 'CREATE_PROJECT',
  SHOW_RESOURCE_BROWSER_MODAL: 'SHOW_RESOURCE_BROWSER_MODAL',
  RESET_SELECTED_RESOURCES: 'RESET_SELECTED_RESOURCES',
  SELECT_RESOURCES: 'SELECT_RESOURCES',
  SET_RESOURCE_LIST: 'SET_RESOURCE_LIST',
  SHOW_ERROR: 'SHOW_ERROR',
  SET_SECTION_DURATION: 'SET_SECTION_DURATION',
  SET_SECTION_START: 'SET_SECTION_START',
  SET_SECTION_START_MEDIA: 'SET_SECTION_START_MEDIA',
  SET_SECTION_END_MEDIA: 'SET_SECTION_END_MEDIA',
  SET_SECTION_ORIGIN_MEDIA_DURATION: 'SET_SECTION_ORIGIN_MEDIA_DURATION',
  CENTER_SHAPE: 'CENTER_SHAPE',
  FIT_SHAPE: 'FIT_SHAPE',
  UPDATE_PROJECT_DURATION: 'UPDATE_PROJECT_DURATION',
  SET_SECTION_CURRENT: 'SET_SECTION_CURRENT',
  SET_FRAMERATE: 'SET_FRAMERATE',
  SET_SECTION_SPLIT: 'SET_SECTION_SPLIT',
  SET_TEXT_SECTION_PROP: 'SET_TEXT_SECTION_PROP',
  SET_TEXT_SECTION: 'SET_TEXT_SECTION',
  SET_IMAGE_SECTION_PROP: 'SET_IMAGE_SECTION_PROP',
  DELETE_CURRENT_SECTION: 'DELETE_CURRENT_SECTION',
  SET_TRACK_PROP: 'SET_TRACK_PROP',
  DELETE_CURRENT_TRACK: 'DELETE_CURRENT_TRACK',
  ADD_TRACK: 'ADD_TRACK',
  SET_VIDEO_SECTION_PROP: 'SET_VIDEO_SECTION_PROP',
  SET_AUDIO_SECTION_PROP: 'SET_AUDIO_SECTION_PROP',
  SET_WAVEFORM_SECTION_PROP: 'SET_WAVEFORM_SECTION_PROP',
  ADD_SECTION: 'ADD_SECTION',
  SET_PROJECT_DURATION: 'SET_PROJECT_DURATION',
  SET_TRACK_CURRENT: 'SET_TRACK_CURRENT',
  MOVE_TRACK: 'MOVE_TRACK',
  SET_TEXT_SECTION_GEOM: 'SET_TEXT_SECTION_GEOM',
  ADD_PLACE_HOLDER_TRANSITION: 'ADD_PLACE_HOLDER_TRANSITION',
  SET_TRANSITION_SECTION_PROP: 'SET_TRANSITION_SECTION_PROP',
  SET_ELSE_TRANSITION_SECTION_PROP: 'SET_ELSE_TRANSITION_SECTION_PROP',
  SET_ELSE_TRANSITION_DURATION_SECTION_PROP: 'SET_ELSE_TRANSITION_DURATION_SECTION_PROP',
  DELETE_SECTION: 'DELETE_SECTION',
  EXPORT_EDL: 'EXPORT_EDL',
  EXPORT_PROJECT_STRUCTURE: 'EXPORT_PROJECT_STRUCTURE',
  SET_SECTION_SRC_PROPERTIES: 'SET_SECTION_SRC_PROPERTIES'
};
