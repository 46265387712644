import React from "react";
import ReactMarkdown from "react-markdown";
import { getEmoji } from "../utils";
export var Markdown = function Markdown(props) {
  var emojiSupport = function emojiSupport(_a) {
    var value = _a.value;
    return React.createElement(React.Fragment, null, value.replace(/:[\w\\+]+:/gi, getEmoji));
  };
  return React.createElement(ReactMarkdown, {
    className: props.className,
    source: props.value,
    allowedTypes: props.allowedTypes,
    linkTarget: "_blank",
    unwrapDisallowed: true,
    renderers: {
      text: emojiSupport
    }
  });
};