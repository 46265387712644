import React from 'react';
import { IAudioResource, IVisible } from '@eolementhe/video-editor-model';

import { IPlayable } from './IPlayable';

interface IProps
  extends Omit<
      IAudioResource,
      'resourceKind' | 'preview' | '_id' | 'name' | 'size' | 'startTime' | 'duration' | 'lowres'
    >,
    IVisible,
    IPlayable {}

interface IState {
  isPlaying: boolean;
}

export class UrlAudio extends React.PureComponent<IProps, IState> {
  private audioElement: HTMLAudioElement = document.createElement('audio');

  public constructor(props: IProps) {
    super(props);
    this.state = {
      isPlaying: this.props.isPlaying
    };
    this.audioElement.loop = true;
  }

  public componentDidMount() {
    this.audioElement.crossOrigin = 'anonymous';
    this.loadAudio();
    this.syncVolume();
  }

  public async componentDidUpdate(oldProps: IProps) {
    if (oldProps.src !== this.props.src) {
      this.loadAudio();
    }
    if (oldProps.mute !== this.props.mute || oldProps.volume !== this.props.volume) {
      this.syncVolume();
    }
    await this.syncAudio();
  }

  public componentWillUnmount() {
    // TODO find a way to destroy the loaded element.
    this.audioElement.pause();
  }

  private loadAudio() {
    this.audioElement.src = this.props.src;
  }

  private syncVolume() {
    this.audioElement.volume = this.props.mute ? 0 : this.props.volume;
  }

  private async syncAudio() {
    if (this.props.visible) {
      const timeDiff = Math.abs(this.audioElement.currentTime * 1000 - this.props.currentTime);
      if (timeDiff > 1000) {
        this.audioElement.currentTime = this.props.currentTime / 1000;
      }
    }
    if (this.props.isPlaying && this.props.visible && !this.state.isPlaying) {
      await this.audioElement.play();
      this.setState({ isPlaying: true });
    } else if ((!this.props.isPlaying && this.state.isPlaying) || (!this.props.visible && this.state.isPlaying)) {
      this.audioElement.pause();
      this.setState({ isPlaying: false });
    }
  }

  public render() {
    return <></>;
  }
}
