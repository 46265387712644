import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { mergeClassName } from '@eolementhe/react-components';

import { APP_NAME } from '../EditorPage/Constants';

export interface ILogoProps {
  className?: string;
  onClick?: () => void;
}

export const Logo: React.FC<ILogoProps> = ({ className, onClick }) => (
  <div className={mergeClassName(className, onClick ? 'clickable' : undefined)} onClick={onClick}>
    <FontAwesomeIcon icon={faVideo} />
    {` ${APP_NAME}`}
    {/* <sup className="text-primary">beta</sup> */}
  </div>
);
