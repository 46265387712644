var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React from "react";
import { FormControl } from "react-bootstrap";
import { mergeClassName } from "../utils";
import { parseTweet } from "@eolementhe/twitter-text";
var TweetInputContext = React.createContext({
  message: "",
  valid: false,
  weightedLength: 0,
  permillage: 0,
  onChange: function onChange() {}
});
var useContext = function useContext() {
  return React.useContext(TweetInputContext);
};
export var TweetInput = function TweetInput(props) {
  var context = __assign(__assign({}, parseTweet(props.message)), props);
  return React.createElement(TweetInputContext.Provider, {
    value: context
  }, React.createElement(TweetInputBody, null));
};
var TweetInputBody = function TweetInputBody() {
  return React.createElement("div", {
    className: "tweet-input"
  }, React.createElement(Message, null), React.createElement(CharacterLimit, null));
};
var Message = function Message() {
  var _a = useContext(),
    valid = _a.valid,
    message = _a.message,
    onChange = _a.onChange;
  var handleChange = React.useCallback(function (event) {
    onChange(event.currentTarget.value);
  }, [onChange]);
  return React.createElement(FormControl, {
    as: "textarea",
    className: valid ? undefined : "tweet-input-message-warning",
    placeholder: "Message",
    onChange: handleChange,
    value: message
  });
};
var CharacterLimit = function CharacterLimit() {
  var _a = useContext(),
    valid = _a.valid,
    weightedLength = _a.weightedLength,
    permillage = _a.permillage;
  var className = mergeClassName("tweet-input-count", "tweet-input-color-" + (valid ? "count" : "warning"));
  return React.createElement("div", {
    className: className
  }, weightedLength + " (" + permillage / 10 + "%)");
};