"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GradientType = void 0;
/**
 * Types of gradient which can be used in conjunction with {@link IFillable}
 * when `fillType` is {@link FillType.gradient}.
 *
 * @public
 */
var GradientType;
(function (GradientType) {
  GradientType["linear"] = "linear";
  GradientType["radial"] = "radial";
})(GradientType = exports.GradientType || (exports.GradientType = {}));