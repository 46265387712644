var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import React from "react";
import { ListGroup } from "react-bootstrap";
import { mergeClassName, suggest } from "../utils";
var SUGGESTION_START = 0;
var DEFAULT_OFFSET = 36;
var TypeheadInput = function (_super) {
  __extends(TypeheadInput, _super);
  function TypeheadInput(props) {
    var _this = _super.call(this, props) || this;
    _this.input = React.createRef();
    _this.suggestionsList = React.createRef();
    _this.currentSuggestionItem = React.createRef();
    _this.state = {
      value: "",
      currentSuggestion: SUGGESTION_START,
      suggestions: suggest(props.suggestions, props.value ? props.value : "", props.caseSensitive),
      showTypehead: false
    };
    _this.handleOnChange = _this.handleOnChange.bind(_this);
    _this.handleShowSuggestions = _this.handleShowSuggestions.bind(_this);
    _this.handleHideSuggestions = _this.handleHideSuggestions.bind(_this);
    _this.handleKeyDown = _this.handleKeyDown.bind(_this);
    _this.scrollToWord = _this.scrollToWord.bind(_this);
    _this.handleClickSuggestion = _this.handleClickSuggestion.bind(_this);
    return _this;
  }
  TypeheadInput.getDerivedStateFromProps = function (nextProps, prevState) {
    var _a;
    if (nextProps.value !== prevState.value) {
      return {
        value: (_a = nextProps.value) !== null && _a !== void 0 ? _a : prevState.value
      };
    }
    return {};
  };
  TypeheadInput.prototype.render = function () {
    var _a;
    return React.createElement("div", {
      className: "core-typehead"
    }, React.createElement("input", {
      type: "text",
      value: this.state.value,
      onChange: this.handleOnChange,
      className: "core-typehead-input",
      ref: this.input,
      onFocus: this.handleShowSuggestions,
      onBlur: this.handleHideSuggestions,
      onKeyDown: this.handleKeyDown
    }), this.state.showTypehead && React.createElement(ListGroup, {
      className: "core-typehead-suggestions",
      ref: this.suggestionsList,
      style: {
        left: 0,
        top: ((_a = this.input.current) === null || _a === void 0 ? void 0 : _a.clientHeight) || DEFAULT_OFFSET
      }
    }, this.renderSuggestions()));
  };
  TypeheadInput.prototype.renderSuggestions = function () {
    var _this = this;
    return this.state.suggestions.map(function (suggestion, i) {
      var isCurrent = i === _this.state.currentSuggestion;
      var active = isCurrent ? " active" : undefined;
      return React.createElement(ListGroup.Item, {
        ref: isCurrent ? _this.currentSuggestionItem : undefined,
        key: i,
        className: mergeClassName("core-typehead-suggestions-item", active),
        "data-index": i,
        onMouseDown: _this.handleClickSuggestion,
        action: true
      }, suggestion);
    }, this);
  };
  TypeheadInput.prototype.handleShowSuggestions = function () {
    var input = this.input.current;
    if (input === null) return;
    this.setState({
      showTypehead: true
    });
  };
  TypeheadInput.prototype.handleHideSuggestions = function () {
    this.setState({
      showTypehead: false
    });
  };
  TypeheadInput.prototype.handleOnChange = function (event) {
    var useState = this.props.value === undefined;
    var value = event.target.value;
    var stateValue = useState ? value : "";
    if (!useState && this.props.onChange) {
      this.props.onChange(value);
    }
    var suggestions = suggest(this.props.suggestions, value, this.props.caseSensitive);
    this.setState({
      value: stateValue,
      suggestions: suggestions,
      currentSuggestion: SUGGESTION_START
    });
  };
  TypeheadInput.prototype.handleKeyDown = function (event) {
    switch (event.key) {
      case "ArrowDown":
        this.moveSuggestionDown();
        break;
      case "ArrowUp":
        this.moveSuggestionUp();
        break;
      case "Enter":
        this.acceptSuggestion(this.state.currentSuggestion);
        break;
      default:
        break;
    }
  };
  TypeheadInput.prototype.scrollToWord = function () {
    if (!this.suggestionsList.current) return;
    if (!this.currentSuggestionItem.current) return;
    this.suggestionsList.current.scrollTop = this.currentSuggestionItem.current.offsetTop;
  };
  TypeheadInput.prototype.moveSuggestionUp = function () {
    var currentSuggestion = this.state.currentSuggestion === 0 ? this.state.suggestions.length - 1 : this.state.currentSuggestion - 1;
    this.setState({
      currentSuggestion: currentSuggestion
    }, this.scrollToWord);
  };
  TypeheadInput.prototype.moveSuggestionDown = function () {
    var currentSuggestion = this.state.currentSuggestion === this.state.suggestions.length - 1 ? 0 : this.state.currentSuggestion + 1;
    this.setState({
      currentSuggestion: currentSuggestion
    }, this.scrollToWord);
  };
  TypeheadInput.prototype.acceptSuggestion = function (index) {
    var value = this.state.suggestions[index];
    var newValue = this.props.value === undefined ? value : "";
    this.setState({
      suggestions: [],
      currentSuggestion: index,
      value: newValue
    });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };
  TypeheadInput.prototype.handleClickSuggestion = function (event) {
    event.preventDefault();
    var indexStr = event.currentTarget.getAttribute("data-index");
    if (indexStr !== null) {
      var index = parseInt(indexStr);
      this.acceptSuggestion(index);
    }
  };
  return TypeheadInput;
}(React.PureComponent);
export { TypeheadInput };