var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
  for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
  return r;
};
import React from 'react';
import throttle from 'lodash/throttle';
import uuidv4 from 'uuid/v4';
import { TimelineControlBar } from './TimelineControlBar';
import { TimelineRuler } from './TimelineRuler';
import { TimelineTrack } from './TimelineTrack';
import { TimelineTrackHeader } from './TimelineTrackHeader';
import { TrackAction } from './TrackAction';
import { TrackKind } from '../model';
import { isNumber } from 'lodash';
var MAGNETISM_LIMIT_IN_PX = 15;
var Timeline = function (_super) {
  __extends(Timeline, _super);
  function Timeline(props) {
    var _this = _super.call(this, props) || this;
    _this.trackColumn = React.createRef();
    _this.headerColumn = React.createRef();
    _this.rulerContainer = React.createRef();
    _this.state = {
      zoom: 1,
      cursorPreviewX: 0,
      showCursorPreview: false,
      showRightBorderHeaderTracklist: true,
      stepDuration: 0,
      stepWidth: 0,
      stepCount: 0,
      zoomMin: 0.1,
      zoomMax: 5
    };
    _this.handleSplitSection = _this.handleSplitSection.bind(_this);
    _this.handleChangeZoom = _this.handleChangeZoom.bind(_this);
    _this.scrollPropagation = _this.scrollPropagation.bind(_this);
    _this.handleChangeCursorPreviewX = _this.handleChangeCursorPreviewX.bind(_this);
    _this.handleChangeShowCursorPreview = _this.handleChangeShowCursorPreview.bind(_this);
    _this.handleChangeStep = _this.handleChangeStep.bind(_this);
    _this.handleZoomLimit = _this.handleZoomLimit.bind(_this);
    _this.onResize = _this.onResize.bind(_this);
    _this.handleResize = throttle(_this.onResize, 300, {
      trailing: true
    });
    return _this;
  }
  Timeline.prototype.componentDidMount = function () {
    var _a, _b;
    var rulerWidth = (_b = (_a = this.rulerContainer.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) !== null && _b !== void 0 ? _b : 0;
    this.setState({
      rulerWidth: rulerWidth
    });
    window.addEventListener('resize', this.handleResize);
  };
  Timeline.prototype.componentWillUnmount = function () {
    window.removeEventListener('resize', this.handleResize);
  };
  Timeline.prototype.scrollPropagation = function () {
    if (this.trackColumn.current === null || this.headerColumn.current === null || this.rulerContainer.current === null) return;
    this.headerColumn.current.scrollTop = this.trackColumn.current.scrollTop;
    this.rulerContainer.current.scrollLeft = this.trackColumn.current.scrollLeft;
    this.setState({
      showRightBorderHeaderTracklist: this.trackColumn.current.scrollLeft === 0
    });
  };
  Timeline.prototype.getZoomScale = function (zoom) {
    return (zoom + 0.01) * 1000;
  };
  Timeline.prototype.handleSplitSection = function (timeTc) {
    var _a;
    var time = timeTc.toTime();
    var _b = this.props,
      currentTrackIndex = _b.currentTrackIndex,
      currentSectionIndex = _b.currentSectionIndex,
      tracks = _b.tracks;
    if (currentTrackIndex === undefined || currentSectionIndex === undefined) {
      throw new Error('No section selected');
    }
    var section = (_a = tracks[currentTrackIndex]) === null || _a === void 0 ? void 0 : _a.sections[currentSectionIndex];
    if (!section || time <= section.start || time >= section.start + section.duration) {
      throw new Error('Time selector is out of the section to split');
    }
    var currentSectionNewDuration = time - section.start;
    var newSection = JSON.parse(JSON.stringify(section));
    newSection.startMedia = section.startMedia != null ? section.startMedia + currentSectionNewDuration : undefined;
    newSection.start = time;
    newSection.duration = section.duration - currentSectionNewDuration;
    newSection.id = uuidv4();
    this.props.onSplitSection(currentTrackIndex, currentSectionIndex, currentSectionNewDuration, newSection);
  };
  Timeline.prototype.handleChangeZoom = function (zoom) {
    this.setState({
      zoom: zoom
    });
  };
  Timeline.prototype.handleChangeCursorPreviewX = function (cursorPreviewX) {
    this.setState({
      cursorPreviewX: cursorPreviewX
    });
  };
  Timeline.prototype.handleChangeShowCursorPreview = function (showCursorPreview) {
    this.setState({
      showCursorPreview: showCursorPreview
    });
  };
  Timeline.prototype.handleChangeStep = function (step) {
    this.setState(__assign({}, step));
  };
  Timeline.prototype.onResize = function () {
    if (this.rulerContainer && this.rulerContainer.current) {
      this.setState({
        rulerWidth: this.rulerContainer.current.offsetWidth
      });
    }
  };
  Timeline.prototype.handleZoomLimit = function (zoomMin, zoomMax) {
    this.setState({
      zoomMin: zoomMin,
      zoomMax: zoomMax
    });
  };
  Timeline.prototype.renderTrackHeader = function (track, trackIndex) {
    var _this = this;
    var currentTrackCss = this.props.currentTrackIndex === trackIndex ? true : false;
    var borderStyle = this.state.showRightBorderHeaderTracklist ? ' border-right-0' : ' ';
    var trackActions = this.props.trackActions === undefined ? function () {
      return [];
    } : this.props.trackActions;
    var selectTrack = function selectTrack() {
      return _this.props.onChangeCurrentTrack(trackIndex, true);
    };
    return React.createElement(TimelineTrackHeader, {
      name: track.name,
      kind: track.kind,
      key: track.id,
      onSelectTrack: selectTrack,
      isSelected: currentTrackCss,
      borderStyle: borderStyle,
      actions: TrackAction.build(trackActions, track, trackIndex)
    });
  };
  Timeline.prototype.renderTimelineHeader = function () {
    var _this = this;
    return React.createElement("div", {
      className: "timeline flex-fill d-flex flex-column overflow-y-hidden timeline-container-headers-scrollbar",
      ref: this.headerColumn
    }, this.props.tracks.map(function (track, i) {
      return _this.renderTrackHeader(track, i);
    }).reverse());
  };
  Timeline.prototype.renderTimelineRuler = function () {
    var _a;
    return React.createElement("div", {
      className: "timeline-ruler overflow-y-hidden overflow-x-hidden border-bottom-0",
      ref: this.rulerContainer
    }, React.createElement(TimelineRuler, {
      timeline: this,
      duration: this.props.duration,
      currentTime: this.props.currentTime,
      scrollLeft: this.trackColumn && this.trackColumn.current ? this.trackColumn.current.scrollLeft : 0,
      offset: this.props.offset,
      displayOffset: this.props.displayOffset,
      zoom: this.state.zoom,
      cursorPreviewX: this.state.cursorPreviewX,
      showCursorPreview: this.state.showCursorPreview,
      stepDuration: this.state.stepDuration,
      stepWidth: this.state.stepWidth,
      markers: this.props.markers,
      stepCount: this.state.stepCount,
      framerate: this.props.framerate,
      displayFrame: this.props.displayFrame,
      containerWidth: (_a = this.state.rulerWidth) !== null && _a !== void 0 ? _a : 0,
      onCurrentTimeChanged: this.props.onChangeCurrentTime,
      onCursorPreviewXChanged: this.handleChangeCursorPreviewX,
      onShowCursorPreviewChanged: this.handleChangeShowCursorPreview,
      onClickMarker: this.props.onClickMarker,
      onChangeMarkerTimeSec: this.props.onChangeMarkerTimeSec,
      onPauseMediaOnSelection: this.props.onPauseMediaOnSelection,
      onStepChanges: this.handleChangeStep,
      onChangeZoomLimit: this.handleZoomLimit
    }));
  };
  Timeline.prototype.findSurroundingSections = function (track, section) {
    var sections = __spreadArrays(track.sections);
    if (!sections.find(function (curSection) {
      return section.id === curSection.id;
    })) {
      sections.push(section);
    }
    var sortedSectionsStarts = __spreadArrays(sections.sort(function (s1, s2) {
      return s1.start > s2.start ? 1 : -1;
    }));
    var sortedSectionsEnds = __spreadArrays(sections.sort(function (s1, s2) {
      return s1.start + s1.duration > s2.start + s2.duration ? 1 : -1;
    }));
    var positionInSectionsStarts = sortedSectionsStarts.findIndex(function (curSection) {
      return section.id === curSection.id;
    });
    var positionInSectionsEnds = sortedSectionsEnds.findIndex(function (curSection) {
      return section.id === curSection.id;
    });
    return {
      previousSection: positionInSectionsStarts > 0 ? sortedSectionsStarts[positionInSectionsStarts - 1] : undefined,
      nextSection: positionInSectionsEnds >= 0 ? sortedSectionsEnds[positionInSectionsEnds + 1] : undefined,
      isOverlappingAnotherSection: positionInSectionsStarts >= 0 && positionInSectionsEnds >= 0 && positionInSectionsStarts !== positionInSectionsEnds
    };
  };
  Timeline.prototype.computeSectionEndDragConstraints = function (track, sectionIndex, startBeforeMove, targetTrackIndex) {
    var section = __assign({}, track.sections[sectionIndex]);
    var targetTrack = isNumber(targetTrackIndex) && this.props.tracks[targetTrackIndex] ? this.props.tracks[targetTrackIndex] : track;
    if (track && targetTrack && targetTrack.id !== track.id) {
      if (!this.props.canMoveInOtherKind && targetTrack.kind !== track.kind) {
        return {
          newSectionStart: startBeforeMove,
          newTrackIndex: undefined
        };
      } else if (this.props.canMoveInOtherKind && targetTrack.kind === track.kind) {
        return {
          newSectionStart: startBeforeMove,
          newTrackIndex: targetTrackIndex
        };
      }
    }
    var projectDuration = this.props.duration;
    var _a = this.findSurroundingSections(targetTrack, section),
      previousSection = _a.previousSection,
      nextSection = _a.nextSection,
      isOverlappingAnotherSection = _a.isOverlappingAnotherSection;
    var currentStart = section.start;
    var currentDuration = section.duration;
    var currentEnd = section.start + currentDuration;
    var currentMiddle = currentEnd - (currentEnd - currentStart) / 2;
    var prevStart = (previousSection === null || previousSection === void 0 ? void 0 : previousSection.start) || 0;
    var prevEnd = prevStart + ((previousSection === null || previousSection === void 0 ? void 0 : previousSection.duration) || 0);
    var prevMiddle = prevEnd - (prevEnd - prevStart) / 2;
    var nextStart = (nextSection === null || nextSection === void 0 ? void 0 : nextSection.start) || projectDuration;
    var nextEnd = nextStart + ((nextSection === null || nextSection === void 0 ? void 0 : nextSection.duration) || 0);
    var nextMiddle = nextEnd - (nextEnd - nextStart) / 2;
    var newSectionStart = currentStart;
    var nearestFromStart = Number.MAX_SAFE_INTEGER;
    var nearestFromEnd = Number.MAX_SAFE_INTEGER;
    this.props.tracks.forEach(function (t) {
      var sections = __spreadArrays(t.sections);
      sections.forEach(function (s) {
        if (s.id === section.id) {
          return;
        }
        var end = s.start + s.duration;
        if (Math.abs(s.start - currentStart) < Math.abs(nearestFromStart - currentStart)) {
          nearestFromStart = s.start;
        }
        if (Math.abs(end - currentStart) < Math.abs(nearestFromStart - currentStart)) {
          nearestFromStart = end;
        }
        if (Math.abs(s.start - currentEnd) < Math.abs(nearestFromEnd - currentEnd)) {
          nearestFromEnd = s.start;
        }
        if (Math.abs(end - currentEnd) < Math.abs(nearestFromEnd - currentEnd)) {
          nearestFromEnd = end;
        }
      });
    });
    var magnetismLimitMs = MAGNETISM_LIMIT_IN_PX * (this.state.stepDuration / this.state.stepWidth);
    if (Math.abs(currentStart - nearestFromStart) < magnetismLimitMs) {
      newSectionStart = nearestFromStart;
    } else if (Math.abs(currentEnd - nearestFromEnd) < magnetismLimitMs) {
      newSectionStart = nearestFromEnd - section.duration;
    }
    if (isOverlappingAnotherSection || nextStart - prevEnd < currentDuration) {
      return {
        newSectionStart: startBeforeMove,
        newTrackIndex: undefined
      };
    } else if (currentStart < prevEnd) {
      if (currentMiddle < prevMiddle) {
        newSectionStart = prevStart - currentDuration;
      } else {
        newSectionStart = prevEnd;
      }
    } else if (currentEnd > nextStart) {
      if (currentMiddle < nextMiddle) {
        newSectionStart = nextStart - currentDuration;
      } else {
        newSectionStart = nextEnd;
      }
    }
    if (newSectionStart < 0 || newSectionStart + section.duration > projectDuration) {
      return {
        newSectionStart: startBeforeMove,
        newTrackIndex: undefined
      };
    }
    return {
      newSectionStart: newSectionStart,
      newTrackIndex: targetTrackIndex
    };
  };
  Timeline.prototype.renderTimelineTrack = function (track, trackIndex) {
    var _this = this;
    var handleChangeSectionStart = function handleChangeSectionStart(sectionIndex, start, isDragging) {
      var newStart = start;
      if (!isDragging) {
        var section = track.sections[sectionIndex];
        var previousSection = _this.findSurroundingSections(track, section).previousSection;
        var previousEnd = (previousSection === null || previousSection === void 0 ? void 0 : previousSection.start) ? previousSection.start + previousSection.duration : 0;
        newStart = Math.max(previousEnd, start);
      }
      return _this.props.onChangeSectionStart(trackIndex, sectionIndex, newStart);
    };
    var handleEndDragSection = function handleEndDragSection(sectionIndex, startBeforeMove, targetTrackIndex) {
      var _a = _this.computeSectionEndDragConstraints(track, sectionIndex, startBeforeMove, targetTrackIndex),
        newSectionStart = _a.newSectionStart,
        newTrackIndex = _a.newTrackIndex;
      _this.props.onChangeSectionStart(trackIndex, sectionIndex, newSectionStart, newTrackIndex);
      if (_this.props.isTransitionHandled && track.sections.length > 0 && track.kind === TrackKind.video && _this.props.onAddTransitionPlaceHolder) {
        _this.props.onAddTransitionPlaceHolder(trackIndex, sectionIndex);
      }
    };
    var handleChangeSectionDuration = function handleChangeSectionDuration(sectionIndex, newDuration) {
      var projectDuration = _this.props.duration;
      var section = track.sections[sectionIndex];
      var nextSection = _this.findSurroundingSections(track, section).nextSection;
      var nextStart = (nextSection === null || nextSection === void 0 ? void 0 : nextSection.start) || projectDuration;
      var newConstraintDuration = Math.min(nextStart - section.start, newDuration);
      if (section.endMedia != null && section.startMedia != null) {
        newConstraintDuration = Math.min(section.endMedia - section.startMedia, newConstraintDuration);
      }
      return _this.props.onChangeSectionDuration(trackIndex, sectionIndex, newConstraintDuration);
    };
    var handleChangeSectionStartMedia = function handleChangeSectionStartMedia(sectionIndex, newDuration) {
      if (_this.props.onChangeSectionStartMedia) {
        return _this.props.onChangeSectionStartMedia(trackIndex, sectionIndex, newDuration >= 0 ? newDuration : 0);
      }
    };
    var handleChangeSectionEndMedia = function handleChangeSectionEndMedia(sectionIndex, newDuration) {
      var section = track.sections[sectionIndex];
      if (_this.props.onChangeSectionEndMedia && section.originMediaDuration) {
        return _this.props.onChangeSectionEndMedia(trackIndex, sectionIndex, newDuration <= section.originMediaDuration ? newDuration : section.originMediaDuration);
      }
    };
    var handleClickEmptySrcSection = function handleClickEmptySrcSection(sectionIndex) {
      if (_this.props.onClickEmptySrcSection) {
        return _this.props.onClickEmptySrcSection(trackIndex, sectionIndex);
      }
    };
    return React.createElement(TimelineTrack, {
      timeline: this,
      duration: this.props.duration,
      track: track,
      currentTime: this.props.currentTime,
      currentSectionIndex: this.props.currentSectionIndex,
      isCurrentTrack: this.props.currentTrackIndex === trackIndex,
      zoom: this.state.zoom,
      cursorPreviewX: this.state.cursorPreviewX,
      scrollLeft: this.trackColumn && this.trackColumn.current ? this.trackColumn.current.scrollLeft : 0,
      showCursorPreview: this.state.showCursorPreview,
      stepCount: this.state.stepCount,
      stepDuration: this.state.stepDuration,
      stepWidth: this.state.stepWidth,
      framerate: this.props.framerate,
      onChangeCurrentSection: this.props.onChangeCurrentSection,
      onEndDragSection: handleEndDragSection,
      onChangeCurrentTime: this.props.onChangeCurrentTime,
      onChangeCursorPreviewX: this.handleChangeCursorPreviewX,
      onChangeShowCursorPreview: this.handleChangeShowCursorPreview,
      onChangeSectionStart: handleChangeSectionStart,
      onChangeSectionDuration: handleChangeSectionDuration,
      onChangeSectionStartMedia: handleChangeSectionStartMedia,
      onChangeSectionEndMedia: handleChangeSectionEndMedia,
      onDeleteSelectedSection: this.props.onDeleteSelectedSection,
      onDropTransition: this.props.onDropTransition,
      onPlaceholderClick: this.props.onPlaceholderClick,
      onTransitionClick: this.props.onTransitionClick,
      onClickEmptySrcSection: handleClickEmptySrcSection
    });
  };
  Timeline.prototype.renderTimelineTracks = function () {
    var _this = this;
    var styleRow = {
      width: this.state.stepCount * this.state.stepWidth + 1
    };
    return React.createElement("div", {
      className: "flex-fill overflow-y-hidden overflow-x",
      ref: this.trackColumn,
      onScroll: this.scrollPropagation
    }, this.props.tracks.map(function (track, i) {
      var currentTrackCss = _this.props.currentTrackIndex === i ? 'timeline-current-track' : 'timeline-unselected-track';
      var changeCurrentTrack = function changeCurrentTrack(event) {
        var unselectSection = event.target === event.currentTarget || !event.isSection;
        event.isSection = false;
        return _this.props.onChangeCurrentTrack(i, unselectSection);
      };
      return React.createElement("div", {
        key: track.id,
        className: "timeline-track timeline-track-content d-flex flex-row " + currentTrackCss + " border-left-0 min-width-100 ",
        id: track.kind + "-" + i,
        style: styleRow,
        onMouseDown: changeCurrentTrack,
        onDragOver: _this.props.onTrackDragOver,
        onDrop: _this.props.onDrop
      }, _this.renderTimelineTrack(track, i));
    }).reverse());
  };
  Timeline.prototype.render = function () {
    return React.createElement("div", {
      className: "timeline-container flex-fill d-flex flex-column border-right min-width-0"
    }, React.createElement(TimelineControlBar, {
      currentSectionIndex: this.props.currentSectionIndex,
      tracks: this.props.tracks,
      currentTrackIndex: this.props.currentTrackIndex,
      duration: this.props.duration,
      currentTime: this.props.currentTime,
      offset: this.props.offset,
      displayOffset: this.props.displayOffset,
      framerate: this.props.framerate,
      isPlaying: this.props.isPlaying,
      canPlay: this.props.canPlay,
      zoom: this.state.zoom,
      hideZoom: this.props.hideZoom,
      displayAddSection: this.props.displayAddSection,
      displayRemoveSection: this.props.displayRemoveSection,
      displaySplitSection: this.props.displaySplitSection,
      zoomMin: this.state.zoomMin,
      zoomMax: this.state.zoomMax,
      displayFrame: this.props.displayFrame,
      isVideoEditor: this.props.isVideoEditor,
      playbackRate: this.props.playbackRate,
      deleteSection: this.props.onDeleteSelectedSection,
      onPlay: this.props.onPlay,
      onPause: this.props.onPause,
      onStop: this.props.onStop,
      onPreviousFrame: this.props.onPreviousFrame,
      onNextFrame: this.props.onNextFrame,
      onChangeZoom: this.handleChangeZoom,
      onAddTrack: this.props.onAddTrack,
      onRemoveTrack: this.props.onRemoveTrack,
      onMoveCurrentTrackUp: this.props.onMoveCurrentTrackUp,
      onMoveCurrentTrackDown: this.props.onMoveCurrentTrackDown,
      onDuplicateCurrentTrack: this.props.onDuplicateCurrentTrack,
      updateDuration: this.props.updateDuration,
      onSplitSection: this.handleSplitSection,
      onChangePlaybackRate: this.props.onChangePlaybackRate
    }), React.createElement("div", {
      className: "timeline-track-wrapper timeline-main m-1 flex-fill d-flex flex-row min-height-0 unselectable-text"
    }, this.props.tracks[0] && this.props.tracks[0].name ? React.createElement("div", {
      className: "timeline-ruler-container d-flex flex-column timeline-ruler-header"
    }, React.createElement("div", {
      className: "timeline-ruler"
    }), this.renderTimelineHeader()) : React.createElement(React.Fragment, null), React.createElement("div", {
      id: "timeline-ruler-wrapper",
      className: "flex-fill d-flex flex-column min-width-0"
    }, this.renderTimelineRuler(), this.renderTimelineTracks())));
  };
  return Timeline;
}(React.PureComponent);
export { Timeline };