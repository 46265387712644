import uuidv4 from 'uuid/v4';
import {
  FillType,
  FontFamily,
  GradientType,
  IAudioTrack,
  IMediaTrack,
  IProject,
  IVideoTrack,
  TrackKind,
  Track,
  ILinearGradient,
  IRadialGradient,
  ISolidFill
} from '@eolementhe/video-editor-model';

interface IDefaultFillStyles {
  solid: ISolidFill;
  radialGradient: IRadialGradient;
  linearGradient: ILinearGradient;
}

export const APP_NAME = 'Eole Edit';

export const MIN_PROJECT_DURATION = 60000;

export const DURATION_PERCENT = 80;

export const DEFAULT_DURATION = 10000;

export function getDefaultScene(width: number, height: number) {
  const mediaTrack: IMediaTrack = {
    id: uuidv4(),
    kind: TrackKind.media,
    name: 'Media',
    visible: true,
    sections: []
  };
  const videoTrack: IVideoTrack = {
    id: uuidv4(),
    kind: TrackKind.video,
    name: 'Video',
    visible: true,
    muted: false,
    width,
    height,
    sections: []
  };
  const audioTrack: IAudioTrack = {
    id: uuidv4(),
    kind: TrackKind.audio,
    name: 'Audio',
    muted: false,
    sections: []
  };
  const tracks: Track[] = [audioTrack, videoTrack, mediaTrack];
  return { duration: MIN_PROJECT_DURATION, tracks };
}

export const DEFAULT_PROJECT: IProject = {
  title: 'My Project',
  isSameVideoSizeProject: 'cut',
  size: {
    width: 1920,
    height: 1080
  },
  videoBitrate: 0,
  duration: MIN_PROJECT_DURATION,
  tracks: []
};

export const DEFAULT_FILL: IDefaultFillStyles = {
  solid: {
    fillType: FillType.solid,
    color: '#ffffff'
  },
  linearGradient: {
    fillType: FillType.gradient,
    gradientType: GradientType.linear,
    startX: 0.5,
    startY: 0,
    endX: 0.5,
    endY: 1,
    colorStops: [
      { position: 0, color: '#007bff' },
      { position: 1, color: '#002F61' }
    ]
  },
  radialGradient: {
    fillType: FillType.gradient,
    gradientType: GradientType.radial,
    startX: 0.5,
    startY: 0.5,
    endX: 0.5,
    endY: 0.5,
    startRadius: 0,
    endRadius: 1,
    colorStops: [
      { position: 0, color: '#007bff' },
      { position: 1, color: '#002F61' }
    ]
  }
};

export const DEFAULT_SHAPE = {
  x: 0,
  y: 0,
  width: 500,
  height: 140,
  rotation: 0,
  borderColor: '#007bff',
  borderSize: 15,
  opacity: 1
};

export const DEFAULT_TEXT = {
  ...DEFAULT_SHAPE,
  text: 'Your text here',
  displayText: 'Your text here',
  fontSize: 50,
  font: FontFamily.arial,
  borderSize: 0,
  fontColor: '#007bff',
  fontStyle: '',
  backgroundColor: '#0000002e'
};
