var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import React from 'react';
import { TimelineTrackSection } from './TimelineTrackSection';
import { Timecode } from '@eolementhe/core';
var TimelineTrack = function (_super) {
  __extends(TimelineTrack, _super);
  function TimelineTrack(props) {
    var _this = _super.call(this, props) || this;
    _this.state = {
      updateCursor: false
    };
    _this.handleMouseUp = _this.handleMouseUp.bind(_this);
    _this.handleMouseDown = _this.handleMouseDown.bind(_this);
    _this.handleMouseEnter = _this.handleMouseEnter.bind(_this);
    _this.handleMouseLeave = _this.handleMouseLeave.bind(_this);
    _this.handleMouseMove = _this.handleMouseMove.bind(_this);
    return _this;
  }
  TimelineTrack.prototype.setCurrentTime = function (x) {
    var currentTime = (x + this.props.scrollLeft) / this.props.stepWidth * this.props.stepDuration;
    var timecode = Timecode.fromTime(currentTime, this.props.framerate);
    this.props.onChangeCurrentTime(timecode);
  };
  TimelineTrack.prototype.handleMouseDown = function (event) {
    this.setCurrentTime(event.nativeEvent.offsetX);
    this.setState({
      updateCursor: true
    });
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  };
  TimelineTrack.prototype.handleMouseUp = function () {
    this.setState({
      updateCursor: false
    });
    window.removeEventListener('mouseup', this.handleMouseUp);
    if (!this.props.showCursorPreview) {
      window.removeEventListener('mousemove', this.handleMouseMove);
    }
  };
  TimelineTrack.prototype.handleMouseEnter = function (event) {
    this.props.onChangeCursorPreviewX(event.nativeEvent.offsetX);
    this.props.onChangeShowCursorPreview(true);
    window.addEventListener('mousemove', this.handleMouseMove);
  };
  TimelineTrack.prototype.handleMouseLeave = function (event) {
    if (this.props.showCursorPreview) {
      this.props.onChangeShowCursorPreview(false);
      window.removeEventListener('mousemove', this.handleMouseMove);
    }
  };
  TimelineTrack.prototype.handleMouseMove = function (event) {
    this.props.onChangeCursorPreviewX(event.offsetX);
    if (this.state.updateCursor) {
      this.setCurrentTime(event.offsetX);
    }
  };
  TimelineTrack.prototype.renderSection = function (section, sectionIndex, scale) {
    var _this = this;
    var handleChangeSectionStart = function handleChangeSectionStart(start, isDragging) {
      return _this.props.onChangeSectionStart(sectionIndex, start, isDragging);
    };
    var handleEndDragSection = function handleEndDragSection(startBeforeMove, destTrackIndex) {
      _this.props.onEndDragSection(sectionIndex, startBeforeMove, destTrackIndex);
    };
    var handleChangeSectionDuration = function handleChangeSectionDuration(duration) {
      _this.props.onChangeSectionDuration(sectionIndex, duration);
    };
    var handleChangeSectionStartMedia = function handleChangeSectionStartMedia(duration) {
      return _this.props.onChangeSectionStartMedia ? _this.props.onChangeSectionStartMedia(sectionIndex, duration) : undefined;
    };
    var handleChangeSectionEndMedia = function handleChangeSectionEndMedia(duration) {
      return _this.props.onChangeSectionEndMedia ? _this.props.onChangeSectionEndMedia(sectionIndex, duration) : undefined;
    };
    return React.createElement(TimelineTrackSection, {
      key: section.id,
      sectionIndex: sectionIndex,
      tracks: this.props.track,
      trackKind: this.props.track.kind,
      stepDuration: this.props.stepDuration,
      onChangeStart: handleChangeSectionStart,
      onChangeDuration: handleChangeSectionDuration,
      onChangeSectionStartMedia: handleChangeSectionStartMedia,
      onChangeSectionEndMedia: handleChangeSectionEndMedia,
      onEndDrag: handleEndDragSection,
      onChangeCurrentSection: this.props.onChangeCurrentSection,
      section: section,
      isCurrentSection: this.props.isCurrentTrack && this.props.currentSectionIndex === sectionIndex,
      scale: this.props.stepWidth,
      onDropTransition: this.props.onDropTransition,
      onPlaceholderClick: this.props.onPlaceholderClick,
      onTransitionClick: this.props.onTransitionClick,
      onClickEmptySrcSection: this.props.onClickEmptySrcSection
    });
  };
  TimelineTrack.prototype.renderSections = function (scale) {
    var _this = this;
    return this.props.track.sections.map(function (section, i) {
      return _this.renderSection(section, i, scale);
    });
  };
  TimelineTrack.prototype.renderMarkers = function (scale) {
    var markers = [];
    var steps = this.props.stepCount;
    for (var i = 0; i <= steps; i++) {
      var zIndexClass = i === 0 ? 'z-index-4' : '';
      markers.push(React.createElement("div", {
        key: i,
        className: "timeline-track-marker position-absolute h-100 " + zIndexClass,
        style: {
          left: i * scale
        }
      }));
    }
    return markers;
  };
  TimelineTrack.prototype.render = function () {
    var marginLeft = (this.props.stepDuration ? this.props.currentTime.toTime() / this.props.stepDuration : 0) * this.props.stepWidth - 1;
    var cursorStyle = {
      marginLeft: marginLeft
    };
    var previewCursorStyle = {
      marginLeft: this.props.cursorPreviewX - 1
    };
    return React.createElement("div", {
      className: "timeline-track position-relative h-100"
    }, this.renderMarkers(this.props.stepWidth), this.renderSections(this.props.stepWidth), this.props.showCursorPreview && React.createElement("div", {
      className: "timeline-track-cursor-preview position-absolute h-100",
      style: previewCursorStyle
    }), React.createElement("div", {
      className: "timeline-track-cursor position-absolute h-100",
      style: cursorStyle
    }), React.createElement("div", {
      className: "position-absolute w-100 h-100",
      onMouseDown: this.handleMouseDown,
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave
    }));
  };
  return TimelineTrack;
}(React.PureComponent);
export { TimelineTrack };