var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
  for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
  return r;
};
import uuidv4 from 'uuid/v4';
import ACTION_TYPES from './actionTypes';
import { Timecode } from '@eolementhe/core';
import Hls from 'hls.js';
var initialState = {
  framerate: 25,
  duration: undefined,
  isPlaying: false,
  isBuffering: false,
  canPlay: false,
  playbackRate: 1,
  tracks: [],
  displayOffset: false,
  offset: undefined,
  url: '',
  markers: [],
  sections: [],
  volume: 1,
  mediaStreams: []
};
function flushBuffer(hls) {
  var _a, _b;
  var isAudio = hls["streamController"].audioOnly;
  if (!isAudio) {
    hls['bufferController'].onBufferFlushing(Hls.Events.BUFFER_FLUSHED, {
      startOffset: 0,
      endOffset: (_a = hls.media) === null || _a === void 0 ? void 0 : _a.duration,
      type: 'audio'
    });
    hls['bufferController'].onBufferFlushing(Hls.Events.BUFFER_FLUSHED, {
      startOffset: 0,
      endOffset: (_b = hls.media) === null || _b === void 0 ? void 0 : _b.duration,
      type: 'video'
    });
  }
}
var reducer = function reducer(state, action) {
  var _a, _b, _c, _d, _e;
  if (state === void 0) {
    state = initialState;
  }
  var type = action.type,
    payload = action.payload;
  switch (type) {
    case ACTION_TYPES.INIT:
      {
        var newState = __assign({}, state);
        var framerate = ((_a = payload === null || payload === void 0 ? void 0 : payload.streamInfo) === null || _a === void 0 ? void 0 : _a.framerate) || 25;
        newState.url = (_b = payload === null || payload === void 0 ? void 0 : payload.streamInfo) === null || _b === void 0 ? void 0 : _b.url;
        newState.framerate = framerate;
        newState.duration = (_c = payload === null || payload === void 0 ? void 0 : payload.streamInfo) === null || _c === void 0 ? void 0 : _c.duration;
        newState.currentTime = Timecode.fromTime(((_d = payload === null || payload === void 0 ? void 0 : payload.streamInfo) === null || _d === void 0 ? void 0 : _d.currentTime) || 0, framerate);
        newState.markers = [];
        newState.sections = [];
        newState.tracks = __spreadArrays(payload.tracks);
        newState.canPlay = false;
        newState.isPlaying = false;
        newState.isBuffering = false;
        newState.playbackRate = 1;
        newState.mediaStreams = [];
        return newState;
      }
    case ACTION_TYPES.SET_IS_PLAYING:
      {
        if (payload.isPlaying && state.mediaStreams) {
          var _loop_1 = function _loop_1(mediaStream) {
            if (mediaStream['hls']) {
              flushBuffer(mediaStream['hls']);
              mediaStream['hls'].on(Hls.Events.ERROR, function (event, data) {
                var _a, _b, _c;
                if (data.fatal) {
                  switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                      console.log('fatal network error encountered, try to recover');
                      (_a = mediaStream['hls']) === null || _a === void 0 ? void 0 : _a.startLoad();
                      break;
                    case Hls.ErrorTypes.MEDIA_ERROR:
                      console.log('fatal media error encountered, try to recover');
                      (_b = mediaStream['hls']) === null || _b === void 0 ? void 0 : _b.recoverMediaError();
                      break;
                    default:
                      (_c = mediaStream['hls']) === null || _c === void 0 ? void 0 : _c.destroy();
                      break;
                  }
                }
                switch (data.details) {
                  case Hls.ErrorDetails.BUFFER_NUDGE_ON_STALL:
                  case Hls.ErrorDetails.FRAG_LOAD_TIMEOUT:
                  case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                  case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
                    if (mediaStream['hls']) {
                      console.log('fatal media error encountered, try to flush');
                      flushBuffer(mediaStream['hls']);
                    }
                    break;
                }
              });
            }
          };
          for (var _i = 0, _f = state.mediaStreams; _i < _f.length; _i++) {
            var mediaStream = _f[_i];
            _loop_1(mediaStream);
          }
        }
        return __assign(__assign({}, state), {
          isPlaying: payload.isPlaying
        });
      }
    case ACTION_TYPES.SET_CURRENT_TIME:
      {
        return __assign(__assign({}, state), {
          currentTime: payload.currentTime
        });
      }
    case ACTION_TYPES.SET_PLAYBACK_RATE:
      {
        return __assign(__assign({}, state), {
          playbackRate: payload.playbackRate
        });
      }
    case ACTION_TYPES.TOGGLE_OFFSET_DISPLAY:
      {
        return __assign(__assign({}, state), {
          displayOffset: !!state.displayOffset
        });
      }
    case ACTION_TYPES.SET_OFFSET:
      {
        return __assign(__assign({}, state), {
          offset: payload.offset,
          displayOffset: !!action.payload.offset
        });
      }
    case ACTION_TYPES.SET_VOLUME:
      {
        return __assign(__assign({}, state), {
          volume: payload.volume
        });
      }
    case ACTION_TYPES.SET_SNAPSHOT_CALLBACK:
      {
        return __assign(__assign({}, state), {
          snapshotCallback: payload.snapshotCallback
        });
      }
    case ACTION_TYPES.CHANGE_TRACK_OUTPUT:
      {
        return __assign(__assign({}, state), {
          changeTrackOutput: __assign({}, payload)
        });
      }
    case ACTION_TYPES.SET_SELECTED_TRACK:
      {
        return __assign(__assign({}, state), {
          selectedTrack: payload.selectedTrack
        });
      }
    case ACTION_TYPES.SET_TRACK_CAN_PLAY:
      {
        var newState = __assign({}, state);
        newState.tracks = __spreadArrays(newState.tracks);
        newState.tracks[payload.trackIndex] = __assign({}, newState.tracks[payload.trackIndex] || {});
        newState.tracks[payload.trackIndex].canPlay = payload.canPlay;
        var canPlay = false;
        if (newState.tracks && newState.tracks.length) {
          canPlay = newState.tracks.every(function (track) {
            return track === null || track === void 0 ? void 0 : track.canPlay;
          });
        }
        newState.canPlay = canPlay;
        return newState;
      }
    case ACTION_TYPES.SET_TRACK_CHANNELS:
      {
        var newState = __assign({}, state);
        newState.tracks = __spreadArrays(newState.tracks);
        newState.tracks[payload.trackIndex] = __assign({}, newState.tracks[payload.trackIndex] || {});
        newState.tracks[payload.trackIndex].channels = __spreadArrays(payload.channels);
        return newState;
      }
    case ACTION_TYPES.MUTE_TRACK:
      {
        var trackIndex = payload.trackIndex,
          isMuted_1 = payload.isMuted;
        var newState = __assign({}, state);
        newState.tracks = __spreadArrays(newState.tracks);
        if (trackIndex != null) {
          newState.tracks[trackIndex] = __assign({}, newState.tracks[trackIndex] || {});
          newState.tracks[trackIndex].isMuted = isMuted_1;
        } else {
          newState.tracks.forEach(function (track) {
            return track.isMuted = isMuted_1;
          });
        }
        return newState;
      }
    case ACTION_TYPES.SET_SECTION_START:
      {
        var newState = __assign({}, state);
        var sectionIndex = payload.sectionIndex,
          start = payload.start;
        newState.sections = __spreadArrays(newState.sections);
        newState.sections[sectionIndex] = __assign({}, newState.sections[sectionIndex]);
        newState.sections[sectionIndex].start = start;
        return newState;
      }
    case ACTION_TYPES.SET_SECTION_END:
      {
        var newState = __assign({}, state);
        newState.sections = __spreadArrays(newState.sections);
        newState.sections[payload.sectionIndex] = __assign({}, newState.sections[payload.sectionIndex]);
        newState.sections[payload.sectionIndex].duration = payload.end - newState.sections[payload.sectionIndex].start;
        return newState;
      }
    case ACTION_TYPES.SET_SECTION_DURATION:
      {
        var newState = __assign({}, state);
        newState.sections = __spreadArrays(newState.sections);
        newState.sections[payload.sectionIndex] = __assign({}, newState.sections[payload.sectionIndex]);
        newState.sections[payload.sectionIndex].duration = payload.duration;
        return newState;
      }
    case ACTION_TYPES.SET_SECTION_COLOR:
      {
        var newState = __assign({}, state);
        newState.sections = __spreadArrays(newState.sections);
        newState.sections[payload.sectionIndex] = __assign({}, newState.sections[payload.sectionIndex]);
        newState.sections[payload.sectionIndex].color = payload.color;
        return newState;
      }
    case ACTION_TYPES.SET_SECTION_NOT_MOVABLE:
      {
        var newState = __assign({}, state);
        newState.sections = __spreadArrays(newState.sections);
        newState.sections[payload.sectionIndex] = __assign({}, newState.sections[payload.sectionIndex]);
        newState.sections[payload.sectionIndex].notMovable = payload.notMovable;
        return newState;
      }
    case ACTION_TYPES.SET_SECTION_NOT_RESIZABLE:
      {
        var newState = __assign({}, state);
        newState.sections = __spreadArrays(newState.sections);
        newState.sections[payload.sectionIndex] = __assign({}, newState.sections[payload.sectionIndex]);
        newState.sections[payload.sectionIndex].notResizable = payload.notResizable;
        return newState;
      }
    case ACTION_TYPES.ADD_SECTION:
      {
        var newState = __assign({}, state);
        newState.sections = __spreadArrays(newState.sections);
        newState.sections.push(__assign(__assign({}, payload), {
          id: uuidv4()
        }));
        return newState;
      }
    case ACTION_TYPES.REMOVE_SECTION:
      {
        var newState = __assign({}, state);
        newState.sections = __spreadArrays(newState.sections);
        newState.sections.splice(payload.sectionIndex, 1);
        return newState;
      }
    case ACTION_TYPES.ADD_MARKER:
      {
        var newState = __assign({}, state);
        newState.markers = __spreadArrays(newState.markers);
        newState.markers.push(__assign(__assign({}, payload), {
          id: uuidv4()
        }));
        return newState;
      }
    case ACTION_TYPES.REMOVE_MARKER:
      {
        var newState = __assign({}, state);
        newState.markers = __spreadArrays(newState.markers);
        newState.markers.splice(payload.markerIndex, 1);
        return newState;
      }
    case ACTION_TYPES.REMOVE_MARKERS:
      {
        var newState = __assign({}, state);
        var _g = action.payload,
          tag_1 = _g.tag,
          title_1 = _g.title;
        if (!tag_1 && !title_1) {
          newState.markers = [];
        } else {
          newState.markers = __spreadArrays(newState.markers);
          newState.markers = newState.markers.filter(function (marker) {
            if (tag_1 && title_1) {
              return marker.tag !== tag_1 || marker.title !== title_1;
            } else if (tag_1) {
              return marker.tag !== tag_1;
            } else if (title_1) {
              return marker.title !== title_1;
            }
          });
        }
        return newState;
      }
    case ACTION_TYPES.SET_MARKER_COLOR:
      {
        var newState = __assign({}, state);
        newState.markers = __spreadArrays(newState.markers);
        newState.markers[payload.markerIndex] = __assign({}, newState.markers[payload.markerIndex]);
        newState.markers[payload.markerIndex].color = payload.color;
        return newState;
      }
    case ACTION_TYPES.SET_MARKER_TITLE:
      {
        var newState = __assign({}, state);
        newState.markers = __spreadArrays(newState.markers);
        newState.markers[payload.markerIndex] = __assign({}, newState.markers[payload.markerIndex]);
        newState.markers[payload.markerIndex].title = payload.title;
        return newState;
      }
    case ACTION_TYPES.SET_MARKER_TAG:
      {
        var newState = __assign({}, state);
        newState.markers = __spreadArrays(newState.markers);
        newState.markers[payload.markerIndex] = __assign({}, newState.markers[payload.markerIndex]);
        newState.markers[payload.markerIndex].tag = payload.tag;
        return newState;
      }
    case ACTION_TYPES.SET_MARKER_NOT_MOVABLE:
      {
        var newState = __assign({}, state);
        newState.markers = __spreadArrays(newState.markers);
        newState.markers[payload.markerIndex] = __assign({}, newState.markers[payload.markerIndex]);
        newState.markers[payload.markerIndex].notMovable = payload.notMovable;
        return newState;
      }
    case ACTION_TYPES.SET_MARKER_PRIORITY:
      {
        var newState = __assign({}, state);
        newState.markers = __spreadArrays(newState.markers);
        newState.markers[payload.markerIndex] = __assign({}, newState.markers[payload.markerIndex]);
        newState.markers[payload.markerIndex].priority = payload.priority;
        return newState;
      }
    case ACTION_TYPES.SET_MARKER_TIME:
      {
        var newState = __assign({}, state);
        newState.markers = __spreadArrays(newState.markers);
        newState.markers[payload.markerIndex] = __assign({}, newState.markers[payload.markerIndex]);
        newState.markers[payload.markerIndex].timeSec = payload.timeSec;
        return newState;
      }
    case ACTION_TYPES.SET_IS_BUFFERING:
      {
        return __assign(__assign({}, state), {
          isBuffering: payload.isBuffering
        });
      }
    case ACTION_TYPES.PUSH_MEDIA_STREAM:
      {
        var newState = __assign({}, state);
        if (newState.mediaStreams && !((_e = newState.mediaStreams) === null || _e === void 0 ? void 0 : _e.find(function (mediaStream) {
          var _a, _b;
          return ((_a = mediaStream['media']) === null || _a === void 0 ? void 0 : _a.id) === ((_b = payload.mediaStream.media) === null || _b === void 0 ? void 0 : _b.id);
        }))) {
          newState.mediaStreams.push(payload.mediaStream);
        }
        return newState;
      }
  }
  return state;
};
export default reducer;