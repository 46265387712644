import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { useRelayEnvironment, fetchQuery, GraphQLTaggedNode } from 'react-relay/hooks';
import { FileResource, Resource, BuildStatusEnum } from '@eolementhe/video-editor-model';
import { ProgressBar } from '@eolementhe/react-components';
import { ResourceGalleryModal } from './ResourceBrowser/ResourceGalleryModal';
import { GET_FILE_QUERY } from '../api/GraphQL';
import { GraphQLGetFileQuery, GraphQLGetFileQueryResponse } from '../__generated__/GraphQLGetFileQuery.graphql';
import { fileTypeFromMime } from './ResourceBrowser/FileType';
import { handleError } from '../utils/handleError';
import { createServerFileUrl } from '../utils/uploadFiles';
import { EoleEditStateEntry, MODALTYPE } from '../redux/types';
import { doSetBuildId } from '../redux/buildAction';
import { doShowBuildModal } from '../redux/actions';
import { getEolementheWebUrl } from '../utils/ServerUtils';
import './BuildStatus.scss';

const statusTitles: Record<BuildStatusEnum, string> = {
  none: '',
  end: 'Polishing everything...',
  video: 'Creating video file...',
  media: 'Generating the media(s)...',
  build: 'Building the resulting file ...',
  blankVideo: 'Filling with blank video',
  videoSection: 'Trimming video section',
  createVideo: 'Merging the video sections',
  audioSection: 'Trimming audio section',
  addAudioStream: 'Adding empty audio stream',
  buildTask: 'Merging everything together',
  addWaveform: 'Adding waveform...'
};

export const BuildStatus: React.FC = () => {
  const { isBuilding, builtVideoId, isBuildModalShown } = useSelector((state: EoleEditStateEntry) => ({
    isBuilding: state.ee.isBuilding,
    builtVideoId: state.ee.builtVideoId,
    isBuildModalShown: state.ee.isBuildModalShown
  }));
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(doSetBuildId());
  };
  return (
    <Modal show={(isBuildModalShown && isBuilding) || !!builtVideoId} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{builtVideoId ? 'Video built' : 'Building Video'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="build-status">
          <BuildStatusBody />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const BuildStatusBody: React.FC = () => {
  const builtVideoId = useSelector((state: EoleEditStateEntry) => state.ee.builtVideoId);
  if (builtVideoId) {
    return <BuildSuccess videoId={builtVideoId} />;
  }
  return <BuildingMessage />;
};

const BuildingMessage: React.FC = () => {
  const buildStatus = useSelector((state: EoleEditStateEntry) => state.ee.buildStatus);
  const dispatch = useDispatch();
  const onClickMinimize = () => {
    dispatch(doShowBuildModal(false, MODALTYPE.BUILD));
  };
  return (
    <>
      {buildStatus && buildStatus.stepProgress && buildStatus.taskProgress ? (
        <>
          <h5>{statusTitles[buildStatus.stepProgress.currentStep]}</h5>
          <div className="w-100">
            <ProgressBar
              textAbove={statusTitles[buildStatus.taskProgress.title]}
              percentProgress={Math.round(buildStatus.taskProgress.progress)}
            />
            <ProgressBar textAbove="Total progression" percentProgress={buildStatus.stepProgress.progress} />
          </div>
          <Modal.Footer>
            <Button className="mx-2" onClick={onClickMinimize}>
              Hide
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <h5>Build in progress...</h5>
        </>
      )}
    </>
  );
};

const BuildSuccess: React.FC<{ videoId: string }> = ({ videoId }) => {
  const [resource, setResource] = React.useState<Resource>();
  const [watch, setWatch] = React.useState(false);
  const environment = useRelayEnvironment();
  const fetchAndUpdateResources = useCallback(
    (id: string): void => {
      (async (): Promise<void> => {
        try {
          const result: GraphQLGetFileQueryResponse | undefined = await fetchQuery<GraphQLGetFileQuery>(
            environment,
            GET_FILE_QUERY as GraphQLTaggedNode,
            { id }
          ).toPromise();
          if (!result?.getFile) {
            return;
          }
          const { _id: fileId, mime, name, src, lowres, size, preview, duration } = result.getFile;
          const resourceKind = fileTypeFromMime(mime);
          if (!resourceKind) {
            throw new Error(`Unexpected mime type received: ${mime}`);
          }

          const res = {
            resourceKind,
            _id: fileId,
            name,
            size,
            src,
            lowres,
            preview,
            title: name,
            duration
          } as FileResource;
          setResource(res);
        } catch (err) {
          handleError(err, 'The preview is unavailable');
        }
      })();
    },
    [environment]
  );

  useEffect(() => {
    fetchAndUpdateResources(videoId);
  }, [videoId, fetchAndUpdateResources]);

  const closePreview = React.useCallback(() => {
    setWatch(false);
  }, [setWatch]);

  const onClickWatch = React.useCallback(() => {
    setWatch(true);
  }, [setWatch]);
  return (
    <>
      {resource && watch ? <ResourceGalleryModal resource={resource} onHide={closePreview} /> : <></>}
      <div className="d-flex align-items-center mb-4">
        <span className="h5 m-0 ml-2">Your video has been successfully built</span>
      </div>
      {resource && (
        <div className="build-status-action">
          <a
            className="mx-2 btn btn-primary btn-lg"
            role="button"
            aria-pressed="true"
            href={createServerFileUrl((resource as FileResource)?.src, true)}
            target="_blank"
            download
            rel="noopener noreferrer"
          >
            Download
          </a>
          <Button className="mx-2" onClick={onClickWatch}>
            Watch
          </Button>
          <a className="mx-2 btn btn-primary btn-lg" href={`${getEolementheWebUrl()}/cc?id=${resource.name}`}>
            <Button className="mx-2"> Subtitle in EoleCC</Button>
          </a>
        </div>
      )}
    </>
  );
};
