import { LabelledTransition } from '../types/LabelledTransition';
// TRANSITIONS
export const DEFAULT_TRANSITION_DURATION = 2; // in second;
export const DEFAULT_TRANSITION_TYPE = 'fade';
export const TRANSITIONS: LabelledTransition[] = [
  {
    effect: {
      type: DEFAULT_TRANSITION_TYPE,
      durationSec: DEFAULT_TRANSITION_DURATION
    },
    label: 'Fade'
  },
  {
    effect: {
      type: 'fadeblack',
      durationSec: DEFAULT_TRANSITION_DURATION
    },
    label: 'Fade to black'
  },
  {
    effect: {
      type: 'wipeleft',
      durationSec: DEFAULT_TRANSITION_DURATION
    },
    label: 'Wipe left'
  },
  {
    effect: {
      type: 'wiperight',
      durationSec: DEFAULT_TRANSITION_DURATION
    },
    label: 'Wipe right'
  },
  {
    effect: {
      type: 'wipeup',
      durationSec: DEFAULT_TRANSITION_DURATION
    },
    label: 'Wipe up'
  },
  {
    effect: {
      type: 'wipedown',
      durationSec: DEFAULT_TRANSITION_DURATION
    },
    label: 'Wipe down'
  }
];
