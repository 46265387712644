import Konva from 'konva';
import React from 'react';
import { Image as KonvaImage, Rect } from 'react-konva';
import { IKonvaNode, IImageResource } from '@eolementhe/video-editor-model';

import { IKonvaShape } from './IKonvaShape';

interface IProps
  extends IKonvaNode,
    Omit<IImageResource, 'resourceKind' | 'preview' | '_id' | 'name' | 'size' | 'lowres'>,
    IKonvaShape {
  imageRef: React.RefObject<Konva.Image> | undefined;
  onImageLoaded(): void;
}

interface IState {
  imageSource: ImageBitmapSource | undefined;
}

export class KonvaUrlImage extends React.PureComponent<IProps, IState> {
  private imageElement: HTMLImageElement = new Image();

  public constructor(props: IProps) {
    super(props);
    this.state = {
      imageSource: undefined
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  public componentDidMount() {
    // https://stackoverflow.com/questions/20424279/
    this.imageElement.setAttribute('crossOrigin', 'anonymous');
    this.imageElement.addEventListener('load', this.handleLoad);
    this.imageElement.addEventListener('error', this.handleError);
    this.loadImage();
  }

  public componentDidUpdate(oldProps: IProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }

  public componentWillUnmount() {
    this.imageElement.removeEventListener('load', this.handleLoad);
    this.imageElement.removeEventListener('error', this.handleError);
  }

  private loadImage() {
    this.imageElement.src = this.props.src;
  }

  private handleLoad() {
    this.setState({
      imageSource: this.imageElement
    });
    this.props.onImageLoaded();
  }

  private handleError() {
    this.setState({
      imageSource: undefined
    });
  }

  public render() {
    return (
      <React.Fragment>
        {(this.state.imageSource && (
          <KonvaImage
            ref={this.props.imageRef}
            image={this.state.imageSource as any}
            opacity={this.props.opacity}
            visible={this.props.visible}
            x={this.props.x}
            y={this.props.y}
            width={this.props.width}
            height={this.props.height}
            offsetX={this.props.offsetX}
            offsetY={this.props.offsetY}
            scaleX={this.props.scaleX}
            scaleY={this.props.scaleY}
            rotation={this.props.rotation}
            draggable
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            onClick={this.props.onClick}
            onDragStart={this.props.onDragStart}
            onDragMove={this.props.onDragMove}
            onDragEnd={this.props.onDragEnd}
            onTransformEnd={this.props.onTransformEnd}
          />
        )) || (
          <Rect
            color="#000000"
            visible={this.props.visible}
            x={this.props.x}
            y={this.props.y}
            width={this.props.width}
            height={this.props.height}
            offsetX={this.props.offsetX}
            offsetY={this.props.offsetY}
            scaleX={this.props.scaleX}
            scaleY={this.props.scaleY}
            rotation={this.props.rotation}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            onClick={this.props.onClick}
            onDragStart={this.props.onDragStart}
            onDragMove={this.props.onDragMove}
            onDragEnd={this.props.onDragEnd}
            onTransformStart={this.props.onTransformStart}
            onTransform={this.props.onTransform}
            onTransformEnd={this.props.onTransformEnd}
          />
        )}
      </React.Fragment>
    );
  }
}
