var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { faSearchMinus, faSearchPlus, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { ButtonGroup, FormControl, InputGroup } from 'react-bootstrap';
import { ButtonVariant, ButtonSize } from '../../../react-bootstrap-types';
import { ActionButton, ActionButtonTitleLayout } from '../../../actions';
var TimelineZoom = function (_super) {
  __extends(TimelineZoom, _super);
  function TimelineZoom(props) {
    var _this = _super.call(this, props) || this;
    _this.range = React.createRef();
    _this.handleChangeRange = _this.handleChangeRange.bind(_this);
    _this.handleClickMinus = _this.handleClickMinus.bind(_this);
    _this.handleClickPlus = _this.handleClickPlus.bind(_this);
    _this.handleClickReset = _this.handleClickReset.bind(_this);
    return _this;
  }
  TimelineZoom.prototype.handleChangeRange = function (event) {
    var value = Number(event.target.value);
    this.props.onChange(value);
  };
  TimelineZoom.prototype.handleClickReset = function () {
    this.props.onChange(1);
  };
  TimelineZoom.prototype.handleClickMinus = function () {
    if (this.range === null || this.range.current === null) {
      return;
    }
    var value = Math.max(this.props.value - 0.1, Number(this.range.current.min));
    this.props.onChange(Number(value.toFixed(2)));
  };
  TimelineZoom.prototype.handleClickPlus = function () {
    if (this.range === null || this.range.current === null) {
      return;
    }
    var value = Math.min(this.props.value + 0.1, Number(this.range.current.max));
    this.props.onChange(Number(value.toFixed(2)));
  };
  TimelineZoom.prototype.renderUnZoomButton = function () {
    var action = {
      title: 'Unzoom',
      description: "Decrement zoom.",
      icon: faSearchMinus,
      execute: this.handleClickMinus
    };
    return React.createElement(InputGroup.Prepend, null, React.createElement(ButtonGroup, null, React.createElement(ActionButton, __assign({}, action, {
      titleLayout: ActionButtonTitleLayout.noTitle,
      variant: ButtonVariant.outlinePrimary,
      size: ButtonSize.sm
    }))));
  };
  TimelineZoom.prototype.renderZoomButton = function () {
    var action = {
      title: 'Zoom',
      description: "Increment zoom.",
      icon: faSearchPlus,
      execute: this.handleClickPlus
    };
    return React.createElement(InputGroup.Append, null, React.createElement(ButtonGroup, null, React.createElement(ActionButton, __assign({}, action, {
      titleLayout: ActionButtonTitleLayout.noTitle,
      variant: ButtonVariant.outlinePrimary,
      size: ButtonSize.sm
    })), this.props.extraButtons));
  };
  TimelineZoom.prototype.renderResetButton = function () {
    var action = {
      title: 'Reset',
      description: 'Reset zoom.',
      icon: faExpandArrowsAlt,
      execute: this.handleClickReset
    };
    return React.createElement(InputGroup.Append, null, React.createElement(ButtonGroup, null, React.createElement(ActionButton, __assign({}, action, {
      titleLayout: ActionButtonTitleLayout.noTitle,
      variant: ButtonVariant.outlinePrimary,
      size: ButtonSize.sm
    })), this.props.extraButtons));
  };
  TimelineZoom.prototype.renderZoomSlider = function () {
    return React.createElement("div", {
      className: "zoom-slider  p-0 m-0 mx-1 mt-2"
    }, React.createElement(FormControl, {
      type: "range",
      size: "sm",
      min: this.props.min,
      max: this.props.max,
      step: 0.01,
      ref: this.range,
      value: this.props.value.toString(),
      onChange: this.handleChangeRange
    }));
  };
  TimelineZoom.prototype.render = function () {
    return React.createElement("div", {
      className: "timeline-zoom d-flex flex-row"
    }, React.createElement(InputGroup, null, this.props.extraButtons ? React.createElement(React.Fragment, null) : this.renderResetButton(), this.renderUnZoomButton(), this.renderZoomSlider(), this.renderZoomButton()));
  };
  return TimelineZoom;
}(React.PureComponent);
export { TimelineZoom };