var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import React from 'react';
import { TimeUtils } from './TimeUtils';
import { TimelineMarker } from './TimelineMarker';
import { Timecode } from '@eolementhe/core';
import TimelineRulerTicks from './TimelineRulerTicks';
var DURATION_LEVEL = [900000, 600000, 300000, 210000, 180000, 150000, 120000, 90000, 60000, 30000, 15000, 5000, 1000, 500, 100];
var MIN_STEP_WIDTH = 70;
var MAX_STEP_WIDTH = 100;
var MAX_MS_DISPLAY = 100;
export var CURSOR_SHIFT = -6;
var TimelineRuler = function (_super) {
  __extends(TimelineRuler, _super);
  function TimelineRuler(props) {
    var _this = _super.call(this, props) || this;
    _this.parentMoveZoneElement = null;
    _this.parentMoveZoneRect = undefined;
    _this.handleMarkerDrag = function (x, index) {
      if (_this.props.onChangeMarkerTimeSec) {
        var currentTime = x / _this.props.stepWidth * _this.props.stepDuration;
        _this.props.onChangeMarkerTimeSec(currentTime / 1000, index);
      }
    };
    _this.onMouseMove = function (event) {
      _this.props.onCursorPreviewXChanged(event.nativeEvent.offsetX);
    };
    _this.state = {
      isMouseDown: false
    };
    _this.onMouseDown = _this.onMouseDown.bind(_this);
    _this.onMouseEnter = _this.onMouseEnter.bind(_this);
    _this.onMouseLeave = _this.onMouseLeave.bind(_this);
    _this.onBodyMouseUp = _this.onBodyMouseUp.bind(_this);
    _this.onParentMouseMove = _this.onParentMouseMove.bind(_this);
    return _this;
  }
  TimelineRuler.prototype.onParentMouseMove = function (evt) {
    if (this.state.isMouseDown && this.parentMoveZoneRect) {
      this.setCurrentPosition(evt.clientX - this.parentMoveZoneRect.left);
    }
  };
  TimelineRuler.prototype.componentDidMount = function () {
    var _a;
    this.parentMoveZoneElement = document.getElementById('timeline-ruler-wrapper');
    this.parentMoveZoneRect = (_a = this.parentMoveZoneElement) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
    if (this.parentMoveZoneElement) {
      this.parentMoveZoneElement.addEventListener('mousemove', this.onParentMouseMove, false);
    }
  };
  TimelineRuler.prototype.componentWillUnmount = function () {
    if (this.parentMoveZoneElement) {
      this.parentMoveZoneElement.removeEventListener('mousemove', this.onParentMouseMove, false);
    }
  };
  TimelineRuler.prototype.componentDidUpdate = function (prevProps) {
    if (!this.props.stepWidth) {
      this.updateZoomLimits();
    }
    if (this.props.duration !== prevProps.duration || this.props.zoom !== prevProps.zoom || this.props.zoom === 1) {
      this.updateSteps();
    }
  };
  TimelineRuler.prototype.setCurrentPosition = function (x) {
    var currentTime = (x + this.props.scrollLeft) / this.props.stepWidth * this.props.stepDuration;
    this.props.onCurrentTimeChanged(Timecode.fromTime(currentTime, this.props.framerate));
  };
  TimelineRuler.prototype.onBodyMouseUp = function (event) {
    document.body.removeEventListener('mouseup', this.onBodyMouseUp);
    if (this.state.isMouseDown) {
      this.setState({
        isMouseDown: false
      });
      if (this.props.onPauseMediaOnSelection) {
        this.props.onPauseMediaOnSelection();
      }
      var rect = this.parentMoveZoneRect;
      if (rect === null || rect === void 0 ? void 0 : rect.left) {
        this.setCurrentPosition(event.clientX - rect.left);
      }
    }
  };
  TimelineRuler.prototype.onMouseDown = function (event) {
    if (this.props.onPauseMediaOnSelection) {
      this.props.onPauseMediaOnSelection();
    }
    this.setState({
      isMouseDown: true
    });
    document.body.addEventListener('mouseup', this.onBodyMouseUp);
  };
  TimelineRuler.prototype.onMouseEnter = function (event) {
    this.props.onCursorPreviewXChanged(event.nativeEvent.offsetX);
    this.props.onShowCursorPreviewChanged(true);
  };
  TimelineRuler.prototype.onMouseLeave = function (event) {
    this.props.onShowCursorPreviewChanged(false);
  };
  TimelineRuler.prototype.renderTicks = function (steps, spacing) {
    var ticks = [];
    var nbTicks = 10;
    for (var i = 0; i <= steps; i++) {
      var tickStyle = {
        marginLeft: i * spacing
      };
      var offset = TimeUtils.formatOffset(this.props.framerate, this.props.offset, this.props.displayOffset);
      var timecode = offset ? Timecode.fromTime(i * this.props.stepDuration, this.props.framerate).add(offset) : Timecode.fromTime(i * this.props.stepDuration, this.props.framerate);
      ticks.push(React.createElement("div", {
        key: ticks.length,
        className: "timeline-ruler-time position-absolute",
        style: tickStyle
      }, TimeUtils.formatTime(timecode, this.props.displayFrame)));
      ticks.push(React.createElement("div", {
        key: ticks.length,
        className: "timeline-ruler-tick position-absolute",
        style: tickStyle
      }));
      for (var j = 1; j < nbTicks; j++) {
        if (i + j / nbTicks > steps) {
          break;
        }
        ticks.push(React.createElement("div", {
          key: ticks.length,
          className: "timeline-ruler-tick-ms position-absolute",
          style: {
            marginLeft: i * spacing + j / nbTicks * spacing
          }
        }));
      }
    }
    return ticks;
  };
  TimelineRuler.prototype.renderMarkers = function (scale) {
    var _this = this;
    return React.createElement(React.Fragment, null, this.props.markers && this.props.markers.map(function (marker, index) {
      return React.createElement(TimelineMarker, {
        key: "marker-" + marker.id,
        index: index,
        stepWidth: _this.props.stepWidth,
        stepDuration: _this.props.stepDuration,
        marker: marker,
        onEndDrag: _this.handleMarkerDrag,
        onDrag: _this.handleMarkerDrag,
        scale: _this.props.stepWidth,
        onClick: _this.props.onClickMarker,
        onPauseMediaOnSelection: _this.props.onPauseMediaOnSelection
      });
    }));
  };
  TimelineRuler.prototype.getMinZoom = function (viewWidth) {
    var duration = this.props.duration;
    var maxMs = duration / 4;
    var scale = maxMs / MAX_STEP_WIDTH;
    var zoom = duration / viewWidth / scale;
    var rounded = Math.round(zoom * 10) / 10;
    return rounded > 0 ? rounded : 0.1;
  };
  TimelineRuler.prototype.getMaxZoom = function (viewWidth) {
    var duration = this.props.duration;
    var scale = MAX_STEP_WIDTH / MAX_MS_DISPLAY;
    var zoom = duration / viewWidth / scale;
    var rounded = Math.round(zoom * 10) / 10;
    return rounded > 10 ? 10 : rounded;
  };
  TimelineRuler.prototype.updateZoomLimits = function () {
    var viewWidth = this.props.containerWidth - 1;
    var minZoom = this.getMinZoom(viewWidth);
    var maxZoom = this.getMaxZoom(viewWidth);
    this.props.onChangeZoomLimit(minZoom, maxZoom);
  };
  TimelineRuler.prototype.getStepWidth = function (viewWidth, zoom) {
    var _a;
    var scale = this.props.duration / viewWidth / zoom;
    var minMs = MIN_STEP_WIDTH * scale;
    var maxMs = MAX_STEP_WIDTH * scale;
    var stepDuration = (_a = DURATION_LEVEL.find(function (duration) {
      return duration >= minMs && duration <= maxMs;
    })) !== null && _a !== void 0 ? _a : maxMs;
    var stepWidth = scale > 0 ? stepDuration / scale : 0;
    return [stepWidth, stepDuration];
  };
  TimelineRuler.prototype.updateSteps = function () {
    var viewWidth = this.props.containerWidth - 1;
    var _a = this.getStepWidth(viewWidth, this.props.zoom),
      stepWidth = _a[0],
      stepDuration = _a[1];
    var stepCount = stepDuration > 0 ? this.props.duration / stepDuration : 0;
    this.props.onStepChanges({
      stepWidth: stepWidth,
      stepDuration: stepDuration,
      stepCount: stepCount
    });
  };
  TimelineRuler.prototype.render = function () {
    var _a = this.props,
      stepWidth = _a.stepWidth,
      stepCount = _a.stepCount,
      stepDuration = _a.stepDuration,
      currentTime = _a.currentTime,
      cursorPreviewX = _a.cursorPreviewX,
      showCursorPreview = _a.showCursorPreview,
      framerate = _a.framerate,
      offset = _a.offset,
      displayOffset = _a.displayOffset,
      displayFrame = _a.displayFrame;
    var style = {
      width: stepCount * stepWidth + 1
    };
    var left = (stepDuration > 0 ? currentTime.toTime() / stepDuration : 0) * stepWidth + CURSOR_SHIFT;
    var cursorStyle = {
      left: left
    };
    var cursorPreviewStyle = {
      left: cursorPreviewX + CURSOR_SHIFT
    };
    return React.createElement("div", {
      className: "timeline-ruler position-relative timeline-ruler-min-height min-width-100",
      style: style
    }, this.renderTicks(stepCount, stepWidth), React.createElement(TimelineRulerTicks, {
      steps: stepCount,
      spacing: stepWidth,
      framerate: framerate,
      offset: offset,
      displayOffset: !!displayOffset,
      stepDuration: stepDuration,
      displayFrame: displayFrame
    }), this.renderMarkers(stepWidth), React.createElement("div", {
      className: "timeline-ruler-tick-ms position-absolute timeline-ruler-border-bottom w-100"
    }), showCursorPreview && React.createElement("div", {
      className: "timeline-ruler-cursor-preview position-absolute",
      style: cursorPreviewStyle
    }), React.createElement("div", {
      className: "timeline-ruler-cursor position-absolute",
      style: cursorStyle
    }), React.createElement("div", {
      className: "position-absolute w-100 h-100",
      style: {
        zIndex: this.state.isMouseDown ? 20 : 1
      },
      onMouseDown: this.onMouseDown,
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onMouseLeave,
      onMouseMove: this.onMouseMove
    }), React.createElement("div", {
      className: "timeline-track-section-grip-right position-absolute"
    }));
  };
  return TimelineRuler;
}(React.PureComponent);
export { TimelineRuler };