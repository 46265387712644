/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLCreateUserMutationVariables = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    company?: string | null;
    jobTitle?: string | null;
    phone?: string | null;
};
export type GraphQLCreateUserMutationResponse = {
    readonly createUser: {
        readonly _id: string;
    };
};
export type GraphQLCreateUserMutation = {
    readonly response: GraphQLCreateUserMutationResponse;
    readonly variables: GraphQLCreateUserMutationVariables;
};



/*
mutation GraphQLCreateUserMutation(
  $email: String!
  $password: String!
  $firstName: String!
  $lastName: String!
  $company: String
  $jobTitle: String
  $phone: String
) {
  createUser(email: $email, password: $password, firstName: $firstName, lastName: $lastName, company: $company, jobTitle: $jobTitle, phone: $phone) {
    _id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "company"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobTitle"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phone"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "company",
        "variableName": "company"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "jobTitle",
        "variableName": "jobTitle"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "phone",
        "variableName": "phone"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "createUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLCreateUserMutation",
    "selections": (v7/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "GraphQLCreateUserMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "5d12990ce5e8d7643f6c2fbd89c1447a",
    "id": null,
    "metadata": {},
    "name": "GraphQLCreateUserMutation",
    "operationKind": "mutation",
    "text": "mutation GraphQLCreateUserMutation(\n  $email: String!\n  $password: String!\n  $firstName: String!\n  $lastName: String!\n  $company: String\n  $jobTitle: String\n  $phone: String\n) {\n  createUser(email: $email, password: $password, firstName: $firstName, lastName: $lastName, company: $company, jobTitle: $jobTitle, phone: $phone) {\n    _id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a50546b3d4c0df134818f5934e015449';
export default node;
