import React from 'react';
import { TimeUtils } from './TimeUtils';
import { Timecode } from '@eolementhe/core';
var TimelineRulerTicks = function TimelineRulerTicks(_a) {
  var steps = _a.steps,
    spacing = _a.spacing,
    framerate = _a.framerate,
    offset = _a.offset,
    displayOffset = _a.displayOffset,
    stepDuration = _a.stepDuration,
    displayFrame = _a.displayFrame;
  var ticks = [];
  var nbTicks = 10;
  for (var i = 0; i <= steps; i++) {
    var tickStyle = {
      marginLeft: i * spacing
    };
    var offsetToAdd = TimeUtils.formatOffset(framerate, offset, displayOffset);
    var timecode = offsetToAdd ? Timecode.fromTime(i * stepDuration, framerate).add(offsetToAdd) : Timecode.fromTime(i * stepDuration, framerate);
    ticks.push(React.createElement("div", {
      key: ticks.length,
      className: "timeline-ruler-time position-absolute",
      style: tickStyle
    }, TimeUtils.formatTime(timecode, displayFrame)));
    ticks.push(React.createElement("div", {
      key: ticks.length,
      className: "timeline-ruler-tick position-absolute",
      style: tickStyle
    }));
    for (var j = 1; j < nbTicks; j++) {
      if (i + j / nbTicks > steps) {
        break;
      }
      ticks.push(React.createElement("div", {
        key: ticks.length,
        className: "timeline-ruler-tick-ms position-absolute",
        style: {
          marginLeft: i * spacing + j / nbTicks * spacing
        }
      }));
    }
  }
  return React.createElement(React.Fragment, null, ticks);
};
export default React.memo(TimelineRulerTicks);