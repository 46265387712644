var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { faArrowDown, faArrowUp, faClone, faCut, faPlus, faImage, faMusic, faSquareFull, faTrash, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { faArrowToRight, faArrowToLeft } from '@fortawesome/pro-solid-svg-icons';
import React, { useState, useEffect, useCallback } from 'react';
import { ButtonGroup, Form } from 'react-bootstrap';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { Timecode } from '@eolementhe/core';
import { ButtonVariant, ButtonSize } from '../../../react-bootstrap-types';
import { ActionButton, ActionButtonWithDropdown, ActionButtonTitleLayout } from '../../../actions';
import { TimelineZoom } from './TimelineZoom';
import { TimeUtils } from './TimeUtils';
import { UpdateDuration } from './UpdateDuration';
import { UpdatePlaybackRate } from './UpdatePlaybackRate';
import { TrackKind } from '../model/ITrack';
var TimelineControlBar = function (_super) {
  __extends(TimelineControlBar, _super);
  function TimelineControlBar(props) {
    var _this = _super.call(this, props) || this;
    _this.handleClickSplit = _this.handleClickSplit.bind(_this);
    return _this;
  }
  TimelineControlBar.prototype.handleClickSplit = function () {
    this.props.onSplitSection(this.props.currentTime);
  };
  TimelineControlBar.prototype.renderLeftToolbar = function () {
    return React.createElement("div", {
      className: "actions-box row align-items-center ml-0"
    }, React.createElement(ButtonToolbar, {
      className: "align-items-center"
    }, React.createElement(ButtonGroup, null, this.renderpreviousFrame(), React.createElement(ActionButton, {
      title: this.props.isPlaying ? 'Pause' : 'Play',
      description: this.props.isPlaying ? 'Pause player' : 'Start player.',
      icon: this.props.isPlaying ? faPause : faPlay,
      canExecute: this.props.canPlay,
      execute: this.props.isPlaying ? this.props.onPause : this.props.onPlay,
      titleLayout: ActionButtonTitleLayout.noTitle,
      variant: ButtonVariant.outlinePrimary,
      size: ButtonSize.sm
    }), React.createElement(ActionButton, {
      titleLayout: ActionButtonTitleLayout.noTitle,
      variant: ButtonVariant.outlinePrimary,
      size: ButtonSize.sm,
      title: 'Stop',
      description: 'Stop the player.',
      icon: faSquareFull,
      execute: this.props.onStop
    }), this.rendernextFrame()), this.props.onChangePlaybackRate && React.createElement(UpdatePlaybackRate, {
      playbackRate: this.props.playbackRate,
      onChangePlaybackRate: this.props.onChangePlaybackRate
    }), this.renderTrackActions(), this.renderSectionActions()));
  };
  TimelineControlBar.prototype.getSameKindTracks = function (kind) {
    return this.props.tracks.filter(function (track) {
      var _a;
      return (_a = track.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(kind);
    });
  };
  TimelineControlBar.prototype.canRemoveTrack = function () {
    if (this.props.currentTrackIndex === undefined) return false;
    var track = this.props.tracks[this.props.currentTrackIndex];
    if (!this.props.isVideoEditor) return false;
    var trackKind = track.kind;
    if (!track.name || !trackKind) return false;
    if (trackKind === 'video') return false;
    if (this.getSameKindTracks(trackKind).length <= 1) return false;
    return true;
  };
  TimelineControlBar.prototype.canMoveUp = function () {
    var _a = this.props,
      currentTrackIndex = _a.currentTrackIndex,
      tracks = _a.tracks,
      isVideoEditor = _a.isVideoEditor;
    if (currentTrackIndex == null || currentTrackIndex >= tracks.length - 1) {
      return false;
    }
    if (!isVideoEditor) {
      return true;
    }
    var track = tracks[currentTrackIndex];
    var trackKind = track.kind;
    if (!trackKind) {
      return false;
    }
    if (trackKind === 'video') {
      return false;
    }
    var sameKindTracks = this.getSameKindTracks(trackKind);
    if (sameKindTracks.length <= 1) {
      return false;
    }
    return true;
  };
  TimelineControlBar.prototype.canMoveDown = function () {
    var _a = this.props,
      currentTrackIndex = _a.currentTrackIndex,
      tracks = _a.tracks,
      isVideoEditor = _a.isVideoEditor;
    if (currentTrackIndex == null || currentTrackIndex < 0) {
      return false;
    }
    if (!isVideoEditor) {
      return true;
    }
    var track = tracks[currentTrackIndex];
    var trackKind = track.kind;
    if (!trackKind) {
      return false;
    }
    if (trackKind === 'video') {
      return false;
    }
    var sameKindTracks = this.getSameKindTracks(trackKind);
    if (sameKindTracks.length <= 1) {
      return false;
    }
    var firstVideoSectionIndex = tracks.findIndex(function (item) {
      return item.kind === 'video';
    });
    return currentTrackIndex - 1 > firstVideoSectionIndex;
  };
  TimelineControlBar.prototype.createTrackAction = function () {
    var _this = this;
    var actions = [];
    if (this.props.onAddTrack) {
      actions.push({
        title: 'Add Track',
        description: 'Add a new track to current scene.',
        icon: faPlus,
        canExecute: true,
        child: [{
          icon: faImage,
          title: 'Add a media track',
          execute: function execute() {
            return _this.props.onAddTrack && _this.props.onAddTrack(TrackKind.media);
          }
        }, {
          icon: faMusic,
          title: 'Add an audio track',
          execute: function execute() {
            return _this.props.onAddTrack && _this.props.onAddTrack(TrackKind.audio);
          }
        }]
      });
    }
    if (this.props.onRemoveTrack) {
      actions.push({
        title: 'Remove Track',
        description: 'Remove current track.',
        icon: faTrash,
        execute: this.props.onRemoveTrack,
        canExecute: this.canRemoveTrack()
      });
    }
    if (this.props.onMoveCurrentTrackUp) {
      actions.push({
        title: 'Move Up',
        description: 'Move current track up in the stack.',
        icon: faArrowUp,
        execute: this.props.onMoveCurrentTrackUp,
        canExecute: this.canMoveUp()
      });
    }
    if (this.props.onMoveCurrentTrackDown) {
      actions.push({
        title: 'Move Down',
        description: 'Move current track down in the stack.',
        icon: faArrowDown,
        execute: this.props.onMoveCurrentTrackDown,
        canExecute: this.canMoveDown()
      });
    }
    if (this.props.onDuplicateCurrentTrack && !this.props.isVideoEditor) {
      actions.push({
        title: 'Duplicate',
        description: 'Duplicate current track.',
        icon: faClone,
        execute: this.props.onDuplicateCurrentTrack,
        canExecute: this.props.currentTrackIndex !== undefined
      });
    }
    return actions;
  };
  TimelineControlBar.prototype.renderTrackActions = function () {
    var actions = this.createTrackAction();
    return React.createElement(React.Fragment, null, actions.length > 0 ? React.createElement(React.Fragment, null, React.createElement("div", {
      className: "ml-3 mr-1"
    }, "Track"), React.createElement(ButtonGroup, null, actions.map(function (action) {
      return React.createElement(ActionButtonWithDropdown, {
        key: action.title,
        action: action
      });
    }))) : React.createElement(React.Fragment, null));
  };
  TimelineControlBar.prototype.renderpreviousFrame = function () {
    if (!this.props.onPreviousFrame) return React.createElement(React.Fragment, null);
    return React.createElement(ActionButton, {
      title: "Previous Frame",
      description: "Move to previous frame",
      icon: faArrowToLeft,
      canExecute: !this.props.isPlaying && this.props.currentTime.toTime() > 1,
      execute: this.props.onPreviousFrame,
      titleLayout: ActionButtonTitleLayout.noTitle,
      variant: ButtonVariant.outlinePrimary,
      size: ButtonSize.sm
    });
  };
  TimelineControlBar.prototype.rendernextFrame = function () {
    if (!this.props.onNextFrame) return React.createElement(React.Fragment, null);
    return React.createElement(ActionButton, {
      title: "Next Frame",
      description: "Move to next frame",
      icon: faArrowToRight,
      canExecute: !this.props.isPlaying && this.props.currentTime.toTime() < this.props.duration,
      execute: this.props.onNextFrame,
      titleLayout: ActionButtonTitleLayout.noTitle,
      variant: ButtonVariant.outlinePrimary,
      size: ButtonSize.sm
    });
  };
  TimelineControlBar.prototype.renderSectionActions = function () {
    var _a;
    var _b = this.props,
      tracks = _b.tracks,
      currentTrackIndex = _b.currentTrackIndex,
      currentSectionIndex = _b.currentSectionIndex,
      currentTime = _b.currentTime;
    var canSplitSection = false;
    var canRemoveSection = false;
    if (currentTrackIndex != null && currentSectionIndex != null) {
      var section = (_a = tracks[currentTrackIndex]) === null || _a === void 0 ? void 0 : _a.sections[currentSectionIndex];
      if (section && currentTime) {
        var time = currentTime.toTime();
        canSplitSection = time > section.start && time < section.start + section.duration;
        canRemoveSection = true;
      }
    }
    var actions = [];
    if (this.props.displayAddSection) actions.push({
      title: 'Add Section',
      description: 'Add a new section in current track.',
      icon: faPlus
    });
    if (this.props.displayRemoveSection) actions.push({
      title: 'Remove section',
      description: 'Remove the selected section',
      icon: faTrash,
      execute: this.props.deleteSection,
      canExecute: canRemoveSection
    });
    if (this.props.displaySplitSection) actions.push({
      title: 'Split Section',
      description: 'Split current section.',
      icon: faCut,
      execute: this.handleClickSplit,
      canExecute: canSplitSection
    });
    return React.createElement(React.Fragment, null, actions.length > 0 ? React.createElement(React.Fragment, null, React.createElement("div", {
      className: "ml-3 mr-1"
    }, "Section"), React.createElement(ButtonGroup, null, actions.map(function (action) {
      return React.createElement(ActionButton, __assign({
        key: action.title
      }, action, {
        titleLayout: ActionButtonTitleLayout.noTitle,
        variant: ButtonVariant.outlinePrimary,
        size: ButtonSize.sm
      }));
    }))) : React.createElement(React.Fragment, null));
  };
  TimelineControlBar.prototype.render = function () {
    var _a = this.props,
      offset = _a.offset,
      displayOffset = _a.displayOffset,
      framerate = _a.framerate,
      currentTime = _a.currentTime,
      displayFrame = _a.displayFrame,
      duration = _a.duration,
      updateDuration = _a.updateDuration;
    return React.createElement("div", {
      className: "timeline-control-bar d-flex p-1 border-bottom justify-content-between align-items-center"
    }, this.renderLeftToolbar(), React.createElement(TimesBox, __assign({}, {
      offset: offset,
      displayOffset: displayOffset,
      framerate: framerate,
      currentTime: currentTime,
      displayFrame: displayFrame,
      duration: duration,
      updateDuration: updateDuration
    })), !this.props.hideZoom && React.createElement(React.Fragment, null, React.createElement(TimelineZoom, {
      value: this.props.zoom,
      onChange: this.props.onChangeZoom,
      min: this.props.zoomMin,
      max: this.props.zoomMax
    })));
  };
  return TimelineControlBar;
}(React.PureComponent);
export { TimelineControlBar };
var TimesBox = function TimesBox(_a) {
  var offset = _a.offset,
    displayOffset = _a.displayOffset,
    framerate = _a.framerate,
    currentTime = _a.currentTime,
    displayFrame = _a.displayFrame,
    duration = _a.duration,
    updateDuration = _a.updateDuration;
  var _b = useState(),
    formattedOffset = _b[0],
    setFormattedOffset = _b[1];
  var _c = useState(displayFrame),
    isInTC = _c[0],
    setIsInTC = _c[1];
  useEffect(function () {
    var newFormattedOffset = TimeUtils.formatOffset(framerate, offset, true);
    newFormattedOffset = (newFormattedOffset === null || newFormattedOffset === void 0 ? void 0 : newFormattedOffset.toTime()) ? newFormattedOffset : undefined;
    setFormattedOffset(newFormattedOffset);
  }, [offset, displayOffset, framerate]);
  var handleMsChange = useCallback(function (e) {
    return setIsInTC(!e.target.checked);
  }, []);
  var durationTC = Timecode.fromTime(duration, framerate);
  return React.createElement("div", {
    className: "times-box"
  }, React.createElement("div", {
    className: "tb-switch"
  }, React.createElement("span", {
    className: "tb-switch-ts"
  }, "TC"), React.createElement(Form, null, React.createElement(Form.Check, {
    type: "switch",
    id: "custom-switch",
    color: ButtonVariant.outlinePrimary,
    label: "",
    checked: !isInTC,
    onChange: handleMsChange
  })), React.createElement("span", {
    className: "tb-switch-ms"
  }, "MS")), React.createElement("div", {
    className: "tb-times"
  }, TimeUtils.formatTime(formattedOffset ? currentTime.add(formattedOffset) : currentTime, isInTC), ' / ', TimeUtils.formatTime(formattedOffset ? durationTC.add(formattedOffset) : durationTC, isInTC)), updateDuration && React.createElement(UpdateDuration, {
    duration: duration,
    updateDuration: updateDuration
  }));
};