import { commitMutation } from 'react-relay';
import { TRIM_MEDIA_MUTATION } from '../api/GraphQL';
import { RELAY_ENVIRONMENT } from '../api/relay-environment';
import { GraphQLTrimMediaMutation } from '../__generated__/GraphQLTrimMediaMutation.graphql';

export const trimMedia = async (srcId: string, owner: string, startTime: number, endTime: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    commitMutation<GraphQLTrimMediaMutation>(RELAY_ENVIRONMENT, {
      mutation: TRIM_MEDIA_MUTATION,
      variables: { srcId, owner, startTime, endTime },
      onCompleted: (res) => {
        resolve(res.trimMedia);
      },
      onError: reject
    });
  });
};
