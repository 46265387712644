import './VideoTrack.scss';
import { IVideoSection, IVideoTrack, IVideoTransitionSection } from '@eolementhe/video-editor-model';
import React, { useRef } from 'react';
import { isNumber } from 'lodash';
import { createServerFileUrl } from '../../../utils/uploadFiles';

interface IVideoTrackProps {
  videoSections: IVideoSection[];
  transitionSections: IVideoTransitionSection[];
  track: IVideoTrack;
  currentTime: number;
  width: number | string;
  height: number | string;
  isPlaying: boolean;
}

interface IVideoLoaderProps {
  section: IVideoSection;
  transitionSections: IVideoTransitionSection[];
  currentSection: boolean;
  height: number | string;
  width: number | string;
  isPlaying: boolean;
  visible: boolean;
  currentTime: number;
  muted: boolean;
  index: number;
}

interface VideoTransitionProps {
  section: IVideoTransitionSection;
}
interface IState {
  isPlaying: boolean;
}
function usePlayPauseEffect(ref: React.RefObject<HTMLVideoElement>, isPlaying: boolean, visible: boolean) {
  return React.useEffect(() => {
    if (ref && ref.current) {
      if (isPlaying && visible) {
        ref.current.play().then(
          () => {},
          (error) => console.error(error)
        );
      } else if ((!isPlaying && !ref.current.paused) || (!visible && !ref.current.paused)) {
        ref.current.pause();
      }
    }
  }, [ref, isPlaying, visible]);
}

function useSynchronizeEffect(
  ref: React.RefObject<HTMLVideoElement>,
  visible: boolean,
  currentTime: number,
  sectionStartMedia: number,
  sectionStart: number
) {
  return React.useEffect(() => {
    if (ref && ref.current) {
      const currentTimeEffective = currentTime + (sectionStartMedia - sectionStart);
      if (visible) {
        const timeDiff = Math.abs(ref.current.currentTime - ((currentTimeEffective / 1000) % ref.current.duration));
        if (timeDiff > 1) {
          ref.current.currentTime = currentTimeEffective / 1000;
        }
      }
    }
  }, [ref, visible, currentTime, sectionStartMedia, sectionStart]);
}

function useChangeVolumeEffect(ref: React.RefObject<HTMLVideoElement>, volume: number, mute: boolean) {
  return React.useEffect(() => {
    // volume could be missing if we can move a section of another type (not the case today)
    if (ref?.current && isNumber(volume)) {
      ref.current.volume = mute ? 0 : volume;
    }
  }, [ref, volume, mute]);
}

function useSetStartMedia(ref: React.RefObject<HTMLVideoElement>, section: IVideoSection, visible: boolean) {
  React.useEffect(() => {
    // section.startMedia could be missing if we can move a section of another type (not the case today)
    if (ref?.current && isNumber(section?.startMedia) && !visible) {
      ref.current.currentTime = section.startMedia / 1000;
    }
  }, [section, ref, visible]);
}

function getVideoTransition(
  section: IVideoSection,
  transition: IVideoTransitionSection,
  type: string,
  effectType: string,
  duration?: number
) {
  if (effectType !== 'fadeblack') {
    if (
      (type === 'before' && section.start + section.duration === transition.start) ||
      (type === 'after' && section.start === transition.start)
    ) {
      return section;
    }
  }
  if (effectType === 'fadeblack') {
    if (
      (duration && type === 'before' && section.start + section.duration - duration === transition.start) ||
      (duration && type === 'after' && section.start - duration === transition.start)
    ) {
      return section;
    }
  }
}

function getTransitionEffect(
  type: string,
  duration: number,
  videoTag: React.RefObject<HTMLVideoElement>,
  option?: string
) {
  if (videoTag.current) {
    if (option === 'out') {
      videoTag.current.style.animationDuration = `${duration}s`;
      videoTag.current.classList.add(`anim-${type}-out`);
      setTimeout(() => {
        if (videoTag.current) {
          videoTag.current.classList.remove(`anim-${type}-out`);
          videoTag.current.style.removeProperty('animation-duration');
          videoTag.current.style.opacity = '1';
        }
      }, duration * 1000);
    }
    if (option === 'in') {
      videoTag.current.style.animationDuration = `${duration}s`;
      videoTag.current.classList.add(`anim-${type}-in`);
      setTimeout(() => {
        if (videoTag.current) {
          videoTag.current.classList.remove(`anim-${type}-in`);
          videoTag.current.style.opacity = '1';
          videoTag.current.style.removeProperty('animation-duration');
        }
      }, duration * 1000);
    }
  }
}

function useTransitionEffect(
  ref: React.RefObject<HTMLVideoElement>,
  transitionSection: IVideoTransitionSection[],
  section: IVideoSection,
  currentTime: number
) {
  let videoTag = useRef<HTMLVideoElement>(null);
  let videoTag2 = useRef<HTMLVideoElement>(null);
  const time = currentTime / 1000;
  transitionSection.forEach((transition) => {
    const type = transition.effect.type;
    const duration = transition.effect.durationSec;
    const FtbDuration = (duration / 2) * 1000;
    const transitionStart = transition.start / 1000;
    const videoBeforeTransition = getVideoTransition(section, transition, 'before', type, FtbDuration);
    const videoAfterTransition = getVideoTransition(section, transition, 'after', type, FtbDuration);
    if (ref.current?.id === videoBeforeTransition?.id) {
      videoTag = ref;
    }
    if (ref.current?.id === videoAfterTransition?.id) {
      videoTag2 = ref;
    }
    if (type !== 'fadeblack') {
      if (videoTag.current && videoBeforeTransition && time > transitionStart && time < transitionStart + 1) {
        getTransitionEffect(type, duration, videoTag, 'out');
      }
      if (videoTag2.current && videoAfterTransition && time > transitionStart && time < transitionStart + 1) {
        getTransitionEffect(type, duration, videoTag2, 'in');
      }
    }
    if (type === 'fadeblack') {
      if (videoBeforeTransition) {
        const transitionStart = (videoBeforeTransition.start + videoBeforeTransition.duration - FtbDuration) / 1000;
        if (videoTag.current && time > transitionStart && time < transitionStart + 1) {
          getTransitionEffect(type, duration / 2, videoTag, 'out');
        }
      }
      if (videoAfterTransition) {
        if (
          videoTag2.current &&
          time > videoAfterTransition.start / 1000 &&
          time < videoAfterTransition.start / 1000 + 1
        ) {
          getTransitionEffect(type, duration / 2, videoTag2, 'in');
        }
      }
    }
  });
}

const VideoSection: React.FC<IVideoLoaderProps> = ({
  section,
  transitionSections,
  currentSection,
  height,
  width,
  isPlaying,
  visible,
  currentTime,
  muted
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  usePlayPauseEffect(videoRef, isPlaying, currentSection);
  useTransitionEffect(videoRef, transitionSections, section, currentTime);
  useSetStartMedia(videoRef, section, visible);
  useSynchronizeEffect(videoRef, currentSection, currentTime, section.startMedia, section.start);
  useChangeVolumeEffect(videoRef, section.volume, muted);
  return (
    <video
      ref={videoRef}
      id={section.id}
      src={section.lowres ? createServerFileUrl(section.lowres) : ''}
      height={height}
      width={width}
      style={{
        position: 'absolute',
        visibility: visible && currentSection ? 'visible' : 'hidden'
      }}
    />
  );
};

export const VideoTrack: React.FC<IVideoTrackProps> = ({
  videoSections,
  transitionSections,
  track,
  isPlaying,
  height,
  width,
  currentTime
}) => {
  const [currentSection, setCurrentSection] = React.useState<IVideoSection[]>([]);
  React.useEffect(() => {
    setCurrentSection(
      videoSections.filter(
        (videoSection) => currentTime >= videoSection.start && currentTime < videoSection.start + videoSection.duration
      )
    );
  }, [currentTime, videoSections]);
  const currentId = currentSection.map((section) => section.id);
  return (
    <>
      {videoSections.map((section: IVideoSection, index) => {
        if (section.srcId) {
          return (
            <VideoSection
              key={section.id}
              section={section}
              currentSection={currentId.includes(section.id) ?? true}
              height={height}
              width={width}
              isPlaying={isPlaying}
              currentTime={currentTime}
              muted={track.muted}
              visible={track.visible}
              index={index}
              transitionSections={transitionSections}
            />
          );
        }
        return <span key={section.id} />;
      })}
    </>
  );
};
