import { fetchQuery } from 'relay-runtime';
import { Environment } from 'react-relay/hooks';
import { IProject } from '@eolementhe/video-editor-model';
import {
  GraphQLGetProjectsQuery,
  GraphQLGetProjectsQueryResponse
} from '../__generated__/GraphQLGetProjectsQuery.graphql';
import { GET_PROJECTS_QUERY } from './GraphQL';

export default async function (environment: Environment): Promise<IProject[]> {
  const result: GraphQLGetProjectsQueryResponse | undefined = await fetchQuery<GraphQLGetProjectsQuery>(
    // @ts-ignore
    environment,
    GET_PROJECTS_QUERY,
    {}
  ).toPromise();
  if (result?.getProjects) {
    // @ts-ignore
    return [...result.getProjects];
  }
  return [];
}
