import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faClipboard } from '@fortawesome/pro-regular-svg-icons';
import { EoleEditStateEntry } from '../redux/types';
import { doShowError } from '../redux/actions';

export const ErrorModal: React.FC = () => {
  const dispatch = useDispatch();
  const error = useSelector((state: EoleEditStateEntry) => state.ee.error);
  const [copied, setIsCopied] = React.useState(false);
  const handleOnHide = React.useCallback(() => {
    dispatch(doShowError());
    setIsCopied(false);
  }, [dispatch, setIsCopied]);

  useEffect(() => {
    if (error) {
      console.error('ErrorModal.tsx', 'error', error);
    }
  }, [error]);
  return (
    <Modal show={!!error} centered onHide={handleOnHide}>
      <Modal.Header closeButton>
        <Modal.Title>{error ? error.readableInfo : 'Something went wrong'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        {error ? (
          <ButtonGroup className="d-flex">
            <Button
              variant="secondary"
              onClick={() => {
                navigator.clipboard.writeText(error.message);
                setIsCopied(true);
              }}
            >
              {copied ? 'Copied' : 'Copy error to clipboard'}
            </Button>
            <Button disabled variant="secondary">
              <FontAwesomeIcon icon={copied ? faClipboardCheck : faClipboard} />
            </Button>
          </ButtonGroup>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};
