import React from "react";
export var ProgressBar = function ProgressBar(_a) {
  var percentProgress = _a.percentProgress,
    textBelow = _a.textBelow,
    textAbove = _a.textAbove;
  return React.createElement("div", {
    className: "rc-progress "
  }, textAbove && React.createElement("div", {
    className: "rc-progress-text"
  }, textAbove), React.createElement("div", {
    className: "progress"
  }, React.createElement("div", {
    className: "rc-progress-percent"
  }, percentProgress, "%"), React.createElement("div", {
    className: "progress-bar bg-info progress-bar-striped",
    style: {
      width: percentProgress + "%"
    },
    role: "progressbar"
  })), textBelow && React.createElement("div", {
    className: "rc-progress-text"
  }, textBelow));
};