import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { faRabbitFast } from '@fortawesome/pro-solid-svg-icons';
var playbackRateSteps = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
export var UpdatePlaybackRate = function UpdatePlaybackRate(_a) {
  var onChangePlaybackRate = _a.onChangePlaybackRate,
    playbackRate = _a.playbackRate;
  var currentPlaybackDuration = playbackRate !== null && playbackRate !== void 0 ? playbackRate : 1;
  var handlePlaybackRate = function handlePlaybackRate(newPlaybackRate) {
    return function () {
      return onChangePlaybackRate(newPlaybackRate);
    };
  };
  return React.createElement(SplitButton, {
    id: "update-playback-rate-dropdown-" + Math.random(),
    className: "update-playback-rate-dropdown ml-2",
    variant: "outline-primary",
    size: "sm",
    title: React.createElement("div", null, React.createElement(FontAwesomeIcon, {
      icon: faRabbitFast,
      size: "sm"
    }), React.createElement("span", null, " x ", currentPlaybackDuration))
  }, playbackRateSteps.map(function (playbackRateStep, i) {
    return React.createElement(Dropdown.Item, {
      className: "update-playback-rate-item",
      key: "update-playback-rate-" + playbackRateStep,
      onClick: handlePlaybackRate(playbackRateStep)
    }, playbackRateStep);
  }));
};