import './HomePage.scss';
import { faFile, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import ImageBackground from '../ImageBackground';
import { HomeLogo } from './HomeLogo';
import { doShowProjectModal, doShowLoadProjectModal } from '../../redux/actions';
import { MODALTYPE } from '../../redux/types';

const infoMessage = `
**Eole Edit** is a simple but powerful software which lets you **create and edit videos** right in your web browser.

It supports various track types such as **Video**, **Audio**, **Image** and **Text**.

`;

const HomePage: React.FC = () => {
  const dispatch = useDispatch();

  const onShowNewProject = useCallback(() => dispatch(doShowProjectModal(true, MODALTYPE.CREATION)), [dispatch]);
  const onShowLoadProject = useCallback(() => dispatch(doShowLoadProjectModal(true)), [dispatch]);
  return (
    <>
      <ImageBackground />
      <div className="container m-auto p-5 d-flex flex-column justify-content-center align-items-center" id="Homepage">
        <HomeLogo />
        <div className="btn-container">
          <button onClick={onShowNewProject}>
            <FontAwesomeIcon icon={faFile} />
            Create a new project
          </button>
          <button onClick={onShowLoadProject}>
            <FontAwesomeIcon icon={faFolderOpen} />
            Load project
          </button>
        </div>
      </div>
      <footer>
        <ReactMarkdown source={infoMessage} />
      </footer>
    </>
  );
};

export { HomePage };
