import * as React from 'react';
import { TextTooltip } from '../../../core/TextTooltip';
import { fixOverlayTrigger } from '../../../react-bootstrap-types';
import { CURSOR_SHIFT } from './TimelineRuler';
export var TimelineMarker = function TimelineMarker(_a) {
  var index = _a.index,
    marker = _a.marker,
    stepDuration = _a.stepDuration,
    scale = _a.scale,
    onEndDrag = _a.onEndDrag,
    onDrag = _a.onDrag,
    onClick = _a.onClick,
    onPauseMediaOnSelection = _a.onPauseMediaOnSelection;
  var _b = React.useState(0),
    leftPosition = _b[0],
    setLeftPosition = _b[1];
  var leftPositionRef = React.useRef(0);
  var _c = React.useState(false),
    isMouseDown = _c[0],
    setIsMouseDown = _c[1];
  var _d = React.useState(false),
    isMouseMoved = _d[0],
    setIsMouseMoved = _d[1];
  var parentMoveZoneRef = React.useRef(null);
  var parentMoveZoneRect = React.useRef(null);
  leftPositionRef.current = leftPosition;
  React.useEffect(function () {
    var left = (stepDuration > 0 ? marker.timeSec * 1000 / stepDuration : 0) * scale;
    setLeftPosition(left);
  }, [stepDuration, scale, marker.timeSec]);
  React.useEffect(function () {
    parentMoveZoneRef.current = document.getElementById('timeline-ruler-wrapper');
    if (!parentMoveZoneRect.current && parentMoveZoneRef.current) {
      parentMoveZoneRect.current = parentMoveZoneRef.current.getBoundingClientRect();
    }
  }, []);
  React.useEffect(function () {
    var mouseUpEvent = function mouseUpEvent() {
      if (isMouseDown) {
        if (isMouseMoved && onEndDrag) {
          onEndDrag(leftPositionRef.current, index);
        } else if (onClick) {
          onClick(marker.timeSec);
          if (onPauseMediaOnSelection) {
            onPauseMediaOnSelection();
          }
        }
      }
      setIsMouseDown(false);
      setIsMouseMoved(false);
    };
    if (isMouseDown) {
      document.body.addEventListener('mouseup', mouseUpEvent, false);
      if (onPauseMediaOnSelection) {
        onPauseMediaOnSelection();
      }
    }
    return function () {
      document.body.removeEventListener('mouseup', mouseUpEvent);
    };
  }, [isMouseDown, isMouseMoved]);
  React.useEffect(function () {
    var mouseMoveEvent = function mouseMoveEvent(e) {
      var rect = parentMoveZoneRect.current;
      if (rect === null || rect === void 0 ? void 0 : rect.left) {
        var newLeftPosition = e.clientX - rect.left;
        if (Math.abs(newLeftPosition - leftPositionRef.current) > 2) {
          setIsMouseMoved(true);
          setLeftPosition(newLeftPosition);
          if (onDrag) {
            onDrag(newLeftPosition, index);
          }
        }
      }
    };
    if (!marker.notMovable && isMouseDown) {
      if (parentMoveZoneRef === null || parentMoveZoneRef === void 0 ? void 0 : parentMoveZoneRef.current) {
        parentMoveZoneRef.current.addEventListener('mousemove', mouseMoveEvent, false);
      }
    }
    return function () {
      if (parentMoveZoneRef.current) {
        parentMoveZoneRef.current.removeEventListener('mousemove', mouseMoveEvent);
      }
    };
  }, [isMouseDown, marker, marker.notMovable]);
  var handleMouseDown = React.useCallback(function () {
    setIsMouseDown(true);
  }, []);
  var tooltipTextFragments = [];
  if (marker.title) {
    tooltipTextFragments.push(marker.title);
  }
  if (marker.tag) {
    tooltipTextFragments.push("[" + marker.tag + "]");
  }
  var tooltipText = tooltipTextFragments.join(' ') || String(marker.timeSec);
  var markerZIndexDefault = 10;
  return React.createElement(TextTooltip, {
    placement: "top",
    trigger: fixOverlayTrigger('hover'),
    text: tooltipText
  }, React.createElement("div", {
    className: "timeline-marker position-absolute",
    style: {
      left: leftPosition + CURSOR_SHIFT,
      cursor: 'pointer',
      zIndex: marker.priority ? markerZIndexDefault + marker.priority : markerZIndexDefault
    },
    onMouseDown: handleMouseDown
  }, React.createElement("div", {
    className: "marker-up",
    style: {
      backgroundColor: marker.color
    }
  }), React.createElement("div", {
    className: "marker-down",
    style: {
      borderTopColor: marker.color
    }
  })));
};