"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResourceType = void 0;
/**
 * Possible resource types. Used in conjunction with {@link IResource}.
 *
 * @public
 */
var ResourceType;
(function (ResourceType) {
  ResourceType["audio"] = "audio";
  ResourceType["video"] = "video";
  ResourceType["videoTransition"] = "videoTransition";
  ResourceType["image"] = "image";
  ResourceType["shape"] = "shape";
  ResourceType["subtitle"] = "subtitle";
  ResourceType["text"] = "text";
  ResourceType["waveform"] = "waveform";
  ResourceType["directory"] = "directory";
})(ResourceType = exports.ResourceType || (exports.ResourceType = {}));