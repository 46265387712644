import React, { FC } from 'react';
import ImageBackground from '../ImageBackground';
import { getEolementheWebUrl } from '../../utils/ServerUtils';

import './LoginPage.scss';
import { HomeLogo } from '../HomePage/HomeLogo';

export const LoginPage: FC = () => {
  return (
    <div className="login-page">
      <ImageBackground />
      <div className="login-page-content d-flex flex-column justify-content-center align-items-center pt-5">
        <HomeLogo />
        <div>You are not logged in</div>
        <div>
          Please go to&nbsp;
          <a href={getEolementheWebUrl()} target="_blank" rel="noopener noreferrer">
            Eolementhe portal
          </a>
          &nbsp;and log in
        </div>
      </div>
    </div>
  );
};
