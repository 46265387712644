"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SectionKind = exports.TrackKind = void 0;
/** @public */
var TrackKind;
(function (TrackKind) {
  TrackKind["media"] = "media";
  TrackKind["video"] = "video";
  TrackKind["audio"] = "audio";
})(TrackKind = exports.TrackKind || (exports.TrackKind = {}));
/** @public */
var SectionKind;
(function (SectionKind) {
  SectionKind["video"] = "video";
  SectionKind["image"] = "image";
  SectionKind["text"] = "text";
  SectionKind["audio"] = "audio";
  SectionKind["videoTransition"] = "videoTransition";
  SectionKind["waveform"] = "waveForm";
})(SectionKind = exports.SectionKind || (exports.SectionKind = {}));