import uuidv4 from 'uuid/v4';
import { commitMutation, GraphQLTaggedNode } from 'react-relay';
import { Environment, fetchQuery } from 'react-relay/hooks';
import { BuildProgress } from '@eolementhe/video-editor-model';
import { Dispatch } from '@reduxjs/toolkit';
import { RELAY_ENVIRONMENT } from '../api/relay-environment';
import { BUILD_VIDEO_MUTATION, GET_BUILD_VIDEO_PROGRESS } from '../api/GraphQL';
import { GraphQLBuildVideoMutation } from '../__generated__/GraphQLBuildVideoMutation.graphql';
import { EoleEditAction } from './types';
import ACTION_TYPES from './actionTypes';

import {
  GraphQLGetBuildVideoQuery,
  GraphQLGetBuildVideoQueryResponse
} from '../__generated__/GraphQLGetBuildVideoQuery.graphql';
import { doSetLastUpdateUserProfile, doShowError } from './actions';

async function getBuildStatus(buildId: string, environment: Environment): Promise<BuildProgress | undefined> {
  const result: GraphQLGetBuildVideoQueryResponse | undefined = await fetchQuery<GraphQLGetBuildVideoQuery>(
    environment,
    GET_BUILD_VIDEO_PROGRESS as GraphQLTaggedNode,
    { buildId }
  ).toPromise();

  if (result) {
    return result.getBuildProgress as BuildProgress;
  }
}

export function doBuildingProject(isBuilding: boolean): EoleEditAction {
  return { type: ACTION_TYPES.BUILDING_PROJECT, payload: { isBuilding } };
}

export function doSetBuildStatus(buildTitle: string, buildStatus?: BuildProgress): EoleEditAction {
  return { type: ACTION_TYPES.BUILDING_STATUS, payload: { buildTitle, buildStatus } };
}

export function doSetBuildId(builtVideoId?: string): EoleEditAction {
  return { type: ACTION_TYPES.SET_BUILD_ID, payload: { builtVideoId } };
}

export function doBuildProject(
  projectId: string,
  title: string,
  isSameVideoSizeProject: string,
  videoBitrate: number,
  framerate: number,
  environment: Environment
) {
  return (dispatch: Dispatch) => {
    const buildId = uuidv4();
    let intervalGetBuildStatus: number = 0;
    intervalGetBuildStatus = window.setInterval(async () => {
      try {
        const status = await getBuildStatus(buildId, environment);
        dispatch(doSetBuildStatus(title, status));
      } catch (error) {
        console.log('buildVideo.ts -> intervalGetBuildStatus ', error);
      }
    }, 1000);

    // @ts-ignore
    commitMutation<GraphQLBuildVideoMutation>(RELAY_ENVIRONMENT, {
      mutation: BUILD_VIDEO_MUTATION,
      variables: { buildId, projectId, framerate, title, isSameVideoSizeProject, videoBitrate },
      onCompleted: (res) => {
        clearInterval(intervalGetBuildStatus);
        dispatch(doSetBuildStatus(title, undefined));
        dispatch(doSetBuildId(res.buildVideo._id));
        dispatch(doBuildingProject(false));
        dispatch(doSetLastUpdateUserProfile());
      },
      onError: (error) => {
        clearInterval(intervalGetBuildStatus);
        dispatch(doSetBuildStatus(title, undefined));
        dispatch(doShowError(error, 'Unable to build'));

        dispatch(doBuildingProject(false));
        dispatch(doSetLastUpdateUserProfile());
      }
    });
    dispatch(doBuildingProject(true));
  };
}
