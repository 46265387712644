import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { IProject } from '@eolementhe/video-editor-model';
import { doCopyProject } from '../redux/actions';
import { EoleEditStateEntry } from '../redux/types';
import './ActionSaveAsDropdown.scss';

const buildInitialProjectName = (projectName: string): string => {
  return `${projectName} - ${moment().format('YYMMDD-HHmmss')}`;
};
interface ActionSaveAsDropdownProps {
  project: any;
  onClose: () => void;
  saveAs?: (project: IProject, newProjectName: string) => void;
}

interface ActionSaveAsDropdownState {
  newProjectName: string;
}
class ActionSaveAsDropdown extends React.Component<ActionSaveAsDropdownProps, ActionSaveAsDropdownState> {
  public constructor(props: ActionSaveAsDropdownProps) {
    super(props);
    this.state = {
      newProjectName: ''
    };
    this.handleOkClick = this.handleOkClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleProjectNameChange = this.handleProjectNameChange.bind(this);
  }

  public componentDidMount() {
    this.setState({
      newProjectName: buildInitialProjectName(this.props?.project?.title || '')
    });
  }

  private handleOkClick(): void {
    if (this.props.project && this.props.saveAs) {
      this.props.saveAs(this.props.project, this.state.newProjectName);
      this.props.onClose();
    }
  }

  private handleCancelClick(): void {
    this.props.onClose();
  }

  private handleProjectNameChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      newProjectName: e.target.value
    });
  }

  public render(): React.ReactNode {
    return (
      <div className="save-as-dropdown">
        <span className="sa-text">Save as:</span>
        <input
          value={this.state.newProjectName}
          onChange={this.handleProjectNameChange}
          className="sa-input"
          type="text"
        />
        <button className="sa-button" onClick={this.handleOkClick}>
          Ok
        </button>
        <button className="sa-button" onClick={this.handleCancelClick}>
          Cancel
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state: EoleEditStateEntry) => {
  return {
    project: state.ee.project
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    saveAs: (project: IProject, newValue: string) => dispatch(doCopyProject(newValue, project))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionSaveAsDropdown);
