import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';

export const CommentPanel: React.FC = () => (
  <div className="d-flex flex-column h-100">
    <div className="flex-fill">
      <Card className="shadow-sm">
        <Card.Header className="h6 ml-0 mr-0 p-1" as={Row}>
          <Col className="text-muted p-0">
            <small>00:22:01.0 - 00:23:03.2</small>
          </Col>
          <Col className="pr-1" sm="auto">
            <button type="button" className="close">
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </button>
          </Col>
        </Card.Header>
        <Card.Body className="p-1">
          <Card.Text>Hello world!</Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted p-1">
          <small>2 days ago by @bbenoist</small>
        </Card.Footer>
      </Card>
    </div>
    <div className="d-flex flex-row">
      <InputGroup>
        <Form.Control />
        <InputGroup.Append>
          <Button>
            <FontAwesomeIcon icon={faPaperPlane} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  </div>
);
