import * as React from 'react';
import { useContext, useDispatch } from './context';
import { mergeClassName } from '../../utils';
import { CheckableInput } from '../CheckableInput';
export var DropdownMenu = function DropdownMenu(props) {
  var _a = useContext(),
    dropdownOpen = _a.dropdownOpen,
    search = _a.search;
  return React.createElement("div", {
    className: mergeClassName('core-checkable-select-panel', dropdownOpen ? 'd-block' : 'd-none')
  }, React.createElement(RenderSearch, {
    onPressEnter: props.onPressEnter
  }), React.createElement("ul", {
    className: "core-checkable-select-list"
  }, props.options.filter(function (option) {
    return option.text.toLowerCase().includes(search.toLowerCase());
  }).map(function (option) {
    return React.createElement(RenderCheckbox, {
      key: option.value,
      value: option.value,
      itemLabel: option.text,
      Actions: props.Actions,
      addSelected: props.addSelected,
      removeSelected: props.removeSelected
    });
  })));
};
var RenderSearch = function RenderSearch(_a) {
  var onPressEnter = _a.onPressEnter;
  var search = useContext().search;
  var dispatch = useDispatch();
  var onChangeSearch = React.useCallback(function (event) {
    dispatch({
      kind: 'set',
      prop: 'search',
      value: event.target.value
    });
  }, [dispatch]);
  var handleKeyPress = React.useCallback(function (event) {
    if (event.key === "Enter" && onPressEnter) {
      onPressEnter(search);
    }
  }, [search, onPressEnter]);
  return React.createElement("input", {
    type: "text",
    className: "core-checkable-select-search",
    placeholder: "Search...",
    value: search,
    onChange: onChangeSearch,
    onKeyPress: handleKeyPress
  });
};
var RenderCheckbox = function RenderCheckbox(_a) {
  var value = _a.value,
    itemLabel = _a.itemLabel,
    Actions = _a.Actions,
    addSelected = _a.addSelected,
    removeSelected = _a.removeSelected;
  var selected = useContext().selected;
  var onChange = React.useCallback(function (checked) {
    if (checked) {
      addSelected(value);
    } else {
      removeSelected(value);
    }
  }, [addSelected, removeSelected, value]);
  return React.createElement(CheckableInput, {
    checked: selected.indexOf(value) >= 0,
    onChange: onChange,
    type: "checkbox",
    key: value,
    className: "core-checkable-select-list-item d-flex align-items-center"
  }, "\xA0", itemLabel, Actions && React.createElement(Actions, {
    value: value
  }));
};