import { ISize } from '@eolementhe/video-editor-model';
import { DEFAULT_PROJECT } from '../components/EditorPage/Constants';

export interface IVideoSize extends ISize {
  name: string;
  width: number;
  height: number;
}

export const VIDEO_SIZES: IVideoSize[] = [
  { width: 1920, height: 1080 }, //must be the first, see getSizeSelectIndex fallback value
  { width: 1280, height: 720 },
  { width: 720, height: 576 },
  { width: 1080, height: 1080 },
  { width: 720, height: 720 },
  { width: 0, height: 0, name: 'Custom' } //must be the last, used for custom size
]
  .map((size) => {
    const name = size.name || `${size.width}x${size.height}`;
    return {
      name,
      width: size.width,
      height: size.height
    } as IVideoSize;
  })
  .flat();

function sizeEquals(sizeA: ISize, sizeB: ISize) {
  return sizeA.width === sizeB.width && sizeA.height === sizeB.height;
}

function getSizeSelectIndex(size: ISize) {
  const found = VIDEO_SIZES.findIndex((s) => sizeEquals(size, s));
  return found > -1 ? found : 0;
}

export const DEFAULT_SIZE_INDEX = getSizeSelectIndex({
  width: DEFAULT_PROJECT.size.width,
  height: DEFAULT_PROJECT.size.height
});
