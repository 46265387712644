import React, { FC, useState, useCallback, useEffect } from 'react';
import { CssFontFamily, FontFamily, ITextSection } from '@eolementhe/video-editor-model';
import { faBold, faItalic, faSave, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useRelayEnvironment } from 'react-relay';
import { fetchQuery } from 'relay-runtime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import Select, { StylesConfig, OnChangeValue } from 'react-select';
import { saveTextPreset, TextPreset } from '../../../utils/saveTextPreset';
import {
  GraphQLGetTextPresetsQuery,
  GraphQLGetTextPresetsQueryResponse
} from '../../../__generated__/GraphQLGetTextPresetsQuery.graphql';
import { GET_TEXT_PRESETS_QUERY } from '../../../api/GraphQL';
import { handleError } from '../../../utils/handleError';
import { doSetTextSection } from '../../../redux/actions';
import { IFontName } from '../../../../../model/dist';

interface IProps {
  currentSectionIndex?: number;
  currentTrackIndex?: number;
  section: ITextSection;
  onChangeUpdateText(
    content: string | number | FontFamily,
    action:
      | 'text'
      | 'fontColor'
      | 'fontStyle'
      | 'borderColor'
      | 'backgroundColor'
      | 'borderSize'
      | 'font'
      | 'fontSize'
      | 'rotation',
    trackIndex: number,
    sectionIndex: number
  ): void;
}

type FontStyle = 'bold' | 'italic';
interface ISelectOption {
  value: string;
  label: string;
}

const MAX_FONT_SIZE = 500;
const MIN_FONT_SIZE = 1;
const MIN_OPACITY = 0;
const MAX_OPACITY = 100;

const FONT_STYLE_ICON = '#ffffff83';
const FONT_STYLE_ICON_ACTIVE = '#ffffff';
const FONT_OPTIONS: ISelectOption[] = Object.entries<IFontName>(CssFontFamily).map(([key, value]) => ({
  label: value.displayName,
  value: key
}));

const MIN_BORDER = 0;
const MAX_BORDER = 6;

const MIN_ROTATION = 0;
const MAX_ROTATION = 360;

const customReactSelectStyles: StylesConfig<ISelectOption> = {
  container: (styles) => ({
    ...styles,
    width: '100%',
    borderRadius: '4px',
    display: 'block',
    boxSizing: 'border-box'
  }),
  control: (styles) => ({
    ...styles,
    minHeight: '44px',
    background: 'transparent',
    border: '2px solid #ffffff66',
    color: '#ffffffcc'
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: '#383838'
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: '14px',
    color: '#fff',
    backgroundColor: isSelected ? '#88888888' : '#383838',
    ':hover': {
      ...styles[':hover'],
      backgroundColor: '#888888'
    }
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#ffffffcc'
  })
};

const getBgOpacity = (backgroundColor: string) => {
  let opacity = 0;
  if (backgroundColor) {
    const hex = backgroundColor.split('#')[1];

    if (hex.length >= 7) {
      opacity = Math.round((parseInt(hex.slice(6), 16) / 255) * 100);
    }
  }

  return opacity;
};

const getBackgroundColor = (backgroundColor: string) => {
  let bgCol = '#000000';
  if (backgroundColor) {
    bgCol = backgroundColor.slice(0, 7);
  }
  return bgCol;
};

const addOpacityToHex = (bgColor?: string, bgOpacity?: string | number): string => {
  if (!bgColor) {
    return '#000000';
  }
  if (!bgOpacity) {
    return `${bgColor}`;
  }
  let opacity: number | string = Math.round((+bgOpacity / 100) * 255);
  if (opacity < 9) {
    opacity = `0${opacity}`;
  }

  return bgColor + opacity.toString(16).toUpperCase();
};

export const EditSectionTextKind: FC<IProps> = ({
  currentSectionIndex,
  currentTrackIndex,
  section,
  onChangeUpdateText
}) => {
  const [fontSize, setFontSize] = useState<string | number>(section.fontSize);
  const [bgColor, setBgColor] = useState<string>(getBackgroundColor(section.backgroundColor));
  const [bgOpacity, setBgOpacity] = useState<string | number>(getBgOpacity(section.backgroundColor));

  const [isPresetOpen, setIsPresetOpen] = useState(false);
  const [presetName, setPresetName] = useState('');
  const [presets, setPresets] = useState<TextPreset[]>([]);
  const [selectedPreset, setSelectedPreset] = useState<TextPreset>();

  const dispatch = useDispatch();
  const environment = useRelayEnvironment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeUpdateTextDebounce = useCallback(debounce(onChangeUpdateText, 100), []);

  useEffect(() => {
    setBgColor(getBackgroundColor(section.backgroundColor));
    setBgOpacity(getBgOpacity(section.backgroundColor));
    setFontSize(section.fontSize);
  }, [section.backgroundColor, section.fontSize]);

  const fetchTextPresets = useCallback(
    (_id?: string): void => {
      (async (): Promise<void> => {
        try {
          const result: GraphQLGetTextPresetsQueryResponse | undefined = await fetchQuery<GraphQLGetTextPresetsQuery>(
            // @ts-ignore
            environment,
            GET_TEXT_PRESETS_QUERY,
            {}
          ).toPromise();
          if (!result) {
            setPresets([]);
            return;
          }
          const newPresets = result.getTextPresets.map((preset) => ({ ...preset }));
          setPresets(newPresets);
          if (_id) {
            setSelectedPreset(newPresets.find((pre) => pre._id === _id));
          }
        } catch (err) {
          handleError(err as Error, 'Cannot get projects');
        }
      })();
    },
    [environment]
  );

  useEffect(() => {
    fetchTextPresets();
  }, [fetchTextPresets]);

  const getFontStyleColor = (type: FontStyle) => {
    const fontStyle = section.fontStyle ? section.fontStyle.split(' ') : [];
    let color = FONT_STYLE_ICON;
    if (fontStyle.indexOf(type) !== -1) {
      color = FONT_STYLE_ICON_ACTIVE;
    }
    return color;
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (currentSectionIndex == null) return;
    if (currentTrackIndex == null) return;
    onChangeUpdateText(e.target.value, 'text', currentTrackIndex, currentSectionIndex);
  };

  const handleColorChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSectionIndex == null) return;
    if (currentTrackIndex == null) return;
    onChangeUpdateTextDebounce(e.target.value, 'fontColor', currentTrackIndex, currentSectionIndex);
  };

  const handleBackgroundColorChange = async () => {
    if (currentSectionIndex == null) return;
    if (currentTrackIndex == null) return;
    onChangeUpdateTextDebounce(
      addOpacityToHex(bgColor, bgOpacity),
      'backgroundColor',
      currentTrackIndex,
      currentSectionIndex
    );
  };

  const handleFontChange = useCallback(
    (selected: OnChangeValue<ISelectOption, false>) => {
      if (currentSectionIndex == null) return;
      if (currentTrackIndex == null) return;
      onChangeUpdateText(selected?.value || '', 'font', currentTrackIndex, currentSectionIndex);
    },
    [currentSectionIndex, currentTrackIndex, onChangeUpdateText]
  );

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSectionIndex == null) return;
    if (currentTrackIndex == null) return;

    let value: string | number = e.target.value;
    const intValue = parseInt(value, 10);

    if (intValue) {
      setFontSize(value);
      value = +value;
      if (value > MAX_FONT_SIZE) value = MAX_FONT_SIZE;
      else if (value < MIN_FONT_SIZE) value = MIN_FONT_SIZE;
      onChangeUpdateText(value, 'fontSize', currentTrackIndex, currentSectionIndex);
    } else {
      setFontSize('');
    }
  };

  const handleFontStyleChange = (type: FontStyle) => {
    if (currentSectionIndex == null) return;
    if (currentTrackIndex == null) return;

    let fontStyleArr: string[] = section.fontStyle ? section.fontStyle.split(' ') : [];

    if (fontStyleArr.indexOf(type) === -1) {
      fontStyleArr.push(type);
    } else {
      fontStyleArr = fontStyleArr.filter((s: string) => s !== type);
    }

    onChangeUpdateText(fontStyleArr.join(' '), 'fontStyle', currentTrackIndex, currentSectionIndex);
  };

  const handleBorderColorChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSectionIndex == null) return;
    if (currentTrackIndex == null) return;
    onChangeUpdateTextDebounce(e.target.value, 'borderColor', currentTrackIndex, currentSectionIndex);
  };

  const handleBorderSizeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSectionIndex == null) return;
    if (currentTrackIndex == null) return;
    onChangeUpdateText(+e.target.value, 'borderSize', currentTrackIndex, currentSectionIndex);
  };

  const handleTextRotationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSectionIndex == null) return;
    if (currentTrackIndex == null) return;
    const inputData = parseInt(e.target.value, 10);
    onChangeUpdateText(inputData, 'rotation', currentTrackIndex, currentSectionIndex);
  };

  const handleSelectPreset = useCallback(
    (selected: OnChangeValue<ISelectOption, false>) => {
      if (currentSectionIndex == null) return;
      if (currentTrackIndex == null) return;
      const newPreset = presets.find((preset) => preset._id === selected?.value);
      if (newPreset) {
        setSelectedPreset(newPreset);
        dispatch(
          doSetTextSection(currentTrackIndex, currentSectionIndex, {
            backgroundColor: newPreset.backgroundColor,
            borderColor: newPreset.borderColor,
            borderSize: newPreset.borderSize,
            font: newPreset.font,
            fontColor: newPreset.fontColor,
            fontSize: newPreset.fontSize,
            fontStyle: newPreset.fontStyle,
            rotation: newPreset.rotation,
            text: newPreset.text
          })
        );
      }
    },
    [currentSectionIndex, currentTrackIndex, dispatch, presets]
  );

  const savePreset = async () => {
    try {
      const res = await saveTextPreset({
        name: presetName,
        text: section.text,
        font: section.font,
        fontColor: section.fontColor,
        fontSize: section.fontSize,
        fontStyle: section.fontStyle,
        backgroundColor: section.backgroundColor,
        borderColor: section.borderColor,
        borderSize: section.borderSize,
        rotation: section.rotation
      });
      fetchTextPresets(res.id);
    } catch (err) {
      console.log(err);
    } finally {
      setPresetName('');
      setIsPresetOpen(false);
    }
  };
  const textPresetOptions: ISelectOption[] = presets.map((preset) => ({
    label: preset.name || 'unknown',
    value: preset._id || 'unknown'
  }));
  const selectedTextPreset = textPresetOptions.find((option) => option.value === selectedPreset?._id);
  const selectedFont = FONT_OPTIONS.find((option) => option.value === section.font);

  return (
    <>
      <h3 className="es-title">Select Preset</h3>
      <div className="es-wrapper">
        <Select<ISelectOption>
          className="basic-single"
          value={selectedTextPreset}
          options={textPresetOptions}
          onChange={handleSelectPreset}
          isSearchable
          styles={customReactSelectStyles}
        />
      </div>

      <h3 className="es-title">Text</h3>
      <div className="es-wrapper">
        <textarea
          className="es-input"
          rows={3}
          placeholder={'Your text here'}
          value={section.text}
          onChange={handleTextChange}
        />
        <div className="text-extras">
          <input className="es-color" type="color" value={section.fontColor} onChange={handleColorChange} />

          <input className="es-input" type="text" value={fontSize} onChange={handleFontSizeChange} />
        </div>
      </div>

      <h3 className="es-title">Text Style</h3>
      <div className="es-wrapper">
        <Select<ISelectOption>
          className="basic-single"
          value={selectedFont}
          options={FONT_OPTIONS}
          onChange={handleFontChange}
          isSearchable
          styles={customReactSelectStyles}
        />
        <span className="es-style-icons">
          <span onClick={() => handleFontStyleChange('bold')} className="ess-icon">
            <FontAwesomeIcon icon={faBold} color={getFontStyleColor('bold')} />
          </span>
          <span onClick={() => handleFontStyleChange('italic')} className="ess-icon">
            <FontAwesomeIcon icon={faItalic} color={getFontStyleColor('italic')} />
          </span>
        </span>
      </div>

      <div className="d-flex justify-content-between">
        <div className="es-column">
          <div className="opacity-wrapper w-100">
            <div className="d-flex flex-column">
              <h3 className="es-title">Background</h3>
              <input
                className="es-color w-100 p-0"
                style={{ border: 0 }}
                type="color"
                value={bgColor}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setBgColor(e.target.value);
                  handleBackgroundColorChange();
                }}
              />
              <div className="d-flex flex-column w-100">
                <label className="mb-0">Opacity</label>
                <div className="d-flex flex-row">
                  <input
                    className="es-input es-opacity-input"
                    type="number"
                    min={MIN_OPACITY}
                    max={MAX_OPACITY}
                    value={bgOpacity}
                    onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                      setBgOpacity(e.target.value);
                      handleBackgroundColorChange();
                    }}
                  />
                  <input
                    className="opacity-range"
                    type="range"
                    min={MIN_OPACITY}
                    max={MAX_OPACITY}
                    value={bgOpacity}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setBgOpacity(e.target.value);
                      handleBackgroundColorChange();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="es-column">
          <div className="opacity-wrapper w-100">
            <div className="d-flex flex-column">
              <h3 className="es-title">Text Stroke</h3>
              <input
                className="es-color w-100 p-0"
                style={{ border: 0 }}
                type="color"
                value={section.borderColor}
                onChange={handleBorderColorChange}
              />
              <div className="d-flex flex-column w-100">
                <label className="mb-0">Size</label>
                <div className="d-flex flex-row">
                  <input
                    className="es-input es-opacity-input"
                    type="number"
                    min={MIN_BORDER}
                    max={MAX_BORDER}
                    value={section.borderSize}
                    onChange={handleBorderSizeChange}
                  />
                  <input
                    className="opacity-range"
                    type="range"
                    min={MIN_BORDER}
                    max={MAX_BORDER}
                    value={section.borderSize}
                    onChange={handleBorderSizeChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h3 className="es-title">Rotation</h3>
      <div className="rotation-wrapper es-wrapper">
        <input
          className="es-input"
          type="number"
          value={section.rotation}
          onChange={handleTextRotationChange}
          min={MIN_ROTATION}
          max={MAX_ROTATION}
        />
        <input
          className="opacity-range"
          type="range"
          value={section.rotation}
          onChange={handleTextRotationChange}
          min={MIN_ROTATION}
          max={MAX_ROTATION}
        />
      </div>

      <h3 className="es-title">Text Preset</h3>
      <div className="es-wrapper">
        {!isPresetOpen ? (
          <button className="es-preset-btn" onClick={() => setIsPresetOpen(true)}>
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            Save Preset
          </button>
        ) : (
          <>
            <input
              className="es-input mr-2"
              placeholder="Save as"
              value={presetName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPresetName(e.target.value)}
            />
            <button className="es-preset-btn mr-2" onClick={savePreset}>
              <FontAwesomeIcon icon={faCheck} color="#77B300" />
            </button>
            <button className="es-preset-btn" onClick={() => setIsPresetOpen(false)}>
              <FontAwesomeIcon icon={faTimes} color="#CC0000" />
            </button>
          </>
        )}
      </div>
    </>
  );
};
