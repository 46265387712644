import { ordered as emojiNames, lib as emojis } from "emojilib";
export function getEmoji(name) {
  var key = name.startsWith(":") && name.endsWith(":") ? name.substr(1, name.length - 2) : name;
  var found = emojis[key];
  return found ? found.char : name;
}
export function getEmojiNames(text) {
  var start = text.startsWith(":") ? 1 : 0;
  var commaEnd = text.length > 1 && text.endsWith(":") ? text.length - 1 : text.length;
  var key = text.substr(start, commaEnd - start).toLowerCase();
  return emojiNames.filter(function (name) {
    return name.includes(key);
  }).sort(function (current, target) {
    if (current === key) return -1;
    if (current.startsWith(key)) {
      return target.startsWith(key) && current > target ? 1 : -1;
    } else if (target.startsWith(key)) {
      return 1;
    }
    return current > target ? 1 : -1;
  });
}