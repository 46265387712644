/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLGetProjectsQueryVariables = {};
export type GraphQLGetProjectsQueryResponse = {
    readonly getProjects: ReadonlyArray<{
        readonly _id: string;
        readonly owner: string;
        readonly duration: number;
        readonly title: string;
        readonly isSameVideoSizeProject: string;
        readonly videoBitrate: number;
        readonly tracks: ReadonlyArray<{
            readonly id: string;
            readonly kind: string;
            readonly name: string;
            readonly muted: boolean | null;
            readonly visible: boolean | null;
            readonly width: number | null;
            readonly height: number | null;
            readonly scale: number | null;
            readonly sections: ReadonlyArray<{
                readonly id: string;
                readonly start: number;
                readonly duration: number;
                readonly kind: string;
                readonly preview: string | null;
                readonly src: string | null;
                readonly srcId: string | null;
                readonly lowres: string | null;
                readonly volume: number | null;
                readonly startMedia: number | null;
                readonly endMedia: number | null;
                readonly text: string | null;
                readonly displayText: string | null;
                readonly fontSize: number | null;
                readonly font: string | null;
                readonly width: number | null;
                readonly height: number | null;
                readonly opacity: number | null;
                readonly cropX: number | null;
                readonly cropY: number | null;
                readonly cropWidth: number | null;
                readonly cropHeight: number | null;
                readonly initialHeight: number | null;
                readonly initialWidth: number | null;
                readonly x: number | null;
                readonly y: number | null;
                readonly rotation: number | null;
                readonly borderSize: number | null;
                readonly fontColor: string | null;
                readonly fontStyle: string | null;
                readonly backgroundColor: string | null;
                readonly borderColor: string | null;
                readonly effect: {
                    readonly type: string | null;
                    readonly durationSec: number | null;
                } | null;
                readonly offset: number | null;
                readonly originMediaDuration: number | null;
                readonly trimmedSrc: string | null;
                readonly size: number | null;
                readonly amplitudeDegree: number | null;
            }>;
        } | null>;
        readonly size: {
            readonly height: number;
            readonly width: number;
        };
        readonly lastUpdate: unknown;
    }>;
};
export type GraphQLGetProjectsQuery = {
    readonly response: GraphQLGetProjectsQueryResponse;
    readonly variables: GraphQLGetProjectsQueryVariables;
};



/*
query GraphQLGetProjectsQuery {
  getProjects {
    _id
    owner
    duration
    title
    isSameVideoSizeProject
    videoBitrate
    tracks {
      id
      kind
      name
      muted
      visible
      width
      height
      scale
      sections {
        id
        start
        duration
        kind
        preview
        src
        srcId
        lowres
        volume
        startMedia
        endMedia
        text
        displayText
        fontSize
        font
        width
        height
        opacity
        cropX
        cropY
        cropWidth
        cropHeight
        initialHeight
        initialWidth
        x
        y
        rotation
        borderSize
        fontColor
        fontStyle
        backgroundColor
        borderColor
        effect {
          type
          durationSec
        }
        offset
        originMediaDuration
        trimmedSrc
        size
        amplitudeDegree
      }
    }
    size {
      height
      width
    }
    lastUpdate
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "getProjects",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSameVideoSizeProject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "videoBitrate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Track",
        "kind": "LinkedField",
        "name": "tracks",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "muted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visible",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scale",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Section",
            "kind": "LinkedField",
            "name": "sections",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "start",
                "storageKey": null
              },
              (v0/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "preview",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "src",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "srcId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lowres",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "volume",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startMedia",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endMedia",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fontSize",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "font",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "opacity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cropX",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cropY",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cropWidth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cropHeight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "initialHeight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "initialWidth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "x",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "y",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rotation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "borderSize",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fontColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fontStyle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backgroundColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "borderColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TransitionEffect",
                "kind": "LinkedField",
                "name": "effect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "durationSec",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "offset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "originMediaDuration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trimmedSrc",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "size",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amplitudeDegree",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Size",
        "kind": "LinkedField",
        "name": "size",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastUpdate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLGetProjectsQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GraphQLGetProjectsQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "01b25d5bce0d17466ffdc49f10e184ef",
    "id": null,
    "metadata": {},
    "name": "GraphQLGetProjectsQuery",
    "operationKind": "query",
    "text": "query GraphQLGetProjectsQuery {\n  getProjects {\n    _id\n    owner\n    duration\n    title\n    isSameVideoSizeProject\n    videoBitrate\n    tracks {\n      id\n      kind\n      name\n      muted\n      visible\n      width\n      height\n      scale\n      sections {\n        id\n        start\n        duration\n        kind\n        preview\n        src\n        srcId\n        lowres\n        volume\n        startMedia\n        endMedia\n        text\n        displayText\n        fontSize\n        font\n        width\n        height\n        opacity\n        cropX\n        cropY\n        cropWidth\n        cropHeight\n        initialHeight\n        initialWidth\n        x\n        y\n        rotation\n        borderSize\n        fontColor\n        fontStyle\n        backgroundColor\n        borderColor\n        effect {\n          type\n          durationSec\n        }\n        offset\n        originMediaDuration\n        trimmedSrc\n        size\n        amplitudeDegree\n      }\n    }\n    size {\n      height\n      width\n    }\n    lastUpdate\n  }\n}\n"
  }
};
})();
(node as any).hash = '652bb7a99407eeca5480a75d0668c63c';
export default node;
