import './ActionExport.scss';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { doExportEDL, doExportProjectStructure } from '../../redux/actions';

export const ActionExport: FC = () => {
  const dispatch = useDispatch();

  const handleExportProject = useCallback(() => {
    dispatch(doExportProjectStructure());
  }, [dispatch]);

  const handleExportEDL = useCallback(() => {
    dispatch(doExportEDL());
  }, [dispatch]);

  const possibleExports = [
    { icon: faWrench, name: 'Project structure', onClick: handleExportProject },
    { icon: faFileAlt, name: 'EDL', onClick: handleExportEDL }
  ];

  return (
    <div className="export-options-menu">
      {possibleExports.map((option) => (
        <div key={option.name} className="export-option p-2" onClick={option.onClick}>
          <FontAwesomeIcon style={{ width: '20px' }} icon={option.icon} />
          <span className="pl-2 option-link">{option.name}</span>
        </div>
      ))}
    </div>
  );
};
