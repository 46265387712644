import React, { FC, useEffect, useState } from 'react';
import { getCrossIFrameCookie, CROSS_IFRAME_COOKIE, listenCrossIFrameCookieChange } from '@eolementhe/core';
import { BrowserRouter, Switch, Route, RouteProps, Redirect } from 'react-router-dom';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { Environment } from 'react-relay';
import { Provider as ReduxProvider, useDispatch, useSelector } from 'react-redux';
import './App.scss';
import { addReducerToStore, getStore } from '@eolementhe/core/dist/redux/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import Swal from 'sweetalert2';
import { EditorPage } from './components/EditorPage/EditorPage';
import { LoginPage } from './components/LoginPage/LoginPage';
import { ProjectModal } from './components/ProjectModal/ProjectModal';
import { HomePage } from './components/HomePage/HomePage';
import { UnauthorizedPage } from './components/UnauthorizedPage/UnauthorizedPage';
import { BuildStatus } from './components/BuildStatus';
import { LoadProjectModal } from './components/LoadProjectModal/LoadProjectModal';
import { ResourceBrowserModal } from './components/ResourceBrowserModal/ResourceBrowserModal';
import { RELAY_ENVIRONMENT } from './api/relay-environment';
import { MenuBar } from './components/MenuBar/MenuBar';
import { ErrorModal } from './components/ErrorModal';
import { doSaveProject } from './redux/actions';
import { reducer } from './redux/reducer';
import { EoleEditStateEntry } from './redux/types';
import { requestEoleEditRight } from './utils/Userprofile';
import { ProjectBuildingModal } from './components/ProjectBuildingModal/ProjectBuildingModal';

addReducerToStore('ee', reducer);

export const App: FC = () => (
  // @ts-ignore
  <RelayEnvironmentProvider environment={RELAY_ENVIRONMENT as Environment}>
    <BrowserRouter>
      <ReduxProvider store={getStore()}>
        <AppWithContext />
      </ReduxProvider>
    </BrowserRouter>
  </RelayEnvironmentProvider>
);

export const AppWithContext: FC = () => {
  const [authToken, setAuthToken] = useState('');
  const [isAuthorizedUser, setAuthorizationUser] = useState<boolean>(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(doSaveProject());
    }, 180000);
    return () => {
      dispatch(doSaveProject());
      return clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getCrossIFrameCookie(CROSS_IFRAME_COOKIE.USER_TOKEN).then(
      (token) => {
        setAuthToken(token);
      },
      (err) => {
        console.error('App.tsx', err);
      }
    );

    listenCrossIFrameCookieChange(() => {
      getCrossIFrameCookie(CROSS_IFRAME_COOKIE.USER_TOKEN).then(
        (token) => {
          setAuthToken(token);
        },
        (err) => {
          console.error('App.tsx', err);
        }
      );
    });

    (async () => {
      if (document.location.search.includes('checkout_success')) {
        await Swal.fire({
          title: 'Payment validated',
          text: 'Your payment has been made',
          icon: 'success',
          confirmButtonText: 'ok'
        });
        window.history.replaceState({}, document.title, document.location.pathname);
      }
    })();
  }, []);
  useEffect(() => {
    let mounted = true;
    if (authToken) {
      requestEoleEditRight().then((eoleEditRight) => {
        if (mounted) {
          setAuthorizationUser(!!eoleEditRight?.right);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [authToken]);

  if (!authToken) {
    return <AppRoutes authToken={authToken} />;
  }

  return (
    <>
      {isAuthorizedUser && <MenuBar />}
      <AppRoutes authToken={authToken} />
      <ProjectModal />
      <BuildStatus />
      <LoadProjectModal />
      <ProjectBuildingModal />
      <ResourceBrowserModal />
      <ErrorModal />
    </>
  );
};

export const LoadingSpinner: FC = () => {
  return (
    <div className="app-spinner-container d-flex justify-content-center align-items-center">
      <FontAwesomeIcon icon={faSpinnerThird} className="app-spinner" size="lg" fixedWidth spin />
    </div>
  );
};

interface IAppRoutesProps {
  authToken: string;
}
export const AppRoutes: FC<IAppRoutesProps> = ({ authToken }: IAppRoutesProps) => {
  const project = useSelector((state: EoleEditStateEntry) => state.ee.project);

  const isLoggedIn = !!authToken;
  const hasProject = isLoggedIn && project !== undefined;
  const [userEoleEditRight, setUserEoleEditRight] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasFetchRight, setHasFetchRight] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    if (isLoggedIn) {
      requestEoleEditRight().then((eoleEditRight) => {
        if (mounted) {
          setUserEoleEditRight(!!eoleEditRight?.right && eoleEditRight?.expiry.getTime() >= new Date().getTime());
          setHasFetchRight(true);
        }
      });
    }
    setIsLoading(false);
    return () => {
      mounted = false;
    };
  }, [isLoggedIn]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Switch>
        <Redirect exact from="/" to={isLoggedIn ? '/home' : '/login'} />
        <PrivateRoute path="/login" component={LoginPage} condition={!isLoggedIn} redirectTo="/home" />
        <Route
          path="/home"
          render={() => {
            if (isLoggedIn && hasFetchRight && userEoleEditRight) {
              return <HomePage />;
            }
            if (isLoggedIn && hasFetchRight && !userEoleEditRight) {
              return <Redirect to="/unauthorized" />;
            }
            if (!isLoggedIn) {
              return <Redirect to="/login" />;
            }

            return <LoadingSpinner />;
          }}
        />
        <Route
          path="/editor/:projectId"
          render={() => {
            if (isLoggedIn && hasFetchRight && userEoleEditRight) {
              return <EditorPage />;
            }

            return <LoadingSpinner />;
          }}
        />
        <PrivateRoute
          path="/editor"
          component={EditorPage}
          condition={hasProject && hasFetchRight && userEoleEditRight}
          redirectTo="/home"
        />
        <PrivateRoute
          path="/unauthorized"
          component={UnauthorizedPage}
          condition={isLoggedIn && !userEoleEditRight}
          redirectTo="/home"
        />
      </Switch>
    </>
  );
};

type PrivateRouteProps = {
  condition: boolean;
  redirectTo: string;
} & RouteProps;

export const PrivateRoute: FC<PrivateRouteProps> = ({ component, condition, redirectTo, ...rest }) => {
  const redirComponent = condition
    ? component
    : () => <Redirect to={{ pathname: redirectTo, state: { from: rest.location } }} />;
  return <Route {...rest} component={redirComponent} />;
};
