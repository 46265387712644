import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Environment, commitMutation, fetchQuery } from 'relay-runtime';
import { useRelayEnvironment } from 'react-relay';
import { IProject } from '@eolementhe/video-editor-model';
import {
  GraphQLGetProjectsQuery,
  GraphQLGetProjectsQueryResponse
} from '../../__generated__/GraphQLGetProjectsQuery.graphql';
import { GraphQLRemoveProjectMutation } from '../../__generated__/GraphQLRemoveProjectMutation.graphql';
import { REMOVE_PROJECT_MUTATION, GET_PROJECTS_QUERY } from '../../api/GraphQL';
import { handleError } from '../../utils/handleError';
import { EoleEditStateEntry } from '../../redux/types';
import { doShowLoadProjectModal, doLoadProject, doCloseProject } from '../../redux/actions';
import LoadProjectSaveAlert from './LoadProjectSaveAlert';
import LoadProjectLine from './LoadProjectLine';

export const LoadProjectModal: React.FC = () => {
  const [projects, setProjects] = React.useState<any[]>([]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState<IProject | undefined>(undefined);
  const history = useHistory();
  const environment = useRelayEnvironment();
  const project = useSelector((state: EoleEditStateEntry) => state.ee.project);
  const isLoadModalShown = useSelector((state: EoleEditStateEntry) => state.ee.isLoadModalShown);
  const dispatch = useDispatch();

  const fetchAndUpdateProjects = useCallback((): void => {
    (async (): Promise<void> => {
      try {
        const result: GraphQLGetProjectsQueryResponse | undefined = await fetchQuery<GraphQLGetProjectsQuery>(
          // @ts-ignore
          environment,
          GET_PROJECTS_QUERY,
          {}
        ).toPromise();
        if (!result) {
          setProjects([]);
          return;
        }

        const projectsResult = result.getProjects.map((object) => ({ ...object }));
        setProjects(projectsResult);
      } catch (err) {
        handleError(err as Error, 'Cannot get projects');
      }
    })();
  }, [environment]);

  React.useEffect(() => {
    fetchAndUpdateProjects();
  }, [isLoadModalShown, fetchAndUpdateProjects]);

  const removeAndFetchProjects = useCallback(
    (env: Environment, id: string): void => {
      commitMutation<GraphQLRemoveProjectMutation>(env, {
        mutation: REMOVE_PROJECT_MUTATION,
        variables: { id },
        onCompleted: () => {
          fetchAndUpdateProjects();
        },
        onError: (err: Error) => {
          handleError(err, "Could'nt delete the project");
        }
      });
    },
    [fetchAndUpdateProjects]
  );

  const handleClickDelete = React.useCallback(() => {
    if (!selectedProject?._id) {
      return;
    }
    if (project && project._id === selectedProject._id) {
      dispatch(doCloseProject());
    }
    // @ts-ignore
    removeAndFetchProjects(environment, selectedProject._id);
    setSelectedProject(undefined);
  }, [dispatch, selectedProject, project, environment, removeAndFetchProjects]);

  const handleHide = React.useCallback(() => {
    dispatch(doShowLoadProjectModal(false));
    setSelectedProject(undefined);
  }, [dispatch]);

  const handleSelectProject = React.useCallback((newSelectedProject: IProject) => {
    setSelectedProject(newSelectedProject);
  }, []);

  const loadProject = React.useCallback(() => {
    if (selectedProject) {
      dispatch(doLoadProject(selectedProject));
    }
    handleHide();
    history.push('/editor');
  }, [history, dispatch, selectedProject, handleHide]);

  const checkAndLoadProject = () => {
    if (project) {
      setShowAlert(true);
    } else {
      loadProject();
    }
  };

  const handleSaveAlertAnswer = (agree: boolean) => {
    if (agree) {
      loadProject();
    }
    setShowAlert(false);
  };

  return (
    <Modal show={isLoadModalShown} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>My Projects</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: 'calc(100vh - 210px)'
        }}
        className="d-flex flex-column"
      >
        <LoadProjectSaveAlert show={showAlert} onAnswer={handleSaveAlertAnswer} />
        <div className="overflow-y-auto min-height-0" style={{ minWidth: '50%' }}>
          <Table hover size="sm" className="resource-browser-column-table">
            <thead>
              <tr>
                <th>Project</th>
                <th>Size</th>
                <th>Duration</th>
                <th>Last Update</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <LoadProjectLine
                  selectedProject={selectedProject}
                  key={project._id}
                  project={project}
                  onSelectProject={handleSelectProject}
                  onLoadProject={checkAndLoadProject}
                />
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer className="pt-1 pb-0 pl-0 pr-0">
        <Button variant="outline-danger" disabled={!selectedProject} onClick={handleClickDelete}>
          Delete Project
        </Button>
        <Button variant="success" disabled={!selectedProject} onClick={checkAndLoadProject}>
          Load Project
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
