import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import { useRelayEnvironment } from 'react-relay';
import { uniqWith } from 'lodash';
import { FileResource, IVideoResource } from '@eolementhe/video-editor-model';
import { EoleEditStateEntry } from '../../redux/types';
import { ILinkedProject } from './IDirectoryViewProps';
import fetchAllProjects from '../../api/fetchAllProjects';
import { ResourceUtils } from './ResourceUtils';
import { handleError } from '../../utils/handleError';
import { doCloseProject } from '../../redux/actions';
import removeOneFile from '../../api/removeOneFile';
import removeOneProject from '../../api/removeOneProject';

interface IRemoveAlertProps {
  show: boolean;
  resourcesToRemove?: FileResource[];
  onClose: (value: boolean) => void;
}

interface IAlertTextBodyProps {
  linkedProjects?: ILinkedProject[];
  ingestInEolementhe?: boolean;
}

interface IAlertTextBodyEolementheFileProps {
  ingestInEolementhe?: boolean;
  linkedProjects?: ILinkedProject[];
}

const AlertTextBodyEolementheFile: React.FC<IAlertTextBodyEolementheFileProps> = ({
  ingestInEolementhe,
  linkedProjects
}) => {
  return (
    <>
      {ingestInEolementhe && (
        <div className={linkedProjects && linkedProjects.length > 0 ? 'mt-4' : ''}>
          <p className="mb-1">The resource is used in at least 1 Eolementhe workflow.</p>
          <p className="text-danger">
            {ingestInEolementhe &&
              'Be aware, by removing this resource, the Eolementhe workflow linked will have its source file deleted permanently.'}
          </p>
        </div>
      )}
    </>
  );
};

interface IAlertTextBodyEoleEditFileProps {
  linkedProjects?: ILinkedProject[];
}
const AlertTextBodyEoleEditFile: React.FC<IAlertTextBodyEoleEditFileProps> = ({ linkedProjects }) => {
  return (
    <>
      {linkedProjects && linkedProjects.length > 0 && (
        <>
          <p className="mb-1">The resource(s) is/are used in the following project(s):</p>
          {linkedProjects.map((project) => (
            <p key={project._id} className="mt-0 mb-0 ml-3 font-bold">
              {project.title}
            </p>
          ))}
          <p className="text-danger">
            {linkedProjects.length > 0 &&
              'Be aware, by removing this resource, projects above will be deleted permanently.'}
          </p>
        </>
      )}
    </>
  );
};

const AlertTextBody: React.FC<IAlertTextBodyProps> = ({ linkedProjects, ingestInEolementhe }) => {
  return (
    <>
      {(linkedProjects && linkedProjects.length > 0) || ingestInEolementhe ? (
        <>
          <AlertTextBodyEoleEditFile linkedProjects={linkedProjects} />
          <AlertTextBodyEolementheFile ingestInEolementhe={ingestInEolementhe} linkedProjects={linkedProjects} />
        </>
      ) : (
        <p>The file is currently not used in any project, so you can remove it safely.</p>
      )}
    </>
  );
};

const RemoveAlert: React.FC<IRemoveAlertProps> = ({ show, onClose, resourcesToRemove }) => {
  const environment = useRelayEnvironment();
  const mountedRef = useRef(false);
  const project = useSelector((state: EoleEditStateEntry) => state.ee.project);
  const [linkedProjects, setLinkedProjects] = useState<undefined | ILinkedProject[]>(undefined);
  const [ingestInEolementhe, setIngestInEolementhe] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!resourcesToRemove) {
      setLinkedProjects(undefined);
      return;
    }
    fetchAllProjects(environment)
      .then((allProjects) => {
        if (!mountedRef.current) {
          return;
        }
        let hasAnIngestInEolementhe = false;
        let newLinkedProjectsToRemove = resourcesToRemove.reduce((accu: ILinkedProject[], resource: FileResource) => {
          if ((resource as IVideoResource).ingestInEolementhe) {
            hasAnIngestInEolementhe = true;
          }
          const projects = ResourceUtils.getLinkedProjects(resource, allProjects);
          return accu.concat(projects);
        }, []);
        newLinkedProjectsToRemove = uniqWith(newLinkedProjectsToRemove, (p1, p2) => p1._id === p2._id);
        setLinkedProjects(newLinkedProjectsToRemove);
        setIngestInEolementhe(hasAnIngestInEolementhe);
      })
      .catch((err) => {
        handleError(err, 'Cannot get projects');
      });

    setLinkedProjects(undefined);
  }, [environment, resourcesToRemove]);
  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleRemove = useCallback(() => {
    (async function doRemove() {
      if (linkedProjects) {
        if (
          project &&
          linkedProjects.filter((linkedProject: ILinkedProject) => linkedProject._id === project._id).length > 0
        ) {
          dispatch(doCloseProject());
        }
        for (const project of linkedProjects) {
          try {
            //Want the remove to be sequential
            /*eslint no-await-in-loop: "off"*/
            await removeOneProject(environment, project._id);
          } catch (err) {
            console.error(
              'RemoveAlert.tsx/handleRemove | Failed to remove a project:',
              'project._id=',
              project._id,
              'err=',
              err
            );
          }
        }
      }
      if (resourcesToRemove) {
        for (const resource of resourcesToRemove) {
          try {
            //Want the remove to be sequential
            /*eslint no-await-in-loop: "off"*/
            await removeOneFile(environment, resource._id);
          } catch (err) {
            console.error(
              'RemoveAlert.tsx/handleRemove | Failed to remove a resource:',
              'project._id=',
              resource._id,
              'err=',
              err
            );
            console.error('Failed to remove a resource', err);
          }
        }
      }

      onClose(true);
    })();
  }, [linkedProjects, resourcesToRemove, environment, dispatch, project, onClose]);
  return (
    <Alert show={show} variant="dark" style={{ position: 'absolute', top: '20%', left: '25%', width: '50%' }}>
      <Alert.Heading>Remove resource(s)</Alert.Heading>
      <AlertTextBody linkedProjects={linkedProjects} ingestInEolementhe={ingestInEolementhe} />
      <hr />
      <div className="d-flex justify-content-end">
        <Button className="mr-2" onClick={handleClose} variant="outline-dark">
          Cancel
        </Button>
        <Button className="mr-2" onClick={handleRemove} variant="outline-danger">
          {(linkedProjects && linkedProjects.length > 0) || ingestInEolementhe ? 'Remove anyway' : 'Remove'}
        </Button>
      </div>
    </Alert>
  );
};

export default RemoveAlert;
