import { ITrack, ITrackSection } from '@eolementhe/video-editor-model';
import { Dispatch } from '@reduxjs/toolkit';
import uuidv4 from 'uuid/v4';
import { DraggableResource, EoleEditAction, EoleEditStateEntry, MODALTYPE } from './types';
import { saveProject } from '../utils/saveProject';
import ACTION_TYPES from './actionTypes';
import { FileResource, IProject } from '../../../model/dist';
import { getDefaultScene } from '../components/EditorPage/Constants';
import { TextSectionPropertyName, TextSectionPropertyValue } from '../types/TextSectionProperty';
import { ImageSectionPropertyName, ImageSectionPropertyValue } from '../types/ImageSectionProperty';
import { TrackPropertyName, TrackPropertyValue } from '../types/TrackProperty';
import { VideoSectionPropertyName, VideoSectionPropertyValue } from '../types/VideoSectionProperty';
import { AudioSectionPropertyName, AudioSectionPropertyValue } from '../types/AudioSectionProperty';
import { TransitionSectionPropertyName, TransitionSectionPropertyValue } from '../types/TransitionSectionProperty';
import { TextPreset } from '../utils/saveTextPreset';
import { editProject } from '../utils/editProject';
import { WaveformSectionPropertyName, WaveformSectionPropertyValue } from '../types/WaveformSectionProperty';

export function doShowError(error?: any, title?: string): EoleEditAction {
  let payload; //no payload = hide
  if (error || title) {
    const message = error ? error.stack || error.message || error.toString() : 'Error';
    payload = { message, readableInfo: title || '' };
  }

  return { type: ACTION_TYPES.SHOW_ERROR, payload };
}
export function doSetProjectSaving(): EoleEditAction {
  return { type: ACTION_TYPES.SET_PROJECT_SAVING };
}

export function doSetProjectSaved(lastSave: string, _id: string): EoleEditAction {
  return { type: ACTION_TYPES.SET_PROJECT_SAVED, payload: { lastSave, _id } };
}

export function doShowSaveSuccess(show: boolean): EoleEditAction {
  return { type: ACTION_TYPES.SHOW_SAVE_SUCCESS, payload: { show } };
}

export function doSetLastUpdateUserProfile(): EoleEditAction {
  return { type: ACTION_TYPES.SET_LAST_UPDATE_USERPROFILE, payload: { lastUpdateUserProfile: new Date() } };
}

export function doSaveProject(): any {
  return (dispatch: Dispatch, getState: () => EoleEditStateEntry) => {
    dispatch(doSetProjectSaving());
    const {
      ee: { project }
    } = getState();
    if (!project || !project.duration || !project.tracks || !project.title) {
      return;
    }
    return saveProject(
      project.duration,
      project.tracks,
      project.title,
      project.isSameVideoSizeProject,
      project.videoBitrate,
      project.size
    ).then(
      (res) => {
        dispatch(doSetProjectSaved(res.lastUpdate, res.id));
        dispatch(doShowSaveSuccess(true));
      },
      (error) => {
        dispatch(doShowError(error, 'Unable to save'));
      }
    );
  };
}

export function doShowProjectModal(show: boolean, modalType: string): EoleEditAction {
  return { type: ACTION_TYPES.SHOW_PROJECT_MODAL, payload: { show, modalType } };
}

export function doShowBuildModal(show: boolean, modalType: string): EoleEditAction {
  return { type: ACTION_TYPES.SHOW_BUILD_MODAL, payload: { show, modalType } };
}

export function doShowProjectBuildingModal(show: boolean): EoleEditAction {
  return { type: ACTION_TYPES.SHOW_PROJECT_BUILDING_MODAL, payload: { show } };
}

export function doShowLoadProjectModal(show: boolean): EoleEditAction {
  return { type: ACTION_TYPES.SHOW_LOAD_PROJECT_MODAL, payload: { show } };
}

export function doShowResourceBrowserModal(show: boolean): EoleEditAction {
  return { type: ACTION_TYPES.SHOW_RESOURCE_BROWSER_MODAL, payload: { show } };
}

export function doLoadProject(project: IProject): EoleEditAction {
  return { type: ACTION_TYPES.LOAD_PROJECT, payload: { project } };
}

export function doCloseProject(): EoleEditAction {
  return { type: ACTION_TYPES.CLOSE_PROJECT };
}

export function doExportEDL(): EoleEditAction {
  return { type: ACTION_TYPES.EXPORT_EDL };
}

export function doExportProjectStructure(): EoleEditAction {
  return { type: ACTION_TYPES.EXPORT_PROJECT_STRUCTURE };
}

export function doCreateProject(
  title: string,
  isSameVideoSizeProject: string,
  width: number,
  height: number,
  videoBitrate: number,
  alreadyExistingDuration?: number,
  alreadyExistingTracks?: ITrack[]
): any {
  return (dispatch: Dispatch) => {
    const { duration, tracks } = getDefaultScene(width, height);
    const project = {
      title,
      isSameVideoSizeProject,
      videoBitrate,
      size: {
        width,
        height
      },
      duration: alreadyExistingDuration ?? duration,
      tracks: alreadyExistingTracks ?? tracks
    };
    dispatch({ type: ACTION_TYPES.CREATE_PROJECT, payload: { project } });
    //Save project asap to create DB entry right away
    setTimeout(() => {
      dispatch(doSaveProject());
    }, 10);
  };
}

export function doCopyProject(title: string, copyingProject: IProject) {
  return (dispatch: Dispatch) => {
    const project = {
      ...copyingProject,
      title,
      tracks: copyingProject.tracks.map((track: ITrack) => ({
        ...track,
        id: uuidv4(),
        //@ts-ignore
        sections: track.sections?.map((section: ITrackSection) => ({
          ...section,
          id: uuidv4()
        }))
      }))
    };
    delete project._id;

    dispatch({ type: ACTION_TYPES.CREATE_PROJECT, payload: { project } });

    //Save project asap to create DB entry right away
    setTimeout(() => {
      dispatch(doSaveProject());
    }, 10);
  };
}

export function doResetSelectedResources(): EoleEditAction {
  return { type: ACTION_TYPES.RESET_SELECTED_RESOURCES };
}

export function doSelectResources(resources: DraggableResource[], areSelected: boolean): EoleEditAction {
  return { type: ACTION_TYPES.SELECT_RESOURCES, payload: { resources, areSelected } };
}

export function doSetResourceList(resourceList: FileResource[]): EoleEditAction {
  return { type: ACTION_TYPES.SET_RESOURCE_LIST, payload: { resourceList } };
}

export function doSetSectionDuration(trackIndex: number, sectionIndex: number, duration: number) {
  return { type: ACTION_TYPES.SET_SECTION_DURATION, payload: { trackIndex, sectionIndex, duration } };
}

export function doSetSectionStartMedia(trackIndex: number, sectionIndex: number, start: number) {
  return { type: ACTION_TYPES.SET_SECTION_START_MEDIA, payload: { trackIndex, sectionIndex, start } };
}

export function doSetSectionEndMedia(trackIndex: number, sectionIndex: number, end: number) {
  return { type: ACTION_TYPES.SET_SECTION_END_MEDIA, payload: { trackIndex, sectionIndex, end } };
}

export function doSetSectionOriginMediaDuration(trackIndex: number, sectionIndex: number, duration: number) {
  return { type: ACTION_TYPES.SET_SECTION_ORIGIN_MEDIA_DURATION, payload: { trackIndex, sectionIndex, duration } };
}

export function doSetSectionStart(
  trackIndex: number,
  sectionIndex: number,
  start: number,
  targetTrackIndex?: number
): any {
  return { type: ACTION_TYPES.SET_SECTION_START, payload: { trackIndex, sectionIndex, start, targetTrackIndex } };
}

export enum CenterShapeDirection {
  vertical = 'vertical',
  horizontal = 'horizontal',
  both = 'both'
}
export type FitDimensions = {
  width: number;
  height: number;
};

export function doCenterShape(direction: CenterShapeDirection) {
  return { type: ACTION_TYPES.CENTER_SHAPE, payload: { direction } };
}
export function doFitShape(dimensions: FitDimensions, type: CenterShapeDirection) {
  return { type: ACTION_TYPES.FIT_SHAPE, payload: { dimensions, type } };
}

export function doUpdateProjectDuration(delta: number, isAdd: boolean) {
  return { type: ACTION_TYPES.UPDATE_PROJECT_DURATION, payload: { delta, isAdd } };
}

export function doSetCurrentSection(currentSectionIndex?: number) {
  return { type: ACTION_TYPES.SET_SECTION_CURRENT, payload: { currentSectionIndex } };
}
export function doSetFrameRate(frameRate: number) {
  return { type: ACTION_TYPES.SET_FRAMERATE, payload: { frameRate } };
}
export function doSetCurrentTrack(currentTrackIndex?: number) {
  return { type: ACTION_TYPES.SET_TRACK_CURRENT, payload: { currentTrackIndex } };
}

export function doSectionSplit(
  trackIndex: number,
  sectionIndex: number,
  currentSectionNewDuration: number,
  newSection: ITrackSection
) {
  return {
    type: ACTION_TYPES.SET_SECTION_SPLIT,
    payload: { trackIndex, sectionIndex, currentSectionNewDuration, newSection }
  };
}

export function doSetTrackProperty(trackIndex: number, propName: TrackPropertyName, propValue: TrackPropertyValue) {
  return {
    type: ACTION_TYPES.SET_TRACK_PROP,
    payload: { trackIndex, propName, propValue }
  };
}

export function doSetTextSection(trackIndex: number, sectionIndex: number, objectKeyValues: TextPreset) {
  return {
    type: ACTION_TYPES.SET_TEXT_SECTION,
    payload: { trackIndex, sectionIndex, objectKeyValues }
  };
}

export function doSetSectionSrcProperties(
  trackIndex: number,
  sectionIndex: number,
  srcId: string,
  src: string,
  preview: string,
  lowres: string,
  text: string
) {
  return {
    type: ACTION_TYPES.SET_SECTION_SRC_PROPERTIES,
    payload: { trackIndex, sectionIndex, srcId, src, preview, lowres, text }
  };
}

export function doSetTextSectionProperty(
  trackIndex: number,
  sectionIndex: number,
  propName: TextSectionPropertyName,
  propValue: TextSectionPropertyValue
) {
  return {
    type: ACTION_TYPES.SET_TEXT_SECTION_PROP,
    payload: { trackIndex, sectionIndex, propValue, propName }
  };
}

export function doSetSectionGeometry(
  trackIndex: number,
  sectionIndex: number,
  posX: number,
  posY: number,
  width: number,
  height: number,
  rotation: number,
  cropX?: number,
  cropY?: number,
  cropWidth?: number,
  cropHeight?: number,
  initialWidth?: number,
  initialHeight?: number
) {
  return {
    type: ACTION_TYPES.SET_TEXT_SECTION_GEOM,
    payload: {
      trackIndex,
      sectionIndex,
      posX,
      posY,
      width,
      height,
      rotation,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      initialWidth,
      initialHeight
    }
  };
}

export function doSetImageSectionProperty(
  trackIndex: number,
  sectionIndex: number,
  propName: ImageSectionPropertyName,
  propValue: ImageSectionPropertyValue
) {
  return {
    type: ACTION_TYPES.SET_IMAGE_SECTION_PROP,
    payload: { trackIndex, sectionIndex, propValue, propName }
  };
}
export function doSetTransitionSectionProperty(
  trackIndex: number,
  sectionIndex: number,
  propName: TransitionSectionPropertyName,
  propValue: TransitionSectionPropertyValue
) {
  return {
    type: ACTION_TYPES.SET_TRANSITION_SECTION_PROP,
    payload: { trackIndex, sectionIndex, propValue, propName }
  };
}
export function doSetElseTransitionSectionProperty(
  trackIndex: number,
  sectionIndex: number,
  propName: TransitionSectionPropertyName,
  propValue: TransitionSectionPropertyValue
) {
  return {
    type: ACTION_TYPES.SET_ELSE_TRANSITION_SECTION_PROP,
    payload: { trackIndex, sectionIndex, propValue, propName }
  };
}
export function doSetElseTransitionDurationSectionProperty(
  trackIndex: number,
  sectionIndex: number,
  propName: TransitionSectionPropertyName,
  propValue: TransitionSectionPropertyValue
) {
  return {
    type: ACTION_TYPES.SET_ELSE_TRANSITION_DURATION_SECTION_PROP,
    payload: { trackIndex, sectionIndex, propValue, propName }
  };
}
export function doSetVideoSectionProperty(
  trackIndex: number,
  sectionIndex: number,
  propName: VideoSectionPropertyName,
  propValue: VideoSectionPropertyValue
) {
  return {
    type: ACTION_TYPES.SET_VIDEO_SECTION_PROP,
    payload: { trackIndex, sectionIndex, propValue, propName }
  };
}

export function doSetAudioSectionProperty(
  trackIndex: number,
  sectionIndex: number,
  propName: AudioSectionPropertyName,
  propValue: AudioSectionPropertyValue
) {
  return {
    type: ACTION_TYPES.SET_AUDIO_SECTION_PROP,
    payload: { trackIndex, sectionIndex, propValue, propName }
  };
}

export function doSetWaveformSectionProperty(
  trackIndex: number,
  sectionIndex: number,
  propName: WaveformSectionPropertyName,
  propValue: WaveformSectionPropertyValue
) {
  return {
    type: ACTION_TYPES.SET_WAVEFORM_SECTION_PROP,
    payload: { trackIndex, sectionIndex, propValue, propName }
  };
}

export function doDeleteCurrentSection() {
  return {
    type: ACTION_TYPES.DELETE_CURRENT_SECTION
  };
}

export function doDeleteSection(trackIndex: number, sectionIndex: number) {
  return {
    type: ACTION_TYPES.DELETE_SECTION,
    payload: { trackIndex, sectionIndex }
  };
}

export function doDeleteCurrentTrack() {
  return {
    type: ACTION_TYPES.DELETE_CURRENT_TRACK
  };
}

export function doAddNewTrack(newTrack: ITrack) {
  return {
    type: ACTION_TYPES.ADD_TRACK,
    payload: { newTrack }
  };
}

export function doAddNewSection(trackIndex: number, newSection: ITrackSection) {
  return {
    type: ACTION_TYPES.ADD_SECTION,
    payload: { trackIndex, newSection }
  };
}

export function doSetProjectDuration(duration: number) {
  return {
    type: ACTION_TYPES.SET_PROJECT_DURATION,
    payload: { duration }
  };
}

export function doMoveTrack(toUp: boolean, trackIndex?: number) {
  return {
    type: ACTION_TYPES.MOVE_TRACK,
    payload: { toUp, trackIndex }
  };
}

export function doEditProject(
  project: IProject,
  _id: string,
  title: string,
  isSameVideoSizeProject: string,
  videoBitrate: number
): any {
  return (dispatch: Dispatch) => {
    dispatch(doSetProjectSaving());

    return editProject(_id, title, isSameVideoSizeProject, videoBitrate).then(
      (res) => {
        dispatch(doSetProjectSaved(res.lastUpdate, res.id));
        dispatch(doShowSaveSuccess(true));
        dispatch(
          doLoadProject({
            ...project,
            _id,
            title,
            isSameVideoSizeProject,
            videoBitrate
          })
        );
        dispatch(doShowProjectModal(false, MODALTYPE.EDITION));
      },
      (error) => {
        dispatch(doShowError(error, 'Unable to save'));
        dispatch(doShowProjectModal(false, MODALTYPE.EDITION));
      }
    );
  };
}

export function doAddVideoTransitionPlaceHolder(trackIndex: number, sectionIndex?: number) {
  return {
    type: ACTION_TYPES.ADD_PLACE_HOLDER_TRANSITION,
    payload: { trackIndex, sectionIndex }
  };
}
