/* eslint-disable react/no-unescaped-entities */
import {
  FontFamily,
  IAudioSection,
  IImageSection,
  ITextSection,
  ITrackSection,
  SectionKind,
  TrackKind,
  IProject,
  IWaveformSection,
  MediaSection,
  IVideoSection
} from '@eolementhe/video-editor-model';
import uuidv4 from 'uuid/v4';
import { isNumber } from 'lodash';
import React from 'react';

import './EditSection.scss';
import { EditSectionTextKind } from './EditSectionTextKind';
import { trimMedia } from '../../../utils/trimMedia';

interface IProps {
  onChangeUpdateText(
    content: string | number | FontFamily,
    action:
      | 'text'
      | 'fontColor'
      | 'fontStyle'
      | 'borderColor'
      | 'backgroundColor'
      | 'borderSize'
      | 'font'
      | 'fontSize'
      | 'rotation',
    trackIndex: number,
    sectionIndex: number
  ): void;
  onChangeUpdateVolume(volume: number, trackIndex: number, sectionIndex: number): void;
  onChangeUpdateSize(size: number, trackIndex: number, sectionIndex: number): void;
  onChangeUpdateAmplitudeDegree(amplitudeDegree: number, trackIndex: number, sectionIndex: number): void;
  onChangeUpdateImage(
    content: string | number,
    action: 'width' | 'height' | 'rotation' | 'opacity',
    trackIndex: number,
    sectionIndex: number
  ): void;
  onAddTrack: (trackKind?: TrackKind) => void;
  onAddTracks: (tracks: MediaSection[]) => void;
  onChangeCurrentTrack(newTrackIndex?: number, unselectSection?: boolean): void;
  onDeleteSection(trackIndex: number, sectionIndex: number): void;
  currentSectionIndex?: number;
  currentTrackIndex?: number;
  project: IProject;
  section?: ITrackSection;
  onHide(): void;
}

const MIN_OPACITY = 0;
const MAX_OPACITY = 100;

export class EditSection extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public componentDidUpdate() {
    if (this.props.currentSectionIndex === null) {
      this.props.onHide();
    }
  }

  private handleImageWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.currentSectionIndex == null) return;
    if (this.props.currentTrackIndex == null) return;
    const inputData = parseInt(e.target.value, 10);
    this.props.onChangeUpdateImage(inputData, 'width', this.props.currentTrackIndex, this.props.currentSectionIndex);
  };

  private handleImageHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.currentSectionIndex == null) return;
    if (this.props.currentTrackIndex == null) return;
    const inputData = parseInt(e.target.value, 10);
    this.props.onChangeUpdateImage(inputData, 'height', this.props.currentTrackIndex, this.props.currentSectionIndex);
  };

  private handleImageRotationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.currentSectionIndex == null) return;
    if (this.props.currentTrackIndex == null) return;
    const inputData = parseInt(e.target.value, 10);
    if (isNumber(inputData)) {
      this.props.onChangeUpdateImage(
        inputData,
        'rotation',
        this.props.currentTrackIndex,
        this.props.currentSectionIndex
      );
    }
  };

  private handleImageOpacityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.currentSectionIndex == null) return;
    if (this.props.currentTrackIndex == null) return;
    const inputData = parseInt(e.target.value, 10);
    this.props.onChangeUpdateImage(
      inputData / 100,
      'opacity',
      this.props.currentTrackIndex,
      this.props.currentSectionIndex
    );
  };

  private handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.currentSectionIndex == null) return;
    if (this.props.currentTrackIndex == null) return;
    const inputData = parseFloat(e.target.value);
    this.props.onChangeUpdateVolume(inputData, this.props.currentTrackIndex, this.props.currentSectionIndex);
  };

  private handleAmplitudeDegreeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.currentSectionIndex == null) return;
    if (this.props.currentTrackIndex == null) return;
    const inputData = parseFloat(e.target.value);
    this.props.onChangeUpdateAmplitudeDegree(inputData, this.props.currentTrackIndex, this.props.currentSectionIndex);
  };

  private handleSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.currentSectionIndex == null) return;
    if (this.props.currentTrackIndex == null) return;
    const inputData = parseFloat(e.target.value);
    this.props.onChangeUpdateSize(inputData, this.props.currentTrackIndex, this.props.currentSectionIndex);
  };

  private handleWaveformClick = () => {
    const { currentSectionIndex, currentTrackIndex, project, section } = this.props;
    this.props.onAddTrack(TrackKind.media);
    (async () => {
      if (
        currentSectionIndex !== undefined &&
        currentTrackIndex !== undefined &&
        this.props.project &&
        section &&
        (section.kind === SectionKind.audio || section.kind === SectionKind.video)
      ) {
        const mediaSection = section as IAudioSection | IVideoSection;
        let text = '';
        if (mediaSection.kind === SectionKind.audio) {
          text = mediaSection.text;
        }
        const newSection: IWaveformSection = {
          id: uuidv4(),
          kind: SectionKind.waveform,
          text,
          src: mediaSection.src,
          srcId: mediaSection.srcId,
          lowres: mediaSection.lowres,
          volume: 1,
          start: 0,
          duration: mediaSection.duration,
          startMedia: mediaSection.startMedia,
          endMedia: mediaSection.endMedia,
          originMediaDuration: mediaSection.duration,
          preview: mediaSection.preview,
          size: 1,
          amplitudeDegree: 1,
          width: project.size.width - project.size.width / 10, // little margin
          height: 1,
          x: project.size.width / 20, // to place the margin at each side
          y: project.size.height / 2,
          rotation: 0,
          opacity: 1
        };
        if (project.owner && mediaSection.duration !== mediaSection.originMediaDuration && mediaSection.srcId) {
          const newTrimmedMediaSrc = await trimMedia(
            mediaSection.srcId,
            project.owner,
            mediaSection.startMedia / 1000,
            mediaSection.endMedia / 1000
          );
          if (newTrimmedMediaSrc) {
            newSection.trimmedSrc = newTrimmedMediaSrc;
          }
        }
        this.props.onChangeCurrentTrack(project.tracks.length);
        setTimeout(() => {
          this.props.onDeleteSection(currentTrackIndex, currentSectionIndex);
          this.props.onAddTracks([newSection]);
        });
      }
    })();
  };

  private displayBodyContent = () => {
    switch (this.props.section?.kind) {
      case 'video':
        break;
      case 'audio':
        return (
          <React.Fragment>
            <h3 className="es-title">Volume</h3>
            <div className="value-wrapper">
              <input
                className="es-input es-volume"
                type="number"
                value={(this.props.section as IAudioSection).volume}
                onChange={this.handleVolumeChange}
                min={0}
                max={1}
                step={0.1}
              />
              <input
                className="opacity-range"
                type="range"
                value={(this.props.section as IAudioSection).volume}
                onChange={this.handleVolumeChange}
                min={0}
                max={1}
                step={0.05}
              />
            </div>
            <h3 className="es-title mt-2">WaveForm</h3>
            <div className="waveform-wrapper">
              <input
                className="es-input es-waveform"
                type="button"
                onClick={this.handleWaveformClick}
                value="generate waveform"
              />
            </div>
            <p className="font-weight-bold text-danger text-align-center">
              The Waveform generation feature is in open Beta.
            </p>
            <p className="font-weight-italic text-danger">
              This version only works on Firefox and Google Chrome browsers.
            </p>
            <p className="text-danger"> Thank you for your understanding.</p>
          </React.Fragment>
        );
      case 'waveForm':
        return (
          <>
            <h3 className="es-title">Volume</h3>
            <div className="value-wrapper">
              <input
                className="es-input es-volume"
                type="number"
                value={(this.props.section as IWaveformSection).volume}
                onChange={this.handleVolumeChange}
                min={0}
                max={1}
                step={0.1}
              />
              <input
                className="opacity-range"
                type="range"
                value={(this.props.section as IWaveformSection).volume}
                onChange={this.handleVolumeChange}
                min={0}
                max={1}
                step={0.05}
              />
            </div>
            <br />
            <h3 className="es-title">Amplitude Degree</h3>
            <div className="value-wrapper">
              <input
                className="es-input es-amplitude-degree"
                type="number"
                value={(this.props.section as IWaveformSection).amplitudeDegree}
                onChange={this.handleAmplitudeDegreeChange}
                min={0}
                max={1.25}
                step={0.1}
              />
              <input
                className="opacity-range"
                type="range"
                value={(this.props.section as IWaveformSection).amplitudeDegree}
                onChange={this.handleAmplitudeDegreeChange}
                min={0}
                max={5}
                step={0.25}
              />
            </div>
            <br />
            <h3 className="es-title">Size</h3>
            <div className="value-wrapper">
              <input
                className="es-input es-size"
                type="number"
                value={(this.props.section as IWaveformSection).size}
                onChange={this.handleSizeChange}
                min={0.1}
                max={2}
                step={0.01}
              />
              <input
                className="opacity-range"
                type="range"
                value={(this.props.section as IWaveformSection).size}
                onChange={this.handleSizeChange}
                min={0.1}
                max={2}
                step={0.01}
              />
            </div>
          </>
        );
      case 'text':
        return (
          <EditSectionTextKind
            currentSectionIndex={this.props.currentSectionIndex}
            currentTrackIndex={this.props.currentTrackIndex}
            section={this.props.section as ITextSection}
            onChangeUpdateText={this.props.onChangeUpdateText}
          />
        );
      case 'image':
        return (
          <React.Fragment>
            <div className="es-field es-field-small">
              <label>Width</label>
              <input
                type="number"
                min={0}
                step={1}
                value={(this.props.section as IImageSection).width}
                onChange={this.handleImageWidthChange}
              />
            </div>
            <div className="es-field es-field-small">
              <label>Height</label>
              <input
                type="number"
                min={0}
                step={1}
                value={(this.props.section as IImageSection).height}
                onChange={this.handleImageHeightChange}
              />
            </div>
            <div className="es-field es-field-small">
              <label>Rotation</label>
              <input
                type="number"
                className="es-volume-editor"
                value={(this.props.section as IImageSection).rotation}
                onChange={this.handleImageRotationChange}
                min={0}
                max={360}
                step={1}
              />
            </div>
            <div>
              <label className="mb-0">Opacity :</label>
              <div className="d-flex justify-content-start">
                <div>
                  <input
                    className="es-input es-opacity-input"
                    type="number"
                    min={MIN_OPACITY}
                    max={MAX_OPACITY}
                    value={Math.round((this.props.section as IImageSection).opacity * 100)}
                    onChange={this.handleImageOpacityChange}
                  />
                </div>
                <div className="p-2">
                  <input
                    className="opacity-range"
                    type="range"
                    min={MIN_OPACITY}
                    max={MAX_OPACITY}
                    value={Math.round((this.props.section as IImageSection).opacity * 100)}
                    onChange={this.handleImageOpacityChange}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        throw new Error('error: no section kind found !');
    }
  };

  public render() {
    return (
      <>
        {this.props.section && this.props.section.kind !== SectionKind.videoTransition ? (
          <div className="edit-section">
            <div className="es-header text-center">
              <div className="es-header-title">{this.props.section.kind} properties</div>
            </div>
            <div className="es-body">{this.displayBodyContent()}</div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}
