import { IProject, ITrack, ITrackSection } from '@eolementhe/video-editor-model';

export function getProjectEndTime(project: IProject): number {
  const trackEndTimes = project.tracks.map((track: ITrack): number => {
    const sectionEndTimes = track.sections.map((section: ITrackSection): number => {
      return section.start + section.duration;
    });
    return Math.max(0, ...sectionEndTimes); // 0 because sections can be empty
  }, 0);
  return Math.max(0, ...trackEndTimes);
}
