import './EditorTimeline.scss';

import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash, faVolumeDown, faVolumeMute } from '@fortawesome/free-solid-svg-icons';

import {
  IVisibleTrack,
  Track,
  IVideoTrack,
  IAudioTrack,
  TrackKind,
  TrackSection,
  SectionKind,
  ITrack
} from '@eolementhe/video-editor-model';
import { IMediaTrack, ITimelineProps, ITrackAction, Timeline } from '@eolementhe/react-components';
import { createServerFileUrl } from '../../utils/uploadFiles';

interface IProps extends ITimelineProps {
  onToggleTrackVisibility(track: ITrack, trackIndex: number): void;
  onToggleTrackMute(track: ITrack, trackIndex: number): void;
}

export class EditorTimeline extends React.Component<IProps> {
  private buildActions(track: ITrack): ITrackAction[] {
    //@ts-ignore
    return [this.buildHideAction(track), this.buildMuteAction(track)];
  }

  private buildEmptyAction(icon: IconProp) {
    return {
      title: 'N/A',
      description: 'N/A',
      icon,
      canExecute: false
    };
  }

  private buildHideAction(track: ITrack) {
    if (track.kind === TrackKind.audio) {
      return this.buildEmptyAction(faEye);
    }
    const visibleTrack = track as unknown as IVisibleTrack;
    const isVisible = visibleTrack.visible;
    const showHide = isVisible ? 'Show' : 'Hide';
    return {
      title: `${showHide} Track`,
      description: `${showHide} track in current scene.`,
      icon: isVisible ? faEye : faEyeSlash,
      active: !isVisible,
      execute: this.props.onToggleTrackVisibility
    };
  }

  private buildMuteAction(track: Track) {
    const hasAudio = track.kind === TrackKind.audio || track.kind === TrackKind.video || track.kind === TrackKind.media;
    if (!hasAudio) {
      return this.buildEmptyAction(faVolumeDown);
    }
    const mutableTrack = track as IVideoTrack | IAudioTrack | IMediaTrack;
    const muteUnmute = mutableTrack.muted ? 'Unmute' : 'Mute';
    return {
      title: `${muteUnmute} track`,
      description: `${muteUnmute} track in current scene`,
      icon: mutableTrack.muted ? faVolumeMute : faVolumeDown,
      active: mutableTrack.muted,
      execute: this.props.onToggleTrackMute
    };
  }

  private parseTracks(trackList: ITrack[]): ITrack[] {
    const tracks: any[] = trackList.map((track) => {
      const sections = (track.sections as TrackSection[]).map((section) => {
        if (section.kind === SectionKind.video || section.kind === SectionKind.image) {
          return {
            ...section,
            preview: section.preview ? createServerFileUrl(section.preview) : ''
          };
        }
        return section;
      });
      return { ...track, sections };
    });
    return tracks;
  }

  public render() {
    const timelineProps = {
      ...this.props,
      //@ts-ignore
      tracks: this.parseTracks(this.props.tracks),
      trackActions: this.buildActions.bind(this)
    };
    //@ts-ignore
    return <Timeline {...timelineProps} />;
  }
}
