import { Environment, RecordSource, Store } from 'relay-runtime';
import { getCrossIFrameCookie, CROSS_IFRAME_COOKIE } from '@eolementhe/core';
import { RelayNetworkLayer, urlMiddleware, authMiddleware, uploadMiddleware } from 'react-relay-network-modern/es';
import { getEoleEditServerUrl } from '../utils/ServerUtils';

export const RELAY_ENVIRONMENT = new Environment({
  network: new RelayNetworkLayer([
    urlMiddleware({
      url: () => Promise.resolve(`${getEoleEditServerUrl()}/graphql`)
    }),
    authMiddleware({
      token: async () => {
        const authToken = await getCrossIFrameCookie(CROSS_IFRAME_COOKIE.USER_TOKEN);
        return authToken || '';
      },
      allowEmptyToken: true
    }),
    uploadMiddleware()
  ]),
  store: new Store(new RecordSource())
}) as Environment;
