import { commitMutation } from 'react-relay';

import { RELAY_ENVIRONMENT } from '../api/relay-environment';
import { SAVE_TEXT_PRESET_MUTATION } from '../api/GraphQL';
import { GraphQLSaveTextPresetMutation } from '../__generated__/GraphQLSaveTextPresetMutation.graphql';

export interface TextPreset {
  _id?: string;
  name?: string;
  text: string;
  font: string;
  fontColor: string;
  fontSize: number;
  fontStyle: string;
  backgroundColor: string;
  borderColor: string;
  borderSize: number;
  rotation: number;
}

interface ITextPresetReturn {
  id: string;
  name: string;
}

export function saveTextPreset(texPreset: TextPreset): Promise<ITextPresetReturn> {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    commitMutation<GraphQLSaveTextPresetMutation>(RELAY_ENVIRONMENT, {
      mutation: SAVE_TEXT_PRESET_MUTATION,
      variables: texPreset,
      onCompleted: (res) =>
        resolve({
          id: res.saveTextPreset._id,
          name: res.saveTextPreset.name as string
        }),
      onError: reject
    });
  });
}
