import {
  faExpand,
  faExpandArrowsAlt,
  faGripLines,
  faGripLinesVertical,
  faArrowsAlt,
  faArrowsAltV,
  faArrowsAltH
} from '@fortawesome/free-solid-svg-icons';

import React, { useState, FC, useEffect } from 'react';
import { faSlidersV } from '@fortawesome/pro-regular-svg-icons';
import { startCase, camelCase, lowerCase } from 'lodash';
import { ButtonGroup } from 'react-bootstrap';
import { SectionKind } from '@eolementhe/video-editor-model';
import { IAction, TimelineZoom } from '@eolementhe/react-components';

import { ActionButton } from '../ActionButton';
import { TrackUtils } from './TrackUtils';

import './ViewportControlBar.scss';

export enum SectionMenu {
  video = 'video',
  image = 'image',
  text = 'text',
  audio = 'audio',
  videoTransition = 'videoTransition'
}

interface IProps {
  scale: number;
  hideOffscreen: boolean;
  currentTrackIndex?: number;
  currentSectionIndex?: number;
  onShowAddSectionModal(type: SectionKind | 'edit'): void;
  onChangeScale(scale: number): void;
  onFitShape(): void;
  onFitShapeVertically(): void;
  onFitShapeHorizontally(): void;
  onCenterShapeVertical(): void;
  onCenterShapeHorizontal(): void;
  onChangeHideOffscreen(value: boolean): void;
  onAutoZoom(): void;
  currentSectionKind?: SectionKind | 'edit';
  showAddTrackModal: boolean;
}

export const ViewportControlBar: FC<IProps> = ({
  currentTrackIndex,
  currentSectionIndex,
  onShowAddSectionModal,
  onChangeScale,
  onFitShape,
  onFitShapeVertically,
  onFitShapeHorizontally,
  onCenterShapeVertical,
  onCenterShapeHorizontal,
  onAutoZoom,
  currentSectionKind,
  scale,
  showAddTrackModal
}) => {
  const [innerScale, setInnerScale] = useState(scale);

  useEffect(() => {
    setInnerScale(scale);
    const videoEditor = document.querySelector('.video-editor-preview') as HTMLDivElement;
    const videoEditorInner = document.querySelector('.video-editor-preview-inner') as HTMLDivElement;
    if (videoEditor && videoEditorInner) {
      videoEditor.scrollTo((videoEditorInner.offsetWidth - videoEditor.offsetWidth) / 2, 0);
    }
  }, [scale]);

  const handleScaleChange = (value: number) => {
    setInnerScale(value);
    onChangeScale(value);
  };

  const renderToolbar = () => {
    const addButtonGroup: IAction[] = Object.keys(SectionMenu).map((el) => {
      const type = SectionKind[el as keyof typeof SectionKind];
      const label = type === SectionKind.videoTransition ? 'transition' : type;
      return {
        icon: TrackUtils.getTrackIcon(type),
        title: startCase(camelCase(label)),
        description: `Add a new ${lowerCase(label)} object on the scene.`,
        execute: () => onShowAddSectionModal(type),
        selected: type === currentSectionKind && showAddTrackModal
      };
    });
    const hasSelection = currentTrackIndex != null;
    const groups: IAction[][] = [
      addButtonGroup,
      [
        {
          icon: faSlidersV,
          title: 'Section settings',
          description: "Manage the selected section's settings",
          execute: () => onShowAddSectionModal('edit'),
          canExecute: currentSectionIndex != null && currentSectionKind !== SectionKind.videoTransition
        },
        {
          icon: faExpandArrowsAlt,
          title: 'Fit',
          description: 'Change object size and position to fit the scene.',
          child: [
            {
              icon: faArrowsAltV,
              title: 'Fit Vertically',
              execute: onFitShapeVertically
            },
            {
              icon: faArrowsAltH,
              title: 'Fit horizontally',
              execute: onFitShapeHorizontally
            },
            {
              icon: faArrowsAlt,
              title: 'Stretch vertically and horizontally',
              execute: onFitShape
            }
          ],
          canExecute: hasSelection
        },
        {
          icon: faGripLinesVertical,
          title: 'Center Horizontally',
          description: 'Move object at the horizontal center of the scene.',
          execute: onCenterShapeHorizontal,
          canExecute: hasSelection
        },
        {
          icon: faGripLines,
          title: 'Center Vertically',
          description: 'Move object at the vertical center of the scene.',
          execute: onCenterShapeVertical,
          canExecute: hasSelection
        }
      ]
    ];

    return groups.map((group, i) => (
      <ButtonGroup key={i} className="mb-2 d-flex flex-column" style={{ width: 35 }}>
        {group.map((action, j) => (
          <ActionButton
            key={j}
            action={action}
            className={currentSectionKind === action.title.toLowerCase() ? 'highlighted' : ''}
          />
        ))}
      </ButtonGroup>
    ));
  };

  const renderZoomToolbar = () => {
    const content = (
      <div className="d-flex flex-column">
        <div className="timeline-zoom-display p-0 border-top border-bottom d-flex">
          <span className="m-auto">{Math.round(innerScale * 100)} %</span>
        </div>
        <ActionButton
          action={{
            title: 'Auto Zoom',
            description: 'Automatically zoom to fit workspace.',
            icon: faExpand,
            execute: onAutoZoom
          }}
        />
      </div>
    );
    return <TimelineZoom value={innerScale} onChange={handleScaleChange} extraButtons={content} min={0.01} max={5} />;
  };

  return (
    <div className="vp-control-bar d-flex p-1 border-bottom flex-column">
      {renderToolbar()}
      {renderZoomToolbar()}
    </div>
  );
};
