import React, { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { doShowProjectBuildingModal } from '../../redux/actions';
import { EoleEditStateEntry } from '../../redux/types';

export const ProjectBuildingModal: FC = () => {
  const isProjectBuildingModalShown = useSelector((state: EoleEditStateEntry) => state.ee.isProjectBuildingModalShown);
  const dispatch = useDispatch();

  const handleHide = React.useCallback(() => {
    dispatch(doShowProjectBuildingModal(false));
  }, [dispatch]);

  return (
    <Modal show={isProjectBuildingModalShown} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Project now building</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: 'calc(100vh - 210px)'
        }}
        className="d-flex flex-column"
      >
        <div className="font-weight-bold">
          Your project is now building, you will receive an email when it is done !
        </div>
        <div>You can also see build status by clicking on the toothed wheel in navbar.</div>
      </Modal.Body>
      <Modal.Footer className="pt-1 pb-0 pl-0 pr-0">
        <Button variant="success" onClick={handleHide}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
