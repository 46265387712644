import { Timecode } from '@eolementhe/core';

export function prettyTime(milliseconds: number) {
  if (milliseconds <= 0) {
    return '00m00s';
  }
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const seconds = Math.floor((milliseconds / 1000) % 60);
  return (
    (hours ? String(hours).padStart(2, '0') + 'h' : '') +
    String(minutes).padStart(2, '0') +
    'm' +
    String(seconds).padStart(2, '0') +
    's'
  );
}

export function TimecodeFormatter(duration: number, frameRate: number) {
  return Timecode.fromTime(duration, frameRate ?? 25).toFString();
}
