import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRelayEnvironment, fetchQuery } from 'react-relay/hooks';
import { GraphQLTaggedNode } from 'react-relay';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBars, faThLarge, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase, camelCase } from 'lodash';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FileResource } from '@eolementhe/video-editor-model';
import { ViewType } from './ViewType';
import { FileView } from './FileView';
import { IconView } from './IconView';
import { ListView } from './ListView';
import { ResourceUtils } from './ResourceUtils';
import { ThumbnailView } from './ThumbnailView';
import { fileTypeFromMime } from './FileType';
import { GET_FILES_QUERY } from '../../api/GraphQL';
import { GraphQLGetFilesQuery } from '../../__generated__/GraphQLGetFilesQuery.graphql';
import { IDirectoryViewProps } from './IDirectoryViewProps';
import { handleError } from '../../utils/handleError';
import { doResetSelectedResources, doSelectResources, doSetResourceList } from '../../redux/actions';
import { EoleEditStateEntry } from '../../redux/types';
import './ResourceBrowser.scss';
import { getEoleEditRight } from '../../utils/Userprofile';
import RemoveAlert from './RemoveAlert';

type FontAwesomeIconRotation = 90 | 180 | 270;

interface ResourceBrowserProps {
  canDownload?: boolean;
  canRemove?: boolean;
  resourceType?: FileResource['resourceKind'];
  canSelect?: boolean;
  onAddTracks?(resource?: FileResource): void;
  defaultViewType?: ViewType;
}

interface ResourceBrowserFooterProps {
  onRemoveResources?: IDirectoryViewProps['onRemoveResources'];
}
const ResourceBrowserFooter: FC<ResourceBrowserFooterProps> = ({ onRemoveResources }) => {
  const selectedResources = useSelector((state: EoleEditStateEntry) => state.ee.selectedResources as FileResource[]);
  const handleRemove = () => {
    if (onRemoveResources) {
      onRemoveResources(selectedResources);
    }
  };
  return (
    <div className="resource-browser-footer mt-2">
      {onRemoveResources && (
        <div className="d-flex justify-content-end">
          <button onClick={handleRemove} className="btn btn-primary" disabled={!selectedResources.length}>
            Remove Selected
          </button>
        </div>
      )}
    </div>
  );
};

export const ResourceBrowser: FC<ResourceBrowserProps> = ({
  canSelect,
  canDownload,
  canRemove,
  defaultViewType,
  resourceType,
  onAddTracks
}) => {
  const unmountedRef = useRef(false); //used to avoid async function to update state after component unmount
  const dispatch = useDispatch();
  const selectedResources = useSelector((state: EoleEditStateEntry) => state.ee.selectedResources as FileResource[]);
  const project = useSelector((state: EoleEditStateEntry) => state.ee.project);
  const resources = useSelector((state: EoleEditStateEntry) => state.ee.resourceList);
  const environment = useRelayEnvironment();
  const [resourcesToRemove, setResourcesToRemove] = useState<undefined | FileResource[]>(undefined);

  const [displayProjectOnly, setDisplayProjectOnly] = useState(false);
  const isRightExpired = getEoleEditRight().expiry.getTime() < new Date().getTime();
  if (isRightExpired) {
    canDownload = false;
  }
  const [viewType, setViewType] = React.useState(defaultViewType || ViewType.list);
  const [currentResourceId, setCurrentResourceId] = React.useState('');

  const fetchAndUpdateResources = useCallback((): void => {
    (async (): Promise<void> => {
      if (unmountedRef.current) {
        return;
      }
      try {
        const result = await fetchQuery<GraphQLGetFilesQuery>(environment, GET_FILES_QUERY as GraphQLTaggedNode, {
          mime: resourceType
        }).toPromise();
        let files: FileResource[] = [];
        if (result && result.getFiles && result.getFiles.length) {
          files = result.getFiles.reduce<FileResource[]>(
            (
              accumulator,
              { _id, mime, name, src, lowres, preview, size, duration, width, height, ingestInEolementhe }
            ) => {
              const resourceKind = fileTypeFromMime(mime);
              if (resourceKind && _id && name) {
                accumulator.push({
                  resourceKind: fileTypeFromMime(mime),
                  _id,
                  name,
                  size,
                  src,
                  lowres,
                  preview,
                  title: name,
                  duration,
                  width,
                  height,
                  ingestInEolementhe
                } as FileResource);
              }
              return accumulator;
            },
            []
          );
        }

        if (unmountedRef.current) {
          return;
        }
        dispatch(doSetResourceList(files));
      } catch (err) {
        handleError(err, 'Cannot get resources');
      }
    })();
  }, [environment, resourceType, dispatch]);

  useEffect(() => {
    fetchAndUpdateResources();
    dispatch(doResetSelectedResources());
    return () => {
      unmountedRef.current = true;
      dispatch(doResetSelectedResources());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickResource = React.useCallback(
    (resource) => {
      if (!isRightExpired) {
        setCurrentResourceId(resource._id);
      }
    },
    [isRightExpired, setCurrentResourceId]
  );

  const handleSelectResources = (resources: FileResource[], areSelected: boolean): void => {
    dispatch(doSelectResources(resources, areSelected));
  };

  const handleRemoveResources = useCallback((resources: FileResource[]) => {
    setResourcesToRemove(resources);
  }, []);
  const handleBackClick = (): void => {
    setCurrentResourceId('');
  };
  const onChangeViewType = setViewType;

  const isResourceInProject = (resource: FileResource): boolean => {
    if (!project) {
      return false;
    }
    if (!resource || !resource.preview) {
      return false;
    }
    for (const track of project.tracks) {
      if (track && track.sections) {
        for (const section of track.sections) {
          if (section?.preview && section.preview.includes(resource.preview)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const renderDropdownContent = (def: {
    type: ViewType;
    icon: IconProp;
    iconRotation?: FontAwesomeIconRotation;
  }): ReactElement => {
    return (
      <>
        <FontAwesomeIcon size="sm" icon={def.icon} rotation={def.iconRotation} />
        {` ${startCase(camelCase(def.type))}`}
      </>
    );
  };

  const renderDropdownItem = (def: {
    type: ViewType;
    icon: IconProp;
    iconRotation?: FontAwesomeIconRotation;
  }): ReactElement => {
    return (
      <Dropdown.Item key={def.type} eventKey={def.type}>
        {renderDropdownContent(def)}
      </Dropdown.Item>
    );
  };

  const renderResourceList = (): ReactElement => {
    let children = ResourceUtils.sortResources(resources);

    //Need to filter again by resourceType because resources are shared between all panel (for reactivity)
    if (resourceType) {
      children = children.filter((elem) => elem.resourceKind === resourceType);
    }

    if (displayProjectOnly) {
      children = children.filter((elem) => isResourceInProject(elem));
    }

    switch (viewType) {
      case ViewType.list:
        return (
          <ListView
            canSelect={canSelect}
            onAddTracks={onAddTracks}
            onSelectResources={handleSelectResources}
            onClickResource={onClickResource}
            resources={children}
            canDownload={canDownload}
            onRemoveResources={canRemove ? handleRemoveResources : undefined}
            selectedResources={selectedResources}
          />
        );
      case ViewType.icons:
        return (
          <IconView
            canSelect={canSelect}
            onSelectResources={handleSelectResources}
            onClickResource={onClickResource}
            resources={children}
            canDownload={canDownload}
            onRemoveResources={canRemove ? handleRemoveResources : undefined}
            selectedResources={selectedResources}
          />
        );
      case ViewType.thumbnails:
        return (
          <ThumbnailView
            className="flex-fill overflow-y-auto min-height-0"
            canSelect={canSelect}
            onAddTracks={onAddTracks}
            onSelectResources={handleSelectResources}
            onClickResource={onClickResource}
            resources={children}
            canDownload={canDownload}
            onRemoveResources={canRemove ? handleRemoveResources : undefined}
            selectedResources={selectedResources}
          />
        );
      default:
        throw new Error(`Unsupported view type ${viewType}`);
    }
  };

  const renderHeader = (): ReactElement => {
    const views = [
      { type: ViewType.list, icon: faBars },
      { type: ViewType.thumbnails, icon: faThLarge }
    ];
    const titleDef = views.find((view) => view.type === viewType) || views[0];

    const changeViewTitle = renderDropdownContent(titleDef);
    return (
      <div>
        <div className="d-flex flex-row justify-content-between align-items-center pb-1 w-100">
          {project && (
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={displayProjectOnly}
                onChange={(): void => setDisplayProjectOnly(!displayProjectOnly)}
              />
              <span className="ml-1">Show project only resources</span>
            </div>
          )}

          <DropdownButton
            id="resource-browser-listview"
            variant="outline-primary"
            size="sm"
            title={changeViewTitle}
            onSelect={onChangeViewType}
          >
            {views.map(renderDropdownItem)}
          </DropdownButton>
        </div>
      </div>
    );
  };

  return (
    <div className="resource-browser flex-fill d-flex flex-column min-height-0">
      {currentResourceId ? (
        <>
          <FontAwesomeIcon icon={faArrowLeft} className="m-1 res-page-return-button" onClick={handleBackClick} />
          <FileView file={resources.find((resource) => resource._id === currentResourceId) as FileResource} />
        </>
      ) : (
        <>
          {renderHeader()}
          {renderResourceList()}
          <ResourceBrowserFooter onRemoveResources={canRemove ? handleRemoveResources : undefined} />
          <RemoveAlert
            show={!!resourcesToRemove?.length}
            resourcesToRemove={resourcesToRemove}
            onClose={(removed) => {
              setResourcesToRemove(undefined);
              if (removed) {
                dispatch(doResetSelectedResources());
                fetchAndUpdateResources();
              }
            }}
          />
        </>
      )}
    </div>
  );
};
