"use strict";

var _slicedToArray = require("/home/node/temp/common/temp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typedEntries = void 0;
/**
 * This is a version of `Object.entries()` with keys correctly typed.
 *
 * @remarks
 * The `Object.entries()` method returns an array of a given
 * object's own enumerable string-keyed property `[key, value]` pairs, in the
 * same order as that provided by a `for...in` loop (the difference being that a
 * for-in loop enumerates properties in the prototype chain as well).
 * The order of the array returned by `Object.entries()` does not depend on how
 * an object is defined.
 * If there is a need for certain ordering then the array should be sorted first
 * like `Object.entries(obj).sort((a, b) => b[0].localeCompare(a[0]));`.
 *
 * This function does not prevents non-typed keys to be returned (e.g.
 * if the object is an instance of an interface with additional properties).
 *
 * @param obj - The object whose own enumerable string-keyed property [key, value]
 * pairs are to be returned.
 * @returns An array of the given object's own enumerable string-keyed property
 * [key, value] pairs.
 *
 * @example List entries in object `{ a: "foo", b: "bar" }`:
 *
 * ```ts
 * const obj = { a: "foo", b: "bar" };
 *
 * // error TS7053: Element implicitly has an 'any' type because expression of
 * // type 'string' can't be used to index type '{ a: string; b: string; }'.
 * // No index signature with a parameter of type 'string' was found on type
 * // '{ a: string; b: string; }'.
 * const untypedKey = Object.entries(obj)[0][0];
 * obj[untypedKey];
 *
 * // OK since type of entry[0] is `keyof typeof obj`.
 * const typedKey = typedEntries(obj)[0][0];
 * obj[typedKey];
 * ```
 *
 * @public
 */
function typedEntries(obj) {
  return Object.entries(obj).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    return [key, value];
  });
}
exports.typedEntries = typedEntries;