var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import { getMpegDashManifest, getTimeline } from '../methods';
import { StreamController } from './StreamController';
var MpegDashStream = function () {
  function MpegDashStream(url) {
    this.manifestUrl = '';
    this.tracks = [];
    this.url = url;
  }
  MpegDashStream.prototype.getTracks = function () {
    return this.tracks;
  };
  MpegDashStream.prototype.parseManifest = function () {
    return getMpegDashManifest(this.url);
  };
  MpegDashStream.prototype.parseTracks = function () {
    return __awaiter(this, void 0, void 0, function () {
      var manifest;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, this.parseManifest()];
          case 1:
            manifest = _a.sent();
            this.manifestUrl = manifest.url;
            this.tracks = manifest.adaptations.map(function (adaptation) {
              var representation = adaptation.representation[0];
              var mimeCodec = adaptation.mimeType + "; codecs=\"" + (representation === null || representation === void 0 ? void 0 : representation.codecs) + "\"";
              var duration = representation.totalDuration / adaptation.timescale;
              var chunkList = _this.getChunkList(adaptation);
              var stream = new StreamController(chunkList);
              stream.initStream(mimeCodec, duration);
              return {
                type: adaptation.contentType,
                id: stream.getPlayerUrl(),
                url: stream.getPlayerUrl(),
                isMuted: false,
                canPlay: false,
                width: adaptation.width,
                height: adaptation.height,
                channels: []
              };
            });
            return [2];
        }
      });
    });
  };
  MpegDashStream.prototype.getChunkList = function (track) {
    var chunkList = [];
    var representation = track === null || track === void 0 ? void 0 : track.representation[0];
    var representationId = representation.id;
    chunkList.push(this.manifestUrl + "/" + this.parseTemplate(track.initializationTemplate, representationId));
    var segmentsUrl = this.getSegments(track, representationId);
    segmentsUrl.map(function (url) {
      return chunkList.push(url);
    });
    return chunkList;
  };
  MpegDashStream.prototype.getSegments = function (track, representationId) {
    var _this = this;
    var timeline = getTimeline(track);
    return timeline.map(function (time) {
      return "" + _this.manifestUrl + _this.parseTemplate(track.mediaTemplate, representationId, time);
    });
  };
  MpegDashStream.prototype.parseTemplate = function (template, representationId, time) {
    var replace = template.replace('$RepresentationID$', representationId);
    if (time !== undefined) {
      return replace.replace('$Time$', time.toString());
    }
    return replace;
  };
  return MpegDashStream;
}();
export { MpegDashStream };