import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase, camelCase } from 'lodash';
import React from 'react';
import { Button } from 'react-bootstrap';
import {
  IProject,
  SectionKind,
  TrackSection,
  IVideoTransitionSection,
  IImageSection,
  TrackKind
} from '@eolementhe/video-editor-model';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TrackFactory } from '../TrackFactory';
import { TrackUtils } from '../TrackUtils';
import { AddImageTrack } from './AddImageTrack';
import { AddVideoTrack } from './AddVideoTrack';
import { AddAudioTrack } from './AddAudioTrack';
import { AddVideoTransitionTrack } from './AddVideoTransitionTrack';
import { EditSection } from './EditSection';
import { DEFAULT_TRANSITION_DURATION } from '../../../utils/Constants';

import './AddTrackModal.scss';
import { SectionMenu } from '../ViewportControlBar';

interface IProps {
  project: IProject;
  show: boolean;
  trackKind?: SectionKind | 'edit';
  defaultDuration: number;
  defaultWidth: number;
  defaultHeight: number;
  currentTrackIndex?: number;
  currentSectionIndex?: number;
  onHide(): void;
  onChangeCurrentTrack(newTrackIndex?: number, unselectSection?: boolean): void;
  automaticFitShape(project: IProject, section: IImageSection): void;
  onAddTracks(tracks: TrackSection[]): void;
  onAddTrack: (trackKind?: TrackKind) => void;
  onChangeUpdateText(
    content: string,
    action: 'text' | 'fontColor' | 'fontStyle' | 'borderColor' | 'backgroundColor' | 'borderSize' | 'font' | 'fontSize',
    trackIndex: number,
    sectionIndex: number
  ): void;
  onChangeUpdateVolume(volume: number, trackIndex: number, sectionIndex: number): void;
  onChangeUpdateSize(size: number, trackIndex: number, sectionIndex: number): void;
  onChangeUpdateAmplitudeDegree(amplitudeDegree: number, trackIndex: number, sectionIndex: number): void;
  onChangeUpdateImage(
    content: string | number,
    action: 'width' | 'height' | 'rotation' | 'opacity',
    trackIndex: number,
    sectionIndex: number
  ): void;
  onChangeUpdateTransition(
    content: string | number,
    action: 'durationSec',
    trackIndex: number,
    sectionIndex: number,
    oldDuration?: number
  ): void;
  onDeleteSection(trackIndex: number, sectionIndex: number): void;
}

interface IState {
  sectionKind?: SectionKind | 'edit';
}

export class AddTrackModal extends React.PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      sectionKind: props.trackKind
    };
    this.handleShowTrackModal = this.handleShowTrackModal.bind(this);
    this.handleHideTrackModal = this.handleHideTrackModal.bind(this);
  }

  public componentDidUpdate(oldProps: IProps) {
    if (this.props.trackKind !== oldProps.trackKind) {
      this.setState({ sectionKind: this.props.trackKind });
    }
  }

  private async handleShowTrackModal(sectionKind: SectionKind) {
    switch (sectionKind) {
      case SectionKind.text:
        const text = TrackFactory.createText(this.props.defaultDuration);
        this.props.onAddTracks([text]);
        this.props.onHide();
        break;
      default:
        this.setState({ sectionKind });
        break;
    }
  }

  private handleHideTrackModal() {
    this.setState({
      sectionKind: undefined
    });
    this.props.onHide();
  }

  private createImageTrackComponent() {
    return (
      <AddImageTrack
        onAddTracks={this.props.onAddTracks}
        project={this.props.project}
        automaticFitShape={this.props.automaticFitShape}
      />
    );
  }

  private createVideoTrackComponent() {
    const { project } = this.props;
    return (
      <AddVideoTrack
        defaultWidth={this.props.defaultWidth}
        defaultHeight={this.props.defaultHeight}
        projectType={project.isSameVideoSizeProject}
        onAddTracks={this.props.onAddTracks}
      />
    );
  }

  private createAudioTrackComponent() {
    return <AddAudioTrack onAddTracks={this.props.onAddTracks} />;
  }

  private createVideoTransitionComponent() {
    const { project, currentTrackIndex, currentSectionIndex } = this.props;
    let section;
    let durationTransitionSelected = DEFAULT_TRANSITION_DURATION;
    if (
      currentTrackIndex != null &&
      currentSectionIndex != null &&
      project &&
      project.tracks &&
      project.tracks[currentTrackIndex] &&
      project.tracks[currentTrackIndex].sections &&
      project.tracks[currentTrackIndex].sections[currentSectionIndex]
    ) {
      section = project.tracks[currentTrackIndex].sections[currentSectionIndex];
      if (section.kind === SectionKind.videoTransition) {
        const sectionSelected = section as IVideoTransitionSection;
        if (sectionSelected.effect && sectionSelected.effect.durationSec) {
          durationTransitionSelected = sectionSelected.effect.durationSec;
        }
      }
    }
    return (
      <AddVideoTransitionTrack
        currentTrackIndex={this.props.currentTrackIndex}
        currentSectionIndex={this.props.currentSectionIndex}
        onChangeUpdateTransition={this.props.onChangeUpdateTransition}
        section={section?.kind === SectionKind.videoTransition ? (section as IVideoTransitionSection) : undefined}
        durationTransitionSelected={durationTransitionSelected}
      />
    );
  }

  private createEditSectionComponent() {
    const { project, currentTrackIndex, currentSectionIndex } = this.props;
    let section;
    if (
      currentTrackIndex != null &&
      currentSectionIndex != null &&
      project &&
      project.tracks &&
      project.tracks[currentTrackIndex] &&
      project.tracks[currentTrackIndex].sections &&
      project.tracks[currentTrackIndex].sections[currentSectionIndex]
    ) {
      section = project.tracks[currentTrackIndex].sections[currentSectionIndex];
    }

    return (
      <EditSection
        onChangeUpdateText={this.props.onChangeUpdateText}
        onChangeUpdateVolume={this.props.onChangeUpdateVolume}
        onChangeUpdateSize={this.props.onChangeUpdateSize}
        onChangeUpdateAmplitudeDegree={this.props.onChangeUpdateAmplitudeDegree}
        onChangeUpdateImage={this.props.onChangeUpdateImage}
        onChangeCurrentTrack={this.props.onChangeCurrentTrack}
        onAddTrack={this.props.onAddTrack}
        onAddTracks={this.props.onAddTracks}
        onDeleteSection={this.props.onDeleteSection}
        currentTrackIndex={this.props.currentTrackIndex}
        currentSectionIndex={this.props.currentSectionIndex}
        onHide={this.props.onHide}
        section={section}
        project={this.props.project}
      />
    );
  }

  private createTrackKindTabs(type: SectionKind | 'edit') {
    const { project, currentTrackIndex, currentSectionIndex} = this.props;
    switch (type) {
      case 'edit':
        if(currentTrackIndex !== undefined && currentSectionIndex !== undefined){
            return this.createEditSectionComponent();
          }
        break;
      case SectionKind.image:
        return this.createImageTrackComponent();
      case SectionKind.video:
        return this.createVideoTrackComponent();
      case SectionKind.audio:
        return this.createAudioTrackComponent();
      case SectionKind.videoTransition:
        if (project.isSameVideoSizeProject === 'uncut') {
          return this.createVideoTransitionComponent();
        }
        break;
      default:
        return <div>{`Unsupported track tab from section type "${type}"`}</div>;
    }
  }

  public render() {
    return (
      <div className="add-tracks-modal overflow-auto" style={{ display: this.props.show ? 'flex' : 'none' }}>
        <FontAwesomeIcon icon={faTimes} className="modal-close-button" onClick={this.props.onHide} />
        <div className="d-flex flex-column add-tracks-modal-body">
          {this.state.sectionKind !== undefined
            ? this.createTrackKindTabs(this.state.sectionKind)
            : Object.keys(SectionMenu).map((key: string) => {
                const type = SectionKind[key as keyof typeof SectionKind];
                return (
                  <Button
                    key={key}
                    type="button"
                    variant="primary"
                    block
                    onClick={() => this.handleShowTrackModal(type)}
                  >
                    <FontAwesomeIcon icon={TrackUtils.getTrackIcon(type)} size="2x" />
                    <br />
                    <p>{startCase(camelCase(key))}</p>
                  </Button>
                );
              })}
        </div>
      </div>
    );
  }
}
