import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Resource, ResourceType, FileResource } from '@eolementhe/video-editor-model';
import { createServerFileUrl } from '../../utils/uploadFiles';
import './ResourceGalleryModal.scss';

interface IProps {
  resource: Resource;
  isSelected?: boolean;
  onPrevious?: () => void;
  onNext?: () => void;
  onHide: () => void;
  onSelect?: () => void;
}

export class ResourceGalleryModal extends React.PureComponent<IProps> {
  public render() {
    const { onHide, onNext, onPrevious, onSelect, resource, isSelected } = this.props;
    const selectButtonVariant = isSelected ? 'danger' : 'success';
    const selectButtonText = isSelected ? 'Unselect' : 'Select';
    const isImage = resource.resourceKind === ResourceType.image;
    const content = <ResourcePreview resource={resource} />;
    return (
      <div className="resource-gallery-modal">
        <div className="position-fixed w-100 h-100 resource-gallery-bg" />
        {isImage && content}
        <div className="position-fixed w-100 h-100 d-flex flex-column p-3 resource-gallery-controls">
          <div className="d-flex flex-row position-relative">
            <h3 className="flex-fill text-truncate">{resource.name}</h3>
            <HideButton onClick={onHide} />
          </div>
          <div className="flex-fill d-flex flex-row">
            {onPrevious ? <PreviousButton onClick={onPrevious} /> : <></>}
            <div className="flex-fill d-flex flex-column min-width-0 min-height-0">
              {isImage ? <div className="flex-fill" /> : content}
              {isSelected ? (
                <Button className="align-self-center" variant={selectButtonVariant} onClick={onSelect}>
                  {selectButtonText}
                </Button>
              ) : (
                <></>
              )}
            </div>
            {onNext ? <NextButton onClick={onNext} /> : <></>}
          </div>
        </div>
      </div>
    );
  }
}

const HideButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button type="button" className="btn resource-gallery-btn" onClick={onClick}>
    <FontAwesomeIcon icon={faTimes} size="2x" />
  </button>
);

const PreviousButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button onClick={onClick} className="btn align-self-center resource-gallery-btn">
    <FontAwesomeIcon icon={faChevronLeft} size="3x" />
  </button>
);

const NextButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button onClick={onClick} className="btn mt-1 align-self-center resource-gallery-btn">
    <FontAwesomeIcon icon={faChevronRight} size="3x" />
  </button>
);

const ResourcePreview: React.FC<{ resource: Resource }> = ({ resource }) => {
  switch (resource.resourceKind) {
    case ResourceType.image:
    case ResourceType.audio:
    case ResourceType.video:
      return <FilePreview file={resource as FileResource} />;
    case ResourceType.directory:
    default:
      throw new Error('Unsupported resource type');
  }
};

const FilePreview: React.FC<{ file: FileResource }> = ({ file }) => {
  const fullUrl = file.lowres || file.src;
  switch (file.resourceKind) {
    case ResourceType.image:
      return <ImagePreview url={createServerFileUrl(fullUrl)} />;
    case ResourceType.video:
      return <VideoPreview url={createServerFileUrl(fullUrl)} />;
    case ResourceType.audio:
      return <AudioPreview url={createServerFileUrl(fullUrl)} />;
    default:
      return <></>;
  }
};

const ImagePreview: React.FC<{ url: string }> = ({ url }) => (
  <div className="position-fixed w-100 h-100 d-flex flex-column resource-gallery-img-body">
    <div className="flex-fill resource-gallery-img" style={{ backgroundImage: `url(${url})` }} />
  </div>
);

const VideoPreview: React.FC<{ url: string }> = ({ url }) => (
  <div className="embed-responsive flex-fill">
    <video className="resource-gallery-video" src={url} controls />
  </div>
);

const AudioPreview: React.FC<{ url: string }> = ({ url }) => <audio src={url} controls />;
