export const getEolementheWebUrl = () => {
  const { host, protocol } = document.location;
  const subHost = host.split('.').slice(-2).join('.');
  if (host.startsWith('localhost')) {
    return `${protocol}//localhost:3000`;
  }
  if (host.startsWith('local')) {
    return `${protocol}//local.${subHost}`;
  }
  return `${protocol}//www.${subHost}`;
};

export const getEoleEditServerUrl = () => {
  const { host, protocol } = document.location;
  const subHost = host.split('.').slice(-2).join('.');
  if (host.startsWith('localhost')) {
    return `${protocol}//localhost:3401`;
  }
  if (host.startsWith('local')) {
    return `${protocol}//local-edit.${subHost}/api`;
  }
  return `${protocol}//edit.${subHost}/api`;
};
