/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLGetFileQueryVariables = {
    id: string;
};
export type GraphQLGetFileQueryResponse = {
    readonly getFile: {
        readonly _id: string;
        readonly type: string;
        readonly mime: string;
        readonly name: string;
        readonly size: number;
        readonly duration: number | null;
        readonly width: number | null;
        readonly height: number | null;
        readonly src: string;
        readonly lowres: string | null;
        readonly preview: string | null;
    } | null;
};
export type GraphQLGetFileQuery = {
    readonly response: GraphQLGetFileQueryResponse;
    readonly variables: GraphQLGetFileQueryVariables;
};



/*
query GraphQLGetFileQuery(
  $id: ID!
) {
  getFile(id: $id) {
    _id
    type
    mime
    name
    size
    duration
    width
    height
    src
    lowres
    preview
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "File",
    "kind": "LinkedField",
    "name": "getFile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "duration",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "width",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "height",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "src",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lowres",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "preview",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLGetFileQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GraphQLGetFileQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3a14939e77c9e48b15cdf9455d77594a",
    "id": null,
    "metadata": {},
    "name": "GraphQLGetFileQuery",
    "operationKind": "query",
    "text": "query GraphQLGetFileQuery(\n  $id: ID!\n) {\n  getFile(id: $id) {\n    _id\n    type\n    mime\n    name\n    size\n    duration\n    width\n    height\n    src\n    lowres\n    preview\n  }\n}\n"
  }
};
})();
(node as any).hash = '660897d082e1579d9a32a30354fd757e';
export default node;
