import './VideoEditor.scss';
import { Timecode } from '@eolementhe/core';
import { faCog, faSlidersH, faBars } from '@fortawesome/free-solid-svg-icons';
import { fetchQuery } from 'react-relay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Konva from 'konva';
import React from 'react';
import { Nav, Tab, TabContainer } from 'react-bootstrap';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import {
  IKonvaNodeBounds,
  IProject,
  TrackKind,
  SectionKind,
  TrackSection,
  IVideoTrack,
  IAudioTrack,
  ITrackSection,
  IMediaTrack,
  IVideoSection,
  IAudioSection,
  FileResource,
  IVideoResource,
  ResourceType,
  IAudioResource,
  IImageResource,
  IImageSection,
  ITrack,
  TransitionEffect,
  IWaveformSection
} from '@eolementhe/video-editor-model';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { debounce, isNumber } from 'lodash';
import { showOpenFileDialog } from '@eolementhe/react-components';
import { IKonvaAnimationFrame } from './IKonvaAnimationFrame';
import { AddTrackModal } from './AddTrack/AddTrackModal';
import { RELAY_ENVIRONMENT } from '../../api/relay-environment';
import { CommentPanel } from './CommentPanel';
import { APP_NAME, DURATION_PERCENT } from './Constants';
import { EditorTimeline } from './EditorTimeline';
import { JobQueuePanel } from './JobQueuePanel';
import { ProjectSettingsPanel } from './ProjectSettingsPanel';
import { TrackFactory } from './TrackFactory';
import { Viewport } from './Viewport/Viewport';
import { ViewportControlBar } from './ViewportControlBar';
import {
  doSetSectionDuration,
  doSetSectionStart,
  doCenterShape,
  doFitShape,
  doUpdateProjectDuration,
  doSetCurrentSection,
  doSetTextSectionProperty,
  doSetImageSectionProperty,
  doSetTransitionSectionProperty,
  doSectionSplit,
  doSetTrackProperty,
  doDeleteCurrentTrack,
  doAddNewTrack,
  doDeleteCurrentSection,
  doSetVideoSectionProperty,
  doSetAudioSectionProperty,
  doAddNewSection,
  doSetProjectDuration,
  doSetCurrentTrack,
  doMoveTrack,
  doSetSectionGeometry,
  CenterShapeDirection,
  FitDimensions,
  doResetSelectedResources,
  doDeleteSection,
  doAddVideoTransitionPlaceHolder,
  doSetElseTransitionSectionProperty,
  doSetSectionEndMedia,
  doSetSectionStartMedia,
  doSetWaveformSectionProperty,
  doLoadProject,
  doSetSectionSrcProperties,
  doSaveProject,
  doSetSectionOriginMediaDuration
} from '../../redux/actions';

import { DraggableResource, EoleEditStateEntry } from '../../redux/types';
import { TextSectionPropertyName, TextSectionPropertyValue } from '../../types/TextSectionProperty';
import { ImageSectionPropertyName, ImageSectionPropertyValue } from '../../types/ImageSectionProperty';
import { TransitionSectionPropertyName, TransitionSectionPropertyValue } from '../../types/TransitionSectionProperty';
import { getEoleEditServerUrl } from '../../utils/ServerUtils';
import { DEFAULT_TRANSITION_DURATION, DEFAULT_TRANSITION_TYPE } from '../../utils/Constants';
import {
  GraphQLGetProjectQuery,
  GraphQLGetProjectQueryResponse
} from '../../__generated__/GraphQLGetProjectQuery.graphql';
import { GET_PROJECT_QUERY } from '../../api/GraphQL';
import { handleError } from '../../utils/handleError';
import { acceptableInputFileExtension, FileUtils } from '../../utils/FileUtils';
import { ResourceUtils } from '../ResourceBrowser/ResourceUtils';
import { uploadFile, UploadProgress } from '../../utils/uploadFiles';
import { UploadStates } from './AddTrack/AddPersonalFileTab';

enum KEYBOARD_CODES {
  DELETE = 'Delete',
  BACKSPACE = 'Backspace',
  SPACE = 'Space',
  RIGHT_ARROW = 'ArrowRight',
  LEFT_ARROW = 'ArrowLeft',
  KEY_M = 'KeyM',
  KEY_C = 'KeyC'
}

const EXCEPTION_TAGS = ['INPUT', 'TEXTAREA', 'BUTTON'];

interface IProps {
  project?: IProject;
  currentTrackIndex?: number;
  currentSectionIndex?: number;
  selectedResources?: DraggableResource[];
  dispatch: Dispatch;
}

interface IState {
  isPlaying: boolean;
  currentTime: number;
  targetTime: number;
  showAddTrackModal: boolean;
  addSectionKind?: SectionKind | 'edit';
  outputVolumes: (number | undefined)[][];
  scale: number;
  hideOffscreen: boolean;
  autoZoom: boolean;
  framerate: number;
  tracksManagerHeight: number;
  resizing: boolean;
  uploads: UploadStates;
}

class VideoEditor extends React.PureComponent<IProps & RouteComponentProps, IState> {
  private mainAnimation: Konva.Animation;

  private tracksManagerRef = React.createRef<HTMLDivElement>();

  private videoEditorAreaRef = React.createRef<HTMLDivElement>();

  public constructor(props: IProps & RouteComponentProps) {
    super(props);
    this.state = {
      isPlaying: false,
      currentTime: 0,
      targetTime: 0,
      showAddTrackModal: false,
      addSectionKind: undefined,
      outputVolumes: [[]],
      scale: 0.33,
      hideOffscreen: false,
      autoZoom: true,
      framerate: 25,
      tracksManagerHeight: 100,
      resizing: false,
      uploads: {}
    };
    this.handlePlay = this.handlePlay.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.handleChangeScale = this.handleChangeScale.bind(this);
    this.handleAddSections = this.handleAddSections.bind(this);
    this.handleUpdateDuration = this.handleUpdateDuration.bind(this);
    this.handleChangeCurrentTime = this.handleChangeCurrentTime.bind(this);
    this.handleChangeCurrentTrack = this.handleChangeCurrentTrack.bind(this);
    this.handleChangeSectionStart = this.handleChangeSectionStart.bind(this);
    this.handleChangeSectionDuration = this.handleChangeSectionDuration.bind(this);
    this.handleChangeSectionStartMedia = this.handleChangeSectionStartMedia.bind(this);
    this.handleChangeSectionEndMedia = this.handleChangeSectionEndMedia.bind(this);
    this.handleShowAddTrackModal = this.handleShowAddTrackModal.bind(this);
    this.handleRemoveTrack = this.handleRemoveTrack.bind(this);
    this.getTotalTrackDuration = this.getTotalTrackDuration.bind(this);
    this.handleDeleteSection = this.handleDeleteSection.bind(this);
    this.handleHideAddTrackModal = this.handleHideAddTrackModal.bind(this);
    this.handleUpdateText = this.handleUpdateText.bind(this);
    this.handleAddTrack = this.handleAddTrack.bind(this);
    this.handleChangeKonvaNodeBounds = this.handleChangeKonvaNodeBounds.bind(this);
    this.handleMoveCurrentTrackUp = this.handleMoveCurrentTrackUp.bind(this);
    this.handleMoveCurrentTrackDown = this.handleMoveCurrentTrackDown.bind(this);
    this.handleToggleTrackVisibility = this.handleToggleTrackVisibility.bind(this);
    this.handleToggleTrackMute = this.handleToggleTrackMute.bind(this);
    this.handleSplitSection = this.handleSplitSection.bind(this);
    this.handleFitShape = this.handleFitShape.bind(this);
    this.handleCenterShapeHorizontal = this.handleCenterShapeHorizontal.bind(this);
    this.handleCenterShapeVertical = this.handleCenterShapeVertical.bind(this);
    this.handleChangeHideOffscreen = this.handleChangeHideOffscreen.bind(this);
    this.handleAutoZoom = this.handleAutoZoom.bind(this);
    this.mainAnimationTick = this.mainAnimationTick.bind(this);
    this.handleGoToPreviousFrame = this.handleGoToPreviousFrame.bind(this);
    this.handleGoToNextFrame = this.handleGoToNextFrame.bind(this);
    this.mainAnimation = new Konva.Animation(this.mainAnimationTick as any);
    this.handleChangeScale = debounce(this.handleChangeScale, 300);
    this.handleTrackDragOver = this.handleTrackDragOver.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDropTransition = this.handleDropTransition.bind(this);
    this.handleAddSection = this.handleAddSection.bind(this);
    this.handleAddTransitionPlaceHolder = this.handleAddTransitionPlaceHolder.bind(this);
    this.handlePlaceholderClick = this.handlePlaceholderClick.bind(this);
    this.handleTransitionClick = this.handleTransitionClick.bind(this);
    this.handleDeleteSelectedSection = this.handleDeleteSelectedSection.bind(this);
    this.handleOpenTextPanel = this.handleOpenTextPanel.bind(this);
  }

  public componentDidMount() {
    document.title = `${APP_NAME}`;
    window.addEventListener('mousemove', this.handleTracksManagerResize);
    window.addEventListener('mouseup', this.handleMouseupResizing);
    window.addEventListener('keydown', this.handleKeyboardShortcuts);
    const projectId = this.props.location.pathname.split('/')[2];
    if (!this.props.project && projectId) {
      if (projectId.match(/^[0-9a-fA-F]{24}$/)) {
        (async (): Promise<void> => {
          try {
            const result: GraphQLGetProjectQueryResponse | undefined = await fetchQuery<GraphQLGetProjectQuery>(
              // @ts-ignore
              RELAY_ENVIRONMENT,
              GET_PROJECT_QUERY,
              { projectId }
            ).toPromise();
            if (!result) {
              return;
            }
            this.props.dispatch(doLoadProject(result.getProject as any));
          } catch (err) {
            handleError(err as Error, 'Cannot get project');
          }
        })();
      } else {
        this.props.history.push('/');
      }
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleTracksManagerResize);
    window.removeEventListener('mouseup', this.handleMouseupResizing);
    window.removeEventListener('keydown', this.handleKeyboardShortcuts);
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.project && this.props.project !== prevProps.project) {
      this.setState({
        outputVolumes: new Array(this.props.project.tracks.length).fill(undefined),
        isPlaying: prevState.isPlaying
      });
    }
  }

  private handleKeyboardShortcuts = (e: KeyboardEvent) => {
    const tagName = (e.target as HTMLElement).tagName;
    if (EXCEPTION_TAGS.indexOf(tagName) !== -1) {
      return;
    }

    switch (e.code) {
      case KEYBOARD_CODES.DELETE:
      case KEYBOARD_CODES.BACKSPACE:
        if (isNumber(this.props.currentTrackIndex) && isNumber(this.props.currentSectionIndex)) {
          this.handleDeleteSelectedSection();
        }
        break;
      case KEYBOARD_CODES.SPACE:
        if (this.state.isPlaying) {
          this.handlePause();
        } else {
          this.handlePlay();
        }
        break;
      case KEYBOARD_CODES.KEY_M:
        if (isNumber(this.props.currentTrackIndex)) {
          const track = this.props.project?.tracks[this.props.currentTrackIndex];
          if (track) {
            this.handleToggleTrackMute(track, this.props.currentTrackIndex);
          }
        }
        break;
      case KEYBOARD_CODES.RIGHT_ARROW:
        if (!this.state.isPlaying) {
          this.handleGoToNextFrame();
        }
        break;
      case KEYBOARD_CODES.LEFT_ARROW:
        if (!this.state.isPlaying) {
          this.handleGoToPreviousFrame();
        }
        break;
      case KEYBOARD_CODES.KEY_C:
        if (isNumber(this.props.currentTrackIndex) && isNumber(this.props.currentSectionIndex)) {
          const currentTrackIndex = this.props.currentTrackIndex;
          const currentSectionIndex = this.props.currentSectionIndex;
          const time = this.state.currentTime;

          const section = this.props.project?.tracks[currentTrackIndex]?.sections[currentSectionIndex] as TrackSection;
          if (!section || time <= section.start || time >= section.start + section.duration) {
            return;
          }

          const currentSectionNewDuration = time - section.start;
          const newSection = { ...section };
          if (newSection.kind === SectionKind.video || newSection.kind === SectionKind.audio) {
            newSection.startMedia = (section as IVideoSection | IAudioSection).startMedia + currentSectionNewDuration;
          }
          newSection.start = time;
          newSection.duration = section.duration - currentSectionNewDuration;
          newSection.id = uuidv4();
          this.handleSplitSection(currentTrackIndex, currentSectionIndex, currentSectionNewDuration, newSection);
        }
        break;
      default:
        break;
    }
  };

  private mainAnimationTick(frame: IKonvaAnimationFrame) {
    if (this.props.project) {
      let currentTime = this.state.currentTime + frame.timeDiff;
      const duration = this.props.project.duration;
      if (currentTime > duration) {
        currentTime = currentTime % duration;
      }
      this.setState({ currentTime });
    }
  }

  private handleChangeSectionDuration(trackIndex: number, sectionIndex: number, duration: number): number {
    this.props.dispatch(doSetSectionDuration(trackIndex, sectionIndex, duration));
    return duration;
  }

  private handleChangeSectionStartMedia(trackIndex: number, sectionIndex: number, duration: number): number {
    this.props.dispatch(doSetSectionStartMedia(trackIndex, sectionIndex, duration));
    return duration;
  }

  private handleChangeSectionEndMedia(trackIndex: number, sectionIndex: number, duration: number): number {
    this.props.dispatch(doSetSectionEndMedia(trackIndex, sectionIndex, duration));
    return duration;
  }

  private handleChangeSectionStart(
    trackIndex: number,
    sectionIndex: number,
    start: number,
    targetTrackIndex?: number
  ): number {
    this.props.dispatch(doSetSectionStart(trackIndex, sectionIndex, start, targetTrackIndex));
    return start;
  }

  private handlePlay() {
    this.setState({ isPlaying: true });
    this.mainAnimation.start();
  }

  private handlePause() {
    this.setState({ isPlaying: false });
    this.mainAnimation.stop();
  }

  private handleStop() {
    this.setState({ isPlaying: false });
    this.mainAnimation.stop();
    this.handleChangeCurrentTime(Timecode.fromTime(0, 25));
  }

  private handleChangeScale(scale: number) {
    this.setState({ scale, autoZoom: false });
  }

  private getSameTrackKindIndex = (kind: TrackKind): number => {
    return this.props.project ? this.props.project.tracks.findIndex((track: ITrack) => track.kind === kind) : -1;
  };

  private createNewTrack = (kind: TrackKind): any => {
    if (this.props.project)
      switch (kind) {
        case TrackKind.video:
          return {
            id: uuidv4(),
            kind,
            name: 'Video',
            visible: true,
            muted: false,
            width: this.props.project.size.width,
            height: this.props.project.size.height,
            sections: []
          };

        case TrackKind.audio:
          return {
            id: uuidv4(),
            kind,
            name: 'Audio',
            muted: false,
            sections: []
          };

        case TrackKind.media:
          return {
            id: uuidv4(),
            kind,
            name: 'Media',
            visible: true,
            scale: this.state.scale,
            sections: []
          };

        default:
          console.error('error createNewTrack');
      }
  };

  private getCurrentTrackKind(): TrackKind | undefined {
    const { project, currentTrackIndex } = this.props;
    if (project && project.tracks && currentTrackIndex != null && project.tracks[currentTrackIndex]) {
      return project.tracks[currentTrackIndex].kind;
    }
  }

  private getTrackKindForSectionKind(sectionKind: SectionKind) {
    switch (sectionKind) {
      case SectionKind.video:
        return TrackKind.video;
      case SectionKind.videoTransition:
        return TrackKind.video;
      case SectionKind.audio:
        return TrackKind.audio;
      default:
        return TrackKind.media;
    }
  }

  private getTotalTrackDuration(trackIndex: number): number {
    if (!this.props.project) {
      return 0;
    }
    const track = this.props.project.tracks[trackIndex];
    return (track.sections as any).reduce((prev: number, current: TrackSection) => prev + current.duration, 0);
  }

  private getTrackIndex(newSection: TrackSection, currentTrackIndex?: number) {
    const currentTrackKind = this.getCurrentTrackKind();
    const sectionTrackKind = this.getTrackKindForSectionKind(newSection.kind);
    let trackIndex = -1;
    if (currentTrackIndex && currentTrackKind && currentTrackKind === sectionTrackKind) {
      trackIndex = currentTrackIndex;
    } else {
      const sameTrackKindIndex = this.getSameTrackKindIndex(sectionTrackKind);
      if (sameTrackKindIndex >= 0) {
        trackIndex = sameTrackKindIndex;
      } else {
        throw new Error('No matching track found');
      }
    }
    return trackIndex;
  }

  private handleAddSections(sections: TrackSection[]) {
    const newSection = sections[0];
    const { project } = this.props;
    if (!project) {
      return;
    }
    const trackIndex = this.getTrackIndex(newSection, this.props.currentTrackIndex);
    this.handleAddSection(trackIndex, newSection);
  }

  private handleAddSection(trackIndex: number, newSection: TrackSection) {
    const { project } = this.props;
    if (!project) {
      return;
    }
    const durationRange = (DURATION_PERCENT * project.duration) / 100;
    const newDuration = this.getTotalTrackDuration(trackIndex) + newSection.duration;

    if (
      newSection.kind === SectionKind.video ||
      newSection.kind === SectionKind.audio ||
      newSection.kind === SectionKind.waveform
    ) {
      newSection.originMediaDuration = newSection.endMedia;
    }

    if (newSection.kind !== SectionKind.videoTransition) {
      const sectionsLength = project.tracks[trackIndex].sections.length;
      const previousSection = project.tracks[trackIndex].sections[sectionsLength - 1];
      if (previousSection) {
        if (previousSection.offset) {
          newSection.offset = previousSection.offset;
        }
        const newStart = previousSection.start + previousSection.duration;
        newSection.start = newStart;
      }
    } else if (
      project.tracks[trackIndex].sections.findIndex(
        (section: ITrackSection) => section.kind === SectionKind.videoTransition && section.start === newSection.start
      ) > -1
    ) {
      return;
    }
    if (newDuration > durationRange) {
      this.props.dispatch(doSetProjectDuration(project.duration + (newDuration - durationRange)));
    }
    this.props.dispatch(doAddNewSection(trackIndex, newSection));
  }

  private handleUpdateDuration(value: number, action: 'add' | 'remove') {
    this.props.dispatch(doUpdateProjectDuration(value, action === 'add'));
  }

  private handleChangeCurrentTime(currentTime: Timecode) {
    this.mainAnimationTick({
      time: 0,
      lastTime: 0,
      frameRate: 0,
      timeDiff: currentTime.toTime() - this.state.currentTime
    });
  }

  private handleTextUpdate = (
    propValue: TextSectionPropertyValue,
    propName: TextSectionPropertyName,
    trackIndex: number,
    sectionIndex: number
  ): void => {
    this.props.dispatch(doSetTextSectionProperty(trackIndex, sectionIndex, propName, propValue));
  };

  private handleVolumeUpdate = (volume: number, trackIndex: number, sectionIndex: number): void => {
    if (trackIndex == null || sectionIndex == null || this.props.project == null) {
      return;
    }
    const section: TrackSection = this.props.project.tracks[trackIndex].sections[sectionIndex];
    if (section.kind === SectionKind.video) {
      this.props.dispatch(doSetVideoSectionProperty(trackIndex, sectionIndex, 'volume', volume));
    } else if (section.kind === SectionKind.audio || section.kind === SectionKind.waveform) {
      this.props.dispatch(doSetAudioSectionProperty(trackIndex, sectionIndex, 'volume', volume));
    }
  };

  private handleAmplitudeDegreeUpdate = (amplitudeDegree: number, trackIndex: number, sectionIndex: number): void => {
    if (trackIndex == null || sectionIndex == null || this.props.project == null) {
      return;
    }
    const section: TrackSection = this.props.project.tracks[trackIndex].sections[sectionIndex];
    if (section.kind === SectionKind.waveform) {
      this.props.dispatch(doSetWaveformSectionProperty(trackIndex, sectionIndex, 'amplitudeDegree', amplitudeDegree));
    }
  };

  private handleSizeUpdate = (size: number, trackIndex: number, sectionIndex: number): void => {
    if (trackIndex == null || sectionIndex == null || this.props.project == null) {
      return;
    }
    const section: TrackSection = this.props.project.tracks[trackIndex].sections[sectionIndex];
    if (section.kind === SectionKind.waveform) {
      this.props.dispatch(doSetWaveformSectionProperty(trackIndex, sectionIndex, 'size', size));
    }
  };

  private handleImageUpdate = (
    propValue: ImageSectionPropertyValue,
    propName: ImageSectionPropertyName,
    trackIndex: number,
    sectionIndex: number
  ): void => {
    this.props.dispatch(doSetImageSectionProperty(trackIndex, sectionIndex, propName, propValue));
  };

  private handleTransitionUpdate = (
    propValue: TransitionSectionPropertyValue,
    propName: TransitionSectionPropertyName,
    trackIndex: number,
    sectionIndex: number
  ): void => {
    this.props.dispatch(doSetTransitionSectionProperty(trackIndex, sectionIndex, propName, propValue));
  };

  private handleChangeCurrentTrack(trackIndex: number | undefined, unselectSection: boolean): void {
    const { currentSectionIndex } = this.props;
    this.props.dispatch(doSetCurrentTrack(trackIndex));

    if (trackIndex !== undefined && currentSectionIndex === undefined && this.props.project) {
      const trackKind = this.props.project.tracks[trackIndex].kind;
      if (trackKind) {
        this.setState(() => ({
          showAddTrackModal: true,
          addSectionKind: trackKind === 'media' ? SectionKind.image : (trackKind as unknown as SectionKind)
        }));
      }
    }
    if (unselectSection) {
      this.props.dispatch(doSetCurrentSection(undefined));
    }
  }

  private handleTimeLineChangeCurrentSection = (newSectionIndex: number) => {
    this.props.dispatch(doSetCurrentSection(newSectionIndex));
    if (newSectionIndex !== undefined) {
      if (this.state.showAddTrackModal === true) {
        this.handleHideAddTrackModal();
      }
      this.setState(() => ({
        showAddTrackModal: true,
        addSectionKind: 'edit'
      }));
    }
  };

  private handleViewportChangeCurrentSection = (currentSectionIndex?: number, currentTrackIndex?: number) => {
    this.props.dispatch(doSetCurrentTrack(currentTrackIndex));
    this.props.dispatch(doSetCurrentSection(currentSectionIndex));
  };

  private async handleShowAddTrackModal(type?: SectionKind | 'edit') {
    const duration = this.props.project?.duration || 5000;

    switch (type) {
      case 'edit':
        this.setState((preState) => ({
          showAddTrackModal: preState.addSectionKind !== type || !this.state.showAddTrackModal,
          addSectionKind: 'edit'
        }));
        break;
      case SectionKind.text:
        if (this.props.currentSectionIndex === undefined) {
          const text = TrackFactory.createText(duration);
          this.handleAddSections([text]);
        }
        this.handleShowAddTrackModal('edit');
        break;
      case SectionKind.audio:
      case SectionKind.video:
      case SectionKind.image:
      case SectionKind.videoTransition:
      default:
        this.setState((preState) => ({
          showAddTrackModal: preState.addSectionKind !== type || !this.state.showAddTrackModal,
          addSectionKind: type
        }));
        break;
    }
  }

  private handleHideAddTrackModal() {
    this.setState({ showAddTrackModal: false, addSectionKind: undefined });
  }

  private handleDeleteSection(trackIndex: number, sectionIndex: number) {
    this.props.dispatch(doDeleteSection(trackIndex, sectionIndex));
  }

  private handleDeleteSelectedSection() {
    this.props.dispatch(doDeleteCurrentSection());
    // reduce project duration
    setTimeout(() => {
      if (this.props.project?.tracks) {
        let maxDuration = 0;
        for (const track of this.props.project?.tracks) {
          for (const section of track.sections) {
            if (section.start + section.duration > maxDuration) {
              maxDuration = section.start + section.duration;
            }
          }
        }
        if (maxDuration !== 0) {
          maxDuration += maxDuration / 10; // add 10% of time margin
          if (this.props.project.duration > maxDuration) {
            this.props.dispatch(doSetProjectDuration(maxDuration));
          }
        }
      }
    });
  }

  private handleAddTrack(trackKind?: TrackKind) {
    if (!trackKind) {
      return;
    }
    const newTrack = this.createNewTrack(trackKind);

    this.props.dispatch(doAddNewTrack(newTrack));

    this.setState({
      showAddTrackModal: false
    });
  }

  private async handleTrackDragOver(event: React.DragEvent<HTMLTableRowElement>) {
    event.preventDefault();
    event.stopPropagation();
  }

  private isSelectedFileResource(selected: any): selected is FileResource {
    return (selected as FileResource).resourceKind !== undefined;
  }

  private isSelectedTransitionEffect(selected: any): selected is TransitionEffect {
    return (selected as TransitionEffect).durationSec !== undefined;
  }

  private async handleDrop(event: React.DragEvent<HTMLTableRowElement>) {
    event.preventDefault();
    event.stopPropagation();
    const selected = this.props.selectedResources;
    const { project } = this.props;
    if (selected && this.isSelectedFileResource(selected[0])) {
      const type: ResourceType = selected[0].resourceKind;
      if (type && type === 'video') {
        const video: IVideoResource = selected[0] as IVideoResource;
        const section: IVideoSection = {
          id: uuidv4(),
          kind: SectionKind.video,
          text: video.name,
          src: video.src,
          srcId: video._id,
          lowres: video.lowres,
          volume: 1,
          x: (1 % 10) * 20,
          y: (1 % 10) * 20,
          width: video.width,
          height: video.height,
          rotation: 0,
          opacity: 1,
          start: 0,
          duration: video.duration,
          startMedia: 0,
          endMedia: 0 + video.duration,
          originMediaDuration: 0 + video.duration,
          preview: video.preview,
          cropX: video.cropX,
          cropY: video.cropY,
          cropWidth: video.cropWidth,
          cropHeight: video.cropHeight,
          initialWidth: video.width,
          initialHeight: video.height
        };
        this.handleAddSections([section]);
        const videoTrackIndex = this.props.project?.tracks.findIndex((track) => track.kind === TrackKind.video);
        if (videoTrackIndex) {
          const videoSections = this.props.project?.tracks[videoTrackIndex].sections;
          if (videoSections && project?.isSameVideoSizeProject === 'uncut') {
            this.props.dispatch(doAddVideoTransitionPlaceHolder(videoTrackIndex));
          }
        }
      } else if (type && type === 'audio') {
        const audio: IAudioResource = selected[0] as IAudioResource;
        const section: IAudioSection = {
          id: uuidv4(),
          kind: SectionKind.audio,
          text: audio.name,
          src: audio.src,
          srcId: audio._id,
          lowres: audio.lowres,
          volume: 1,
          start: 0,
          duration: audio.duration,
          startMedia: 0,
          endMedia: 0 + audio.duration,
          originMediaDuration: 0 + audio.duration,
          preview: audio.preview
        };
        this.handleAddSections([section]);
      } else if (type && type === 'image') {
        const image: IImageResource = selected[0] as IImageResource;
        const section: IImageSection = {
          id: uuidv4(),
          preview: image.preview,
          text: image.name,
          start: 0,
          duration: 10000,
          kind: SectionKind.image,
          src: image.src,
          srcId: image._id,
          lowres: image.lowres,
          x: (1 % 10) * 20,
          y: (1 % 10) * 20,
          width: image.width,
          height: image.height,
          rotation: 0,
          opacity: 1
        };
        this.handleAddSections([section]);
        if (this.props.project) {
          this.automaticsFitShape(this.props.project, section, CenterShapeDirection.both);
        }
      }
    }
    this.props.dispatch(doResetSelectedResources());
  }

  private handleAddTransitionPlaceHolder(trackIndex: number, sectionIndex: number) {
    const { project } = this.props;
    if (project && project.isSameVideoSizeProject === 'uncut') {
      this.props.dispatch(doAddVideoTransitionPlaceHolder(trackIndex, sectionIndex));
    }
  }

  private handleRemoveTrack() {
    this.props.dispatch(doDeleteCurrentTrack());
  }

  private handleUpdateText(trackIndex: number, sectionIndex: number, text: string) {
    this.props.dispatch(doSetTextSectionProperty(trackIndex, sectionIndex, 'text', text));
  }

  private handleChangeKonvaNodeBounds(trackIndex: number, sectionId: string, bounds: IKonvaNodeBounds) {
    if (!this.props.project?.tracks) {
      return;
    }
    //TODO: We should always use sectionIndex for consistency
    const track = this.props.project.tracks[trackIndex];
    const sectionIndex = track.sections.findIndex((section: TrackSection) => section.id === sectionId);
    this.props.dispatch(
      doSetSectionGeometry(
        trackIndex,
        sectionIndex,
        Math.round(bounds.x),
        Math.round(bounds.y),
        Math.round(bounds.width),
        Math.round(bounds.height),
        Math.round(bounds.rotation),
        Math.round(bounds.crop?.cropX ? bounds.crop?.cropX : 0),
        Math.round(bounds.crop?.cropY ? bounds.crop?.cropY : 0),
        Math.round(bounds.crop?.cropWidth ? bounds.crop?.cropWidth : 0),
        Math.round(bounds.crop?.cropHeight ? bounds.crop?.cropHeight : 0),
        Math.round(bounds.initialWidth!),
        Math.round(bounds.initialHeight!)
      )
    );
  }

  private handleMoveCurrentTrackUp() {
    this.props.dispatch(doMoveTrack(true));
  }

  private handleMoveCurrentTrackDown() {
    this.props.dispatch(doMoveTrack(false));
  }

  private handleToggleTrackVisibility(track: ITrack, trackIndex: number) {
    if (track.kind !== TrackKind.audio) {
      const mutableTrack = track as IVideoTrack | IMediaTrack;
      this.props.dispatch(doSetTrackProperty(trackIndex, 'visible', !mutableTrack.visible));
    }
  }

  private handleToggleTrackMute(track: ITrack, trackIndex: number) {
    if (track.kind === TrackKind.audio || track.kind === TrackKind.video) {
      this.props.dispatch(doSetTrackProperty(trackIndex, 'muted', !(track as IVideoTrack | IAudioTrack).muted));
    }
  }

  private handleGoToPreviousFrame() {
    const time = this.state.currentTime - 1000 / (this.state.framerate || 25);
    this.setState({ currentTime: time > 0 ? time : 0 });
  }

  private handleGoToNextFrame() {
    const time = this.state.currentTime + 1000 / (this.state.framerate || 25);
    const projectDuration = this.props.project?.duration || 0;
    this.setState({ currentTime: time < projectDuration ? time : projectDuration });
  }

  private handleSplitSection(
    trackIndex: number,
    sectionIndex: number,
    currentSectionNewDuration: number,
    newSection: ITrackSection
  ) {
    this.props.dispatch(doSectionSplit(trackIndex, sectionIndex, currentSectionNewDuration, newSection));
  }

  private computeImageRatio(
    url: string,
    projectWidth: number,
    projectHeight: number,
    type: CenterShapeDirection
  ): Promise<FitDimensions> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const isLandscape = width > height;
        const ratio = isLandscape ? width / height : height / width;
        let resultObj: FitDimensions = { width, height };
        switch (type) {
          case CenterShapeDirection.vertical: {
            resultObj = {
              width: isLandscape ? projectHeight * ratio : projectHeight / ratio,
              height: projectHeight
            };
            break;
          }
          case CenterShapeDirection.horizontal: {
            resultObj = {
              width: projectWidth,
              height: isLandscape ? projectWidth / ratio : projectWidth * ratio
            };
            break;
          }
          default: {
            const ratio = Math.min(projectWidth / width, projectHeight / height);
            resultObj = {
              width: width * ratio,
              height: height * ratio
            };
            break;
          }
        }
        resolve(resultObj);
      };
      img.src = url;
    });
  }

  private async fitShape(project: IProject, section: ITrackSection, type: CenterShapeDirection) {
    const projectWidth = project.size.width;
    const projectHeight = project.size.height;

    let dimensions: FitDimensions = {
      width: type !== CenterShapeDirection.vertical ? projectWidth : 0,
      height: type !== CenterShapeDirection.horizontal ? projectHeight : 0
    };

    if (
      section.kind === SectionKind.image ||
      section.kind === SectionKind.waveform ||
      section.kind === SectionKind.video
    ) {
      const url = getEoleEditServerUrl() + (section as IImageSection | IWaveformSection | IVideoSection).src;
      dimensions = await this.computeImageRatio(url, projectWidth, projectHeight, type);
    }
    this.props.dispatch(doFitShape(dimensions, type));
  }

  private async handleFitShape(type: CenterShapeDirection) {
    const { project, currentTrackIndex, currentSectionIndex } = this.props;
    if (
      project &&
      project.tracks &&
      currentTrackIndex != null &&
      currentSectionIndex != null &&
      project.tracks[currentTrackIndex]
    ) {
      const track = project.tracks[currentTrackIndex];
      const section = track.sections[currentSectionIndex];
      await this.fitShape(project, section, type);
    }
  }

  private async automaticsFitShape(project: IProject, section: IImageSection, type: CenterShapeDirection) {
    const widthCondition = section.width > project.size.width;
    const heightCondition = section.height > project.size.height;
    if ((widthCondition || heightCondition) && project) {
      await this.fitShape(project, section, type);
    }
  }

  private handleCenterShapeHorizontal() {
    this.props.dispatch(doCenterShape(CenterShapeDirection.horizontal));
  }

  private handleCenterShapeVertical() {
    this.props.dispatch(doCenterShape(CenterShapeDirection.vertical));
  }

  private handleChangeHideOffscreen(hideOffscreen: boolean) {
    this.setState({ hideOffscreen });
  }

  private handleAutoZoom() {
    if (this.state.autoZoom) return;
    this.setState({ autoZoom: true });
  }

  private handleTracksManagerResize = (e: any) => {
    if (!this.state.resizing) return;
    if (!this.tracksManagerRef.current) return;

    const originalHeight: number = this.state.tracksManagerHeight;
    const originalElementPosition: number = this.tracksManagerRef.current?.offsetTop;
    const actualMouseHeight: number = e.clientY;

    const finalHeight: number = originalHeight + (originalElementPosition - actualMouseHeight);

    if (finalHeight > 100 && finalHeight < 600) {
      this.setState({ tracksManagerHeight: finalHeight });
    }
    return;
  };

  private handleMouseupResizing = () => {
    if (this.state.resizing) {
      this.setState({ resizing: false });
      this.handleAutoZoom();
    }
  };

  private handleDropTransition = (sectionIndex: number) => {
    const selected = this.props.selectedResources;
    const trackIndex = this.props.currentTrackIndex;
    if (selected && this.isSelectedTransitionEffect(selected[0]) && trackIndex) {
      const transitionEffect = selected[0];
      if (transitionEffect.type === 'fadeblack') {
        this.props.dispatch(
          doSetElseTransitionSectionProperty(trackIndex, sectionIndex, 'durationSec', transitionEffect.durationSec)
        );
        this.props.dispatch(
          doSetElseTransitionSectionProperty(trackIndex, sectionIndex, 'type', transitionEffect.type)
        );
      } else {
        this.props.dispatch(
          doSetTransitionSectionProperty(trackIndex, sectionIndex, 'durationSec', transitionEffect.durationSec)
        );
        this.props.dispatch(doSetTransitionSectionProperty(trackIndex, sectionIndex, 'type', transitionEffect.type));
      }
    }
    this.props.dispatch(doResetSelectedResources());
  };

  private handleTransitionClick = () => {
    this.setState(() => ({
      showAddTrackModal: true,
      addSectionKind: SectionKind.videoTransition
    }));
  };

  private handleOpenTextPanel = () => {
    this.setState(() => ({
      showAddTrackModal: true,
      addSectionKind: 'edit'
    }));
  };

  private handlePlaceholderClick = (sectionIndex: number) => {
    const trackIndex = this.props.currentTrackIndex;
    if (trackIndex) {
      this.props.dispatch(
        doSetTransitionSectionProperty(trackIndex, sectionIndex, 'durationSec', DEFAULT_TRANSITION_DURATION)
      );
      this.props.dispatch(doSetTransitionSectionProperty(trackIndex, sectionIndex, 'type', DEFAULT_TRANSITION_TYPE));
    }
    this.props.dispatch(doSetCurrentSection(sectionIndex));
  };

  private handleClickEmptySrcSection = async (trackIndex: number, sectionIndex: number) => {
    const section = this.props.project?.tracks[trackIndex].sections[sectionIndex];
    if (section) {
      const resourceTypeOfSection = ResourceUtils.getResourceTypeOfSection(section.kind);
      const acceptableInputFileExtensionsWithDot = acceptableInputFileExtension[resourceTypeOfSection].map(
        (extension) => `.${extension}`
      );
      const file = await showOpenFileDialog(acceptableInputFileExtensionsWithDot.join(', '));
      if (file?.[0] && FileUtils.isAcceptedInputFiles(file, resourceTypeOfSection)) {
        let mimeType = '';
        if (!file[0].type) {
          mimeType = `${resourceTypeOfSection}/${FileUtils.getExtension(file[0])}`;
        }
        uploadFile(file[0], mimeType, (uploadProgress: UploadProgress) => {
          // We can have several uploads
          const uploadId = uploadProgress.uploadId;
          const fileName = uploadProgress.file.name;
          const upProgress = uploadProgress.status.uploadProgress;
          const lrProgress = uploadProgress.status.lowresProgress;
          const resource = uploadProgress.resource;

          const newStateUploads: UploadStates = this.state.uploads;
          if (resource) {
            if (newStateUploads[uploadId]) {
              delete newStateUploads[uploadId];
            }
            this.props.dispatch(
              doSetSectionSrcProperties(
                trackIndex,
                sectionIndex,
                resource._id,
                resource.src,
                resource.preview,
                resource.lowres,
                resource.name
              )
            );
            if (resource.resourceKind !== ResourceType.image) {
              this.props.dispatch(doSetSectionEndMedia(trackIndex, sectionIndex, resource.duration));
              this.props.dispatch(doSetSectionOriginMediaDuration(trackIndex, sectionIndex, resource.duration));
            }
            this.props.dispatch(doSaveProject());
          } else if (uploadProgress.error || upProgress === -1 || lrProgress === -1) {
            delete newStateUploads[uploadId];
          } else if (!newStateUploads[uploadId]) {
            newStateUploads[uploadId] = { uploadId, fileName, upProgress, lrProgress };
          } else {
            newStateUploads[uploadId] = {
              ...newStateUploads[uploadId],
              ...{ uploadId, fileName, upProgress, lrProgress }
            };
          }
          this.setState({ uploads: newStateUploads });
        });
      }
    }
  };

  public render() {
    return (
      <div className="video-editor flex-fill d-flex flex-column">
        <div className="video-editor-top flex-fill border-bottom d-flex flex-row">
          <div className="video-editor-area-container flex-fill d-flex" ref={this.videoEditorAreaRef}>
            <ViewportControlBar
              currentSectionIndex={this.props.currentSectionIndex}
              currentSectionKind={
                this.props.currentTrackIndex &&
                this.props.currentSectionIndex &&
                this.props.project?.tracks[this.props.currentTrackIndex].sections[this.props.currentSectionIndex]
                  ? this.props.project.tracks[this.props.currentTrackIndex].sections[this.props.currentSectionIndex]
                      .kind
                  : undefined
              }
              showAddTrackModal={this.state.showAddTrackModal}
              scale={this.state.scale}
              hideOffscreen={this.state.hideOffscreen}
              currentTrackIndex={this.props.currentTrackIndex}
              onChangeScale={this.handleChangeScale}
              onShowAddSectionModal={this.handleShowAddTrackModal}
              onFitShape={() => this.handleFitShape(CenterShapeDirection.both)}
              onFitShapeVertically={() => this.handleFitShape(CenterShapeDirection.vertical)}
              onFitShapeHorizontally={() => this.handleFitShape(CenterShapeDirection.horizontal)}
              onCenterShapeHorizontal={this.handleCenterShapeHorizontal}
              onCenterShapeVertical={this.handleCenterShapeVertical}
              onChangeHideOffscreen={this.handleChangeHideOffscreen}
              onAutoZoom={this.handleAutoZoom}
            />

            {this.props.project && (
              <AddTrackModal
                project={this.props.project}
                defaultDuration={this.props.project.duration}
                defaultWidth={this.props.project.size.width}
                defaultHeight={this.props.project.size.height}
                show={this.state.showAddTrackModal}
                onHide={this.handleHideAddTrackModal}
                automaticFitShape={(project: IProject, section: IImageSection) =>
                  this.automaticsFitShape(project, section, CenterShapeDirection.both)
                }
                onAddTracks={this.handleAddSections}
                onAddTrack={this.handleAddTrack}
                onChangeCurrentTrack={this.handleChangeCurrentTrack}
                onChangeUpdateText={this.handleTextUpdate}
                onChangeUpdateVolume={this.handleVolumeUpdate}
                onChangeUpdateSize={this.handleSizeUpdate}
                onChangeUpdateAmplitudeDegree={this.handleAmplitudeDegreeUpdate}
                onChangeUpdateImage={this.handleImageUpdate}
                onChangeUpdateTransition={this.handleTransitionUpdate}
                onDeleteSection={this.handleDeleteSection}
                currentSectionIndex={this.props.currentSectionIndex}
                currentTrackIndex={this.props.currentTrackIndex}
                trackKind={this.state.addSectionKind}
              />
            )}

            {this.props.project && (
              <Viewport
                tracks={this.props.project.tracks}
                currentTrackIndex={this.props.currentTrackIndex}
                currentSectionIndex={this.props.currentSectionIndex}
                project={this.props.project}
                currentTime={this.state.currentTime}
                isPlaying={this.state.isPlaying}
                videoWidth={this.props.project.size.width}
                videoHeight={this.props.project.size.height}
                scale={this.state.scale}
                hideOffscreen={this.state.hideOffscreen}
                onUpdateText={this.handleUpdateText}
                onChangeCurrentSection={this.handleViewportChangeCurrentSection}
                onChangeOutputVolume={this.handleVolumeUpdate}
                onChangeKonvaNodeBounds={this.handleChangeKonvaNodeBounds}
                initialWidth={this.props.project.size.width}
                initialHeight={this.props.project.size.height}
                autoZoom={this.state.autoZoom}
                onChangeScale={this.handleChangeScale}
                onAutoZoom={this.handleAutoZoom}
                openTextPanel={this.handleOpenTextPanel}
              />
            )}
          </div>
          <div className="video-editor-right-panel d-flex flex-column">
            <TabContainer defaultActiveKey="properties" transition={false}>
              <Nav variant="tabs" fill justify>
                <Nav.Item>
                  <Nav.Link eventKey="properties">
                    <FontAwesomeIcon icon={faSlidersH} />
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="comments">
                    <FontAwesomeIcon icon={faComments} />
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="settings">
                    <FontAwesomeIcon icon={faCog} />
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="jobs">
                    <FontAwesomeIcon icon={faCogs} />
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
              <Tab.Content className="video-editor-properties ml-0 mr-0 border-left p-1 flex-fill d-flex flex-column">
                <Tab.Pane eventKey="properties"></Tab.Pane>
                <Tab.Pane eventKey="comments" className="h-100">
                  <CommentPanel />
                </Tab.Pane>
                {this.props.project && (
                  <Tab.Pane eventKey="settings">
                    <ProjectSettingsPanel project={this.props.project} />
                  </Tab.Pane>
                )}
                <Tab.Pane eventKey="jobs">
                  <JobQueuePanel />
                </Tab.Pane>
              </Tab.Content>
            </TabContainer>
          </div>
        </div>

        {/* Div for section resizing */}
        <div className="tracklist-resizer-wrapper">
          <div
            className="tracklist-resizer"
            onMouseDown={(event) => {
              // prevent the text selection highlighting
              event.preventDefault();
              this.setState({
                resizing: true
              });
            }}
          >
            <FontAwesomeIcon className="tracklist-resizer-icon" icon={faBars} />
          </div>
        </div>
        <div
          className="video-editor-bottom d-flex flex-column"
          style={{
            height: this.state.tracksManagerHeight,
            overflowY: 'scroll'
          }}
          ref={this.tracksManagerRef}
        >
          {this.props.project && (
            <EditorTimeline
              canPlay={true}
              tracks={this.props.project.tracks}
              duration={this.props.project.duration}
              isPlaying={this.state.isPlaying}
              currentTime={Timecode.fromTime(this.state.currentTime, this.state.framerate)}
              currentTrackIndex={this.props.currentTrackIndex}
              currentSectionIndex={this.props.currentSectionIndex}
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              onStop={this.handleStop}
              displayAddSection={false}
              displayRemoveSection={true}
              isVideoEditor={true}
              displaySplitSection={true}
              framerate={this.state.framerate}
              hideZoom={false}
              displayFrame={false}
              canMoveInOtherKind={true}
              onPreviousFrame={this.handleGoToPreviousFrame}
              onNextFrame={this.handleGoToNextFrame}
              onChangeCurrentTime={this.handleChangeCurrentTime}
              onChangeCurrentTrack={this.handleChangeCurrentTrack}
              onChangeCurrentSection={this.handleTimeLineChangeCurrentSection}
              onChangeSectionStart={this.handleChangeSectionStart}
              onChangeSectionDuration={this.handleChangeSectionDuration}
              onChangeSectionStartMedia={this.handleChangeSectionStartMedia}
              onChangeSectionEndMedia={this.handleChangeSectionEndMedia}
              onMoveCurrentTrackUp={this.handleMoveCurrentTrackUp}
              onMoveCurrentTrackDown={this.handleMoveCurrentTrackDown}
              onAddTransitionPlaceHolder={this.handleAddTransitionPlaceHolder}
              onAddTrack={this.handleAddTrack}
              onAddSection={this.handleAddSection}
              onRemoveTrack={this.handleRemoveTrack}
              onDeleteSection={this.handleDeleteSection}
              onDeleteSelectedSection={this.handleDeleteSelectedSection}
              onSplitSection={this.handleSplitSection}
              onToggleTrackVisibility={this.handleToggleTrackVisibility}
              onToggleTrackMute={this.handleToggleTrackMute}
              updateDuration={this.handleUpdateDuration}
              onTrackDragOver={this.handleTrackDragOver}
              onDrop={this.handleDrop}
              onDropTransition={this.handleDropTransition}
              onPlaceholderClick={this.handlePlaceholderClick}
              onTransitionClick={this.handleTransitionClick}
              onClickEmptySrcSection={this.handleClickEmptySrcSection}
              isTransitionHandled={true}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: EoleEditStateEntry) => {
  return {
    project: state.ee.project,
    currentTrackIndex: state.ee.currentTrackIndex,
    currentSectionIndex: state.ee.currentSectionIndex,
    selectedResources: state.ee.selectedResources
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VideoEditor));
