/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLTrimMediaMutationVariables = {
    srcId: string;
    owner: string;
    startTime: number;
    endTime: number;
};
export type GraphQLTrimMediaMutationResponse = {
    readonly trimMedia: string;
};
export type GraphQLTrimMediaMutation = {
    readonly response: GraphQLTrimMediaMutationResponse;
    readonly variables: GraphQLTrimMediaMutationVariables;
};



/*
mutation GraphQLTrimMediaMutation(
  $srcId: String!
  $owner: String!
  $startTime: Float!
  $endTime: Float!
) {
  trimMedia(srcId: $srcId, owner: $owner, startTime: $startTime, endTime: $endTime)
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "owner"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "srcId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startTime"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endTime",
        "variableName": "endTime"
      },
      {
        "kind": "Variable",
        "name": "owner",
        "variableName": "owner"
      },
      {
        "kind": "Variable",
        "name": "srcId",
        "variableName": "srcId"
      },
      {
        "kind": "Variable",
        "name": "startTime",
        "variableName": "startTime"
      }
    ],
    "kind": "ScalarField",
    "name": "trimMedia",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLTrimMediaMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GraphQLTrimMediaMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "951515f2a949d2990566fc9caab4846b",
    "id": null,
    "metadata": {},
    "name": "GraphQLTrimMediaMutation",
    "operationKind": "mutation",
    "text": "mutation GraphQLTrimMediaMutation(\n  $srcId: String!\n  $owner: String!\n  $startTime: Float!\n  $endTime: Float!\n) {\n  trimMedia(srcId: $srcId, owner: $owner, startTime: $startTime, endTime: $endTime)\n}\n"
  }
};
})();
(node as any).hash = 'd03016038620f47e514d114d2af28d95';
export default node;
