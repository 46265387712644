/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLGetFilesQueryVariables = {
    mime?: string | null;
};
export type GraphQLGetFilesQueryResponse = {
    readonly getFiles: ReadonlyArray<{
        readonly _id: string;
        readonly type: string;
        readonly mime: string;
        readonly name: string;
        readonly size: number;
        readonly duration: number | null;
        readonly width: number | null;
        readonly height: number | null;
        readonly src: string;
        readonly lowres: string | null;
        readonly ingestInEolementhe: boolean | null;
        readonly preview: string | null;
    }>;
};
export type GraphQLGetFilesQuery = {
    readonly response: GraphQLGetFilesQueryResponse;
    readonly variables: GraphQLGetFilesQueryVariables;
};



/*
query GraphQLGetFilesQuery(
  $mime: String
) {
  getFiles(mime: $mime) {
    _id
    type
    mime
    name
    size
    duration
    width
    height
    src
    lowres
    ingestInEolementhe
    preview
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mime"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mime",
        "variableName": "mime"
      }
    ],
    "concreteType": "File",
    "kind": "LinkedField",
    "name": "getFiles",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "duration",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "width",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "height",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "src",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lowres",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ingestInEolementhe",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "preview",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLGetFilesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GraphQLGetFilesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9f40f9016b34e3ef20ab7381a3a32b9e",
    "id": null,
    "metadata": {},
    "name": "GraphQLGetFilesQuery",
    "operationKind": "query",
    "text": "query GraphQLGetFilesQuery(\n  $mime: String\n) {\n  getFiles(mime: $mime) {\n    _id\n    type\n    mime\n    name\n    size\n    duration\n    width\n    height\n    src\n    lowres\n    ingestInEolementhe\n    preview\n  }\n}\n"
  }
};
})();
(node as any).hash = '76aea714ae5ca6d3deb5f902d93a60d5';
export default node;
