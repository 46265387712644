import React, { useState, FC, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { Button, Nav, OverlayTrigger, Overlay, Popover } from 'react-bootstrap';
import { IAction, IActionChildren, mergeClassName } from '@eolementhe/react-components';
import './ActionButton.scss';

interface IProps {
  action: IAction;
  className?: string;
}

export const ActionButton: FC<IProps> = ({ action, className }) => {
  const { active, canExecute, execute, icon, child, description, title, TypeElement, type, selected, spin } = action;
  const executable = canExecute == null || canExecute;

  const [isChildToggled, setIsChildToggled] = useState(false);
  const [childTarget, setChildTarget] = useState(null);

  const [isDropdownToggled, setIsDropdownToggled] = useState(false);
  const [dropdownMouseEnter, setDropdownMouseEnter] = useState(false);

  const attachChildRef = (target: any) => setChildTarget(target);

  useEffect(() => {
    if (canExecute && isDropdownToggled) {
      const domClick = () => {
        if (!dropdownMouseEnter) {
          setIsDropdownToggled(false);
        }
      };
      document.body.addEventListener('click', domClick);
      return () => {
        document.body.removeEventListener('click', domClick);
      };
    }
  }, [canExecute, dropdownMouseEnter, isDropdownToggled]);

  const renderPopover = () => {
    return (
      <Popover id="action-popover">
        <Popover.Title as="h3">
          <FontAwesomeIcon icon={icon} />
          {` ${title}`}
        </Popover.Title>
        <Popover.Content>{description}</Popover.Content>
      </Popover>
    );
  };

  const renderButton = () => {
    const isActive = (isChildToggled && executable) || selected ? 'ab-active' : '';
    const actionBtnClasses = mergeClassName('action-button', isActive, className);

    return child ? (
      <>
        <Button
          ref={attachChildRef}
          type="button"
          variant="outline-primary"
          className={actionBtnClasses}
          onClick={() => setIsChildToggled(!isChildToggled)}
          active={active}
          disabled={!executable}
        >
          <FontAwesomeIcon icon={icon} size="sm" fixedWidth spin={spin} />
        </Button>
        <Overlay
          target={childTarget}
          show={isChildToggled}
          placement="right"
          rootClose
          onHide={() => setIsChildToggled(false)}
        >
          {({ ...props }: any) => (
            <div {...props} className="ab-children" style={{ ...props.style }}>
              {child.map(({ execute, title, icon }: IActionChildren) => (
                <Button
                  key={title}
                  title={title}
                  className="action-button"
                  type="button"
                  variant="outline-primary"
                  onClick={execute}
                >
                  <FontAwesomeIcon icon={icon} size="sm" fixedWidth />
                </Button>
              ))}
            </div>
          )}
        </Overlay>
      </>
    ) : (
      <Button
        type="button"
        variant="outline-primary"
        className={actionBtnClasses}
        size="sm"
        onClick={execute}
        active={active}
        disabled={!executable}
      >
        <FontAwesomeIcon icon={icon} fixedWidth spin={spin} />
      </Button>
    );
  };

  const renderNavLink = () => {
    return (
      <Nav.Link onClick={execute} active={active} disabled={!executable}>
        <FontAwesomeIcon icon={icon} size="sm" fixedWidth spin={spin} />
      </Nav.Link>
    );
  };

  const renderDropdown = () => {
    const disabledIcon = !canExecute ? { color: '#282828' } : {};
    return (
      <span className="ab-dropdown">
        <Button
          variant="outline-primary"
          className="action-button"
          size="sm"
          onClick={() => setIsDropdownToggled(!isDropdownToggled)}
          active={false}
          disabled={!canExecute}
        >
          <FontAwesomeIcon icon={icon} fixedWidth style={{ width: '0.8rem', ...disabledIcon }} />
          <FontAwesomeIcon icon={faSortDown} size="sm" style={{ transform: 'translateY(-3px)', ...disabledIcon }} />
        </Button>
        {isDropdownToggled && (
          <div
            className="ab-dropdown-content"
            onMouseEnter={() => setDropdownMouseEnter(true)}
            onMouseLeave={() => setDropdownMouseEnter(false)}
          >
            {TypeElement && <TypeElement onClose={() => setIsDropdownToggled(false)} />}
          </div>
        )}
      </span>
    );
  };

  const renderClickable = () => {
    switch (type) {
      case 'dropdown':
        return renderDropdown();
      case 'nav':
        return renderNavLink();
      case undefined:
      case 'button':
        return renderButton();
      default:
        throw new Error('Unsupported clickable type');
    }
  };
  const clickable = renderClickable();
  //Don't display tooltip popover when another popup is displayed
  if (isDropdownToggled || isChildToggled || !executable) {
    return clickable;
  }
  return (
    <OverlayTrigger
      trigger={['hover', 'hover']}
      placement={isChildToggled && child ? 'top' : 'bottom'}
      delay={{ show: 150, hide: 0 }}
      overlay={renderPopover()}
    >
      {child ? renderButton() : clickable}
    </OverlayTrigger>
  );
};
