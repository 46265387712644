export var fr = {
  emailAddress: "Adresse Email",
  password: "Mot de passe",
  firstName: "Prénom",
  lastName: "Nom de famille",
  company: "Compagnie/Entreprise",
  phone: "Numéro de téléphone",
  jobTitle: "Intitulé du poste",
  disclaimer: "<0>En soumettant les informations, vous acceptez les <1>conditions générales d'utilisation</1>." + " Pour plus d'informations sur la manière dont Videomenthe traite les informations, se référer à la " + "<3>déclaration de confidentialité</3>. " + "Nous sommes susceptibles de vous envoyer les e-mails concernant votre compte.</0>",
  submit: "Envoyer",
  register: "Inscription",
  signIn: "Connexion",
  passwordReset: "Changement de mot de passe",
  alreadyHaveAccount: "Vous avez déjà un compte ?",
  forgotPassword: "Vous avez oublié votre mot de passe ?",
  dontHaveAccount: "Vous n'avez pas de compte ?",
  jobTitles: {
    undefined: "-- sélectionner une option --",
    antennaManager: "gestionnaire d'antenne",
    assistantDirector: "assistant de réalisation",
    audiovisualEditor: "monteur audiovisuel",
    broadcastManager: "chargé de diffusion",
    chiefEditor: "rédacteur en chef",
    chiefInformationOfficer: "directeur des SI",
    communityManager: "community manager",
    contentCreator: "créateur de contenu/infuenceur",
    digitalImageRestorer: "restaurateur numérique image",
    digitalManager: "directeur digital",
    director: "réalisateur",
    distributor: "distributeur",
    documentalist: "documentaliste",
    entrepreneur: "entrepreneur",
    executiveDirector: "directeur exécutif",
    formatDeveloper: "développeur de formats",
    freelance: "indépendant/freelance",
    humanResourcesManager: "responsable RH",
    imageConsultant: "consultant/conseiller en image",
    itDeveloperWebIntegrator: "dévellopeur informatique/intégrateur web",
    journalist: "journaliste",
    mediaProjectManager: "chef de projet media",
    operationsManager: "responsable d'exploitation",
    postProductionManager: "responsable de post-production",
    producer: "producteur",
    productionAssistant: "assistant de production",
    productionManager: "responsable de production",
    programmingOfficer: "chargé de programmation",
    soundEngineer: "ingénieur son",
    technicalManager: "directeur technique",
    translatorInterpreterLocalizer: "traducteur/interprète/localisateur",
    videoEngineer: "ingénieur vidéo",
    visualMarketingCommunicationManager: "responsable marketing/communication visuelle"
  }
};