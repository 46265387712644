var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React from "react";
function useOpenNewWindow(props) {
  return React.useCallback(function () {
    var attrs = Object.entries(props.window).map(function (_a) {
      var key = _a[0],
        value = _a[1];
      return key + "=" + value;
    }).join(",");
    window.open(props.url, props.url, attrs);
  }, [props]);
}
export var PopupLink = function PopupLink(props) {
  return props.window ? React.createElement(WindowPopupLink, {
    url: props.url,
    window: props.window
  }, props.children) : React.createElement(BlankTargetPopupLink, __assign({}, props), props.children);
};
var BlankTargetPopupLink = function BlankTargetPopupLink(props) {
  return React.createElement("a", {
    href: props.url,
    target: "_blank",
    rel: "noopener noreferrer"
  }, props.children);
};
var WindowPopupLink = function WindowPopupLink(props) {
  return React.createElement("span", {
    className: "link-button",
    onClick: useOpenNewWindow(props)
  }, props.children);
};