import React from 'react';
import { Table } from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash, faExclamationTriangle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { startCase, camelCase } from 'lodash';
import { LinkButton } from '@eolementhe/react-components';
import { FileResource, IVideoResource } from '@eolementhe/video-editor-model';
import { useRelayEnvironment } from 'react-relay';
import { useDispatch } from 'react-redux';
import { IDirectoryViewProps, ILinkedProject } from './IDirectoryViewProps';
import { IResourceInfo } from './IResourceInfo';
import { ResourceUtils } from './ResourceUtils';
import { createServerFileUrl } from '../../utils/uploadFiles';
import { handleError } from '../../utils/handleError';
import fetchAllProjects from '../../api/fetchAllProjects';
import { doSelectResources } from '../../redux/actions';

export const ListView: React.FC<IDirectoryViewProps> = (props) => {
  const handleOnSelect = (resource: FileResource, selected: boolean) => {
    props.onSelectResources([resource], selected);
  };
  const handleSelectAll = (selected: boolean) => {
    props.onSelectResources([...props.resources], selected);
  };
  return (
    <div className="flex-fill overflow-y-auto min-height-0">
      <Table hover size="sm" responsive>
        <TableHead {...props} selectedResources={props.selectedResources} onSelectAll={handleSelectAll} />
        <TableBody {...props} selectedResources={props.selectedResources} onSelect={handleOnSelect} />
      </Table>
    </div>
  );
};

type TableHeadProps = Pick<
  IDirectoryViewProps,
  | 'canSelect'
  | 'resources'
  | 'selectedResources'
  | 'canDownload'
  | 'onRemoveResources'
  | 'onSelectResources'
  | 'onAddTracks'
> &
  SelectAllProps;
const TableHead: React.FC<TableHeadProps> = (props) => {
  const displayCondition = (props.canDownload && props.onRemoveResources) || props.onAddTracks;
  return (
    <thead>
      <tr>
        {props.canSelect && <SelectAll {...props} />}
        <th className="w-100">Name</th>
        <th className="w-auto text-nowrap"> </th>
        <th className="w-auto text-nowrap">Type</th>
        <th className="w-auto text-nowrap">Size</th>
        {displayCondition && <th className="w-auto text-nowrap">Actions</th>}
      </tr>
    </thead>
  );
};

type SelectAllProps = Pick<IDirectoryViewProps, 'canSelect' | 'resources' | 'selectedResources'> & {
  onSelectAll: (selected: boolean) => void;
};
const SelectAll: React.FC<SelectAllProps> = ({ resources, selectedResources, onSelectAll }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onSelectAll(e.target.checked);
  const isAllSelected = !!(
    selectedResources &&
    selectedResources.length &&
    resources.length === selectedResources.length
  );
  return (
    <th className="w-auto pr-0">
      <input type="checkbox" aria-label={`Select All`} checked={isAllSelected} onChange={handleChange} />
    </th>
  );
};

type TableBodyProps = Pick<
  IDirectoryViewProps,
  'selectedResources' | 'resources' | 'onClickResource' | 'onRemoveResources' | 'onAddTracks'
> & {
  onSelect: (resource: FileResource, selected: boolean) => void;
};

const TableBody: React.FC<TableBodyProps> = (props) => {
  const [projects, setProjects] = React.useState<any[]>([]);
  const environment = useRelayEnvironment();
  React.useEffect(() => {
    let mounted = true;
    fetchAllProjects(environment)
      .then((allProjects) => {
        if (mounted) {
          setProjects(allProjects);
        }
      })
      .catch((err) => {
        handleError(err, 'Cannot get projects');
      });

    return () => {
      mounted = false;
    };
  }, [environment]);

  return (
    <tbody>
      {props.resources.map(ResourceUtils.getResourceInfo).map((info) => (
        <ResourceRow key={info.resource._id} projects={projects} {...props} resourceInfo={info} />
      ))}
    </tbody>
  );
};

type ResourceRowProps = Pick<
  IDirectoryViewProps,
  'canSelect' | 'canDownload' | 'onRemoveResources' | 'onClickResource' | 'selectedResources' | 'onAddTracks'
> & {
  projects: any[];
  resourceInfo: IResourceInfo;
  onSelect: (resource: FileResource, selected: boolean) => void;
};

const ResourceRow: React.FC<ResourceRowProps> = ({ resourceInfo: { icon, resource, type, size }, ...props }) => {
  const [linkedProjects, setLinkedProjects] = React.useState<any[]>([]);
  const handleNameColumnClick = () => (props.onClickResource ? props.onClickResource(resource) : undefined);
  const displayCondition = (props.canDownload && props.onRemoveResources) || props.onAddTracks;
  const dispatch = useDispatch();
  React.useEffect(() => {
    setLinkedProjects(ResourceUtils.getLinkedProjects(resource, props.projects));
  }, [setLinkedProjects, props.projects, resource]);
  const handleDragStart = (event: React.DragEvent<HTMLTableRowElement>) => {
    dispatch(doSelectResources([resource], true));
  };
  return (
    <tr draggable onDragStart={handleDragStart}>
      {props.canSelect && (
        <CheckboxColumn
          canSelect={props.canSelect}
          selectedResources={props.selectedResources}
          onSelect={props.onSelect}
          resource={resource}
        />
      )}
      <NameColumn icon={icon} name={resource.name} onClick={handleNameColumnClick} />
      <LinkedProjectColumn linkedProjects={linkedProjects} resource={resource} />
      <TypeColumn type={type} />
      <SizeColumn size={size} />
      {displayCondition && (
        <ActionsColumn
          canDownload={props.canDownload}
          resource={resource}
          onRemoveResources={props.onRemoveResources}
          linkedProjects={linkedProjects}
          onAddTracks={props.onAddTracks}
        />
      )}
    </tr>
  );
};

type CheckboxColumnProps = Pick<IDirectoryViewProps, 'canSelect' | 'selectedResources'> & {
  resource: FileResource;
  onSelect: (resource: FileResource, selected: boolean) => void;
};
const CheckboxColumn: React.FC<CheckboxColumnProps> = ({ canSelect, selectedResources, onSelect, resource }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(resource, e.target.checked);
  };
  return (
    <td className="w-auto pr-0">
      <input
        type="checkbox"
        aria-label={`Select ${resource.name}`}
        disabled={!canSelect}
        checked={
          !!(
            selectedResources &&
            selectedResources.find((selectedResource: FileResource) => selectedResource._id === resource._id)
          )
        }
        onChange={handleChange}
      />
    </td>
  );
};

function createTooltipText(linkedProjects: any[], ingestInEolementhe: boolean | undefined): string {
  let title = '';
  if (linkedProjects.length > 0) {
    title += `${linkedProjects.length} Eole-Edit projects`;
    if (ingestInEolementhe) {
      title += ', ';
    }
  }
  if (ingestInEolementhe) {
    title += `1 Eolementhe workflow`;
  }
  return title;
}

interface ILinkedProjectColumnProps {
  linkedProjects: any[];
  resource: FileResource;
}

const LinkedProjectColumn: React.FC<ILinkedProjectColumnProps> = ({ linkedProjects, resource }) => {
  const ingestInEolementhe = (resource as IVideoResource).ingestInEolementhe;
  const linkedFileCount = linkedProjects.length + Number(ingestInEolementhe);
  return (
    <td
      className="w-auto text-nowrap unselectable-text text-center font-weight-light font-italic"
      title={createTooltipText(linkedProjects, ingestInEolementhe)}
    >
      {(linkedProjects.length > 0 || ingestInEolementhe) && (
        <>
          <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
          <span className="align-middle"> Linked file{linkedFileCount > 1 ? 's' : ''}</span>
        </>
      )}
    </td>
  );
};

interface INameColumnProps {
  icon: IconProp;
  name: string;
  onClick: () => void;
}
const NameColumn: React.FC<INameColumnProps> = ({ icon, name, onClick }) => (
  <td className="w-100 unselectable-text clickable" onClick={onClick}>
    <FontAwesomeIcon icon={icon} size="sm" fixedWidth />
    &nbsp;{name}
  </td>
);

const TypeColumn: React.FC<{ type: string }> = ({ type }) => (
  <td className="w-auto text-nowrap unselectable-text">{startCase(camelCase(type))}</td>
);

const SizeColumn: React.FC<{ size: string }> = ({ size }) => (
  <td className="w-auto text-nowrap unselectable-text">{size}</td>
);

type ActionsColumnProps = Pick<IDirectoryViewProps, 'canDownload' | 'onRemoveResources' | 'onAddTracks'> & {
  linkedProjects: ILinkedProject[];
  resource: FileResource;
};
const ActionsColumn: React.FC<ActionsColumnProps> = ({ canDownload, resource, onRemoveResources, onAddTracks }) => (
  <>
    <td className="unselectable-text d-flex justify-content-center">
      {canDownload && <DownloadLink url={(resource as FileResource).src} />}
      {onRemoveResources && <RemoveButton resource={resource} onRemoveResources={onRemoveResources} />}
      {onAddTracks && <AddTrackButton resource={resource} onAddTracks={onAddTracks} />}
    </td>
  </>
);

const AddTrackButton: React.FC<{ resource: FileResource; onAddTracks: (resource?: FileResource) => void }> = ({
  resource,
  onAddTracks
}) => {
  return (
    <LinkButton onClick={() => onAddTracks(resource)}>
      <FontAwesomeIcon icon={faPlus} fixedWidth title={'Add to current track'} />
    </LinkButton>
  );
};

const DownloadLink: React.FC<{ url: string }> = ({ url }) => {
  return (
    <a href={createServerFileUrl(url, true)} target="_blank" download rel="noopener noreferrer">
      <FontAwesomeIcon icon={faDownload} fixedWidth />
    </a>
  );
};

const RemoveButton: React.FC<{
  resource: FileResource;
  onRemoveResources: IDirectoryViewProps['onRemoveResources'];
}> = ({ resource, onRemoveResources }) => {
  const handleRemove = React.useCallback(() => {
    if (onRemoveResources) {
      onRemoveResources([resource]);
    }
  }, [onRemoveResources, resource]);
  return (
    <>
      <LinkButton onClick={handleRemove}>
        <FontAwesomeIcon icon={faTrash} fixedWidth />
      </LinkButton>
    </>
  );
};
