import Konva from 'konva';
// import { get } from "lodash";
import React from 'react';
import { Group, Layer, Stage, Text, Transformer, Rect } from 'react-konva';
import ResizeObserver from 'react-resize-observer';
import {
  IAudioSection,
  IImageSection,
  IKonvaNode,
  IKonvaNodeBounds,
  IMediaTrack,
  IPosition,
  ITextSection,
  IVideoSection,
  IVideoTrack,
  IAudioTrack,
  IVisibleTrack,
  MediaSection,
  SectionKind,
  Track,
  TrackKind,
  TrackSection,
  CssFontFamily,
  IWaveformSection,
  IVideoTransitionSection,
  IProject
} from '@eolementhe/video-editor-model';
import { VideoTrack } from './VideoTrack';

import { IKonvaDimension } from './IKonvaDimension';
import { KonvaUrlImage } from './KonvaUrlImage';
import { UrlAudio } from './UrlAudio';
import { KonvaTextArea } from './KonvaTextArea';
import { KonvaVideo } from './KonvaVideo';
import { createServerFileUrl } from '../../../utils/uploadFiles';
import { KonvaWaveform } from './KonvaWaveform';

interface IProps {
  tracks: Track[];
  currentTrackIndex?: number;
  currentSectionIndex?: number;
  currentTime: number;
  isPlaying: boolean;
  videoWidth: number;
  videoHeight: number;
  scale: number;
  hideOffscreen: boolean;
  initialHeight: number;
  initialWidth: number;
  autoZoom: boolean;
  project: IProject;
  onChangeScale(scale: number): void;
  onUpdateText(trackIndex: number, sectionIndex: number, text: string): void;
  onChangeCurrentSection(sectionIndex?: number, trackIndex?: number): void;
  onChangeOutputVolume(volume: number, trackIndex?: number, sectionIndex?: number): void;
  onChangeKonvaNodeBounds(trackIndex: number, sectionId: string, bounds: IKonvaNodeBounds): void;
  onAutoZoom(): void;
  openTextPanel(): void;
}

interface IState {
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
  editingText?: {
    node: Konva.Text;
    // node: Konva.Label;
    trackIndex: number;
    sectionIndex: number;
    stagePosition: IPosition;
  };
}

interface IImageSize {
  width: number;
  height: number;
}

export class Viewport extends React.PureComponent<IProps, IState> {
  private containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  private stage?: Konva.Stage = undefined;

  private layer: React.RefObject<Konva.Layer> = React.createRef();

  private transformer: React.RefObject<Konva.Transformer> = React.createRef();

  private textArea = React.createRef<HTMLTextAreaElement>();

  // private currentShapeRef: React.RefObject<Konva.Shape> | undefined;
  private currentShapeRef: any;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      scaleX: 1,
      scaleY: 1
    };
    this.handleContainerResize = this.handleContainerResize.bind(this);
    this.createSection = this.createSection.bind(this);
    this.handleShapeMouseEnter = this.handleShapeMouseEnter.bind(this);
    this.handleShapeMouseLeave = this.handleShapeMouseLeave.bind(this);
    this.handleShapeClick = this.handleShapeClick.bind(this);
    this.handleShapeDragStart = this.handleShapeDragStart.bind(this);
    this.handleShapeDragMove = this.handleShapeDragMove.bind(this);
    this.handleShapeDragEnd = this.handleShapeDragEnd.bind(this);
    this.handleShapeTransformStart = this.handleShapeTransformStart.bind(this);
    this.handleShapeTransform = this.handleShapeTransform.bind(this);
    this.handleShapeTransformEnd = this.handleShapeTransformEnd.bind(this);
    this.handleDoubleClickText = this.handleDoubleClickText.bind(this);
    this.handleHideTextAreaClick = this.handleHideTextAreaClick.bind(this);
    this.handleHideTextArea = this.handleHideTextArea.bind(this);
    this.getCurrentVisibility = this.getCurrentVisibility.bind(this);
  }

  public componentDidMount() {
    if (!this.containerRef.current) {
      return;
    }
    this.setState({
      width: this.containerRef.current.offsetWidth,
      height: this.containerRef.current.offsetHeight
    });
    window.addEventListener('resize', this.handleContainerResize);
    // Auto zoom on new project screen or opening an existing project.
    this.autoZoom();
  }

  public componentWillUnmount() {
    window.addEventListener('resize', this.handleContainerResize);
  }

  public componentDidUpdate(oldProps: IProps) {
    if (
      this.currentShapeRef !== undefined &&
      this.currentShapeRef.current !== null &&
      this.transformer.current !== null
    ) {
      this.transformer.current.setNode(this.currentShapeRef.current);
    } else if (this.transformer.current !== null && (!this.currentShapeRef || !this.currentShapeRef.current)) {
      this.transformer.current.detach();
    }
    if (this.layer.current !== null) {
      this.layer.current.batchDraw();
    }
    if (this.props.autoZoom !== oldProps.autoZoom) {
      this.autoZoom();
    }
  }

  private handleContainerResize() {
    if (this.containerRef.current === null || this.stage === undefined) {
      return;
    }
    // now we need to fit stage into parent
    const containerWidth = this.containerRef.current.offsetWidth;
    // to do this we need to scale the stage
    const scale = containerWidth / this.state.width;

    this.setState({
      width: this.containerRef.current.offsetWidth,
      height: this.containerRef.current.offsetHeight,
      scaleX: scale,
      scaleY: scale
    });
    this.stage.batchDraw();
  }

  private getViewportDimensions() {
    const videoWidth = this.props.videoWidth * this.props.scale;
    const videoHeight = this.props.videoHeight * this.props.scale;
    const isBiggerWidth =
      this.containerRef.current === null ? true : videoWidth > this.containerRef.current.offsetWidth;
    const isBiggerHeight =
      this.containerRef.current === null ? true : videoHeight > this.containerRef.current.offsetHeight;
    const stageWidth = isBiggerWidth ? videoWidth : this.state.width;
    const stageHeight = isBiggerHeight ? videoHeight : this.state.height;
    const stageScaleX = isBiggerWidth ? 1 : this.state.scaleX;
    const stageScaleY = isBiggerHeight ? 1 : this.state.scaleY;
    const videoX = (videoWidth * stageScaleX) / 2 - (this.props.scale * this.props.videoWidth) / 2;
    const videoY = (videoHeight * stageScaleX) / 2 - (this.props.scale * this.props.videoHeight) / 2;
    return {
      videoX,
      videoY,
      videoWidth,
      videoHeight,
      stageWidth,
      stageHeight,
      stageScaleX,
      stageScaleY
    };
  }

  // private inspectNode(track: MediaSection) {
  //   const isShape = track.kind === TrackKind.shape;
  //   const shape = isShape ? (track as IShapeTrack & IKonvaNode) : undefined;
  //   const isPath =
  //   isShape && shape !== undefined && shape.shapeKind === ShapeKind.path;
  //   const path = isPath ? (shape as IPathTrack & IKonvaNode) : undefined;
  //   const noOffset =
  //     isShape &&
  //     shape !== undefined &&
  //     (hasSingleRadius || shape.shapeKind === ShapeKind.ellipse);
  //   const hasSingleRadius =
  //     isShape &&
  //     shape !== undefined &&
  //     (shape.shapeKind === ShapeKind.regularPolygon ||
  //       shape.shapeKind === ShapeKind.wedge ||
  //       shape.shapeKind === ShapeKind.star);
  //   return { isShape, shape, hasSingleRadius, noOffset, isPath, path };
  // }

  // private inspectNode(section: Omit<MediaSection, "visible">) {
  //   const isShape = false;
  //   const shape = undefined;
  //   const isPath = false;
  //   const path = undefined;
  //   const noOffset = false;
  //   const hasSingleRadius = false;
  //   return { isShape, shape, hasSingleRadius, noOffset, isPath, path };
  // }

  // private getNodeDimensions(
  //   track: Omit<IKonvaNode & MediaSection, "visible">
  // ): IKonvaDimension {
  //   const { videoX, videoY } = this.getViewportDimensions();
  //   const { hasSingleRadius, noOffset, isPath, path } = this.inspectNode(track);
  //   const pathScaleX =
  //     isPath && path !== undefined ? track.width / path.pathSize.width : NaN;
  //   const pathScaleY =
  //     isPath && path !== undefined ? track.height / path.pathSize.height : NaN;
  //   const offsetX =
  //     isPath && path !== undefined
  //       ? path.pathSize.width / 2
  //       : (track.width / 2) * this.props.scale;
  //   const offsetY =
  //     isPath && path !== undefined
  //       ? path.pathSize.height / 2
  //       : (track.height / 2) * this.props.scale;
  //   const width = track.width * this.props.scale;
  //   const height = track.height * this.props.scale;
  //   const scaleX =
  //     hasSingleRadius && width < height
  //       ? width / height
  //       : isPath
  //       ? pathScaleX * this.props.scale
  //       : 1;
  //   const scaleY =
  //     hasSingleRadius && height < width
  //       ? height / width
  //       : isPath
  //       ? pathScaleY * this.props.scale
  //       : 1;
  //   const x =
  //     track.x * this.props.scale + videoX + offsetX * (isPath ? scaleX : 1);
  //   const y =
  //     track.y * this.props.scale + videoY + offsetY * (isPath ? scaleY : 1);
  //   const radius = hasSingleRadius ? Math.max(width, height) / 2 : NaN;
  //   const radiusX = hasSingleRadius ? NaN : width / 2;
  //   const radiusY = hasSingleRadius ? NaN : height / 2;
  //   const dimensions = {
  //     x,
  //     y,
  //     width,
  //     height,
  //     offsetX: noOffset ? 0 : offsetX,
  //     offsetY: noOffset ? 0 : offsetY,
  //     scaleX,
  //     scaleY,
  //     radius,
  //     radiusX,
  //     radiusY,
  //   };
  //   return dimensions;
  // }

  private getNodeDimensions(section: Omit<IKonvaNode & MediaSection, 'visible'>): IKonvaDimension {
    const { videoX, videoY } = this.getViewportDimensions();
    const offsetX = (section.width / 2) * this.props.scale;
    const offsetY = (section.height / 2) * this.props.scale;
    const width = section.width * this.props.scale;
    const height = section.height * this.props.scale;
    const scaleX = 1;
    const scaleY = 1;
    const x = section.x * this.props.scale + videoX + offsetX;
    const y = section.y * this.props.scale + videoY + offsetY;
    const radius = NaN;
    const radiusX = width / 2;
    const radiusY = height / 2;
    const crop = this.getCrop(
      { width: section.initialWidth!, height: section.initialHeight! },
      { width: section.width, height: section.height }
    );

    const dimensions = {
      x,
      y,
      width,
      height,
      offsetX,
      offsetY,
      scaleX,
      scaleY,
      radius,
      radiusX,
      radiusY,
      cropX: crop.cropX,
      cropY: crop.cropY,
      cropWidth: crop.cropWidth,
      cropHeight: crop.cropHeight
    };
    return dimensions;
  }

  // private getKonvaFill(track: ITextSection, dimensions: IKonvaDimension) {
  //   const fill = track.fill;
  //   const colorStops = get(fill, "colorStops", [])
  //     .map((stop: IGradientColorStop) => [stop.position, stop.color])
  //     .flat();
  //   const { noOffset, hasSingleRadius } = this.inspectNode(track);
  //   const pathFactorX = dimensions.scaleX;
  //   const pathFactorY = dimensions.scaleY;
  //   const maxSize = Math.max(dimensions.width, dimensions.height);
  //   const width =
  //     noOffset && hasSingleRadius ? maxSize : dimensions.width * pathFactorX;
  //   const height =
  //     noOffset && hasSingleRadius ? maxSize : dimensions.height * pathFactorY;
  //   const offsetX = noOffset ? width / 2 : 0;
  //   const offsetY = noOffset ? height / 2 : 0;
  //   const radius = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / 2;
  //   const fillInfo = {
  //     priority: this.getFillPriority(fill),
  //     color: get(fill, "color", DEFAULT_FILL.solid.color),
  //     start: {
  //       x: get(fill, "startX", NaN) * width - offsetX,
  //       y: get(fill, "startY", NaN) * height - offsetY,
  //     },
  //     end: {
  //       x: get(fill, "endX", NaN) * width - offsetX,
  //       y: get(fill, "endY", NaN) * height - offsetY,
  //     },
  //     startRadius: get(fill, "startRadius", 0) * radius,
  //     endRadius: get(fill, "endRadius", 0) * radius,
  //     colorStops,
  //   };
  //   return fillInfo;
  // }

  private isTrackVisible(track: Track, section: TrackSection) {
    const visible = track.kind === TrackKind.audio || (track as unknown as IVisibleTrack).visible;
    return (
      visible && section.start <= this.props.currentTime && section.start + section.duration >= this.props.currentTime
    );
  }

  private getCurrentVisibility() {
    if (this.props.currentTrackIndex != null && this.props.currentSectionIndex != null) {
      const currentTrack = this.props.tracks[this.props.currentTrackIndex];
      const currentSection = currentTrack.sections[this.props.currentSectionIndex];
      if (!currentSection) return false;
      return this.isTrackVisible(currentTrack, currentSection);
    }
    return false;
  }

  private createSection(track: Track, section: TrackSection, trackIndex: number, sectionIndex: number) {
    const isSelected = trackIndex === this.props.currentTrackIndex && sectionIndex === this.props.currentSectionIndex;
    let ref;
    let node;

    switch (section.kind) {
      case SectionKind.video:
        if ((section.srcId || section.lowres) && this.props.project.isSameVideoSizeProject === 'cut') {
          ref = React.createRef<Konva.Image>();
          node = this.createVideo(
            section as IVideoSection,
            track as IVideoTrack,
            section.id,
            trackIndex,
            sectionIndex,
            ref
          );
        }
        break;
      case SectionKind.videoTransition:
        break;
      case SectionKind.text:
        ref = isSelected ? React.createRef<Konva.Text>() : undefined;
        node = this.createText(section as ITextSection, track, trackIndex, sectionIndex, ref);
        break;
      case SectionKind.image:
        if (section.srcId || section.lowres) {
          ref = isSelected ? React.createRef<Konva.Image>() : undefined;
          node = this.createImage(
            section as IImageSection,
            track as IMediaTrack,
            section.id,
            trackIndex,
            sectionIndex,
            ref
          );
        }
        break;
      case SectionKind.audio:
        if (section.srcId || section.lowres) {
          node = this.createAudio(section as IAudioSection, track as IAudioTrack, section.id);
        }
        break;
      case SectionKind.waveform:
        if (section.srcId || section.lowres) {
          ref = undefined; // ref is used by konva Transformer to resize, but we don't use it with waveform section
          node = this.createWaveform(
            section as IWaveformSection,
            track as IMediaTrack,
            section.id,
            trackIndex,
            sectionIndex,
            ref
          );
        }
        break;
      default:
        throw new Error('Unsupported section type');
    }
    if (isSelected) {
      this.currentShapeRef = ref;
    }
    return node;
  }

  private selectNode(sectionIndex: number, trackIndex: number) {
    if (this.props.currentTrackIndex !== trackIndex || this.props.currentSectionIndex !== sectionIndex) {
      this.props.onChangeCurrentSection(sectionIndex, trackIndex);
    }
  }

  private createText(
    section: ITextSection,
    track: Track,
    trackIndex: number,
    sectionIndex: number,
    ref: React.RefObject<Konva.Text> | undefined
  ) {
    const dimensions = this.getNodeDimensions(section);
    const isEditing =
      this.state.editingText !== undefined &&
      this.state.editingText.trackIndex === trackIndex &&
      this.state.editingText.sectionIndex === sectionIndex;
    return (
      <React.Fragment key={section.id}>
        <Rect
          x={dimensions.x}
          y={dimensions.y}
          width={dimensions.width}
          height={dimensions.height}
          fill={section.backgroundColor}
          offsetX={dimensions.offsetX}
          offsetY={dimensions.offsetY}
          rotation={section.rotation}
          visible={this.isTrackVisible(track, section) && !isEditing}
        />
        <Text
          ref={ref}
          text={section.text}
          fontSize={section.fontSize * this.props.scale}
          fontFamily={CssFontFamily[section.font].konvaName}
          fontStyle={section.fontStyle}
          fillPriority="solid"
          fill={section.fontColor}
          stroke={section.borderColor}
          strokeEnabled={section.borderSize > 0}
          strokeWidth={section.borderSize * this.props.scale}
          opacity={section.opacity}
          visible={this.isTrackVisible(track, section) && !isEditing}
          x={dimensions.x}
          y={dimensions.y}
          width={dimensions.width}
          height={dimensions.height}
          offsetX={dimensions.offsetX}
          offsetY={dimensions.offsetY}
          scaleX={dimensions.scaleX}
          scaleY={dimensions.scaleY}
          align="center"
          verticalAlign="middle"
          rotation={section.rotation}
          draggable
          onMouseEnter={() => this.handleShapeMouseEnter()}
          onMouseLeave={() => this.handleShapeMouseLeave()}
          onClick={() => this.handleShapeClick(section.id, track.id)}
          onDragStart={(e) => this.handleShapeDragStart(section.id, track.id, trackIndex, e)}
          onDragMove={(e) => this.handleShapeDragMove(trackIndex, section.id, e)}
          onDragEnd={(e) => this.handleShapeDragEnd(trackIndex, section.id, e)}
          onTransformStart={(e) => this.handleShapeTransformStart(trackIndex, section.id, e)}
          onTransform={(e) => this.handleShapeTransform(trackIndex, section.id, e)}
          onTransformEnd={(e) => this.handleShapeTransformEnd(trackIndex, section.id, e)}
          onDblClick={() => this.handleDoubleClickText(trackIndex, sectionIndex, ref)}
        />
      </React.Fragment>
    );
  }

  private createImage(
    section: IImageSection,
    track: IMediaTrack,
    key: string,
    trackIndex: number,
    sectionIndex: number,
    ref: React.RefObject<Konva.Image> | undefined
  ) {
    const dimensions = this.getNodeDimensions(section);
    const src = createServerFileUrl(section.lowres || section.src);

    return (
      <KonvaUrlImage
        key={key}
        imageRef={ref}
        src={src}
        opacity={section.opacity}
        visible={this.isTrackVisible(track, section)}
        x={dimensions.x}
        y={dimensions.y}
        width={dimensions.width}
        height={dimensions.height}
        offsetX={dimensions.offsetX}
        offsetY={dimensions.offsetY}
        scaleX={dimensions.scaleX}
        scaleY={dimensions.scaleY}
        rotation={section.rotation}
        onImageLoaded={() => {}}
        onMouseEnter={() => this.handleShapeMouseEnter()}
        onMouseLeave={() => this.handleShapeMouseLeave()}
        onClick={() => this.handleShapeClick(section.id, track.id)}
        onDragStart={(e) => this.handleShapeDragStart(section.id, track.id, trackIndex, e)}
        onDragMove={(e) => this.handleShapeDragMove(trackIndex, section.id, e)}
        onDragEnd={(e) => this.handleShapeDragEnd(trackIndex, section.id, e)}
        onTransformStart={(e) => this.handleShapeTransformStart(trackIndex, section.id, e)}
        onTransform={(e) => this.handleShapeTransform(trackIndex, section.id, e)}
        onTransformEnd={(e) => this.handleShapeTransformEnd(trackIndex, section.id, e)}
      />
    );
  }

  private createVideo(
    section: IVideoSection,
    track: IVideoTrack,
    key: string,
    trackIndex: number,
    sectionIndex: number,
    ref: React.RefObject<Konva.Image> | undefined
  ) {
    const dimensions = this.getNodeDimensions(section);
    const src = createServerFileUrl(section.lowres || section.src);
    return (
      <KonvaVideo
        duration={section.duration}
        startTime={section.start}
        key={key}
        videoRef={ref}
        src={src}
        opacity={section.opacity}
        visible={this.isTrackVisible(track, section)}
        x={dimensions.x}
        y={dimensions.y}
        width={dimensions.width}
        height={dimensions.height}
        offsetX={dimensions.offsetX}
        offsetY={dimensions.offsetY}
        scaleX={dimensions.scaleX}
        scaleY={dimensions.scaleY}
        rotation={section.rotation}
        onVideoLoaded={() => {}}
        onMouseEnter={() => this.handleShapeMouseEnter()}
        onMouseLeave={() => this.handleShapeMouseLeave()}
        onClick={() => this.handleShapeClick(section.id, track.id)}
        onDragStart={(e) => this.handleShapeDragStart(section.id, track.id, trackIndex, e)}
        onDragMove={(e) => this.handleShapeDragMove(trackIndex, section.id, e)}
        onDragEnd={(e) => this.handleShapeDragEnd(trackIndex, section.id, e)}
        onTransformStart={(e) => this.handleShapeTransformStart(trackIndex, section.id, e)}
        onTransform={(e) => this.handleShapeTransform(trackIndex, section.id, e)}
        onTransformEnd={(e) => this.handleShapeTransformEnd(trackIndex, section.id, e)}
        isPlaying={this.props.isPlaying}
        sectionStartMedia={section.startMedia}
        sectionStart={section.start}
        sectionVolume={section.volume}
        muted={track.muted}
        currentTime={this.props.currentTime + (section.startMedia - section.start)}
        videoSection={section}
        cropX={dimensions.cropX}
        cropY={dimensions.cropY}
        cropWidth={dimensions.cropWidth}
        cropHeight={dimensions.cropHeight}
      />
    );
  }

  private createAudio(section: IAudioSection, track: IAudioTrack | IMediaTrack, key: string) {
    const src = createServerFileUrl(section.lowres || section.src);

    return (
      <UrlAudio
        key={key}
        visible={this.isTrackVisible(track, section)}
        src={src}
        mute={track.muted}
        volume={section.volume}
        isPlaying={this.props.isPlaying}
        currentTime={this.props.currentTime + (section.startMedia - section.start)}
        //onChangeOutputVolume={(volume) => this.props.onChangeOutputVolume(volume, section.id)}
      />
    );
  }

  private createWaveform(
    section: IWaveformSection,
    track: IMediaTrack,
    key: string,
    trackIndex: number,
    sectionIndex: number,
    ref: React.RefObject<Konva.Group> | undefined
  ) {
    const dimensions = this.getNodeDimensions(section);
    const src = createServerFileUrl(section.lowres || section.src);
    const waveCountBars = 128;

    return (
      <KonvaWaveform
        key={key}
        waveformRef={ref}
        src={src}
        visible={this.isTrackVisible(track, section)}
        x={dimensions.x}
        y={dimensions.y}
        width={dimensions.width}
        height={dimensions.height}
        size={section.size}
        amplitudeDegree={section.amplitudeDegree}
        offsetX={dimensions.offsetX}
        offsetY={dimensions.offsetY}
        scaleX={dimensions.scaleX}
        scaleY={dimensions.scaleY}
        opacity={section.opacity}
        isSelected={this.props.currentSectionIndex === sectionIndex && this.props.currentTrackIndex === trackIndex}
        mute={track.muted}
        rotation={section.rotation}
        isPlaying={this.props.isPlaying}
        currentTime={this.props.currentTime + (section.startMedia - section.start)}
        volume={section.volume}
        waveCountBars={waveCountBars}
        onMouseEnter={() => this.handleShapeMouseEnter()}
        onMouseLeave={() => this.handleShapeMouseLeave()}
        onClick={() => this.handleShapeClick(section.id, track.id)}
        onDragStart={(e) => this.handleShapeDragStart(section.id, track.id, trackIndex, e)}
        onDragMove={(e) => this.handleShapeDragMove(trackIndex, section.id, e)}
        onDragEnd={(e) => this.handleShapeDragEnd(trackIndex, section.id, e)}
      />
    );
  }

  private updateShape(trackIndex: number, sectionId: string, node: Konva.Node) {
    // Transformer is changing scale.
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    const { videoX, videoY } = this.getViewportDimensions();

    let x, y, width, height, crop;
    let initialScaleX = 1;
    let initialScaleY = 1;
    if (node instanceof Konva.RegularPolygon || node instanceof Konva.Star || node instanceof Konva.Wedge) {
      const track = this.props.tracks[trackIndex] as any;
      const { videoX, videoY } = this.getViewportDimensions();
      initialScaleX = track.width > track.height ? 1 : track.width / track.height;
      initialScaleY = track.height > track.width ? 1 : track.height / track.width;
      width = (track.width * scaleX) / initialScaleX;
      height = (track.height * scaleY) / initialScaleY;
      const offsetX = width / 2;
      const offsetY = height / 2;
      x = (node.x() - videoX) / this.props.scale - offsetX;
      y = (node.y() - videoY) / this.props.scale - offsetY;
    } else if (node instanceof Konva.Path) {
      const track = this.props.tracks[trackIndex] as any;
      const pathScaleX = track.width / track.pathSize.width;
      const pathScaleY = track.height / track.pathSize.height;
      const offsetX = track.pathSize.width / 2;
      const offsetY = track.pathSize.height / 2;
      initialScaleX = pathScaleX * this.props.scale;
      initialScaleY = pathScaleY * this.props.scale;
      x = (node.x() - videoX - offsetX * scaleX) / this.props.scale;
      y = (node.y() - videoY - offsetY * scaleY) / this.props.scale;
      width = (track.width * scaleX) / this.props.scale / pathScaleX;
      height = (track.height * scaleY) / this.props.scale / pathScaleY;
    } else {
      x = (node.x() - (node.width() * scaleX) / 2 - videoX) / this.props.scale;
      y = (node.y() - (node.height() * scaleY) / 2 - videoY) / this.props.scale;
      width = (node.width() * scaleX) / this.props.scale;
      height = (node.height() * scaleY) / this.props.scale;
      if (node.attrs.image instanceof HTMLVideoElement) {
        const track = this.props.tracks[trackIndex] as any;
        const currentSection: IVideoSection = track.sections.find((section: IVideoSection) => section.id === sectionId);
        crop = this.getCrop(
          { width: node.attrs.image.videoWidth, height: node.attrs.image.videoHeight },
          { width: currentSection.width, height: currentSection.height }
        );
        node.setAttrs(crop);
      }
    }
    node.scaleX(initialScaleX);
    node.scaleY(initialScaleY);
    this.props.onChangeKonvaNodeBounds(trackIndex, sectionId, {
      x,
      y,
      width,
      height,
      rotation: node.rotation(),
      crop,
      initialWidth: node?.attrs.image?.videoWidth ? node.attrs.image.videoWidth : 0,
      initialHeight: node?.attrs.image?.videoHeight ? node.attrs.image.videoHeight : 0
    });
  }

  private getCrop(initialVideoSize: IImageSize, videoSize: IImageSize) {
    const width = videoSize.width;
    const height = videoSize.height;
    const aspectRatio = width / height;
    const imageRatio = initialVideoSize.width / initialVideoSize.height;
    let newWidth = 0;
    let newHeight = 0;
    let x = 0;
    let y = 0;

    if (aspectRatio >= imageRatio) {
      newWidth = initialVideoSize.width;
      newHeight = initialVideoSize.width / aspectRatio;
    } else {
      newWidth = initialVideoSize.height * aspectRatio;
      newHeight = initialVideoSize.height;
    }

    //center-middle positions : https://konvajs.org/docs/sandbox/Scale_Image_To_Fit.html
    x = (initialVideoSize.width - newWidth) / 2;
    y = (initialVideoSize.height - newHeight) / 2;

    return {
      cropX: Math.round(x),
      cropY: Math.round(y),
      cropWidth: Math.round(newWidth),
      cropHeight: Math.round(newHeight)
    };
  }

  private handleShapeMouseEnter() {
    if (this.containerRef.current === null) {
      return;
    }
    this.containerRef.current.style.cursor = 'move';
  }

  private handleShapeMouseLeave() {
    if (this.containerRef.current === null) {
      return;
    }
    this.containerRef.current.style.cursor = 'default';
  }

  private handleShapeClick(sectionId: string, trackId: string) {
    const trackIndex = this.props.tracks.findIndex((track) => track.id === trackId);
    //TODO: Fix ts-ignore
    // @ts-ignore
    const sectionIndex = this.props.tracks[trackIndex].sections.findIndex((section) => section.id === sectionId);
    this.selectNode(sectionIndex, trackIndex);
  }

  private handleShapeDragStart(
    sectionId: string,
    trackId: string,
    trackIndex: number,
    event: Konva.KonvaEventObject<DragEvent>
  ) {
    this.handleShapeClick(sectionId, trackId);
    this.updateShape(trackIndex, sectionId, event.currentTarget);
  }

  private handleShapeDragMove(trackIndex: number, sectionId: string, event: Konva.KonvaEventObject<MouseEvent>) {
    this.updateShape(trackIndex, sectionId, event.currentTarget);
  }

  private handleShapeDragEnd(trackIndex: number, sectionId: string, event: Konva.KonvaEventObject<MouseEvent>) {
    this.updateShape(trackIndex, sectionId, event.currentTarget);
  }

  private handleShapeTransformStart(trackIndex: number, sectionId: string, event: Konva.KonvaEventObject<Event>) {
    this.updateShape(trackIndex, sectionId, event.currentTarget);
  }

  private handleShapeTransform(trackIndex: number, sectionId: string, event: Konva.KonvaEventObject<Event>) {
    this.updateShape(trackIndex, sectionId, event.currentTarget);
  }

  private handleShapeTransformEnd(trackIndex: number, sectionId: string, event: Konva.KonvaEventObject<Event>) {
    this.updateShape(trackIndex, sectionId, event.currentTarget);
  }

  private handleDoubleClickText(
    trackIndex: number,
    sectionIndex: number,
    // ref: React.RefObject<Konva.Text> | undefined
    ref: any
  ) {
    if (this.props.tracks[trackIndex].sections[sectionIndex].kind === SectionKind.text) {
      this.props.openTextPanel();
    }
    if (ref === undefined || ref.current === null || this.stage === undefined) return;
    const stageRect = this.stage.container().getBoundingClientRect();
    this.setState({
      editingText: {
        node: ref.current,
        trackIndex,
        sectionIndex,
        stagePosition: { x: stageRect.left, y: stageRect.top }
      }
    });
    setTimeout(() => {
      window.addEventListener('click', this.handleHideTextAreaClick);
    });
  }

  private handleHideTextAreaClick() {
    if (this.textArea.current) {
      this.handleHideTextArea(this.textArea.current.value);
    }
  }

  private handleHideTextArea(value?: string) {
    window.removeEventListener('click', this.handleHideTextAreaClick);
    if (this.state.editingText === undefined) return;
    this.setState({ editingText: undefined });
    if (value !== undefined) {
      this.props.onUpdateText(this.state.editingText.trackIndex, this.state.editingText.sectionIndex, value);
    }
  }

  private autoZoom() {
    // if (!this.props.autoZoom) return;
    const videoContainer = document.querySelector<HTMLDivElement>('.video-editor-area-container');
    if (videoContainer) {
      const rect = videoContainer.getBoundingClientRect();
      const heightFactor = rect.height / this.props.initialHeight;
      const widthFactor = rect.width / this.props.initialWidth;
      const optimalFactor = Math.min(heightFactor, widthFactor);
      this.props.onChangeScale(optimalFactor);
    }
  }

  private checkDeselect = (e: any) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      this.props.onChangeCurrentSection(undefined, undefined);
    }
  };

  public render() {
    const dimensions = this.getViewportDimensions();
    const cutMode = this.props.project && this.props.project.isSameVideoSizeProject === 'cut';
    const unCutMode = this.props.project && this.props.project.isSameVideoSizeProject === 'uncut';
    const videoTrack = this.props.tracks.filter((track) => track.kind === TrackKind.video)[0];
    const sectionVideo = videoTrack.sections as IVideoSection[];
    const sectionTransition = videoTrack.sections as IVideoTransitionSection[];
    const transitionSections = sectionTransition.filter((section) => section.kind === SectionKind.videoTransition);
    const videoSections = sectionVideo.filter((section) => section.kind === SectionKind.video);

    return (
      <div className="video-editor-preview flex-fill" ref={this.containerRef}>
        <div
          className="video-editor-preview-inner border-wrapper"
          style={{
            position: 'relative',
            width: dimensions.videoWidth,
            height: dimensions.videoHeight,
            margin: '0 auto',
            backgroundColor: 'black',
            overflow: this.props.hideOffscreen ? 'hidden' : 'visible'
          }}
        >
          {unCutMode && (
            <VideoTrack
              videoSections={videoSections}
              transitionSections={transitionSections}
              track={videoTrack as IVideoTrack}
              currentTime={this.props.currentTime}
              width="100%"
              height="100%"
              isPlaying={this.props.isPlaying}
            />
          )}
          <Stage
            ref={(ref) => {
              this.stage = ref?.getStage() ?? undefined;
            }}
            style={{
              position: 'absolute',
              width: dimensions.videoWidth,
              height: dimensions.videoHeight
            }}
            width={dimensions.videoWidth}
            height={dimensions.videoHeight}
            scaleX={dimensions.stageScaleX}
            scaleY={dimensions.stageScaleY}
            onMouseDown={this.checkDeselect}
            onTouchStart={this.checkDeselect}
          >
            <Layer ref={this.layer}>
              <Group>
                {this.props.tracks.map((track, trackIndex) => {
                  if (track.kind === TrackKind.video && cutMode) {
                    return track.sections.map((section: TrackSection, sectionIndex: number) =>
                      this.createSection(track, section, trackIndex, sectionIndex)
                    );
                  }
                  if (track.kind === TrackKind.media) {
                    return track.sections.map((section: TrackSection, sectionIndex: number) =>
                      this.createSection(track, section, trackIndex, sectionIndex)
                    );
                  }
                  if (track.kind === TrackKind.audio) {
                    return track.sections.map((section: TrackSection, sectionIndex: number) =>
                      this.createSection(track, section, trackIndex, sectionIndex)
                    );
                  }
                  return [];
                })}
                <Transformer
                  ref={this.transformer}
                  visible={this.getCurrentVisibility()}
                  rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
                />
              </Group>
            </Layer>
          </Stage>
        </div>
        {this.state.editingText !== undefined && (
          <KonvaTextArea
            value={
              this.props.tracks[this.state.editingText.trackIndex].sections[
                this.state.editingText.sectionIndex
              ] as ITextSection
            }
            node={this.state.editingText.node}
            stagePosition={this.state.editingText.stagePosition}
            onHide={this.handleHideTextArea}
          />
        )}
        <ResizeObserver onResize={this.handleContainerResize} />
      </div>
    );
  }
}
