var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { ButtonSize, ButtonVariant } from '../react-bootstrap-types/Button';
import { ActionPopover } from './ActionPopover';
import { ActionButtonType } from './IAction';
import { mergeClassName } from '../utils';
export var ActionButtonTitleLayout;
(function (ActionButtonTitleLayout) {
  ActionButtonTitleLayout["noTitle"] = "noTitle";
  ActionButtonTitleLayout["noIcon"] = "noIcon";
  ActionButtonTitleLayout["right"] = "right";
  ActionButtonTitleLayout["bottom"] = "bottom";
})(ActionButtonTitleLayout || (ActionButtonTitleLayout = {}));
export var ActionButton = function ActionButton(props) {
  var type = props.type,
    icon = props.icon,
    _a = props.titleLayout,
    titleLayout = _a === void 0 ? ActionButtonTitleLayout.right : _a,
    className = props.className,
    _b = props.size,
    size = _b === void 0 ? ButtonSize.default : _b,
    _c = props.variant,
    variant = _c === void 0 ? ButtonVariant.primary : _c,
    active = props.active,
    placement = props.placement,
    description = props.description,
    execute = props.execute;
  var canExecute = props.canExecute === undefined || props.canExecute;
  var title = props.btnText || props.title;
  var renderContent = function renderContent() {
    switch (titleLayout) {
      case ActionButtonTitleLayout.noTitle:
        return React.createElement(FontAwesomeIcon, {
          icon: icon,
          fixedWidth: true
        });
      case ActionButtonTitleLayout.right:
        return React.createElement("div", {
          className: "d-flex flex-row align-items-center"
        }, React.createElement(FontAwesomeIcon, {
          icon: icon,
          fixedWidth: true
        }), "\xA0", "" + title);
      case ActionButtonTitleLayout.bottom:
        return React.createElement("div", {
          className: "d-flex flex-column align-items-center"
        }, React.createElement(FontAwesomeIcon, {
          icon: icon,
          fixedWidth: true
        }), React.createElement("div", null, title));
      case ActionButtonTitleLayout.noIcon:
        return title;
      default:
        throw new Error("Unsupported title layout \"" + titleLayout + "\"");
    }
  };
  var renderButton = function renderButton() {
    var activeClass = active ? ' active' : '';
    var btnSize = size === ButtonSize.default ? '' : " btn-" + size;
    var defaultClassName = mergeClassName('btn', "btn-" + variant, "" + btnSize + activeClass);
    var buttonProps = {
      type: type === ActionButtonType.submit ? 'submit' : 'button',
      className: className ? mergeClassName(defaultClassName, className) : defaultClassName,
      onClick: execute,
      disabled: !canExecute
    };
    return React.createElement("button", __assign({}, buttonProps), renderContent());
  };
  var renderNavLink = function renderNavLink() {
    return React.createElement(Nav.Link, {
      onClick: execute,
      active: active,
      disabled: !canExecute
    }, renderContent());
  };
  var renderClickable = function renderClickable() {
    switch (type) {
      case undefined:
      case ActionButtonType.button:
      case ActionButtonType.submit:
        return renderButton();
      case ActionButtonType.nav:
        return renderNavLink();
      default:
        throw new Error('Unsupported clickable type');
    }
  };
  var clickable = renderClickable();
  if (!canExecute) {
    return clickable;
  }
  return React.createElement(ActionPopover, {
    placement: placement,
    title: props.title,
    description: description,
    icon: icon
  }, clickable);
};