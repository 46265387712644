import React from 'react';
import { APP_NAME } from '../EditorPage/Constants';
import './HomeLogo.scss';

export const HomeLogo: React.FC = () => {
  return (
    <>
      <img src="/icons/icon-216x216.png" alt="VideoMenthe" className="homepage-logo" />
      <h1>{APP_NAME}</h1>
    </>
  );
};
