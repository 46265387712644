export var en = {
  emailAddress: "Email address",
  password: "Password",
  firstName: "First name",
  lastName: "Last name",
  company: "Company",
  phone: "Phone number",
  jobTitle: "Job title",
  disclaimer: "<0>By creating an account, you agree to the <1>Terms of Service</1>." + " For more information about Videomenthe's privacy practices, see the " + "<3>Videomenthe Privacy Statement</3>. We'll occasionally send you account-related emails.</0>",
  submit: "Submit",
  register: "Register",
  signIn: "Sign In",
  passwordReset: "Password Reset",
  alreadyHaveAccount: "Already have an account?",
  forgotPassword: "Forgot your password?",
  dontHaveAccount: "Don't have an account?",
  jobTitles: {
    undefined: "-- select an option --",
    antennaManager: "antenna manager",
    assistantDirector: "assistant director",
    audiovisualEditor: "audiovisual editor",
    broadcastManager: "broadcast manager",
    chiefEditor: "chief editor",
    chiefInformationOfficer: "chief information officer",
    communityManager: "community manager",
    contentCreator: "content creator",
    digitalImageRestorer: "digital image restorer",
    digitalManager: "digital manager",
    director: "director",
    distributor: "distributor",
    documentalist: "documentalist",
    entrepreneur: "entrepreneur",
    executiveDirector: "executive director",
    formatDeveloper: "format developer",
    freelance: "freelance",
    humanResourcesManager: "human resources manager",
    imageConsultant: "image consultant",
    itDeveloperWebIntegrator: "it developer/web integrator",
    journalist: "journalist",
    mediaProjectManager: "media project manager",
    operationsManager: "operations manager",
    postProductionManager: "post-production manager",
    producer: "producer",
    productionAssistant: "production assistant",
    productionManager: "production manager",
    programmingOfficer: "programming officer",
    soundEngineer: "sound engineer",
    technicalManager: "technical manager",
    translatorInterpreterLocalizer: "translator/interpreter/localizer",
    videoEngineer: "video engineer",
    visualMarketingCommunicationManager: "visual marketing/communication manager"
  }
};