import { IVideoTransitionSection } from '@eolementhe/video-editor-model';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { doSelectResources } from '../../../redux/actions';
import { LabelledTransition } from '../../../types/LabelledTransition';
import { TRANSITIONS } from '../../../utils/Constants';
import './AddVideoTransitionTrack.scss';

interface IProps {
  currentTrackIndex?: number;
  currentSectionIndex?: number;
  section?: IVideoTransitionSection;
  onChangeUpdateTransition(
    content: string | number,
    action: 'durationSec' | 'type',
    trackIndex: number,
    sectionIndex: number,
    oldDuration?: number
  ): void;
  durationTransitionSelected: number;
}

export const AddVideoTransitionTrack: FC<IProps> = ({
  currentSectionIndex,
  currentTrackIndex,
  section,
  onChangeUpdateTransition,
  durationTransitionSelected
}) => {
  const [durationTransition, setDurationTransition] = useState<number>(durationTransitionSelected);
  const [previewsPlaying, setPreviewsPlaying] = useState<boolean[]>([]);
  const dispatch = useDispatch();
  const handleDragStart = (transition: LabelledTransition) => {
    dispatch(doSelectResources([transition.effect], true));
  };
  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSectionIndex == null || currentTrackIndex == null) return;
    if (section) {
      const duration = parseFloat(e.target.value);
      setDurationTransition(duration);
      onChangeUpdateTransition(duration, 'durationSec', currentTrackIndex, currentSectionIndex);
    }
  };

  useEffect(() => {
    setDurationTransition(durationTransitionSelected);
  }, [durationTransitionSelected]);

  const onMouseEnterThumbnail = (index: number) => {
    const copyOfPreviewsPlaying = [...previewsPlaying];
    copyOfPreviewsPlaying[index] = true;
    setPreviewsPlaying(copyOfPreviewsPlaying);
  };

  const onMouseMovePreview = (event: React.MouseEvent<HTMLVideoElement>) => {
    const video = event.currentTarget;
    video.play();
  };

  const onMouseLeavePreview = (event: React.MouseEvent<HTMLVideoElement>, index: number) => {
    const video = event.currentTarget;
    video.pause();
    video.currentTime = 0;
    const copyOfPreviewsPlaying = [...previewsPlaying];
    copyOfPreviewsPlaying[index] = false;
    setPreviewsPlaying(copyOfPreviewsPlaying);
  };

  const handleClick = (transition: LabelledTransition) => {
    if (currentSectionIndex == null || currentTrackIndex == null) return;
    if (section) {
      const oldDuration = section.effect.durationSec;
      onChangeUpdateTransition(transition.effect.type, 'type', currentTrackIndex, currentSectionIndex, oldDuration);
    }
  };

  return (
    <div className="m-2">
      <div className="font-weight-bold">Transitions</div>
      <div className="transition-preview-container">
        <React.Fragment>
          <h3 className="es-title">{`Duration : ${durationTransition}s`}</h3>
          <div className="duration-wrapper">
            <input
              className="es-duration-input ml-1"
              type="range"
              value={durationTransition}
              onChange={handleDurationChange}
              min={0}
              max={5}
              step={0.01}
            />
          </div>
        </React.Fragment>
        {TRANSITIONS.map((transition, index) => (
          <div key={transition.effect.type} className="transition-preview-block mt-2">
            <img
              src={`/ressources/transitionPreviews/poster/${transition.effect.type}.png`}
              style={{ zIndex: 5 }}
              className={`transition-preview ml-1 ${section?.effect.type === transition.effect.type ? 'selected' : ''}`}
              alt={`${transition.label} transition`}
              onDragStart={() => handleDragStart(transition)}
              onMouseEnter={() => onMouseEnterThumbnail(index)}
            />
            <video
              draggable={true}
              src={`/ressources/transitionPreviews/${transition.effect.type}.mp4`}
              poster={`/ressources/transitionPreviews/poster/${transition.effect.type}.mp4`}
              style={{ zIndex: previewsPlaying[index] ? 10 : 0 }}
              className={`transition-preview ml-1 transition-video ${
                section?.effect.type === transition.effect.type ? 'selected' : ''
              }`}
              onClick={() => handleClick(transition)}
              onDragStart={() => handleDragStart(transition)}
              onMouseMove={onMouseMovePreview}
              onMouseLeave={(event) => onMouseLeavePreview(event, index)}
            />
            <div className="mt-2">{transition.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
