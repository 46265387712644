import { FileResource } from '@eolementhe/video-editor-model';

export type FileKind = FileResource['resourceKind'];

export function fileTypeFromMime(mimeType?: string): FileKind | undefined {
  if (!mimeType) {
    return;
  }
  const fileKind = mimeType.substr(0, mimeType.indexOf('/'));
  const found = ['audio', 'video', 'image'].find((key) => key === fileKind);
  if (!found) {
    return;
  }
  return found as FileKind;
}
