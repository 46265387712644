var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React from "react";
var DEFAULT_CONTEXT = {
  selected: [],
  search: "",
  dropdownOpen: false,
  suggestions: []
};
function reduceValue(context, action) {
  switch (action.kind) {
    case "set":
      return action.value;
    default:
      throw new Error("Unsupported reducer action.");
  }
}
function reduce(context, action) {
  var _a;
  return __assign(__assign({}, context), (_a = {}, _a[action.prop] = reduceValue(context, action), _a));
}
var CheckableSelectContext = React.createContext(DEFAULT_CONTEXT);
var CheckableSelectDispatchContext = React.createContext(function () {
  return 0;
});
export var CheckableSelectContextProvider = function CheckableSelectContextProvider(_a) {
  var children = _a.children,
    selected = _a.selected;
  var defaultContext = __assign(__assign({}, DEFAULT_CONTEXT), {
    selected: selected
  });
  var reducer = React.useCallback(function (context, action) {
    return reduce(context, action);
  }, []);
  var _b = React.useReducer(reducer, defaultContext),
    state = _b[0],
    dispatch = _b[1];
  return React.createElement(CheckableSelectDispatchContext.Provider, {
    value: dispatch
  }, React.createElement(CheckableSelectContext.Provider, {
    value: __assign(__assign({}, state), {
      selected: selected
    })
  }, children));
};
export function useContext() {
  return React.useContext(CheckableSelectContext);
}
export function useDispatch() {
  return React.useContext(CheckableSelectDispatchContext);
}