import Konva from 'konva';
import React from 'react';
import { Image as KonvaImage, Rect } from 'react-konva';
import { IKonvaNode, IVideoResource, IVideoSection } from '@eolementhe/video-editor-model';
import { isNumber } from 'lodash';
import { IKonvaShape } from './IKonvaShape';

interface IKonvaVideo {
  videoRef: React.RefObject<Konva.Image> | undefined;
  onVideoLoaded(): void;
  isPlaying: boolean;
  sectionStartMedia: number;
  sectionStart: number;
  sectionVolume: number;
  muted: boolean;
  currentTime: number;
  videoSection: IVideoSection;
}

interface IProps
  extends IKonvaNode,
    Omit<IVideoResource, 'resourceKind' | 'preview' | '_id' | 'name' | 'size' | 'lowres'>,
    IKonvaShape,
    IKonvaVideo {}

interface IState {
  videoSource: HTMLVideoElement | undefined;
}
export class KonvaVideo extends React.PureComponent<IProps, IState> {
  private videoElement: HTMLVideoElement = document.createElement('video');

  private image: Konva.Image | undefined;

  private animation: Konva.Animation | undefined;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      videoSource: undefined
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  public async componentDidMount() {
    // https://stackoverflow.com/questions/20424279/
    this.videoElement.setAttribute('crossOrigin', 'anonymous');
    this.videoElement.addEventListener('canplay', async () => {
      this.handleLoad();
      if (this.props.videoRef && this.props.videoRef.current) {
        this.image = this.props.videoRef.current;
        this.animation = new Konva.Animation(() => {}, this.image.getLayer());
      }
    });
    this.videoElement.addEventListener('error', this.handleError);
    this.setFirstFrameView();
    this.loadVideo();
  }

  public componentDidUpdate(oldProps: IProps): void {
    if (oldProps.src !== this.props.src) {
      this.videoElement.src = this.props.src;
    }
    this.setPlayAndPause();
    this.setVolume();
    this.synchronizeVideoWithTimeline();
  }

  public componentWillUnmount() {
    this.videoElement.removeEventListener('canplay', this.handleLoad);
    this.videoElement.removeEventListener('error', this.handleError);
    this.destroyVideo();
  }

  private setFirstFrameView() {
    this.videoElement.currentTime = 0.25;
  }

  private setPlayAndPause() {
    if (this.props.videoRef && this.props.videoRef.current) {
      if (this.props.isPlaying && this.props.visible && this.animation) {
        this.videoElement.play().then(
          () => {},
          (error) => console.error(error)
        );
        this.animation.start();
      } else if (
        this.animation &&
        ((!this.props.isPlaying && !this.videoElement.paused) || (!this.props.visible && !this.videoElement.paused))
      ) {
        this.videoElement.pause();
        this.animation.stop();
      }
    }
  }

  private setVolume() {
    if (this.props.videoRef && this.props.videoRef.current && isNumber(this.props.sectionVolume)) {
      this.videoElement.volume = this.props.muted ? 0 : this.props.sectionVolume;
    }
  }

  private synchronizeVideoWithTimeline() {
    if (this.props.videoRef && this.props.videoRef.current) {
      const currentTimeEffective = this.props.currentTime;
      if (this.props.visible) {
        const timeDiff = Math.abs(
          this.videoElement.currentTime - ((currentTimeEffective / 1000) % this.videoElement.duration)
        );
        if (timeDiff > 1) {
          this.videoElement.currentTime = currentTimeEffective / 1000;
        }
      }
    }
  }

  private handleLoad() {
    this.setState({
      videoSource: this.videoElement
    });
    this.props.onVideoLoaded();
  }

  private handleError() {
    this.setState({
      videoSource: undefined
    });
  }

  private loadVideo() {
    this.videoElement.src = this.props.src;
    this.videoElement.load();
  }

  private destroyVideo() {
    if (this.state.videoSource) {
      this.state.videoSource.pause();
      this.state.videoSource.removeAttribute('src');
      this.state.videoSource.load();
    }
  }

  public render() {
    return (
      <React.Fragment>
        {(this.state.videoSource && (
          <KonvaImage
            ref={this.props.videoRef}
            image={this.state.videoSource as any}
            opacity={this.props.opacity}
            visible={this.props.visible}
            x={this.props.x}
            y={this.props.y}
            cropX={this.props.cropX}
            cropY={this.props.cropY}
            cropWidth={this.props.cropWidth}
            cropHeight={this.props.cropHeight}
            width={this.props.width}
            height={this.props.height}
            offsetX={this.props.offsetX}
            offsetY={this.props.offsetY}
            scaleX={this.props.scaleX}
            scaleY={this.props.scaleY}
            rotation={this.props.rotation}
            draggable
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            onClick={this.props.onClick}
            onDragStart={this.props.onDragStart}
            onDragMove={this.props.onDragMove}
            onDragEnd={this.props.onDragEnd}
            onTransformEnd={this.props.onTransformEnd}
            onTransform={this.props.onTransform}
          />
        )) || (
          <Rect
            color="#000000"
            visible={this.props.visible}
            x={this.props.x}
            y={this.props.y}
            width={this.props.width}
            height={this.props.height}
            offsetX={this.props.offsetX}
            offsetY={this.props.offsetY}
            scaleX={this.props.scaleX}
            scaleY={this.props.scaleY}
            rotation={this.props.rotation}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            onClick={this.props.onClick}
            onDragStart={this.props.onDragStart}
            onDragMove={this.props.onDragMove}
            onDragEnd={this.props.onDragEnd}
            onTransformStart={this.props.onTransformStart}
            onTransform={this.props.onTransform}
            onTransformEnd={this.props.onTransformEnd}
          />
        )}
      </React.Fragment>
    );
  }
}
