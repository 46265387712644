import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { mergeClassName } from "../utils";
export var ReportButton = function ReportButton(props) {
  return props.reported ? React.createElement("span", {
    className: mergeClassName("idea-report-button-reported", props.className, props.reportedClassName)
  }, "Reported as inappropriate") : React.createElement(FontAwesomeIcon, {
    icon: faFlag,
    className: mergeClassName("idea-report-button-unreported", props.className, props.unreportedClassName),
    onClick: props.onReport
  });
};