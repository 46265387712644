var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import { Timecode } from '@eolementhe/core';
import { debounce } from 'lodash';
import { doSetCurrentTime, doSetIsPlaying, doSetVolume } from '../redux/actions';
import { getStore } from '../redux/store';
var currentMediaSyncInstances = [];
var currentVelocity = 0;
var _currentMediaElements = [];
function getMediaElements() {
  if (!_currentMediaElements.length) {
    _currentMediaElements = Array.from(document.querySelectorAll('.media-element'));
    _currentMediaElements[0].addEventListener('volumechange', function (evt) {
      var _a;
      var volume = (_a = evt === null || evt === void 0 ? void 0 : evt.target) === null || _a === void 0 ? void 0 : _a.volume;
      if (volume !== null) {
        getStore().dispatch(doSetVolume(volume));
      }
    });
  }
  return _currentMediaElements;
}
var _currentTimingData = {
  to: undefined,
  duration: 0,
  sampler: undefined,
  timeUpdateSub: undefined,
  changeSub: undefined
};
var _framerate = 25;
var _durationSec = 0;
var STARTUP_SHIFTING = 0.04;
function onTimeUpdate(position) {
  if (_currentTimingData.to) {
    getStore().dispatch(doSetCurrentTime(Timecode.fromTimeInHtmlVideoSeconds(position - STARTUP_SHIFTING, _framerate)));
  }
}
var previousToValue = {
  velocity: undefined,
  position: undefined
};
var onTimeObjectChange = debounce(function onChange(toValue) {
  if (previousToValue.velocity !== toValue.velocity) {
    getStore().dispatch(doSetIsPlaying(!!toValue.velocity));
  }
  previousToValue = toValue;
}, 200);
function handleError(error) {
  console.error(error);
}
function getTimingObject() {
  return __awaiter(this, void 0, void 0, function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (!_currentTimingData.to) {
            _currentTimingData.duration = _durationSec;
            _currentTimingData.to = new window.TIMINGSRC.TimingObject({
              range: [STARTUP_SHIFTING, _durationSec]
            });
            _currentTimingData.sampler = new window.TIMINGSRC.TimingSampler(_currentTimingData.to, {
              period: 80
            });
            _currentTimingData.timeUpdateSub = _currentTimingData.sampler.on('change', onTimeUpdate);
            _currentTimingData.changeSub = _currentTimingData.to.on('change', onTimeObjectChange);
          }
          if (currentMediaSyncInstances.length === 0) {
            getMediaElements().forEach(function (mediaElement) {
              currentMediaSyncInstances.push(window.MCorp.mediaSync(mediaElement, _currentTimingData.to, {
                remember: false,
                mode: 'skip',
                automute: true
              }));
            });
          }
          return [4, _currentTimingData.to.ready];
        case 1:
          _a.sent();
          return [2, _currentTimingData.to];
      }
    });
  });
}
export function initAllMedia(framerate, durationMs) {
  _framerate = framerate || 25;
  _durationSec = durationMs ? durationMs / 1000 + STARTUP_SHIFTING : 0;
  if (_currentTimingData) {
    if (_currentTimingData.sampler) {
      _currentTimingData.sampler.clear();
      _currentTimingData.sampler.off(_currentTimingData.timeUpdateSub);
      delete _currentTimingData.sampler;
    }
    if (_currentTimingData.to) {
      _currentTimingData.to.off(_currentTimingData.changeSub);
      delete _currentTimingData.to;
    }
    _currentTimingData = {
      to: undefined,
      duration: 0,
      sampler: undefined,
      timeUpdateSub: undefined,
      changeSub: undefined
    };
  }
  if (_currentMediaElements && _currentMediaElements.length) {
    _currentMediaElements = [];
  }
  if (currentMediaSyncInstances && currentMediaSyncInstances.length) {
    currentMediaSyncInstances.forEach(function (sync) {
      sync.stop();
    });
    currentMediaSyncInstances = [];
  }
  getStore().dispatch(doSetVolume(1));
}
export function playAllMedia() {
  getTimingObject().then(function (to) {
    to.update({
      velocity: currentVelocity || 1
    });
  }).catch(handleError);
}
export function getTimeMedia() {
  return __awaiter(this, void 0, void 0, function () {
    var timingObject;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4, getTimingObject()];
        case 1:
          timingObject = _a.sent();
          return [2, timingObject.query()];
      }
    });
  });
}
export function isAllMediaPlaying() {
  return __awaiter(this, void 0, void 0, function () {
    var timingObject, velocity;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4, getTimingObject()];
        case 1:
          timingObject = _a.sent();
          velocity = timingObject.query().velocity;
          return [2, !!velocity];
      }
    });
  });
}
export function pauseAllMedia() {
  (function () {
    return __awaiter(this, void 0, void 0, function () {
      var playing, timingObject, err_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 4,, 5]);
            return [4, isAllMediaPlaying()];
          case 1:
            playing = _a.sent();
            if (!playing) return [3, 3];
            return [4, getTimingObject()];
          case 2:
            timingObject = _a.sent();
            currentVelocity = 0;
            timingObject.update({
              velocity: currentVelocity
            });
            _a.label = 3;
          case 3:
            return [3, 5];
          case 4:
            err_1 = _a.sent();
            console.error('mediaTrackManager.ts/pauseAllMedia | ERROR', err_1);
            return [3, 5];
          case 5:
            return [2];
        }
      });
    });
  })();
}
export function stopAllMedia() {
  if (!_currentTimingData.to || !_currentTimingData.to.isReady()) {
    return;
  }
  currentVelocity = 0;
  getTimingObject().then(function (to) {
    to.update({
      position: 0,
      velocity: currentVelocity
    });
  }).catch(handleError);
}
export function setPlaybackRateAllMedia(playbackRate) {
  if (playbackRate === void 0) {
    playbackRate = 1;
  }
  currentVelocity = playbackRate;
  getTimingObject().then(function (to) {
    var velocity = to.query().velocity;
    if (velocity !== 0) {
      to.update({
        velocity: currentVelocity
      });
    }
  }).catch(handleError);
}
export function setCurrentTimeAllMedia(newTimeMs) {
  getTimingObject().then(function (to) {
    to.update({
      position: (newTimeMs + 1) / 1000 + STARTUP_SHIFTING
    });
  }).catch(handleError);
}
export function jumpToNextFrames(nbFrames) {
  getTimingObject().then(function (to) {
    nbFrames = nbFrames || 1;
    var frameDurationMs = 1000 / _framerate;
    var duration = _currentTimingData.duration;
    var position = to.query().position;
    var multiplier = Math.floor(position * 1000 / frameDurationMs);
    var newPosition = (frameDurationMs * multiplier + frameDurationMs * nbFrames + 1) / 1000;
    if (newPosition > duration) {
      newPosition = duration;
    }
    to.update({
      position: newPosition
    });
  }).catch(handleError);
}
export function jumpToPreviousFrames(nbFrames) {
  getTimingObject().then(function (to) {
    nbFrames = nbFrames || 1;
    var frameDurationMs = 1000 / _framerate;
    var position = to.query().position;
    var multiplier = Math.floor(position * 1000 / frameDurationMs);
    var newPosition = (frameDurationMs * multiplier - frameDurationMs * nbFrames + 1) / 1000;
    if (newPosition < 0) {
      newPosition = 0;
    }
    to.update({
      position: newPosition
    });
  }).catch(handleError);
}
export function togglePlayPauseAllMedia(forcePlayPause) {
  (function () {
    return __awaiter(this, void 0, void 0, function () {
      var currentlyPlaying, playingValue, action, err_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            return [4, isAllMediaPlaying()];
          case 1:
            currentlyPlaying = _a.sent();
            playingValue = currentlyPlaying;
            action = void 0;
            if (forcePlayPause == null) {
              playingValue = !currentlyPlaying;
              action = playingValue ? 'play' : 'pause';
            } else if (forcePlayPause && !currentlyPlaying) {
              playingValue = true;
              action = 'play';
            } else if (!forcePlayPause && currentlyPlaying) {
              playingValue = false;
              action = 'pause';
            }
            if (action) {
              if (action === 'play') {
                playAllMedia();
              } else {
                pauseAllMedia();
              }
            }
            return [3, 3];
          case 2:
            err_2 = _a.sent();
            console.log('mediaTrackManager.ts/togglePlayPauseAllMedia | ERROR', err_2);
            return [3, 3];
          case 3:
            return [2];
        }
      });
    });
  })();
}
export function changeVolumeAllMedia(volume) {
  if (volume === void 0) {
    volume = 1;
  }
  var mediaElements = getMediaElements();
  if (volume >= 0 && volume <= 1) {
    mediaElements.forEach(function (mediaElement) {
      return mediaElement.volume = volume;
    });
  } else {
    console.error('mediaTrackManager.ts/muteAll | Volume provided is invalid :', 'volume=', volume);
  }
}