import { IKonvaNode, IWaveFormResource } from '@eolementhe/video-editor-model';
import Konva from 'konva';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Arrow, Group, Line, Rect } from 'react-konva';

import { IKonvaShape } from './IKonvaShape';
import { IPlayable } from './IPlayable';

interface IProps
  extends Omit<
      IWaveFormResource,
      'resourceKind' | 'preview' | '_id' | 'name' | 'size' | 'startTime' | 'duration' | 'lowres'
    >,
    IKonvaNode,
    Omit<IKonvaShape, 'onTransformStart' | 'onTransform' | 'onTransformEnd'>,
    IPlayable {
  waveCountBars: number;
  waveformRef: React.RefObject<Konva.Group> | undefined;
  isSelected: boolean;
  size: number;
  amplitudeDegree: number;
}

export const KonvaWaveform: FC<IProps> = (props: IProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(props.isPlaying);
  const [audioElement, setAudio] = useState<HTMLAudioElement | undefined>();
  const [audioCtx, setAudioContext] = useState<AudioContext | undefined>();
  const [analyser, setAnalyser] = useState<AnalyserNode | undefined>();
  const [frequencyArray, setFrequencyArray] = useState<Float32Array | undefined>();
  const [lines, setLines] = useState<JSX.Element[] | undefined>();
  const [linesRef, setLinesRef] = useState<Array<Konva.Line | null>>();
  const [maxBarHeight, setMaxBarHeight] = useState<number>(0);

  const [waveBarWidth, setWaveBarWidth] = useState<number>(
    (props.width / props.waveCountBars - props.width / 1000) * props.size
  );

  useEffect(() => {
    const audioElem = document.createElement('audio');
    audioElem.crossOrigin = 'anonymous';
    setAudio(audioElem);
  }, []);

  useEffect(() => {
    setWaveBarWidth((props.width / props.waveCountBars - props.width / 1000) * props.size);
  }, [props.size, props.waveCountBars, props.width]);

  useEffect(() => {
    const lines: JSX.Element[] = [];
    const refs: Array<Konva.Line | null> = [];
    for (let i = 0; i < props.waveCountBars; i++) {
      const x = (props.width / props.waveCountBars) * i * props.size;
      const y = props.offsetY;
      const yEnd = props.offsetY;

      const line = (
        <Line
          points={[x, y, x, yEnd]}
          ref={(node) => refs.push(node)}
          key={i}
          hitStrokeWidth={waveBarWidth + 15}
          stroke="white"
          strokeWidth={waveBarWidth}
          lineCap="round"
        />
      );
      lines.push(line);
    }
    setLines(lines);
    setLinesRef(refs);
  }, [props.offsetY, props.waveCountBars, props.width, waveBarWidth, props.size]);

  const currentTime = audioElement?.currentTime;
  useEffect(() => {
    if (isPlaying && frequencyArray && analyser && linesRef) {
      analyser.getFloatTimeDomainData(frequencyArray);
      for (const [i, ref] of linesRef.entries()) {
        if (ref) {
          const barHeight = Math.abs(frequencyArray[i]) * 100 * props.amplitudeDegree;
          const x = (props.width / props.waveCountBars) * i * props.size;
          const y = props.offsetY - barHeight;
          const yEnd = props.offsetY + barHeight;
          ref.to({
            points: [x, y, x, yEnd],
            duration: 0.05
          });
        }
      }
    }
  }, [
    analyser,
    frequencyArray,
    isPlaying,
    linesRef,
    props.amplitudeDegree,
    props.offsetY,
    props.waveCountBars,
    props.width,
    props.size,
    currentTime
  ]);

  const loadAudio = useCallback(() => {
    if (audioElement) {
      audioElement.src = props.src;
      if (!audioCtx) {
        setAudioContext(new AudioContext());
      }
    }
  }, [audioCtx, audioElement, props.src]);

  const syncVolume = useCallback(() => {
    if (audioElement) {
      audioElement.volume = props.mute ? 0 : props.volume;
    }
  }, [audioElement, props.mute, props.volume]);

  const syncAudio = useCallback(async () => {
    if (audioElement && audioElement.src) {
      if (props.visible) {
        const timeDiff = Math.abs(audioElement.currentTime * 1000 - props.currentTime);
        if (timeDiff > 1000) {
          audioElement.currentTime = props.currentTime / 1000;
        }
      }
      if (props.isPlaying && props.visible && !isPlaying) {
        await audioElement.play();
        setIsPlaying(true);
      } else if ((!props.isPlaying && isPlaying) || (!props.visible && isPlaying)) {
        audioElement.pause();
        setIsPlaying(false);
      }
    }
  }, [audioElement, isPlaying, props.currentTime, props.isPlaying, props.visible]);

  useEffect(() => {
    loadAudio();
    syncVolume();
  }, [loadAudio, syncVolume]);

  useEffect(() => {
    (async () => {
      await syncAudio();
    })();
  }, [props.src, props.mute, syncAudio]);

  useEffect(() => {
    if (audioElement?.src && audioCtx) {
      const mediaSource = audioCtx.createMediaElementSource(audioElement);
      const analyserNode = audioCtx.createAnalyser();
      mediaSource.connect(analyserNode);
      setFrequencyArray(new Float32Array(analyserNode.frequencyBinCount));
      analyserNode.connect(audioCtx.destination);
      setAnalyser(analyserNode);
      (async () => {
        const arraybuffer = await fetch(audioElement.src).then((r) => r.arrayBuffer());
        audioCtx.decodeAudioData(arraybuffer, (buffer: AudioBuffer) => {
          const data = buffer.getChannelData(0);
          data.sort((a, b) => a - b);
          setMaxBarHeight(Math.abs(data[0]) * 100);
        });
      })();
    }
  }, [audioCtx, audioElement]);

  return (
    <>
      <Rect
        fillEnabled={false}
        width={(props.width + 10) * props.size}
        height={20}
        strokeWidth={2}
        stroke="dodgerblue"
        x={props.x - 10 * props.size}
        y={props.y - 10}
        visible={props.isSelected}
        offsetX={props.offsetX}
        offsetY={props.offsetY}
      />
      <Group visible={props.isSelected}>
        <Line
          points={[
            props.x - 20,
            props.y - maxBarHeight * props.amplitudeDegree,
            props.x + 20,
            props.y - maxBarHeight * props.amplitudeDegree
          ]}
          stroke="green"
          strokeWidth={2}
        />
        <Arrow
          points={[props.x, props.y - maxBarHeight / 4, props.x, props.y - maxBarHeight * props.amplitudeDegree + 4]}
          stroke="green"
          strokeWidth={2}
        />
        <Line
          points={[
            props.x - 20,
            props.y + maxBarHeight * props.amplitudeDegree,
            props.x + 20,
            props.y + maxBarHeight * props.amplitudeDegree
          ]}
          stroke="green"
          strokeWidth={2}
        />
        <Arrow
          points={[props.x, props.y + maxBarHeight / 4, props.x, props.y + maxBarHeight * props.amplitudeDegree - 4]}
          stroke="green"
          strokeWidth={2}
        />
      </Group>
      <Group
        ref={props.waveformRef}
        opacity={props.opacity}
        visible={props.visible}
        x={props.x}
        y={props.y}
        width={props.width}
        height={props.height}
        offsetX={props.offsetX}
        offsetY={props.offsetY}
        scaleX={props.scaleX}
        scaleY={props.scaleY}
        rotation={props.rotation}
        draggable
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClick}
        onDragStart={props.onDragStart}
        onDragMove={props.onDragMove}
        onDragEnd={props.onDragEnd}
      >
        {lines}
      </Group>
    </>
  );
};
