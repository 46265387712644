import { commitMutation } from 'react-relay';
import { Environment, GraphQLTaggedNode } from 'react-relay/hooks';
import { REMOVE_FILE_MUTATION } from './GraphQL';
import { GraphQLRemoveFileMutation } from '../__generated__/GraphQLRemoveFileMutation.graphql';

export default async function (environment: Environment, id: string): Promise<string> {
  return new Promise((resolve, reject) => {
    commitMutation<GraphQLRemoveFileMutation>(environment, {
      mutation: REMOVE_FILE_MUTATION as GraphQLTaggedNode,
      variables: { id },
      onCompleted: () => {
        resolve(id);
      },
      onError: (err: Error) => {
        reject(err);
      }
    });
  });
}
