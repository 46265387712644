var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
  for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
  return r;
};
import React from "react";
import { Timecode } from "@eolementhe/core";
import { mergeClassName } from "../utils";
function useText(value) {
  var _a = React.useState(value === null || value === void 0 ? void 0 : value.toString(false)),
    text = _a[0],
    setText = _a[1];
  var onChangeText = React.useCallback(function (event) {
    return setText(event.currentTarget.value);
  }, [setText]);
  return [text, setText, onChangeText];
}
function validateValue(fps, value, min, max) {
  if (!value || !Timecode.validateString(value, fps, false)) {
    return false;
  }
  var timecode = Timecode.fromString(value, fps);
  var minOk = !min || timecode.compareTime(min) >= 0;
  var maxOk = !max || max.compareTime(timecode) >= 0;
  return minOk && maxOk;
}
function useIsValid(fps, text, min, max) {
  var isValid = validateValue(fps, text, min, max);
  return isValid ? [] : ["text-danger"];
}
export var TimecodeInput = function TimecodeInput(_a) {
  var value = _a.value,
    min = _a.min,
    max = _a.max,
    fps = _a.fps,
    className = _a.className,
    onChange = _a.onChange;
  var _b = React.useState(value),
    lastValue = _b[0],
    setLastValue = _b[1];
  var _c = useText(value),
    text = _c[0],
    setText = _c[1],
    onChangeText = _c[2];
  React.useEffect(function () {
    if (value !== lastValue) {
      setLastValue(value);
      if (value) {
        setText(value.toString(false));
      }
    } else if (validateValue(fps, text, min, max)) {
      var newValue = Timecode.fromString(text, fps);
      if (!value || !newValue.equals(value)) {
        onChange(newValue);
      }
    } else if (value !== undefined) {
      onChange(undefined);
    }
  }, [fps, text, min, max, value, setText, lastValue, onChange]);
  var onBlur = React.useCallback(function () {
    if (value) {
      setText(value.toString(false));
    }
  }, [value, setText]);
  return React.createElement("input", {
    type: "text",
    className: mergeClassName.apply(void 0, __spreadArrays([className], useIsValid(fps, text, min, max))),
    value: text,
    onChange: onChangeText,
    onBlur: onBlur
  });
};