/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GraphQLGetTextPresetsQueryVariables = {};
export type GraphQLGetTextPresetsQueryResponse = {
    readonly getTextPresets: ReadonlyArray<{
        readonly _id: string;
        readonly owner: string;
        readonly name: string;
        readonly text: string;
        readonly font: string;
        readonly fontColor: string;
        readonly fontSize: number;
        readonly fontStyle: string;
        readonly backgroundColor: string;
        readonly borderColor: string;
        readonly borderSize: number;
        readonly rotation: number;
    }>;
};
export type GraphQLGetTextPresetsQuery = {
    readonly response: GraphQLGetTextPresetsQueryResponse;
    readonly variables: GraphQLGetTextPresetsQueryVariables;
};



/*
query GraphQLGetTextPresetsQuery {
  getTextPresets {
    _id
    owner
    name
    text
    font
    fontColor
    fontSize
    fontStyle
    backgroundColor
    borderColor
    borderSize
    rotation
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TextPreset",
    "kind": "LinkedField",
    "name": "getTextPresets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "font",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fontColor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fontSize",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fontStyle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "backgroundColor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "borderColor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "borderSize",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rotation",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLGetTextPresetsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GraphQLGetTextPresetsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ecfcad216594a3b57b2aec5c7a6d2d3b",
    "id": null,
    "metadata": {},
    "name": "GraphQLGetTextPresetsQuery",
    "operationKind": "query",
    "text": "query GraphQLGetTextPresetsQuery {\n  getTextPresets {\n    _id\n    owner\n    name\n    text\n    font\n    fontColor\n    fontSize\n    fontStyle\n    backgroundColor\n    borderColor\n    borderSize\n    rotation\n  }\n}\n"
  }
};
})();
(node as any).hash = '8e34ec8a2952fe54a00027973fabf537';
export default node;
