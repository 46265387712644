import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, CardColumns } from 'react-bootstrap';
import {
  Resource,
  ResourceType,
  FileResource,
  IImageResource,
  IVideoResource,
  IAudioResource
} from '@eolementhe/video-editor-model';
import { IAction } from '@eolementhe/react-components';
import { ResourceUtils } from './ResourceUtils';
import { ResourceGalleryModal } from './ResourceGalleryModal';
import { ThumbnailCard } from './ThumbnailCard';
import { createServerFileUrl } from '../../utils/uploadFiles';
import { IDirectoryViewProps } from './IDirectoryViewProps';

type IProps = IDirectoryViewProps & {
  className?: string;
  currentGalleryResource?: number;
  showGallery?: boolean;
  showSelectedBorder?: boolean;
  onShowGallery?: (index: number) => void;
  onHideGallery?: () => void;
  onPreviousGalleryImage?: () => void;
  onNextGalleryImage?: () => void;
};

interface IState {
  currentGalleryResource: number;
  showGallery: boolean;
}

export class ThumbnailView extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      currentGalleryResource: 0,
      showGallery: false
    };
    this.handleGallerySelect = this.handleGallerySelect.bind(this);
    this.handleHideGallery = this.handleHideGallery.bind(this);
    this.handleGalleryPrevious = this.handleGalleryPrevious.bind(this);
    this.handleGalleryNext = this.handleGalleryNext.bind(this);
    this.renderResource = this.renderResource.bind(this);
  }

  private getCurrentGalleryResource(): number {
    const { currentGalleryResource } = this.props;
    const { currentGalleryResource: currentGalleryResourceState } = this.state;
    return currentGalleryResource || currentGalleryResourceState;
  }

  private isSelected(resource: Resource): boolean {
    const { selectedResources } = this.props;
    const rest = !!(
      selectedResources &&
      selectedResources.find((selectedResource: FileResource) => selectedResource._id === resource._id)
    );
    return rest;
  }

  private handleGallerySelect(): void {
    const currentGalleryResource = this.getCurrentGalleryResource();
    this.props.onSelectResources([this.props.resources[currentGalleryResource]], true);
  }

  private handleGalleryPrevious() {
    // if (this.props.selectedIds === undefined) return;
    // const newIndex = this.state.currentGalleryResource - 1;
    // const currentGalleryResource = newIndex < 0 ? this.props.selectedIds.length - 1 : newIndex;
    // this.setState({ currentGalleryResource });
  }

  private handleGalleryNext() {
    if (!this.props.selectedResources) {
      return;
    }
    const newIndex = this.state.currentGalleryResource + 1;
    const currentGalleryResource = newIndex >= this.props.selectedResources.length ? 0 : newIndex;
    this.setState({ currentGalleryResource });
  }

  private handleShowGallery(currentGalleryResource: number) {
    if (this.props.onShowGallery !== undefined) {
      this.props.onShowGallery(currentGalleryResource);
    } else {
      this.setState({
        showGallery: true,
        currentGalleryResource
      });
    }
  }

  private handleHideGallery() {
    this.setState({ showGallery: false });
  }

  public render() {
    return (
      <>
        <div className={this.props.className}>
          <CardColumns>{this.props.resources.map(this.renderResource)}</CardColumns>
        </div>
        {this.renderGallery()}
      </>
    );
  }

  private renderResource(resource: Resource, index: number) {
    switch (resource.resourceKind) {
      case ResourceType.image:
      case ResourceType.video:
      case ResourceType.audio:
        return this.renderFile(resource, index);
      // case ResourceType.directory:
      //   return this.renderDirectory(resource as IDirectoryResource);
      default:
        throw new Error(`Unsupported resource type ${resource.resourceKind}.`);
    }
  }

  private renderFile(file: FileResource, index: number) {
    switch (file.resourceKind) {
      case ResourceType.image:
        return this.renderImage(file, index);
      case ResourceType.video:
        return this.renderVideo(file, index);
      case ResourceType.audio:
        return this.renderAudio(file, index);
      default:
        return this.renderDefaultFile(file);
    }
  }

  private renderImage(image: IImageResource, index: number) {
    return this.renderImageCard(image, index, image.preview);
  }

  private renderVideo(video: IVideoResource, index: number) {
    if (video.preview) {
      return this.renderImageCard(video, index, video.preview);
    }
    const content = this.renderDefaultFileContent(video);
    return this.renderExpandableCard(video, index, content);
  }

  private renderImageCard(resource: FileResource, index: number, url: string) {
    const content = <Card.Img src={createServerFileUrl(url)} style={{ width: '100%', height: 'auto' }} />;
    return this.renderExpandableCard(resource, index, content);
  }

  private renderAudio(audio: IAudioResource, index: number) {
    const content = this.renderDefaultFileContent(audio);
    return this.renderExpandableCard(audio, index, content);
  }

  private renderDefaultFile(file: FileResource) {
    const content = this.renderDefaultFileContent(file);
    return this.renderThumbnailCard(file, content);
  }

  private renderDefaultFileContent(file: FileResource) {
    const icon = ResourceUtils.getFileIcon(file.resourceKind);
    return (
      <Card.Body className="unselectable-text text-center">
        <FontAwesomeIcon icon={icon} size="3x" />
        <div>{file.name}</div>
      </Card.Body>
    );
  }

  private renderExpandableCard(resource: FileResource, index: number, content: JSX.Element) {
    const extraActions: IAction[] = [
      {
        title: 'Expand',
        description: 'Show file in gallery view.',
        icon: faExpand,
        execute: () => this.handleShowGallery(index)
      }
    ];
    return this.renderThumbnailCard(resource, content, extraActions);
  }

  private renderThumbnailCard(resource: FileResource, content: JSX.Element, extraActions?: IAction[]) {
    const { onSelectResources, canSelect, showSelectedBorder, canDownload, onAddTracks } = this.props;
    return (
      <ThumbnailCard
        key={resource._id}
        content={content}
        extraActions={extraActions}
        canSelect={canSelect}
        onAddTracks={onAddTracks}
        resource={resource}
        selected={this.isSelected(resource)}
        showSelectedBorder={showSelectedBorder}
        onSelect={(selected) => onSelectResources([resource], selected)}
        canDownload={canDownload}
        resourceName={resource.name}
        url={
          resource.resourceKind === ResourceType.image ||
          resource.resourceKind === ResourceType.video ||
          resource.resourceKind === ResourceType.audio
            ? resource.src
            : undefined
        }
        onRemoveResources={() => this.props.onRemoveResources && this.props.onRemoveResources([resource])}
        canRemove={this.props.onRemoveResources !== undefined}
      />
    );
  }

  private renderGallery() {
    const currentGalleryResource = this.getCurrentGalleryResource();
    const resource = this.props.resources[currentGalleryResource];
    const showGallery = this.props.showGallery !== undefined ? this.props.showGallery : this.state.showGallery;
    const galleryProps =
      !showGallery || resource === undefined
        ? undefined
        : {
            resource,
            isSelected: this.isSelected(resource)
          };
    const onHideGallery = this.props.onHideGallery !== undefined ? this.props.onHideGallery : this.handleHideGallery;
    const onPreviousGalleryImage =
      this.props.onPreviousGalleryImage !== undefined ? this.props.onPreviousGalleryImage : this.handleGalleryPrevious;
    const onNextGalleryImage =
      this.props.onNextGalleryImage !== undefined ? this.props.onNextGalleryImage : this.handleGalleryNext;
    return (
      <>
        {galleryProps !== undefined && (
          <ResourceGalleryModal
            resource={galleryProps.resource}
            isSelected={galleryProps.isSelected}
            onPrevious={onPreviousGalleryImage}
            onNext={onNextGalleryImage}
            onHide={onHideGallery}
            onSelect={this.handleGallerySelect}
          />
        )}
      </>
    );
  }
}
