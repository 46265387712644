import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Button, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { EoleEditStateEntry } from '../../../redux/types';

interface ITab {
  tabKey: string;
  title: string;
  icon: IconProp;
  disabled?: boolean;
  content: JSX.Element;
}

interface IProps {
  tabs: ITab[];
  bottomTab?: ITab;
  defaultTabKey: string;
  onClickAdd?: () => void;
}

interface IState {
  activeTabKey: string;
}
export const AddTrackTabs: FC<IProps> = ({ tabs, defaultTabKey, bottomTab, onClickAdd }) => {
  const [activeTabKey, setActiveTabKey] = useState(defaultTabKey);
  const selectedResources = useSelector((state: EoleEditStateEntry) => state.ee.selectedResources);

  const handleSelectTab = (activeTabK: string) => {
    setActiveTabKey(activeTabK);
  };
  const allTabs = bottomTab !== undefined ? tabs.concat(bottomTab) : tabs;
  return (
    <>
      <Tab.Container activeKey={activeTabKey} onSelect={handleSelectTab}>
        <div className="d-flex flex-row flex-fill min-height-0">
          <div className="d-flex flex-column col-sm-2 p-0">
            <Nav variant="pills" className="flex-column flex-fill">
              {tabs.map((tab) => (
                <Nav.Item key={tab.tabKey}>
                  <Nav.Link eventKey={tab.tabKey} disabled={tab.disabled ?? false}>
                    <Row>
                      <FontAwesomeIcon icon={tab.icon} size="lg" fixedWidth className="ml-1" />
                      <div className="ml-2 align-self-center">{tab.title}</div>
                      {tab.disabled ? (
                        <sup>
                          <Badge variant="primary">Coming Soon</Badge>
                        </sup>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </Nav.Link>
                </Nav.Item>
              ))}
              <div className="flex-fill" />
              {bottomTab !== undefined && (
                <Nav.Item className="mt-auto">
                  <Nav.Link eventKey={bottomTab.tabKey}>
                    <Row>
                      <FontAwesomeIcon icon={bottomTab.icon} size="lg" fixedWidth className="ml-1" />
                      <div className="ml-2 align-self-center">{bottomTab.title}</div>
                    </Row>
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </div>
          <div className="col-sm-10 d-flex flex-column pr-0">
            <Tab.Content className="flex-fill d-flex flex-column min-height-0">
              {allTabs.map((tab) => (
                <Tab.Pane key={tab.tabKey} eventKey={tab.tabKey} className="flex-fill d-flex flex-column min-height-0">
                  {activeTabKey === tab.tabKey && tab.content}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
      {onClickAdd && (
        <Modal.Footer className="pt-1 pb-0 pl-0 pr-0">
          return (
          <Button variant="success" disabled={!selectedResources.length} onClick={onClickAdd}>
            {'Add' + (selectedResources.length ? ` (${selectedResources.length})` : '')}
          </Button>
          );
        </Modal.Footer>
      )}
    </>
  );
};
