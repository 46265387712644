import React, { FC, ChangeEvent } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';

export interface ISizeNumberProps {
  value: number;
  label?: string;
  appendedText?: string;
  min?: number;
  max?: number;
  step?: number;
  isRow?: boolean;
  onSizeChange: (newSize: number) => void;
}

export const SizeNumber: FC<ISizeNumberProps> = ({
  value,
  label,
  appendedText,
  min,
  max,
  step,
  isRow,
  onSizeChange
}) => {
  const handleChangeNumber = (event: ChangeEvent<HTMLInputElement>) => {
    onSizeChange(parseFloat(event.currentTarget.value) || 0);
  };

  const Content = (
    <>
      {label && (
        <Form.Label column sm="auto">
          {label}
        </Form.Label>
      )}
      <InputGroup>
        <Form.Control
          type="number"
          min={min || 0}
          max={max}
          step={step || 1}
          value={value}
          onChange={handleChangeNumber}
        />
        <InputGroup.Append>
          <InputGroup.Text>{appendedText || 'px'}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </>
  );
  return !isRow ? <Form.Group as={Col}>{Content}</Form.Group> : Content;
};
