import React from "react";
import { mergeClassName } from "../utils";
import { MarkdownMessage } from "./MarkdownMessage";
import { ReportButton } from "./ReportButton";
export var Comment = function Comment(props) {
  var created = props.created.toLocaleString("default", {
    year: "numeric",
    month: "short",
    day: "numeric"
  });
  return React.createElement("div", {
    className: mergeClassName("idea-comment", props.className)
  }, React.createElement("div", {
    className: "idea-comment-header"
  }, React.createElement("div", {
    className: "idea-comment-username"
  }, props.author), React.createElement("div", {
    className: "idea-comment-created"
  }, "commented on " + created), React.createElement(ReportButton, {
    className: "idea-comment-report",
    reported: props.reported,
    onReport: props.onReport
  })), React.createElement(MarkdownMessage, {
    className: "idea-comment-message",
    value: props.message
  }));
};