import * as React from "react";
import { isEmail } from "@eolementhe/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ChipsInputContextProvider, useContext, useDispatch } from "./context";
import { DropdownMenu } from "./DropdownMenu";
export var ChipsInput = function ChipsInput(props) {
  return React.createElement(ChipsInputContextProvider, {
    selected: props.selected
  }, React.createElement(ChipsInputContent, {
    options: props.options,
    placeholder: props.placeholder,
    addSelected: props.addSelected,
    removeSelected: props.removeSelected,
    control: props.control
  }));
};
function moveSuggestionDown(context, dispatch) {
  var currentSuggestionIndex = context.currentSuggestion === context.suggestions.length - 1 ? 0 : context.currentSuggestion + 1;
  dispatch({
    kind: "set",
    prop: "currentSuggestion",
    value: currentSuggestionIndex
  });
}
function moveSuggestionUp(context, dispatch) {
  var currentSuggestionIndex = context.currentSuggestion === 0 ? context.suggestions.length - 1 : context.currentSuggestion - 1;
  dispatch({
    kind: "set",
    prop: "currentSuggestion",
    value: currentSuggestionIndex
  });
}
function acceptSuggestion(context, addSelectCallback) {
  var index = context.currentSuggestion;
  var suggestion = context.suggestions[index];
  if (suggestion) {
    addSelectCallback(suggestion);
  }
}
function clearInputValue(dispatch) {
  dispatch({
    kind: "set",
    prop: "text",
    value: ""
  });
}
function isUnValid(value) {
  return value === "" || value === " " || value === "," || value === ";";
}
function handleControl(value, control) {
  switch (control) {
    case "email":
      return isEmail(value);
    default:
      return !isUnValid(value);
  }
}
function acceptValue(context, addSelectCallback, dispatch, control) {
  if (handleControl(context.text, control)) {
    addSelectCallback(context.text);
  }
  clearInputValue(dispatch);
}
function handleKey(event, context, dispatch, addSelectCallback, control) {
  switch (event.key) {
    case "ArrowDown":
      moveSuggestionDown(context, dispatch);
      break;
    case "ArrowUp":
      moveSuggestionUp(context, dispatch);
      break;
    case "Enter":
      acceptSuggestion(context, addSelectCallback);
      break;
    case " ":
    case ",":
    case ";":
      acceptValue(context, addSelectCallback, dispatch, control);
      break;
    default:
      break;
  }
}
function handleLooseFocus(context, dispatch, addSelectCallback) {
  acceptValue(context, addSelectCallback, dispatch);
  dispatch({
    kind: "set",
    prop: "dropdownOpen",
    value: !context.dropdownOpen
  });
}
var ChipsInputContent = function ChipsInputContent(props) {
  var context = useContext();
  var dispatch = useDispatch();
  var toggleDropdown = React.useCallback(function () {
    handleLooseFocus(context, dispatch, props.addSelected);
  }, [dispatch, context, props.addSelected]);
  var handleOnChange = React.useCallback(function (event) {
    dispatch({
      kind: "set",
      prop: "text",
      value: event.target.value
    });
  }, [dispatch]);
  var handleKeyDown = React.useCallback(function (event) {
    handleKey(event, context, dispatch, props.addSelected, props.control);
  }, [context, dispatch, props.addSelected, props.control]);
  return React.createElement("div", {
    className: "core-chips-container"
  }, React.createElement("div", {
    className: "core-chips-input-container"
  }, React.createElement(ChipsTags, {
    removeSelected: props.removeSelected
  }), React.createElement("input", {
    type: "text",
    value: context.text,
    placeholder: props.placeholder,
    onChange: handleOnChange,
    className: "core-chips-input",
    onFocus: toggleDropdown,
    onBlur: toggleDropdown,
    onKeyDown: handleKeyDown
  })), React.createElement(DropdownMenu, {
    options: props.options,
    addSelected: props.addSelected
  }));
};
var ChipsTags = function ChipsTags(props) {
  var selected = useContext().selected;
  return React.createElement(React.Fragment, null, selected.map(function (chip) {
    return React.createElement(ChipElement, {
      key: chip,
      chip: chip,
      removeSelected: props.removeSelected
    });
  }));
};
var ChipElement = function ChipElement(_a) {
  var chip = _a.chip,
    removeSelected = _a.removeSelected;
  var handleClick = function handleClick() {
    removeSelected(chip);
  };
  return React.createElement("span", {
    className: "core-chips-tag",
    key: chip
  }, chip, React.createElement(FontAwesomeIcon, {
    icon: faTimesCircle,
    onClick: handleClick,
    className: "core-chips-tag-icon"
  }));
};