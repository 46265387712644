var TrackAction = function () {
  function TrackAction(action, track, trackIndex) {
    this.track = track;
    this.trackIndex = trackIndex;
    this.icon = action.icon;
    this.title = action.title;
    this.description = action.description;
    this.active = action.active;
    this.canExecute = action.canExecute;
    this.executeAction = action.execute;
    this.execute = this.execute.bind(this);
  }
  TrackAction.prototype.execute = function () {
    if (this.executeAction !== undefined && (this.canExecute === undefined || this.canExecute)) {
      this.executeAction(this.track, this.trackIndex);
    }
  };
  TrackAction.build = function (builder, track, trackIndex) {
    var actions = builder(track, trackIndex);
    return actions.map(function (action) {
      return new TrackAction(action, track, trackIndex);
    });
  };
  return TrackAction;
}();
export { TrackAction };