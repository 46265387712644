import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, fr, es } from '@eolementhe/react-components';

export function initI18n() {
  i18n
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      resources: {
        en: { translation: en },
        fr: { translation: fr },
        es: { translation: es }
      },
      interpolation: {
        escapeValue: false
      }
    })
    .catch((error) => {
      throw error;
    });
}

export default i18n;
