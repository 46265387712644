import { TrackMappingModes } from '../video-player/controller/IMediaStream';
var FFT_SIZE = 2048;
var MIN_DBMS = -50;
var vueMeterListeners = [];
var vueMeterListenersInterval;
function registerVueMeterListener(listenerToRegister) {
  if (!vueMeterListeners.find(function (listener) {
    return listenerToRegister === listener;
  })) {
    vueMeterListeners.push(listenerToRegister);
  }
  if (!vueMeterListenersInterval) {
    vueMeterListenersInterval = window.setInterval(function () {
      vueMeterListeners.forEach(function (listener) {
        return listener.grabAnalyserData();
      });
      vueMeterListeners.forEach(function (listener) {
        return listener.computeAnalyserData();
      });
      vueMeterListeners.forEach(function (listener) {
        return listener.fireAnalyserData();
      });
    }, 80);
  }
}
function unregisterVueMeterListener(listenerToUnregister) {
  var index = vueMeterListeners.findIndex(function (listener) {
    return listenerToUnregister === listener;
  });
  if (index >= 0) {
    vueMeterListeners.splice(index, 1);
  }
  if (vueMeterListeners.length <= 0 && vueMeterListenersInterval) {
    clearInterval(vueMeterListenersInterval);
    vueMeterListenersInterval = 0;
  }
}
var AudioAnalyser = function () {
  function AudioAnalyser(options) {
    this.options = options;
    this.gainNodes = [];
    this.analyserDataArray = [];
    this.peakInstantaneousPowerDecibelsArray = [];
  }
  AudioAnalyser.prototype.connect = function (mediaElement, maxChannels, outputMappingMode) {
    if (maxChannels === void 0) {
      maxChannels = 0;
    }
    if (outputMappingMode === void 0) {
      outputMappingMode = TrackMappingModes.mixed;
    }
    console.log('AudioAnalyser.ts/connect', "mediaElement=" + !!mediaElement, "maxChannels=" + maxChannels, "outputMappingMode=" + outputMappingMode);
    if (this.audioContext && this.currentMediaElementObj === mediaElement && this.currentMediaElementSrc === mediaElement.src) {
      return;
    }
    this.disconnect();
    this.currentMediaElementObj = mediaElement;
    this.currentMediaElementSrc = mediaElement.src;
    this.audioContext = new AudioContext();
    this.audioSource = this.audioContext.createMediaElementSource(mediaElement);
    this.analyserNodes = [];
    if (!maxChannels) {
      this.analyserNodes[0] = this.audioContext.createAnalyser();
      this.analyserNodes[0].fftSize = FFT_SIZE;
      this.analyserDataArray[0] = new Float32Array(FFT_SIZE);
      this.audioSource.connect(this.analyserNodes[0]);
      this.analyserNodes[0].connect(this.audioContext.destination);
    } else {
      var channelCount = maxChannels;
      this.splitterNode = new ChannelSplitterNode(this.audioContext, {
        numberOfOutputs: channelCount
      });
      this.mergerNode = new ChannelMergerNode(this.audioContext, {
        numberOfInputs: channelCount
      });
      this.audioSource.connect(this.splitterNode);
      this.gainNodes = [];
      for (var chanelIndex = 0; chanelIndex < channelCount; chanelIndex++) {
        this.analyserNodes[chanelIndex] = this.audioContext.createAnalyser();
        this.analyserNodes[chanelIndex].fftSize = FFT_SIZE;
        this.analyserDataArray[chanelIndex] = new Float32Array(FFT_SIZE);
        this.gainNodes[chanelIndex] = new GainNode(this.audioContext);
        this.splitterNode.connect(this.analyserNodes[chanelIndex], chanelIndex, 0);
        this.analyserNodes[chanelIndex].connect(this.gainNodes[chanelIndex], 0);
        if (outputMappingMode === TrackMappingModes.unmapped) {
          this.gainNodes[chanelIndex].connect(this.mergerNode, 0, chanelIndex);
        } else if (outputMappingMode === TrackMappingModes.stereo) {
          var mixedOutputIndex = channelCount > 1 ? chanelIndex % 2 : 0;
          this.gainNodes[chanelIndex].connect(this.mergerNode, 0, mixedOutputIndex);
        } else {
          this.gainNodes[chanelIndex].connect(this.mergerNode, 0, 0);
          if (channelCount > 1) {
            this.gainNodes[chanelIndex].connect(this.mergerNode, 0, 1);
          }
        }
      }
      this.mergerNode.connect(this.audioContext.destination);
    }
    if (this.options.onVueMeter) {
      registerVueMeterListener(this);
    }
    this.updateChannelsChange();
  };
  AudioAnalyser.prototype.setTrackOutput = function (connectOutput, chanelIndexes) {
    if (!this.gainNodes) {
      if (this.analyserNodes && this.analyserNodes[0] && this.audioContext) {
        this.analyserNodes[0][connectOutput ? 'connect' : 'disconnect'](this.audioContext.destination);
      }
    } else {
      this.setGainValue(connectOutput ? 1 : 0, chanelIndexes);
    }
  };
  AudioAnalyser.prototype.disconnect = function () {
    unregisterVueMeterListener(this);
    if (this.gainNodes) {
      this.gainNodes.forEach(function (gainNode) {
        return gainNode.disconnect();
      });
    }
    this.gainNodes = undefined;
    if (this.splitterNode) {
      this.splitterNode.disconnect();
      delete this.splitterNode;
    }
    if (this.mergerNode) {
      this.mergerNode.disconnect();
      delete this.mergerNode;
    }
    if (this.analyserNodes) {
      this.analyserNodes.forEach(function (analyserNode) {
        return analyserNode.disconnect();
      });
    }
    this.analyserNodes = undefined;
    if (this.audioSource) {
      this.audioSource.disconnect();
      delete this.audioSource;
    }
    if (this.audioContext) {
      this.audioContext.close().then(function () {}, function () {});
      delete this.audioContext;
    }
    this.analyserDataArray = [];
  };
  AudioAnalyser.prototype.setGainValue = function (value, chanelIndexes) {
    var _this = this;
    if (!this.gainNodes) {
      return;
    }
    if (chanelIndexes == null) {
      this.gainNodes.forEach(function (gainNode, chanelIndex) {
        gainNode.gain.value = value;
      });
    } else if (chanelIndexes.length) {
      chanelIndexes.forEach(function (chanelIndex) {
        _this.gainNodes[chanelIndex].gain.value = value;
      });
    }
    this.updateChannelsChange();
  };
  AudioAnalyser.prototype.updateChannelsChange = function () {
    if (this.gainNodes && this.options.onChannelsChange) {
      var channels = this.gainNodes.map(function (gainNode) {
        return {
          gainValue: gainNode.gain.value
        };
      });
      this.options.onChannelsChange(channels);
    }
  };
  AudioAnalyser.prototype.grabAnalyserData = function () {
    var _this = this;
    if (!this.analyserNodes) {
      return;
    }
    this.analyserNodes.forEach(function (analyserNode, index) {
      analyserNode.getFloatTimeDomainData(_this.analyserDataArray[index]);
    });
  };
  AudioAnalyser.prototype.fireAnalyserData = function () {
    if (!this.options.onVueMeter) {
      return;
    }
    var decibelPercents = this.peakInstantaneousPowerDecibelsArray.map(function (peakInstantaneousPowerDecibels) {
      var decibelToPercent = (peakInstantaneousPowerDecibels - MIN_DBMS) * 100 / -MIN_DBMS;
      return Math.min(Math.max(Math.round(decibelToPercent), 0), 100);
    });
    this.options.onVueMeter(decibelPercents);
  };
  AudioAnalyser.prototype.computeAnalyserData = function () {
    var _this = this;
    this.peakInstantaneousPowerDecibelsArray = [];
    this.analyserDataArray.forEach(function (analyserDataArray, index) {
      var peakInstantaneousPower = 0;
      var len = analyserDataArray.length;
      for (var i = 0; i < len; i++) {
        peakInstantaneousPower = Math.max(Math.pow(analyserDataArray[i], 2), peakInstantaneousPower);
      }
      var peakInstantaneousPowerDecibels = 10 * Math.log10(peakInstantaneousPower);
      if (peakInstantaneousPowerDecibels > 0) {
        peakInstantaneousPowerDecibels = 0;
      } else if (peakInstantaneousPowerDecibels <= MIN_DBMS) {
        peakInstantaneousPowerDecibels = MIN_DBMS;
      }
      _this.peakInstantaneousPowerDecibelsArray.push(peakInstantaneousPowerDecibels);
    });
  };
  return AudioAnalyser;
}();
export { AudioAnalyser };