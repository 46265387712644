export var Placement;
(function (Placement) {
  Placement["autoStart"] = "auto-start";
  Placement["auto"] = "auto";
  Placement["autoEnd"] = "auto-end";
  Placement["topStart"] = "top-start";
  Placement["top"] = "top";
  Placement["topEnd"] = "top-end";
  Placement["rightStart"] = "right-start";
  Placement["right"] = "right";
  Placement["rightEnd"] = "right-end";
  Placement["bottomEnd"] = "bottom-end";
  Placement["bottom"] = "bottom";
  Placement["bottomStart"] = "bottom-start";
  Placement["leftEnd"] = "left-end";
  Placement["left"] = "left";
  Placement["leftStart"] = "left-start";
})(Placement || (Placement = {}));