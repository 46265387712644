"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CssFontFamily = void 0;
/**
 * The list of CSS font families supported by our stack.
 *
 * @public
 */
exports.CssFontFamily = {
  arial: {
    displayName: 'Arial',
    konvaName: 'arial',
    cssFamily: 'Arial, Helvetica, sans-serif'
  },
  arialBlack: {
    displayName: 'Arial Black',
    konvaName: 'arial Black',
    cssFamily: 'Arial Black, Gadget, sans-serif'
  },
  courierNew: {
    displayName: 'Courier New',
    konvaName: 'Courier New',
    cssFamily: 'Courier New, Courier, monospace'
  },
  comicSans: {
    displayName: 'Comic Sans MS',
    konvaName: 'Comic Sans MS',
    cssFamily: 'Comic Sans MS, cursive, sans-serif'
  },
  impact: {
    displayName: 'Impact',
    konvaName: 'impact',
    cssFamily: 'Impact, Charcoal, sans-serif'
  },
  trebuchet: {
    displayName: 'Trebuchet MS',
    konvaName: 'Trebuchet MS',
    cssFamily: 'Trebuchet MS, Helvetica, sans-serif'
  },
  verdana: {
    displayName: 'Verdana',
    konvaName: 'verdana',
    cssFamily: 'Verdana, Geneva, sans-serif'
  },
  georgia: {
    displayName: 'Georgia',
    konvaName: 'georgia',
    cssFamily: 'Georgia, serif'
  },
  palatinoLinotype: {
    displayName: 'Palatino Linotype',
    konvaName: 'Palatino Linotype',
    cssFamily: 'Palatino Linotype, Book Antiqua, Palatino, serif'
  },
  timesNewRoman: {
    displayName: 'Times New Roman',
    konvaName: 'Times New Roman',
    cssFamily: 'Times New Roman, Times, serif'
  }
};