"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addZeros = void 0;
/**
 * Converts the given number to string then prepends it with zeros it its size
 * is less than the length given as argument.
 *
 * @param value - The value to convert to string with zeros.
 * @param length - The minimum length of the returned string.
 * @returns A new string with the value eventually prepended by zeros.
 *
 * @example Add zeros to the value `10` to get a minimum length of 3 characters:
 *
 * ```ts
 * addZeros(10, 3); // 010
 * ```
 *
 * @example
 * Add zeros to the value `1000` to get a minimum length of 3 characters:
 *
 * ```ts
 * addZeros(1000, 3); // 1000
 * ```
 *
 * @public
 */
function addZeros(value, length) {
  return value.toString().padStart(length, "0");
}
exports.addZeros = addZeros;