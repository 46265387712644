var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import React from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { showOpenFileDialog } from '../utils/file-dialog';
var FileInput = function (_super) {
  __extends(FileInput, _super);
  function FileInput(props) {
    var _this = _super.call(this, props) || this;
    _this.render = function () {
      return React.createElement(InputGroup, {
        onDragOver: _this.handleDrag,
        onDragLeave: _this.handleDrag,
        onDrop: _this.handleDrop,
        onClick: _this.handleClickBrowse
      }, React.createElement(Form.Control, {
        type: "text",
        placeholder: _this.props.files.length ? _this.props.files.map(function (file) {
          return file.name;
        }).join(', ') : 'Drag & drop or click Browse',
        disabled: true
      }), React.createElement(InputGroup.Append, null, React.createElement(Button, null, React.createElement(FontAwesomeIcon, {
        icon: faUpload
      }), ' Browse')));
    };
    _this.handleClickBrowse = _this.handleClickBrowse.bind(_this);
    _this.handleDrag = _this.handleDrag.bind(_this);
    _this.handleDrop = _this.handleDrop.bind(_this);
    return _this;
  }
  FileInput.prototype.componentDidMount = function () {
    this.initDropZone();
  };
  FileInput.prototype.componentDidUpdate = function (oldProps) {
    var _a, _b;
    var dropZone = (_a = this.props.dropZone) === null || _a === void 0 ? void 0 : _a.current;
    if (dropZone && dropZone !== ((_b = oldProps.dropZone) === null || _b === void 0 ? void 0 : _b.current)) {
      this.initDropZone();
    }
  };
  FileInput.prototype.initDropZone = function () {
    var _a;
    var dropZone = (_a = this.props.dropZone) === null || _a === void 0 ? void 0 : _a.current;
    if (!dropZone) return;
    dropZone.ondragover = this.handleDrag;
    dropZone.ondragleave = this.handleDrag;
    dropZone.ondrop = this.handleDrop;
  };
  FileInput.prototype.handleClickBrowse = function () {
    return __awaiter(this, void 0, void 0, function () {
      var fileList, files;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, showOpenFileDialog(this.props.acceptedMime)];
          case 1:
            fileList = _a.sent();
            files = fileList ? FileInput.fileListToArray(fileList) : [];
            this.props.onChange(files);
            return [2];
        }
      });
    });
  };
  FileInput.prototype.handleDrag = function (event) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        event.preventDefault();
        event.stopPropagation();
        return [2];
      });
    });
  };
  FileInput.prototype.handleDrop = function (event) {
    return __awaiter(this, void 0, void 0, function () {
      var files;
      return __generator(this, function (_a) {
        event.preventDefault();
        event.stopPropagation();
        files = this.getDroppedFiles(event);
        this.props.onChange(files);
        return [2];
      });
    });
  };
  FileInput.prototype.getDroppedFiles = function (event) {
    if (!event.dataTransfer) return [];
    var items = event.dataTransfer.items;
    if (items) {
      var files = [];
      var max = this.props.multiple ? items.length : items.length > 0 ? 1 : 0;
      for (var i = 0; i < max; i++) {
        if (items[i].kind === 'file') {
          var file = items[i].getAsFile();
          if (file) {
            files.push(file);
          }
        }
      }
      return files;
    } else {
      return FileInput.fileListToArray(event.dataTransfer.files);
    }
  };
  FileInput.fileListToArray = function (fileList) {
    var files = [];
    for (var i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }
    return files;
  };
  return FileInput;
}(React.PureComponent);
export { FileInput };