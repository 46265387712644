export default {
  NOOP: 'NOOP',
  INIT: 'INIT_',
  SET_IS_PLAYING: 'SET_IS_PLAYING',
  SET_PLAYBACK_RATE: 'SET_PLAYBACK_RATE',
  CHANGE_TRACK_OUTPUT: 'CHANGE_TRACK_OUTPUT',
  SET_TRACK_CHANNELS: 'SET_TRACK_CHANNELS',
  SET_TRACK_CAN_PLAY: 'SET_TRACK_CAN_PLAY',
  MUTE_TRACK: 'MUTE_TRACK',
  SET_VOLUME: 'SET_VOLUME',
  SET_SELECTED_TRACK: 'SET_SELECTED_TRACK',
  TOGGLE_OFFSET_DISPLAY: 'TOGGLE_OFFSET_DISPLAY',
  SET_OFFSET: 'SET_OFFSET',
  SET_IS_BUFFERING: 'SET_IS_BUFFERING',
  SET_SECTION_DURATION: 'SET_SECTION_DURATION',
  SET_SECTION_START: 'SET_SECTION_START',
  SET_SECTION_END: 'SET_SECTION_END',
  SET_SECTION_COLOR: 'SET_SECTION_COLOR',
  SET_SECTION_NOT_MOVABLE: 'SET_SECTION_NOT_MOVABLE',
  SET_SECTION_NOT_RESIZABLE: 'SET_SECTION_NOT_RESIZABLE',
  REMOVE_SECTION: 'REMOVE_SECTION',
  ADD_SECTION: 'ADD_SECTION',
  ADD_MARKER: 'ADD_MARKER',
  REMOVE_MARKER: 'REMOVE_MARKER',
  REMOVE_MARKERS: 'REMOVE_MARKERS',
  SET_MARKER_COLOR: 'SET_MARKER_COLOR',
  SET_MARKER_TITLE: 'SET_MARKER_TITLE',
  SET_MARKER_TAG: 'SET_MARKER_TAG',
  SET_MARKER_NOT_MOVABLE: 'SET_MARKER_NOT_MOVABLE',
  SET_MARKER_PRIORITY: 'SET_MARKER_PRIORITY',
  SET_MARKER_TIME: 'SET_MARKER_TIME',
  SET_CURRENT_TIME: 'SET_CURRENT_TIME',
  SET_SNAPSHOT_CALLBACK: 'SET_SNAPSHOT_CALLBACK',
  PUSH_MEDIA_STREAM: 'PUSH_MEDIA_STREAM'
};