import { ResourceType } from '@eolementhe/video-editor-model';
import { saveAs } from 'file-saver';

export const acceptableInputFileExtension: Record<ResourceType, string[]> = {
  video: ['mp4', 'avi', 'mov', 'mxf'],
  audio: ['wav', 'mp3'],
  image: ['jpeg', 'jpg', 'png', 'gif'],
  shape: [],
  videoTransition: [],
  subtitle: [],
  text: [],
  directory: []
};

export class FileUtils {
  public static fileNameFromUrl(url: string) {
    if (url) {
      const match = url.match(/.*\/(.+?)\./);
      if (match && match.length > 1) {
        return match[1];
      }
    }
    return undefined;
  }

  public static isImage(mimeType: string) {
    return mimeType.startsWith('image/');
  }

  public static isVideo(mimeType: string) {
    return mimeType.startsWith('video/');
  }

  public static isAudio(mimeType: string) {
    return mimeType.startsWith('audio/');
  }

  public static async readBlob(blob: Blob, isText = false) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        resolve(event.target.result);
      };
      reader.onerror = () => {
        reader.abort();
        reject();
      };
      if (isText) {
        reader.readAsText(blob);
      } else {
        reader.readAsDataURL(blob);
      }
    });
  }

  public static stringToBlob(data: string, isBase64: boolean) {
    const chars = isBase64 ? atob(data) : data;
    const bytes = new Array(chars.length);
    for (let i = 0; i < chars.length; i++) {
      bytes[i] = chars.charCodeAt(i);
    }
    return new Blob([new Uint8Array(bytes)]);
  }

  public static showSaveFileDialog(fileName: string, data: string, isBase64 = false) {
    saveAs(FileUtils.stringToBlob(data, isBase64), fileName);
  }

  public static getExtension(inputFile: File | null, withDot?: boolean) {
    const fileName = inputFile?.name?.toLocaleLowerCase();
    if (!fileName) {
      return '';
    }
    const extensionWithoutDot = fileName.split('.').pop() || '';
    return withDot && extensionWithoutDot ? `.${extensionWithoutDot}` : extensionWithoutDot;
  }

  public static isAcceptedInputFile(inputFile: File, fileType: ResourceType): boolean {
    const extension = FileUtils.getExtension(inputFile);
    return Boolean(extension && acceptableInputFileExtension[fileType].includes(extension));
  }

  public static isAcceptedInputFiles(inputFiles: FileList, fileType: ResourceType): boolean {
    return [...inputFiles].every((file: File) => FileUtils.isAcceptedInputFile(file, fileType));
  }
}
